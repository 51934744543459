<div *ngIf="!loading">
  <div class="p-grid">
    <div class="p-col-6">
      <h3>{{ 'MANUEL_SALE' | translate }}</h3>
    </div>
    <div class="p-col-6">
    </div>
  </div>

  <div class="p-grid">
    <div class="p-col-5 div-bordered" style="min-height: 580px">
      <div *ngIf="saleList.length > 0">

        <div class="p-grid grid-div">
          <table style="width: 100%;font-size: 14px;">
            <thead>
            <th [style]="{width: '40%'}">{{'NAME' | translate}}</th>
            <th [style]="{width: '10%'}">{{'PRICE' | translate}}</th>
            <th [style]="{width: '20%'}">{{'DISCOUNT' | translate}}</th>
            <th [style]="{width: '30%'}">{{'QUANTITY' | translate}}</th>
            <th [style]="{width: '20%'}">{{'TOTAL' | translate}}</th>
            <th></th>
            </thead>
            <tbody>
            <tr *ngFor="let item of saleList; index as i">
              <td>{{item.stock?.name}}/{{item.name}}</td>
              <td class="p-text-right">{{item.price | countryCurrency}}</td>
              <td class="p-text-right">{{ item.discount | countryCurrency}}</td>
              <td class="p-text-right" (click)="showKeyDisplay(item)">{{ item.quantity | number: '1.2-2'}} {{item.stock?.unit.name}}</td>
              <td class="p-text-right">{{ item.amount | countryCurrency}}</td>
              <td class="p-text-right"><i class="pi pi-trash" (click)="removeRow(i)"></i> </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <td colspan="5" style="height: 100px"></td>
            </tr>
            <tr>
              <td colspan="3" class="p-text-right">Alt Toplam:</td>
              <td class="p-text-right">{{sale[0].subTotal}}</td>
            </tr>
            <tr>
              <td colspan="3" class="p-text-right">Vergiler Toplam:</td>
              <td class="p-text-right">{{sale[0].totalTax}}</td>
            </tr>
            <tr>
              <td colspan="3" class="p-text-right">İndirmler Toplam:</td>
              <td class="p-text-right">{{sale[0].discount}}</td>
            </tr>
            <tr>
              <td colspan="3" class="p-text-right">Genel Toplam:</td>
              <td class="p-text-right">{{sale[0].grandTotal}}</td>
            </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>
    <div class="p-col-7 div-bordered">
      Kategoriler
      <div class="p-grid grid-div">
        <div class="p-col div-bordered p-justify-around {{ mainCategory === main.id ? 'active': 'passive'}}"
             (click)="selectMainCategory(main.id)" *ngFor="let main of mainCategories">
          <b>{{main.name}}</b>
        </div>
      </div>
      <div *ngIf="selectedCategories.length > 0">
        <p-divider></p-divider>
        <div class="p-grid grid-div">
          <div class="p-col div-bordered {{ subCategory === sub.id ? 'active': 'passive'}}"
               (click)="selectSubCategory(sub.id)" *ngFor="let sub of selectedCategories">
            <b >{{sub.name}}</b>
          </div>
        </div>
      </div>

      <div *ngIf="products.length > 0">
        <p-divider></p-divider>
        <div class="p-grid grid-div">
          <div class="p-col div-bordered" (click)="addItem(product)" *ngFor="let product of products">
            <b >{{product.stock?.name}}/{{product.name}}</b>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button pButton label="{{'SAVE' | translate}}" class="p-mb-3"
          [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
          [disabled]=" " (click)="save()"></button>
</div>

<p-dialog [(visible)]="keyDisplay" [style]="{width: '30vw'}">
  <div class="p-grid grid-div p-mt-4">
    <div class="p-col-4 div-bordered text_align-center">
      <b class="number">1</b>
    </div>
    <div class="p-col-4 div-bordered text_align-center">
      <b class="number">2</b>
    </div>
    <div class="p-col-4 div-bordered text_align-center">
      <b class="number">3</b>
    </div>

    <div class="p-col-4 div-bordered text_align-center">
      <b class="number">4</b>
    </div>
    <div class="p-col-4 div-bordered text_align-center">
      <b class="number">5</b>
    </div>
    <div class="p-col-4 div-bordered text_align-center">
      <b class="number">6</b>
    </div>

    <div class="p-col-4 div-bordered text_align-center">
      <b class="number">7</b>
    </div>
    <div class="p-col-4 div-bordered text_align-center">
      <b class="number">8</b>
    </div>
    <div class="p-col-4 div-bordered text_align-center">
      <b class="number">9</b>
    </div>
    <div class="p-col-4 div-bordered text_align-center">
      <b class="number">OK</b>
    </div>
    <div class="p-col-4 div-bordered text_align-center">
      <b class="number">0</b>
    </div>
    <div class="p-col-4 div-bordered text_align-center">
      <b class="number">sil</b>
    </div>
  </div>
</p-dialog>
