<app-loading-indicator [loading]="loading"></app-loading-indicator>
<div class="p-grid" style="padding: 0px" *ngIf="loading === false">
  <div class="p-sm-12 p-md-6 p-xl-6 p-lg-6" style="padding: 0px">
    <div class="p-col-12" >
      <p-card [style]="{borderRadius:'10px'}">
        <h3 class="h-text"><i class="pi pi-wallet h-icon"></i> {{'INCOME' | translate}}</h3>
        <div class="p-grid" style="padding: 0px" *ngIf="loading === false">
          <div class="p-col-6 full-div" style="padding: 0px">
            <div class="inside-div-gradient-blue">
              <a [style]="{color: 'white'}" [routerLink]="['/reports/sale-reports/daily-income']"
                 [queryParams]="{startDate: parameters.startDate, lastDate: parameters.lastDate, location: parameters.location}">
                <table class="table-section" style="width: 100%" *ngIf="categories">
                  <thead>
                  <tr>
                    <th [style]="{fontSize:'18px',float: 'left',textAlign: 'left'}"><b class="fw-500">{{'TOTAL_INCOME' | translate}}</b>
                    </th>
                    <th [style]="{width: '50%',textAlign:'right',fontSize:'22px'}">
                      {{sale?.total - sale?.taxTotal | countryCurrency}}
                    </th>
                  </tr>
                  </thead>
                  <tbody *ngFor=" let category of categories.categories">
                  <tr [style]="{borderBottom: '1px solid #ffffff'}">
                    <td [style]="{width: '50%',fontSize:'14px',borderBottom: '1px solid rgb(255 255 255 / 32%)'}"><b
                      class="fw-500">{{ category.name}}</b></td>
                    <td [style]="{width: '50%',textAlign:'right',fontSize:'16px'}"><b
                      class="fw-500"></b> {{ category.sumSales.total | countryCurrency}}</td>
                  </tr>
                  </tbody>
                </table>
              </a>

            </div>
            <div class="inside-div-no-background p-mt-3">
              <table class="table-section" style="width: 100%" *ngIf="categories">
                <thead>
                <tr>
                  <td [style]="{width: '60%',fontSize:'18px'}"><b class="fw-500">{{'TOTAL_DISCOUNT' | translate}}</b>
                  </td>
                  <td [style]="{width: '40%',textAlign:'right',fontSize:'22px'}"><b
                    class="fw-500"></b> {{sale?.discount | countryCurrency}}</td>
                </tr>
                </thead>
                <tbody *ngFor=" let category of categories.categories">
                <tr [style]="{borderBottom: '1px solid #ffffff'}">
                  <td [style]="{width: '50%',fontSize:'14px',borderBottom: '1px solid rgb(181 178 178 / 32%'}"><b
                    class="fw-500">{{ category.name}}</b></td>
                  <td [style]="{width: '50%',textAlign:'right',fontSize:'16px'}"><b
                    class="fw-500"></b> {{ category.sumSales.discountTotal | countryCurrency}}</td>
                </tr>
                </tbody>

              </table>

            </div>
          </div>
          <div class="p-col-6 full-div" style="padding: 0px">
            <div class="p-col div-sums"
                 [style]="{width: '100%', marginTop: '-10px', height: '100%',flexDirection: 'column',float: 'left', justifyContent: 'space-around'}">
              <div class="inside-div p-mt-1">
                <table class="table-section" style="width: 100%">
                  <tr>
                    <td [style]="{width: '60%',fontSize:'14px'}"><b
                      class="fw-500">{{'TOTAL_NUMBER_OF_CHECKS' | translate}}</b></td>
                    <td [style]="{width: '40%',textAlign:'right',fontSize:'20px'}"><b
                      class="fw-500"></b> {{sale?.totalCustomer}}</td>
                  </tr>
                </table>
              </div>
              <div class="inside-div p-mt-1">
                <table class="table-section" style="width: 100%">
                  <tr>
                    <td [style]="{width: '60%',fontSize:'14px'}"><b
                      class="fw-500">{{'INCOME_AVERAGE_OF_CHECKS' | translate}}</b></td>
                    <td [style]="{width: '40%',textAlign:'right',fontSize:'20px'}"><b
                      class="fw-500"></b> {{sale?.totalCustomerAverage | countryCurrency}}</td>
                  </tr>
                </table>
              </div>
              <div class="inside-div p-mt-1">
                <table class="table-section" style="width: 100%">
                  <tr>
                    <td [style]="{width: '60%',fontSize:'14px'}"><b
                      class="fw-500">{{'TOTAL_NUMBER_OF_CUSTOMER' | translate}}</b></td>
                    <td [style]="{width: '40%',textAlign:'right',fontSize:'20px'}"><b
                      class="fw-500"></b> {{sale?.totalCuver}}</td>
                  </tr>
                </table>
              </div>
              <div class="inside-div p-mt-1">
                <table class="table-section" style="width: 100%">
                  <tr>
                    <td [style]="{width: '60%',fontSize:'14px'}"><b
                      class="fw-500">{{'AVERAGE_INCOME_PER_CUSTOMER' | translate}}</b></td>
                    <td [style]="{width: '40%',textAlign:'right',fontSize:'20px'}"><b
                      class="fw-500"></b> {{sale?.totalCuverAverage | countryCurrency}}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
      </p-card>
    </div>
    <div class="p-col-12" >
      <p-card [style]="{borderRadius:'10px'}">
        <h3 class="h-text"><i class="pi pi-chart-line h-icon"></i> {{'MENU_ANALYSIS' | translate}}</h3>
        <table style="width: 100%;    border: 2px solid #efefef;">
          <tr>
            <td [style]="{width:'25%',height: '30px'}"><b>{{'TOTAL_INCOME' | translate}}</b></td>
            <td [style]="{width:'25%',height: '30px'}"><b>{{'TOTAL_COST' | translate}}</b></td>
            <td [style]="{width:'25%',height: '30px'}"><b>{{'COST_RATIO' | translate}}</b></td>
          </tr>
          <tr>
            <td
              [style]="{width:'25%',height: '30px',borderBottom: '2px solid #26bc5b'}">{{sale?.total - sale?.taxTotal | countryCurrency}}</td>
            <td
              [style]="{width:'25%',height: '30px',borderBottom: '2px solid #1E3A8A'}">{{sale?.totalCost | countryCurrency}}</td>
            <td
              [style]="{width:'25%',height: '30px',borderBottom: '2px solid #EF4444'}">{{ getProfitRatio((sale?.total - sale?.taxTotal) , sale?.totalCost)}}</td>
          </tr>
          <tr>
            <td colspan="3">
              <a [routerLink]="['/reports/sale-reports/menu-analysis']"
                 [queryParams]="{startDate: parameters.startDate, lastDate: parameters.lastDate, location: parameters.location}">
                <div class="p-col div-sums" style="padding: 0px !important; margin: 0px !important;">
                  <div class="inside-div-no-background" style="max-height: 600px; width: 100%; padding: 3px !important;position: relative;">
                    <div style="position: absolute;right: 0;top:0;z-index: 1;margin-right: 45px;margin-top: 15px">Star</div>
                    <div style="position: absolute;right: 0;bottom:0;z-index: 1;margin-right: 45px;margin-bottom: 50px">Puzzle</div>
                    <div style="position: absolute;left: 0;top:0;z-index: 1;margin-left: 45px;margin-top: 15px">PlowHourse</div>
                    <div style="position: absolute;left: 0;bottom:0;z-index: 1;margin-left: 45px;margin-bottom: 50px">Dog</div>
                    <div style="position: absolute;left: 2px;top:310px;z-index: 1;width: 75px; color: white; background: white">.</div>
                    <div style="position: absolute;right: 3px;top:310px;z-index: 1;width: 75px; color: white; background: white">.</div>
                    <canvasjs-chart [options]="chartOptions" [styles]="{width: '100%', height:'320px'}"></canvasjs-chart>
                  </div>
                </div>
              </a>
            </td>
          </tr>
        </table>
      </p-card>
    </div>
    <div class="p-col-12">
      <p-card [style]="{borderRadius:'10px'}">
        <h3 class="h-text"><i class="pi pi-credit-card h-icon"></i> {{'INCOME_EXPENSE_ANALYSIS' | translate}}</h3>
        <div class="p-grid" style="padding: 0px" *ngIf="loading === false">
          <a [style]="{color: 'white'}" [routerLink]="['/reports/income-expense-analysis']"
             [queryParams]="{startDate: parameters.startDate, lastDate: parameters.lastDate, location: parameters.location}">
            <div class="p-col-12">
              <p-table [value]="incomeExpenseAnalys" styleClass="p-datatable-sm">
                <ng-template pTemplate="header">
                  <tr style="background-color: white">
                    <th
                      [style]="{width: '13%', background: 'white !important', color: 'black', borderRight: '1px solid black'}">{{'DATE' | translate}}</th>
                    <th
                      [style]="{width: '12%', background: 'white !important', color: 'green', borderRight: '1px solid green'}">{{'NET_INCOME' | translate}}</th>
                    <th
                      [style]="{width: '12%', background: 'white !important', color: '#885f5f', borderRight: '1px solid #885f5f'}">{{'EXPENSE' | translate}}</th>
                    <th
                      [style]="{width: '12%', background: 'white !important', color: '#885f5f', borderRight: '1px solid #885f5f'}">{{'COST' | translate}}
                      %
                    </th>
                    <th
                      [style]="{width: '12%', background: 'white !important', color: '#47bfd4', borderRight: '1px solid #47bfd4'}">{{'PROFIT' | translate}}
                      /{{'LOSS' | translate}}</th>
                    <th
                      [style]="{width: '12%', background: 'white !important', color: '#47bfd4', border: 'none'}">{{'PROFIT' | translate}}
                      /{{'LOSS' | translate}}%
                    </th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-dates>
                  <tr>
                    <td >{{dates.saleDate}}</td>
                    <td [style]="{textAlign:'right'}">{{(dates.netTotal) | countryCurrency}}</td>
                    <td [style]="{textAlign:'right'}">{{(dates.totalCost) | countryCurrency}}</td>
                    <td [style]="{textAlign:'right'}">{{((dates.totalCost / dates.netTotal) * 100) | number :'1.2-2'}}
                      %
                    </td>
                    <td
                      [style]="{textAlign:'right'}">{{(dates.netTotal - dates.totalCost) | countryCurrency}}</td>
                    <td
                      [style]="{textAlign:'right'}">{{((dates.netTotal - dates.totalCost) / dates.netTotal) * 100  | number :'1.2-2'}}
                      %
                    </td>

                  </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                  <tr>
                    <td>{{'TOTAL' | translate}}</td>
                    <td
                      [style]="{textAlign:'right'}">{{(incomeExpenseAnalysTotal?.netTotal) | countryCurrency}}</td>
                    <td
                      [style]="{textAlign:'right'}">{{incomeExpenseAnalysTotal?.totalCost | countryCurrency}}</td>
                    <td
                      [style]="{textAlign:'right'}">{{(incomeExpenseAnalysTotal?.totalCost / incomeExpenseAnalysTotal?.netTotal) * 100 | number :'1.2-2'}}
                      %
                    </td>
                    <td
                      [style]="{textAlign:'right'}">{{incomeExpenseAnalysTotal?.netTotal - incomeExpenseAnalysTotal?.totalCost | countryCurrency}}</td>
                    <td
                      [style]="{textAlign:'right'}">{{((incomeExpenseAnalysTotal?.netTotal - incomeExpenseAnalysTotal?.totalCost) / sale?.netTotal) * 100 | number :'1.2-2'}}
                      %
                    </td>
                  </tr>
                </ng-template>
              </p-table>

            </div>
          </a>
        </div>
      </p-card>
    </div>
    <div class="p-col-12" >
      <p-card [style]="{borderRadius:'10px'}">
        <h3 class="h-text"><i class="pi pi-credit-card h-icon"></i> {{'ACCOUNTING' | translate}}</h3>
        <div class="p-grid" style="padding: 0px" *ngIf="loading === false">
          <div class="p-col-12">
            <p-panel header="{{ 'THIS_MOUNTH' | translate}}" [style]="{width: '100% !important'}" [toggleable]="true"
                     [collapseIcon]="'pi pi-chevron-up'" [expandIcon]="'pi pi-chevron-down'">
              <div class="row2-1">
                <div class="div1 border-left-dark-blue">
                  <div class="div1-title">{{ 'TODAY' | translate}}</div>
                  <div class="div1-number">{{incomeInvoice?.today | countryCurrency }}</div>
                </div>
                <div class="div1 border-left-blue">
                  <div class="div1-title">{{ 'YESTERDAY' | translate}}</div>
                  <div class="div1-number">{{incomeInvoice?.yesterday | countryCurrency }}</div>
                </div>
                <div class="div1 border-left-green">
                  <div class="div1-title">{{ 'LAST_WEEK' | translate}}</div>
                  <div class="div1-number">{{incomeInvoice?.lastWeek | countryCurrency }}</div>
                </div>
              </div>
              <div class="row2-2">
                <div class="div1">
                  <div class="div1-title">{{ 'CREDITS_VALUE' | translate}}</div>
                  <div class="div1-title" style="font-size: 12px;color: #CBD5E1">{{ 'VALUES' | translate}}</div>
                  <div class="div1-number">{{ returnInvoice?.valueTotal | countryCurrency }}</div>
                </div>
                <div class="div2">
                  <div class="div2-title">{{ 'CREDITS' | translate}}</div>
                  <div class="div2-title" style="font-size: 12px;color: #CBD5E1">{{ 'COUNT' | translate}}</div>
                  <div class="div2-number">{{ returnInvoice?.total }}</div>
                </div>

              </div>
            </p-panel>
          </div>
          <div class="p-col-12">
            <p-panel header="{{ 'LAST_MOUNTH' | translate}}" [style]="{width: '100%'}" [toggleable]="true"
                     [collapsed]="true" [collapseIcon]="'pi pi-chevron-up'" [expandIcon]="'pi pi-chevron-down'">

            </p-panel>
          </div>
        </div>
      </p-card>
    </div>
  </div>
  <div class="p-sm-12 p-md-6 p-xl-6 p-lg-6" style="padding: 0px">
    <div class="p-col-12">
      <p-card [style]="{borderRadius:'10px'}">
        <h3 class="h-text"><i class="pi pi-map-marker h-icon"></i> {{'LOCATION' | translate}}</h3>
        <div class="p-grid" style="padding: 0px" *ngIf="loading === false">
          <div class="p-col-5">
            <p-dropdown
              [style]="{width: '100% !important',border:'none',borderBottom: '2px solid #47bfd4'}"
              [options]="locations" appendTo="body" (onChange)="onSelectLocation($event)"
              [ngModel]="parameters.location"
              optionLabel="name" optionValue="id" placeholder="{{ 'ALL_LOCATIONS'| translate}}"
              [filter]="true" filterBy="name">
            </p-dropdown>
          </div>
          <div class="p-col-5">

            <p-calendar id="start" name="start" selectionMode="range" dateFormat="yy-mm-dd" date [showTime]="false"
                        [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}" appendTo="body"
                        [(ngModel)]="dateRange" (ngModelChange)="onSelectDate($event)"
                        placeholder="{{'SELECT_DATE'| translate}}">
            </p-calendar>
          </div>
          <div class="p-col-2">
            <p-button [style]="{height: '100%'}" type="submit" label="{{'SEARCH' | translate}}" (click)="load()"></p-button>
          </div>
        </div>
      </p-card>
    </div>
    <div class="p-col-12">
      <p-card [style]="{borderRadius:'10px'}">
        <h3 class="h-text"><i class="pi pi-shopping-cart h-icon"></i> {{'PURCHASE' | translate}}</h3>
        <div class="p-grid" style="padding: 0px" *ngIf="loading === false">
          <div class="p-col-4 full-div">
            <div class="purchase-report-div border-left-dark-blue">
              <div class="purchase-report-div-title">{{ 'PENDING_THIS_WEEK' | translate | uppercase}}</div>
              <div class="purchase-report-div-number">{{purchase?.grandTotal | countryCurrency }}</div>
            </div>
          </div>
          <div class="p-col-4 full-div">
            <a [style]="{color: '#64748B'}" routerLink="/orders/order-history">
              <div class="purchase-report-div border-left-blue">
                <div class="purchase-report-div-title">{{'WAITING_FOR_APPROVALS' | translate | uppercase}}</div>
                <div class="purchase-report-div-number">{{purchase?.waitingForApprovalCount}}</div>
                <div class="purchase-report-div-number" *ngIf="purchase?.waitingForApprovalCount > 0">{{purchase?.waitingForApproval | countryCurrency}}</div>
              </div>
            </a>
          </div>
          <div class="p-col-4 full-div">
            <a [style]="{color: '#64748B'}" routerLink="/orders/receive-order">
              <div class="purchase-report-div border-left-green">
                <div class="purchase-report-div-title">{{'TOTAL_TO_RECEIVE' | translate | uppercase}}</div>
                <div class="purchase-report-div-number">{{purchase?.isReceived}}</div>
              </div>
            </a>
          </div>
          <p-table [value]="purchase?.orders" styleClass="p-datatable-sm">
            <ng-template pTemplate="body" let-order let-i="rowIndex">
              <tr *ngIf="i < 3 && order.status== 'WAITING_FOR_APPROVAL' && order.isReceived == false">
                <td [style]="{width: '15%'}">{{ order.code}}</td>
                <td [style]="{width: '45%'}">{{ order.createdAt.date | date: 'Y-MM-dd'}}</td>
                <td [style]="{width: '10%'}">{{ order.totalUnits}}</td>
                <td [style]="{width: '20%'}">{{ order.total | countryCurrency}}</td>
                <td [style]="{width: '5%'}">
                  <a [style]="{color: '#64748B'}" routerLink="/orders/order-details/{{ order.id}}">
                    <i class="pi pi-file-pdf" [style]="{color: '#ff2525'}"></i>
                  </a>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="summary" class="text_align-center">
              <div class="img-div-result p-mb-4">
                <div>
                  <img *ngIf="purchase?.orders?.length === 0" src="assets/img/takvim-new.png" width="150px;"
                       style="margin-top:0px;margin-left:36%">
                  <br>
                </div>
              </div>
            </ng-template>
          </p-table>
        </div>
      </p-card>
    </div>
    <div class="p-col-12">
      <p-card [style]="{borderRadius:'10px'}">
        <h3 class="h-text"><i class="pi pi-send h-icon"></i> {{'STOCK_TRANSFERS' | translate}}</h3>
        <div class="p-grid" style="padding: 0px" *ngIf="loading === false">
          <div class="p-col-4 full-div">
            <div class="purchase-report-div background-gradient-dark-blue">
              <div class="purchase-report-div-title" style="color:#ffffff ">{{ 'TRANSFER_THIS_WEEK' | translate | uppercase}}</div>
              <div class="purchase-report-div-number" style="color: #ffffff">{{transfer?.grandTotal | countryCurrency }}</div>
            </div>
          </div>
          <div class="p-col-4 full-div">
            <a [style]="{color: '#ffffff'}" routerLink="/stock-transfers">
              <div class="purchase-report-div background-gradient-blue">
                <div class="purchase-report-div-title" style="color:#ffffff ">{{'WAITING_FOR_APPROVALS' | translate | uppercase}}</div>
                <div class="purchase-report-div-number" style="color: #ffffff">{{transfer?.waitingForApproval}}</div>
              </div>
            </a>
          </div>
          <div class="p-col-4 full-div">
            <div class="purchase-report-div background-gradient-green">
              <div class="purchase-report-div-title" style="color:#ffffff ">{{'TOTAL_TO_TRANSFER' | translate | uppercase}}</div>
              <div class="purchase-report-div-number" style="color: #ffffff">{{transfer?.total}}</div>
            </div>
          </div>
          <div class="p-col-12" [style]="{ padding: '10px'}">
            <p-table [value]="transfer?.transfers" styleClass="p-datatable-sm">
              <ng-template pTemplate="body" let-transfer let-i="rowIndex">
                <tr *ngIf="i < 3">
                  <td [style]="{width: '15%'}">{{ transfer.code}}</td>
                  <td [style]="{width: '35%'}">{{ transfer.createdAt.date | date: 'Y-MM-dd'}}</td>
                  <td [style]="{width: '20%'}">{{ transfer.status | translate}}</td>
                  <td [style]="{width: '20%'}">{{ transfer.amount | countryCurrency}}</td>
                  <td [style]="{width: '5%'}">
                    <i class="pi pi-file-pdf" [style]="{color: '#ff2525'}"></i>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="summary" class="text_align-center">
                <div class="img-div-result p-mb-4">
                  <div>
                    <img *ngIf="transfer?.transfers?.length === 0" src="assets/img/sleep-cat-new.png" width="150px"
                         style="margin-top:0px;margin-left:36%">
                  </div>
                </div>
              </ng-template>
            </p-table>
          </div>
        </div>
      </p-card>
    </div>
    <div class="p-col-12">
      <p-card [style]="{borderRadius:'10px'}">
        <h3 class="h-text"><i class="pi pi-trash h-icon"></i> {{'WASTE_EVENTS' | translate}}</h3>
        <div class="p-grid" style="padding: 0px" *ngIf="loading === false">
          <div class="waste-list">
            <div class="row-list border-left-dark-blue">
              <div class="div1-title">{{'YESTERDAY' | translate}}</div>
              <div class="div1-number">{{wasteEvent?.yesterday | countryCurrency}}</div>
            </div>
            <div class="row-list border-left-blue">
              <div class="div1-title">{{'TODAY' | translate}}</div>
              <div class="div1-number">{{wasteEvent?.today | countryCurrency}}</div>
            </div>
            <div class="row-list border-left-green">
              <div class="div1-title">{{'THIS_WEEK' | translate}}</div>
              <div class="div1-number">{{wasteEvent?.lastWeek | countryCurrency}}</div>
            </div>
          </div>
          <div class="report-area-content" [style]="{ padding: '10px'}">
            <p-table [value]="wasteEvent?.query" styleClass="p-datatable-sm">
              <ng-template pTemplate="body" let-order let-i="rowIndex">
                <tr *ngIf="i < 3">
                  <td [style]="{width: '15%'}">{{ order.code}}</td>
                  <td [style]="{width: '20%'}">{{ order.createdAt.date | date: 'Y-MM-dd'}}</td>
                  <td [style]="{width: '40%'}">{{ order.name}}</td>
                  <td [style]="{width: '20%'}">{{ order.value | countryCurrency}}</td>
                  <td [style]="{width: '5%'}">
                    <i class="pi pi-file-pdf" [style]="{color: '#ff2525'}"></i>
                  </td>
                </tr>
              </ng-template>
              <ng-template pTemplate="summary" class="text_align-center">
                <div class="img-div-result p-mb-4">
                  <div>
                    <img *ngIf="wasteEvent?.query?.length === 0" src="assets/img/trash-new.png" width="150px"
                         style="margin-top:0px;margin-left:37%">
                  </div>
                </div>
              </ng-template>
            </p-table>
          </div>
        </div>
      </p-card>
    </div>
  </div>
</div>

<!---
<div class="p-grid" style="padding: 0px" *ngIf="loading === false">
  <div class="p-sm-12 p-md-6 p-xl-6 p-lg-6" style="padding: 0px">
    <div class="p-col-12" >
        <div class="report-area" id="income_sums">
          <div class="report-area-title">{{'INCOME' | translate}}</div>
          <div class="report-area-content">
            <div class="p-grid div-sums">
              <div class="p-sm-12 p-md-6">
                <div class="inside-div-gradient-blue">
                  <a [style]="{color: 'white'}" [routerLink]="['/reports/sale-reports/daily-income']"
                     [queryParams]="{startDate: parameters.startDate, lastDate: parameters.lastDate, location: parameters.location}">
                    <table class="table-section" *ngIf="categories">
                      <thead>
                      <tr>
                        <th [style]="{fontSize:'18px',float: 'left'}"><b class="fw-500">{{'TOTAL_INCOME' | translate}}</b>
                        </th>
                        <th [style]="{width: '50%',textAlign:'right',fontSize:'22px'}">
                          {{sale?.subTotal | countryCurrency}}
                        </th>
                      </tr>
                      </thead>
                      <tbody *ngFor=" let category of categories.categories">
                      <tr [style]="{borderBottom: '1px solid #ffffff'}">
                        <td [style]="{width: '50%',fontSize:'14px',borderBottom: '1px solid rgb(255 255 255 / 32%'}"><b
                          class="fw-500">{{ category.name}}</b></td>
                        <td [style]="{width: '50%',textAlign:'right',fontSize:'16px'}"><b
                          class="fw-500"></b> {{ category.amountByName.total | countryCurrency}}</td>
                      </tr>
                      </tbody>
                    </table>
                  </a>

                </div>
                <div class="inside-div-no-background p-mt-3">
                  <table class="table-section" *ngIf="categories">
                    <thead>
                    <tr>
                      <td [style]="{width: '60%',fontSize:'18px'}"><b class="fw-500">{{'TOTAL_DISCOUNT' | translate}}</b>
                      </td>
                      <td [style]="{width: '40%',textAlign:'right',fontSize:'22px'}"><b
                        class="fw-500"></b> {{sale?.discount | countryCurrency}}</td>
                    </tr>
                    </thead>
                    <tbody *ngFor=" let category of categories.categories">
                    <tr [style]="{borderBottom: '1px solid #ffffff'}">
                      <td [style]="{width: '50%',fontSize:'14px',borderBottom: '1px solid rgb(181 178 178 / 32%'}"><b
                        class="fw-500">{{ category.name}}</b></td>
                      <td [style]="{width: '50%',textAlign:'right',fontSize:'16px'}"><b
                        class="fw-500"></b> {{ category.amountByName.discount | countryCurrency}}</td>
                    </tr>
                    </tbody>

                  </table>

                </div>
              </div>
              <div class="p-sm-12 p-md-6">
                <div class="p-col div-sums"
                     [style]="{width: '100%', marginTop: '-10px', height: '100%',flexDirection: 'column',float: 'left', justifyContent: 'space-around'}">
                  <div class="inside-div p-mt-1">
                    <table class="table-section">
                      <tr>
                        <td [style]="{width: '60%',fontSize:'14px'}"><b
                          class="fw-500">{{'TOTAL_NUMBER_OF_CHECKS' | translate}}</b></td>
                        <td [style]="{width: '40%',textAlign:'right',fontSize:'20px'}"><b
                          class="fw-500"></b> {{sale?.totalCheck}}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="inside-div p-mt-1">
                    <table class="table-section">
                      <tr>
                        <td [style]="{width: '60%',fontSize:'14px'}"><b
                          class="fw-500">{{'INCOME_AVERAGE_OF_CHECKS' | translate}}</b></td>
                        <td [style]="{width: '40%',textAlign:'right',fontSize:'20px'}"><b
                          class="fw-500"></b> {{sale?.totalAverage | countryCurrency}}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="inside-div p-mt-1">
                    <table class="table-section">
                      <tr>
                        <td [style]="{width: '60%',fontSize:'14px'}"><b
                          class="fw-500">{{'TOTAL_NUMBER_OF_CUSTOMER' | translate}}</b></td>
                        <td [style]="{width: '40%',textAlign:'right',fontSize:'20px'}"><b
                          class="fw-500"></b> {{sale?.totalCustomer}}</td>
                      </tr>
                    </table>
                  </div>
                  <div class="inside-div p-mt-1">
                    <table class="table-section">
                      <tr>
                        <td [style]="{width: '60%',fontSize:'14px'}"><b
                          class="fw-500">{{'AVERAGE_INCOME_PER_CUSTOMER' | translate}}</b></td>
                        <td [style]="{width: '40%',textAlign:'right',fontSize:'20px'}"><b
                          class="fw-500"></b> {{sale?.totalCustomerAverage | countryCurrency}}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>

            </div>

          </div>
        </div>
    </div>
    <div class="p-col-12">
      <div class="report-area" id="menu_analysis">
        <div class="report-area-title">{{'MENU_ANALYSIS' | translate}}</div>
        <div class="report-area-content">
          <div class="p-col div-sums tablem">
            <table class="">
              <tr>
                <td [style]="{width:'25%',height: '30px'}"><b>{{'TOTAL_INCOME' | translate}}</b></td>
                <td [style]="{width:'25%',height: '30px'}"><b>{{'TOTAL_COST' | translate}}</b></td>
                <td [style]="{width:'25%',height: '30px'}"><b>{{'COST_RATIO' | translate}}</b></td>
              </tr>
              <tr>
                <td
                  [style]="{width:'25%',height: '30px',borderBottom: '2px solid #26bc5b'}">{{sale?.subTotal | countryCurrency}}</td>
                <td
                  [style]="{width:'25%',height: '30px',borderBottom: '2px solid #1E3A8A'}">{{menuAnalys?.totalCost | countryCurrency}}</td>
                <td
                  [style]="{width:'25%',height: '30px',borderBottom: '2px solid #EF4444'}">{{ getProfitRatio(sale?.subTotal, menuAnalys?.totalCost)}}</td>
              </tr>
              <tr>
                <td colspan="3">
                  <a [routerLink]="['/reports/sale-reports/menu-analysis']"
                     [queryParams]="{startDate: parameters.startDate, lastDate: parameters.lastDate, location: parameters.location}">
                    <div class="p-col div-sums" style="padding: 0px !important; margin: 0px !important;">
                      <div class="inside-div-no-background" style="max-height: 600px; width: 100%; padding: 3px !important;position: relative;">
                        <div style="position: absolute;right: 0;top:0;z-index: 1;margin-right: 45px;margin-top: 15px">Star</div>
                        <div style="position: absolute;right: 0;bottom:0;z-index: 1;margin-right: 45px;margin-bottom: 50px">Puzzle</div>
                        <div style="position: absolute;left: 0;top:0;z-index: 1;margin-left: 45px;margin-top: 15px">PlowHourse</div>
                        <div style="position: absolute;left: 0;bottom:0;z-index: 1;margin-left: 45px;margin-bottom: 50px">Dog</div>
                        <div style="position: absolute;left: 2px;top:310px;z-index: 1;width: 75px; color: white; background: white">.</div>
                        <div style="position: absolute;right: 3px;top:310px;z-index: 1;width: 75px; color: white; background: white">.</div>
                        <canvasjs-chart [options]="chartOptions" [styles]="{width: '100%', height:'320px'}"></canvasjs-chart>
                      </div>
                    </div>
                  </a>
                </td>
              </tr>
            </table>

          </div>
        </div>
      </div>
    </div>
    <div class="p-col-12">
      <div class="report-area">
        <div class="report-area-title">{{'ACCOUNTING' | translate}}</div>
        <div class="report-area-content">
          <div class="accounting">
            <p-panel header="{{ 'THIS_MOUNTH' | translate}}" [style]="{width: '100% !important'}" [toggleable]="true"
                     [collapseIcon]="'pi pi-chevron-up'" [expandIcon]="'pi pi-chevron-down'">
              <div class="row2-1">
                <div class="div1 border-left-dark-blue">
                  <div class="div1-title">{{ 'TODAY' | translate}}</div>
                  <div class="div1-number">{{incomeInvoice?.today | countryCurrency }}</div>
                </div>
                <div class="div1 border-left-blue">
                  <div class="div1-title">{{ 'YESTERDAY' | translate}}</div>
                  <div class="div1-number">{{incomeInvoice?.yesterday | countryCurrency }}</div>
                </div>
                <div class="div1 border-left-green">
                  <div class="div1-title">{{ 'LAST_WEEK' | translate}}</div>
                  <div class="div1-number">{{incomeInvoice?.lastWeek | countryCurrency }}</div>
                </div>
              </div>
              <div class="row2-2">
                <div class="div1">
                  <div class="div1-title">{{ 'CREDITS_VALUE' | translate}}</div>
                  <div class="div1-title" style="font-size: 12px;color: #CBD5E1">{{ 'VALUES' | translate}}</div>
                  <div class="div1-number">{{ returnInvoice?.valueTotal | countryCurrency }}</div>
                </div>
                <div class="div2">
                  <div class="div2-title">{{ 'CREDITS' | translate}}</div>
                  <div class="div2-title" style="font-size: 12px;color: #CBD5E1">{{ 'COUNT' | translate}}</div>
                  <div class="div2-number">{{ returnInvoice?.total }}</div>
                </div>

              </div>
            </p-panel>
            <p-panel header="{{ 'LAST_MOUNTH' | translate}}" [style]="{width: '100%'}" [toggleable]="true"
                     [collapsed]="true" [collapseIcon]="'pi pi-chevron-up'" [expandIcon]="'pi pi-chevron-down'">

            </p-panel>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="p-sm-12 p-md-6 p-xl-6 p-lg-6">
    <div class="p-col-12">
      <div class="report-area" *ngIf="location.length > 1">
        <div class="report-area-title">{{'LOCATION' | translate}}</div>
        <div class="p-grid report-area-content">
          <div class="p-col-5">
            <p-dropdown
              [style]="{width: '100% !important',border:'none',borderBottom: '2px solid #47bfd4'}"
              [options]="location" appendTo="body" (onChange)="onSelectLocation($event)"
              [ngModel]="parameters.location"
              optionLabel="name" optionValue="id" placeholder="{{ 'SELECT_LOCATION'| translate}}"
              [filter]="true" filterBy="name">
            </p-dropdown>
          </div>
          <div class="p-col-5">

            <p-calendar id="start" name="start" selectionMode="range" dateFormat="yy-mm-dd" date [showTime]="false"
                        [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}" appendTo="body"
                        [(ngModel)]="dateRange" (ngModelChange)="onSelectDate($event)"
                        placeholder="{{'SELECT_DATE'| translate}}">
            </p-calendar>
          </div>
          <div class="p-col-2">
            <p-button [style]="{height: '100%'}" type="submit" label="{{'SEARCH' | translate}}" (click)="load()"></p-button>
          </div>
        </div>
      </div>
    </div>
    <div class="p-col-12">
      <div class="report-area">
        <div class="report-area-title">{{'PURCHASE' | translate}}</div>
        <div class="report-area-content">
          <div class="p-col-4">
            <div class="div1 border-left-dark-blue">
              <div class="div1-title">{{ 'PENDING_THIS_WEEK' | translate | uppercase}}</div>
              <div class="div1-number">{{purchase?.grandTotal | countryCurrency }}</div>
            </div>
          </div>
          <div class="p-col-4">
            <a [style]="{color: '#64748B'}" routerLink="/orders/order-history">
              <div class="div1 border-left-blue">
                <div class="div1-title">{{'WAITING_FOR_APPROVALS' | translate | uppercase}}</div>
                <div class="div1-number">{{purchase?.waitingForApprovalCount}}</div>
                <div class="div1-number" *ngIf="purchase?.waitingForApprovalCount > 0">{{purchase?.waitingForApproval | countryCurrency}}</div>
              </div>
            </a>
          </div>
          <div class="p-col-4">
            <a [style]="{color: '#64748B'}" routerLink="/orders/receive-order">
              <div class="div1 border-left-green">
                <div class="div1-title">{{'TOTAL_TO_RECEIVE' | translate | uppercase}}</div>
                <div class="div1-number">{{purchase?.isReceived}}</div>
              </div>
            </a>
          </div>
        </div>
        <div class="report-area-content" [style]="{ padding: '10px'}">
          <p-table [value]="purchase?.orders" styleClass="p-datatable-sm">
            <ng-template pTemplate="body" let-order let-i="rowIndex">
              <tr *ngIf="i < 3 && order.status== 'WAITING_FOR_APPROVAL' && order.isReceived == false">
                <td [style]="{width: '15%'}">{{ order.code}}</td>
                <td [style]="{width: '45%'}">{{ order.createdAt.date | date: 'Y-MM-dd'}}</td>
                <td [style]="{width: '10%'}">{{ order.totalUnits}}</td>
                <td [style]="{width: '20%'}">{{ order.total | countryCurrency}}</td>
                <td [style]="{width: '5%'}">
                  <a [style]="{color: '#64748B'}" routerLink="/orders/order-details/{{ order.id}}">
                    <i class="pi pi-file-pdf" [style]="{color: '#ff2525'}"></i>
                  </a>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="summary" class="text_align-center">
              <div class="img-div-result p-mb-4">
                <div>
                  <img *ngIf="purchase?.orders?.length === 0" src="assets/img/sleeping-cat.svg" width="80px"
                       style="margin-top:0px">
                  <br>
                  {{purchase?.orders?.length != 0 ? '' : 'NO_RECORDS' | translate }}
                </div>
              </div>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
    <div class="p-col-12">
      <div class="report-area">
        <div class="report-area-title">{{'STOCK_TRANSFERS' | translate}}</div>
        <div class="report-area-content">
          <div class="p-col-4">
            <div class="div1 background-gradient-dark-blue">
              <div class="div1-title">{{ 'TRANSFER_THIS_WEEK' | translate | uppercase}}</div>
              <div class="div1-number">{{transfer?.grandTotal | countryCurrency }}</div>
            </div>
          </div>
          <div class="p-col-4">
            <a [style]="{color: '#ffffff'}" routerLink="/stock-transfers">
              <div class="div1 background-gradient-blue">
                <div class="div1-title">{{'WAITING_FOR_APPROVALS' | translate | uppercase}}</div>
                <div class="div1-number">{{transfer?.waitingForApproval}}</div>
              </div>
            </a>
          </div>
          <div class="p-col-4">
            <div class="div1 background-gradient-green">
              <div class="div1-title">{{'TOTAL_TO_TRANSFER' | translate | uppercase}}</div>
              <div class="div1-number">{{transfer?.total}}</div>
            </div>
          </div>
        </div>
        <div class="report-area-content" [style]="{ padding: '10px'}">
          <p-table [value]="transfer?.transfers" styleClass="p-datatable-sm">
            <ng-template pTemplate="body" let-transfer let-i="rowIndex">
              <tr *ngIf="i < 3">
                <td [style]="{width: '15%'}">{{ transfer.code}}</td>
                <td [style]="{width: '35%'}">{{ transfer.createdAt.date | date: 'Y-MM-dd'}}</td>
                <td [style]="{width: '20%'}">{{ transfer.status | translate}}</td>
                <td [style]="{width: '20%'}">{{ transfer.amount | countryCurrency}}</td>
                <td [style]="{width: '5%'}">
                  <i class="pi pi-file-pdf" [style]="{color: '#ff2525'}"></i>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="summary" class="text_align-center">
              <div class="img-div-result p-mb-4">
                <div>
                  <img *ngIf="transfer?.transfers?.length === 0" src="assets/img/sleeping-cat.svg" width="80px"
                       style="margin-top:0px">
                  <br>
                  {{transfer?.transfers?.length != 0 ? '' : 'NO_RECORDS' | translate }}
                </div>
              </div>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
    <div class="p-col-12">
      <div class="report-area">
        <div class="report-area-title">{{'WASTE_EVENTS' | translate}}</div>
        <div class="report-area-content" [style]="{padding :'10px'}">
          <div class="waste-list">
            <div class="row-list border-left-dark-blue">
              <div class="div1-title">{{'YESTERDAY' | translate}}</div>
              <div class="div1-number">{{wasteEvent?.yesterday | countryCurrency}}</div>
            </div>
            <div class="row-list border-left-blue">
              <div class="div1-title">{{'TODAY' | translate}}</div>
              <div class="div1-number">{{wasteEvent?.today | countryCurrency}}</div>
            </div>
            <div class="row-list border-left-green">
              <div class="div1-title">{{'THIS_WEEK' | translate}}</div>
              <div class="div1-number">{{wasteEvent?.lastWeek | countryCurrency}}</div>
            </div>
          </div>
        </div>
        <div class="report-area-content" [style]="{ padding: '10px'}">
          <p-table [value]="wasteEvent?.query" styleClass="p-datatable-sm">
            <ng-template pTemplate="body" let-order let-i="rowIndex">
              <tr *ngIf="i < 3">
                <td [style]="{width: '15%'}">{{ order.code}}</td>
                <td [style]="{width: '20%'}">{{ order.createdAt.date | date: 'Y-MM-dd'}}</td>
                <td [style]="{width: '40%'}">{{ order.name}}</td>
                <td [style]="{width: '20%'}">{{ order.value | countryCurrency}}</td>
                <td [style]="{width: '5%'}">
                  <i class="pi pi-file-pdf" [style]="{color: '#ff2525'}"></i>
                </td>
              </tr>
            </ng-template>
            <ng-template pTemplate="summary" class="text_align-center">
              <div class="img-div-result p-mb-4">
                <div>
                  <img *ngIf="wasteEvent?.query?.length === 0" src="assets/img/waste-events.jpeg" width="120px"
                       style="margin-top:0px">
                  <br>
                  {{wasteEvent?.query?.length != 0 ? '' : 'NO_RECORDS' | translate }}
                </div>
              </div>
            </ng-template>
          </p-table>
        </div>
      </div>
    </div>
  </div>
</div>
--->
<app-assistant [page]="'DASHBOARD'" [style]="{float: 'left',marginLeft: '45px'}"></app-assistant>
