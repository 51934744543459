<app-loading-indicator [loading]="loading"></app-loading-indicator>

<p-card *ngIf="!loading" id="pdf-content">

  <div class="p-grid" style="margin: -1.5rem 0;">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{'INCOME_EXPENSE_ANALYSIS' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'daily-income'"></app-page-info>
      <p-button icon="pi pi-file-excel" [style]="{marginLeft: '5px', float:'right'}"></p-button>
      <p-button icon="pi pi-file-pdf" [style]="{marginLeft: '5px', float:'right'}"></p-button>
    </div>
  </div>
  <div class="p-grid" id="button">
    <div class="p-col-12 p-md-6 p-lg-3">
      <p-dropdown
        [style]="{width: '100% !important',border:'none',borderBottom: '2px solid #47bfd4'}"
        [options]="locations" appendTo="body" (onChange)="onSelectLocation($event)"
        [ngModel]="parameters.location"
        optionLabel="name" optionValue="id" placeholder="{{ 'SELECT_LOCATION'| translate}}"
        [filter]="true" filterBy="name">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <p-calendar id="start" name="start"  selectionMode="range" dateFormat="yy-mm-dd" date [showTime]="false"
                  [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}" appendTo="body"
                  [(ngModel)]="dateRange" (ngModelChange)="onSelectDate($event)"
                  placeholder="{{'SELECT_DATE'| translate}}">
      </p-calendar>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-button [style]="{height: '100%'}" type="submit" (click)="load()" label="{{'SEARCH' | translate}}"></p-button>
    </div>
  </div>
  <div >
    <div class="p-grid">
      <div class="p-col-12">
        <div class="p-col div-sums" style="padding: 0px !important">
          <div>
            <div class="inside-div-no-background" style="overflow: auto;max-height: 600px; padding: 3px !important">
              <p-table [value]="saleDates" styleClass="p-datatable-sm">
                <ng-template pTemplate="header">
                  <tr>
                    <th [style]="{width: '13%'}" pSortableColumn="saleDate">
                      <p-sortIcon field="saleDate"></p-sortIcon>{{'DATE' | translate}}</th>
                    <th [style]="{width: '12%'}" pSortableColumn="netTotal">
                      <p-sortIcon field="netTotal"></p-sortIcon>{{'NET_INCOME' | translate}}</th>
                    <th [style]="{width: '12%'}" pSortableColumn="totalCost">
                      <p-sortIcon field="totalCost"></p-sortIcon>{{'EXPENSE' | translate}}</th>
                    <th [style]="{width: '12%'}" pSortableColumn="costRate">
                      <p-sortIcon field="costRate"></p-sortIcon>{{'COST' | translate}} %</th>
                    <th [style]="{width: '12%'}" pSortableColumn="profit">
                      <p-sortIcon field="profit"></p-sortIcon>{{'PROFIT' | translate}}/{{'LOSS' | translate}}</th>
                    <th [style]="{width: '12%'}" pSortableColumn="profitRate">
                      <p-sortIcon field="profitRate"></p-sortIcon>{{'PROFIT' | translate}}/{{'LOSS' | translate}} %</th>
                    <th [style]="{width: '12%'}" pSortableColumn="cuver">
                      <p-sortIcon field="cuver"></p-sortIcon>{{'TOTAL_NUMBER_OF_CUSTOMER' | translate}}</th>
                    <th [style]="{width: '12%'}" pSortableColumn="cuverNet">
                      <p-sortIcon field="cuverNet"></p-sortIcon>{{'AVERAGE_INCOME_PER_CUSTOMER' | translate}}</th>
                    <th [style]="{width: '12%'}" pSortableColumn="cuverCost">
                      <p-sortIcon field="cuverCost"></p-sortIcon>{{'AVERAGE_EXPENSE_PER_CUSTOMER' | translate}}</th>
                  </tr>
                </ng-template>
                <ng-template pTemplate="body" let-dates>
                  <tr >
                    <td>{{dates.saleDate}}</td>
                    <td [style]="{textAlign:'right'}">{{(dates.netTotal) | countryCurrency}}</td>
                    <td [style]="{textAlign:'right'}">{{(dates.totalCost) | countryCurrency}}</td>
                    <td [style]="{textAlign:'right'}">{{(dates.costRate) | number :'1.2-2'}}%</td>
                    <td [style]="{textAlign:'right'}">{{(dates.profit) | countryCurrency}}</td>
                    <td [style]="{textAlign:'right'}">{{(dates.profitRate)  | number :'1.2-2'}}%</td>
                    <td [style]="{textAlign:'right'}">{{(dates.cuver)}}</td>
                    <td [style]="{textAlign:'right'}">{{(dates.cuverNet)  | countryCurrency}}</td>
                    <td [style]="{textAlign:'right'}">{{(dates.cuverCost) | countryCurrency}}</td>

                  </tr>
                </ng-template>
                <ng-template pTemplate="footer">
                  <tr >
                    <td>{{'TOTAL' | translate}}</td>
                    <td [style]="{textAlign:'right'}">{{(sale?.netTotal) | countryCurrency}}</td>
                    <td [style]="{textAlign:'right'}">{{sale?.totalCost | countryCurrency}}</td>
                    <td [style]="{textAlign:'right'}">{{(sale?.totalCost / sale?.netTotal)*100 | number :'1.2-2'}}%</td>
                    <td [style]="{textAlign:'right'}">{{sale?.netTotal - sale?.totalCost | countryCurrency}}</td>
                    <td [style]="{textAlign:'right'}">{{((sale?.netTotal - sale?.totalCost) / sale?.netTotal) * 100 | number :'1.2-2'}}%</td>
                    <td [style]="{textAlign:'right'}">{{(sale?.totalCuver)}}</td>
                    <td [style]="{textAlign:'right'}">{{(sale?.netTotal / sale?.totalCuver) | countryCurrency}}</td>
                    <td [style]="{textAlign:'right'}">{{(sale?.totalCost / sale?.totalCuver)| countryCurrency}}</td>
                  </tr>
                </ng-template>
              </p-table>
            </div>
          </div>
        </div>
      </div>
      <div class="p-col-12">
        <p-chart type="bar" [data]="data" [options]="chartOptions" height="400px"></p-chart>
      </div>
    </div>
  </div>
</p-card>
