<div class="p-grid">
  <div class="p-col-10 p-offset-1">
    <app-loading-indicator [loading]="loading"></app-loading-indicator>
    <form action="" *ngIf="!loading && !paymentForm" (submit)="send()">
      <p-card class="p-mt-6">
        <div class="p-p-3 p-text-center">
          <img [src]="'/assets/img/logo-qapera.svg'" alt="" height="75">
        </div>
        <h1 class="p-text-center">{{ 'PAYMENT_TITLE'|translate }}</h1>
        <p class="p-text-center">{{ 'PAYMENT_TITLE_DESCRIPTION'|translate }}</p>
        <p>{{ selectedPrice * (locationCount + locationLength) > 1 ? selectedPrice * (locationCount + locationLength) : '' | countryCurrency}}</p>
        <div class="p-grid">
          <div class="p-col-8">
            <h3>Paket Bilgileri</h3>
            <p-table styleClass="p-datatable-striped">
              <ng-template pTemplate="header">
                <tr>
                  <th [style]="{width:'30%'}">Paket</th>
                  <th [style]="{width:'20%'}">Periot</th>
                  <th [style]="{width:'20%'}">Lokasyon</th>
                  <th [style]="{width:'20%'}">Fiyat</th>
                  <th [style]="{width:'20%'}">Toplam</th>
                </tr>
                <tr>
                  <td>{{ selectedPlain}} Paket</td>
                  <td>{{ selectedPeriod}}</td>
                  <td class="p-text-right">{{ locationCount}} Adet</td>
                  <td class="p-text-right">{{selectedPrice | countryCurrency}}</td>
                  <td class="p-text-right">{{ total  | countryCurrency}}</td>

                </tr>
              </ng-template>
              <ng-template pTemplate="footer">
                <tr>
                  <td colspan="5" [style]="{height:'50px'}"></td>
                </tr>
                <tr>
                  <td colspan="2"></td>
                  <td colspan="2">Alt Toplam</td>
                  <td class="p-text-right">{{ subTotal | countryCurrency}}</td>
                </tr>
                <tr>
                  <td colspan="2"></td>
                  <td colspan="2">Vergi %20</td>
                  <td class="p-text-right">{{ tax  | countryCurrency}}</td>
                </tr>
                <tr>
                  <td colspan="2"></td>
                  <td colspan="2">Genel Toplam</td>
                  <td class="p-text-right">{{ total  | countryCurrency}}</td>
                </tr>
              </ng-template>

            </p-table>
            </div>
          <div class="p-col-4 paymnet-area">
            <h3>Kart Bilgileri</h3>
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col">
                <label for="cardHolderName">{{'CARD_HOLDER_NAME' | translate}}</label>
                <input id="cardHolderName" type="text" pInputText [(ngModel)]="card.cardHolderName" name="cardHolderName">
              </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col">
                <label for="cardNumber">{{'CARD_NUMBER' | translate}}</label>
                <p-inputMask id="cardNumber" mask="9999 9999 9999 9999" type="text"  [(ngModel)]="card.cardNumber" name="cardNumber"></p-inputMask>
              </div>
            </div>
            <div class="p-fluid p-formgrid p-grid">
              <div class="p-field p-col">
                <label for="expireMonth">{{'EXPIRY_MONTH' | translate}}</label>
                <p-inputMask id="expireMonth" mask="99" type="text" [(ngModel)]="card.expireMonth" name="expireMonth"></p-inputMask>
              </div>
              <div class="p-field p-col">
                <label for="expireYear">{{'EXPIRY_YEAR' | translate}}</label>
                <p-inputMask id="expireYear" type="text" mask="99" [(ngModel)]="card.expireYear" name="expireYear"></p-inputMask>
              </div>
              <div class="p-field p-col">
                <label for="cvc">{{'CVC' | translate}}</label>
                <p-inputMask id="cvc" type="text" mask="999" [(ngModel)]="card.cvc" name="cvc"></p-inputMask>
              </div>
            </div>
            <p-button [icon]="submitted ? 'pi pi-spin pi-spinner': 'pi pi-check'" [style]="{width:'100%'}"
                      type="submit" label="{{ payTotal | countryCurrency }} {{'PAY' | translate}}">
            </p-button>
          </div>

        </div>
      </p-card>
    </form>
    <div  [innerHTML]="paymentForm"></div>
    <div id="iyzipay-checkout-form" class="responsive"></div>
  </div>
</div>
