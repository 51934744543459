<p-card>
  <app-loading-indicator [loading]="loading"></app-loading-indicator>
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'SUPPLIER_PRODUCT' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'SUPPLIER_PRODUCT'"></app-page-info>
      <p-button icon="pi pi-arrow-down" [style]="{marginLeft: '5px', float:'right'}"></p-button>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-2">

    </div>
    <div class="p-col-12 p-md-6 p-lg-2">

    </div>
    <div class="p-col-12 p-md-6 p-lg-2">

    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12" style="overflow-x: auto;">
      <form (ngSubmit)="onLoadStock()">
        <button pButton type="submit" icon="pi pi-search" label="{{'search' | translate}}"
                class="p-d-none p-button-success p-mr-2 p-mb-4"></button>
        <p-table styleClass="p-datatable-striped" [value]="stocks" styleClass="p-datatable-striped" [lazy]="true"
                 responsiveLayout="scroll" dataKey="id"[totalRecords]="totalRecords" [loading]="loading">
          <ng-template pTemplate="header">
            <tr>
              <th [style]="{width: '10%'}" pSortableColumn="variants.code">Stok Kodu <p-sortIcon field="variants.code"></p-sortIcon></th>
              <th [style]="{width: '20%'}" pSortableColumn="variants.name">Stok Adı <p-sortIcon field="variants.name"></p-sortIcon></th>
              <th [style]="{width: '30%'}" pSortableColumn="supplier.name">Tedarikçi <p-sortIcon field="supplier.name"></p-sortIcon></th>
              <th [style]="{width: '15%'}" pSortableColumn="category.name">Kategori <p-sortIcon field="category.name"></p-sortIcon></th>
              <th [style]="{width: '10%'}" pSortableColumn="variants.price">Fiyat <p-sortIcon field="variants.price"></p-sortIcon></th>
              <th [style]="{width: '10%'}">Vergi Oranı</th>
              <th [style]="{width: '10%'}">Vergi Değeri</th>
            <tr>
              <td><input type="text" pInputText  [(ngModel)]="parameters['variants.code']" name="variantsCode"
                          style="box-shadow: 0px 4px 6px -1px rgb(0 0 0 / 10%), 0px 2px 4px -2px rgb(0 0 0 / 10%);
                          border-radius: 4px;
                          border: none;" ></td>
              <td><input type="text" pInputText [(ngModel)]="parameters['variants.name']" name="variantsName"
                         style="box-shadow: 0px 4px 6px -1px rgb(0 0 0 / 10%), 0px 2px 4px -2px rgb(0 0 0 / 10%);
                          border-radius: 4px;
                          border: none;">
              </td>
              <td>
                <p-dropdown appendTo="body" [options]="contacts" [(ngModel)]="parameters['supplier.id']" (onChange)="changeContact($event)" optionLabel="name" optionValue="id" [filter]="true" filterBy="name"></p-dropdown>
              </td>
              <td><input type="text" pInputText [(ngModel)]="parameters['category.name']" name="contactName"
                         style="box-shadow: 0px 4px 6px -1px rgb(0 0 0 / 10%), 0px 2px 4px -2px rgb(0 0 0 / 10%);
                          border-radius: 4px;
                          border: none;" >
              </td>
              <td><input type="text" pInputText [(ngModel)]="parameters['variants.price']" name="variantsPrice"
                         style="box-shadow: 0px 4px 6px -1px rgb(0 0 0 / 10%), 0px 2px 4px -2px rgb(0 0 0 / 10%);
                          border-radius: 4px;
                          border: none;" >
              </td>
              <td></td>
              <td></td>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-row>
            <tr>
              <td>{{ row.variants[0].code}}</td>
              <td>{{ row.variants[0].name}}</td>
              <td>{{ row.supplier.name}}</td>
              <td>{{ row.category.name}}</td>
              <td class="p-text-right">{{ row.variants[0].price | countryCurrency}}</td>
              <td class="p-text-right"> % {{ row.tax.name}}</td>
              <td class="p-text-right">{{ (row.variants[0].price * row.tax.rate / 100) | countryCurrency}}</td>
            </tr>

          </ng-template>
          <ng-template pTemplate="footer" *ngIf="noData == true" >
            <tr >
              <td colspan="7">Sonuç Bulumamadı</td>
            </tr>
          </ng-template>
        </p-table>
      </form>
      <div class="p-grid" style="padding-top: 20px" *ngIf="moreLoading">
        <div class="p-col-4 p-offset-4" style="">
          <button pButton label="{{ 'MORE' | translate}}" class="p-ml-3 p-mb-3" [style]="{width:'100%'}"
                  [icon]="'pi pi-arrow-down'"
                  (click)="onLoadStock()"></button>
        </div>
      </div>

    </div>
  </div>



</p-card>

