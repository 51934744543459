<app-loading-indicator [loading]="loading"></app-loading-indicator>
<div class="new-order-main">
  <div class="row1">
    <p-card class="">
    <div class="div-header">
      <div class="search-div">
        <div class="search-div-content">
          <span class="p-input-icon-left">
              <i class="pi pi-search"></i>
              <input type="text" [style]="{width:'115%'}" pInputText placeholder="{{ 'GLOBAL_SEARCH'| translate }}"  pInputText [(ngModel)]="parameters.name" (change)="changeNameInput($event)" />
          </span>
        </div>
      </div>
      <div class="search-div">
        <div class="search-div-content">
            <p-dropdown [style]="{width: '100%'}" [options]="searchContacts" [(ngModel)]="parameters['supplier.id']" (onChange)="changeContact($event)"
                        optionLabel="name" optionValue="id"[filter]="true" filterBy="name">
            </p-dropdown>
          </div>
      </div>
      <div class="search-div">
          <div class="search-div-content">
            <p-dropdown [style]="{width: '100%'}" [options]="categories" [(ngModel)]="parameters['category.id']"
                        (onChange)="changeCategory($event)" optionLabel="name" optionValue="id" [filter]="true" filterBy="name">
            </p-dropdown>
          </div>
        </div>
      <div class="search-div">
          <div class="search-div-content">
            <div class="right-div">
              <p-splitButton label="{{ 'ACTIONS' | translate}}" [model]="this.actionItems" styleClass="p-button-outlined mr-2 mb-2"></p-splitButton>
              <p-button icon="pi pi-question" [style]="{marginLeft: '5px'}"></p-button>
            </div>

          </div>
        </div>
    </div>
    <div class="div-header">
      <div class="search-div">
        <div class="search-div-content">
            <p-dropdown [style]="{width: '100%'}" [options]="searchLocations" [(ngModel)]="parameters['location.id']" (onChange)="changeContact($event)"
                        optionLabel="name" optionValue="id"
                        [filter]="true" filterBy="name"></p-dropdown>
          </div>
      </div>
    </div>
    <div class="content"><form (ngSubmit)="onLoadStock()">
      <button pButton type="submit" icon="pi pi-search" label="{{'search' | translate}}" class="p-d-none p-button-success p-mr-2 p-mb-4"></button>
    </form>
      <p-table #table styleClass="p-datatable-striped"
               [value]="stocks" [lazy]="true" responsiveLayout="scroll" dataKey="id"
               [paginator]="true" [rows]="10" [totalRecords]="totalRecords" [loading]="loading">
        <ng-template pTemplate="header">
          <tr>
            <th [style]="{width: '50px'}"></th>
            <th  pSortableColumn="variants.name">{{ 'STOCK'| translate }}
              <p-sortIcon field="variants.name"></p-sortIcon>
            </th>
            <th  pSortableColumn="category.name">{{ 'UNIT_OF_ORDERING'| translate }}
              <p-sortIcon field="category.name"></p-sortIcon>
            </th>
            <th pSortableColumn="variants.price">{{ 'PRICE'| translate }}
              <p-sortIcon field="variants.price"></p-sortIcon>
            </th>
            <th  pSortableColumn="variants.price">{{ 'ON_HAND_PAR'| translate }}
              <p-sortIcon field="variants.price"></p-sortIcon>
            </th>
            <th pSortableColumn="variants.price">{{ 'QUANTITY'| translate }}
              <p-sortIcon field="variants.price"></p-sortIcon>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row let-i="rowIndex" let-expanded="expanded">
          <tr [style]="{background : expanded ? '#cdcdcd' : ''}">
            <td>
              <button *ngIf="row.purchaseOptions?.length > 0" type="button" pButton pRipple [pRowToggler]="row"
                      class="p-button-text p-button-rounded p-button-plain"
                      [icon]="expanded ? 'pi pi-minus' : 'pi pi-plus'"></button>
            </td>
            <td>{{ row.variants[0].name}}
              <a href="javascript:void(0)" (click)="edit(row)">
                <i class="pi pi-pencil"style="font-size: 10px"></i>
              </a>
              <br>
              {{ row.supplier?.name  }}
            </td>
            <td>{{ row.variants[0].minOrder}} {{ row.unit.name}}</td>
            <td>{{ row.variants[0].price | countryCurrency }}</td>
            <td>
              <div class="on-hand-div">
                <div class="on-hand-div-row1">{{ row.variants[0].amount}}/</div>
                <div class="on-hand-div-row2"><p-inplace closable="closable" [style]="{float: 'left'}">
                  <ng-template pTemplate="display">
                    {{ row.variants[0].alertLevel !== 0 ? row.variants[0].alertLevel : 'set'}}
                  </ng-template>
                  <ng-template pTemplate="content">
                    <div class="p-inputgroup">
                      <input name="alertLevel" type="number" id="{{i}}" pInputText placeholder="AlertLevel"
                             [(ngModel)]="row.variants[0].alertLevel" [style]="{width: '75%'}">
                      <button type="button" (click)="changeAlertLevel(row.variants[0].alertLevel, row , i)" pButton
                              pRipple icon="pi pi-check" styleClass="p-button-success"></button>
                    </div>
                  </ng-template>
                </p-inplace></div>
                <div class="on-hand-div-row3">
                  <button *ngIf="row.variants[0].alertLevel > 0 && row.variants[0].alertLevel > row.variants[0].amount "
                           pButton pRipple type="button"
                           icon="pi pi-arrow-right"
                           class="p-button-rounded"
                           [style]="{float:'right',marginTop: '-5px',background:'none',border: 'none',color:'black'}"
                           (click)="onChangeQuantityTotal(row.variants[0].alertLevel - row.variants[0].amount, row)">
                </button></div>
              </div>
            </td>
            <td>
              <p-inputNumber [(ngModel)]="row.variants[0].orderQuantity" mode="decimal" min="0" [showButtons]="true"
                             buttonLayout="horizontal" spinnerMode="vertical" (onInput)="onChangeQuantity($event, row , i)"
                             decrementButtonClass="p-button-secondary" incrementButtonClass="p-button-secondary"
                             incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus">
              </p-inputNumber>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="rowexpansion" let-row>
          <tr>
            <td colspan="6" [style]="{padding: '0px'}">
              <div class="p-3" [style]="{padding: '0px !important'}">
                <p-table [value]="row.purchaseOptions" dataKey="id">
                  <ng-template pTemplate="body" let-order let-b="orderIndex">
          <tr [style]="{background : '#cdcdcd'}">
            <td [style]="{width: '50px'}"></td>
            <td>{{order.name}} <br>{{order.supplier.name}}</td>
            <td>{{ order.orderType}}
              <p *ngIf="order.baseUnitQuantity"
                 [style]="{marginTop: '0px'}">{{ order.baseUnitQuantity}} {{ row.unit.name}}</p>
            </td>
            <td>{{order.price | countryCurrency }} <br>
              <p *ngIf="order.baseUnitQuantity"
                 [style]="{marginTop: '0px'}">{{ (order.price / order.baseUnitQuantity) | countryCurrency }}
                / {{ row.unit.name}}</p>
              <p *ngIf="!order.baseUnitQuantity"
                 [style]="{marginTop: '0px'}">{{ (order.price / order.packQuantity) | countryCurrency }}
                / {{ row.unit.name}}</p>
            </td>
            <td>
              <!---PurchaseOption Only One Unit--->
              <div *ngIf="order.packageName == null && order.packPerCase == null" class="p-grid">
                <div class="p-col-6" [style]="{width : '60%',float: 'left'}">
                  <div [style]="{float: 'left'}"> {{ order.onHand  }} /</div>
                  <p-inplace closable="closable" [style]="{float: 'left'}">
                    <ng-template pTemplate="display">
                      {{ order.alertLevel }}
                    </ng-template>
                    <ng-template pTemplate="content">
                      <div class="p-inputgroup">
                        <input name="alertLevel" type="number" id="{{b}}" pInputText placeholder="AlertLevel"
                               [(ngModel)]="order.alertLevel">
                        <button type="button"
                                (click)="changeAlertLevel(row.variants[0].alertLevel, row , b, order.packQuantity , order.alertLevel)"
                                pButton pRipple icon="pi pi-check" styleClass="p-button-success"></button>
                      </div>
                    </ng-template>
                  </p-inplace>
                </div>
                <div class="p-col-6" [style]="{width : '38%',float: 'left'}">
                  <button
                    *ngIf="row.variants[0].alertLevel > 0 && (row.variants[0].amount / order.packQuantity) < (row.variants[0].alertLevel  / order.packQuantity) "
                    pButton pRipple type="button"
                    icon="pi pi-arrow-right"
                    class="p-button-rounded"
                    [style]="{float:'right',marginTop: '-5px'}"
                    (click)="onChangeQuantityTotal((order.alertLevel - order.onHand), row, order)">
                  </button>
                </div>
              </div>
              <!---PurchaseOption Only One Unit End--->
              <!---PurchaseOption Only One BaseUnit Quantity --->
              <div *ngIf="order.baseUnitQuantity" class="p-grid">
                <div class="p-col-6" [style]="{width : '60%',float: 'left'}">
                  <div [style]="{float: 'left'}"> {{ order.onHand  }} /</div>
                  <p-inplace closable="closable" [style]="{float: 'left'}">
                    <ng-template pTemplate="display">
                      {{ order.alertLevel }}
                    </ng-template>
                    <ng-template pTemplate="content">
                      <div class="p-inputgroup">
                        <input name="alertLevel" type="number" id="{{ b }}" pInputText placeholder="AlertLevel"
                               [(ngModel)]="order.alertLevel">
                        <button type="button"
                                (click)="changeAlertLevel(row.variants[0].alertLevel, row , b, order.baseUnitQuantity, order.alertLevel )"
                                pButton pRipple icon="pi pi-check" styleClass="p-button-success"></button>
                      </div>
                    </ng-template>
                  </p-inplace>
                </div>
                <div class="p-col-6" [style]="{width : '38%',float: 'left'}">
                  <button
                    *ngIf="row.variants[0].alertLevel > 0 && (row.variants[0].amount / order.baseUnitQuantity) < (row.variants[0].alertLevel  / order.baseUnitQuantity) "
                    pButton pRipple type="button"
                    icon="pi pi-arrow-right"
                    class="p-button-rounded"
                    [style]="{float:'right',marginTop: '-5px'}"
                    (click)="onChangeQuantityTotal((order.alertLevel - order.onHand), row ,order)">
                  </button>
                </div>
              </div>
              <!---PurchaseOption Only One Unit End--->
            </td>
            <td>
              <p-inputNumber [(ngModel)]="order.orderQuantity" mode="decimal" min="1" step="1" [showButtons]="true"
                             buttonLayout="horizontal" spinnerMode="vertical"
                             (onInput)="onChangeQuantity($event, row , b, order )" decrementButtonClass="p-button-secondary"
                             incrementButtonClass="p-button-secondary" incrementButtonIcon="pi pi-plus"
                             decrementButtonIcon="pi pi-minus">
              </p-inputNumber>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="6">There are no order for this product yet.</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    </td>
    </tr>
    </ng-template>
    </p-table>
    </div>
    </p-card>
  </div>
  <div class="row2">
  <p-card  *ngIf="supplierCount > 0">
  <div class="p-grid">
  <div class="p-col-12" [style]="{width : '78%',float: 'left'}">
    <h5>{{ invoices[activeInvoice] ? invoices[activeInvoice].supplierName : '' }}</h5></div>
  <div class="p-col-6" [style]="{width : '20%',float: 'left',marginTop:'10px'}">
    <i class="pi pi-shopping-cart" style="font-size: 2rem"(click)="op.toggle($event)"></i>
    <span class="CountNotification ng-binding">{{supplierCount }}</span>
  </div>
  </div>
  <p-divider></p-divider>
  <p-table styleClass="p-datatable-striped" [value]="invoices[activeInvoice].data.items ? invoices[activeInvoice].data.items : selectInvoicesItems">
  <ng-template pTemplate="body" let-item let-i="rowIndex">
    <tr style="background: #E2E8F0;border-radius: 6px">
      <td width="20%" [style]="{fontSize:'35px', color: '#fff', background: '#CBD5E1'}">{{ item.quantity}}</td>
      <td width="50%">
        {{ item.name}} {{ item.code}}<br>
        {{ item.totalOrder }} {{ item.unit}}<br>
        <b [style]="{fontSize:'16px'}">{{ item.unitPrice}} TL</b></td>
      <td width="20%"><b>{{ item.subtotal}} TL</b></td>
      <td width="10%">
        <button [style]="{width:'20px', height:'20px'}" pButton pRipple type="button" icon="pi pi-times"
                class="p-button-rounded p-button-primary p-button-outlined"
                (click)="removeInvoiceItem(i, invoices[activeInvoice].key)"></button>
      </td>
    </tr>
  </ng-template>
  </p-table>
  <p-divider></p-divider>
  <div class="p-fluid">
  <div class="p-field">
    <label for="comment">{{'COMMENT' | translate}}</label>
    <input name="comment" id="comment" [(ngModel)]="invoices[activeInvoice].data.description"
           (change)="setDescription($event)" type="text" pInputText autofocus>
  </div>
  <div class="p-field">
    <label for="deliveryDate">{{'DATE' | translate}}</label>
    <p-calendar id="deliveryDate" [(ngModel)]="invoices[activeInvoice].data.expectedDate" [baseZIndex]="99999"
                (onSelect)="setDate($event)" dateFormat="yy-mm-dd"></p-calendar>
  </div>
  <div class="p-field">
    <label for="location">{{'LOCATION' | translate}}</label>
    <p-dropdown id="location" [options]="locations" [(ngModel)]="invoices[activeInvoice].data.location"
                (onChange)="setLocation($event)" placeholder="{{ 'SELECT_LOCATION'| translate }}" optionLabel="name"
                optionValue="@id" [filter]="true" filterBy="name"></p-dropdown>

  </div>

  <div class="p-field">
    <label for="status">{{'STATUS' | translate}}</label>
    <p id="status">{{ invoices[activeInvoice].data.status | translate}} <i (click)="onDisplayInvoiceStatus()"
                                                                           class="pi pi-info"></i></p>
  </div>
  </div>
  <p-divider></p-divider>
  <div class="p-grid">
  <div class="p-col-6" [style]="{width : '100%',float: 'left'}"><h4>{{ 'TOTAL'| translate }}
    : {{ invoices[activeInvoice] ? invoices[activeInvoice].data.subtotal : '000.00' }} TL</h4></div>
  <div class="p-col-6" [style]="{width : '100%',float: 'left'}">
    <div class="p-col-6" [style]="{width : '48%',float: 'left'}">
      <button pButton label="{{ 'CANCEL' | translate}}" icon="pi pi-times" class="p-mr-2"
              (click)="removeInvoice(invoices[activeInvoice].key)"></button>
    </div>
    <div class="p-col-6" [style]="{width : '48%',float: 'left'}">
      <button pButton label="{{ 'SAVE' | translate}}" class="p-ml-3 p-mb-3"
              [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" [disabled]="this.submitted"
              (click)="save(invoices[activeInvoice].key)"></button>
    </div>
  </div>
  </div>
  </p-card></div>
</div>

<p-dialog [(visible)]="display" [style]="{width: '50vw'}">
  <ng-template pTemplate="header">
    <div style="width: 50%">
      <h3>{{ 'SELECT_SUPPLIER_FOR_ORDER'| translate }}
      <small class="small-text">{{ 'SELECT_SUPPLIER_FOR_ORDER_DESCRIPTION'| translate }}</small>
      </h3>
    </div>
  </ng-template>
  <div class="dialogDiv">
    <div class="dialog-main-div">
      <div class="dialog-main-div-row1"><span class="p-input-icon-right" [style]="{width: '100%'}">
        <i class="pi pi-search"></i>
        <input type="text" pInputText [style]="{width: '100%'}"/>
    </span></div>
      <div class="dialog-main-div-row2" *ngFor="let contac of contacts" [style]="{width: '100%'}">
        <a href="javascript:void(0)" style="color: #444444" (click)="selectContact(contac.id)">
          <div class="list-div">
           <div class="supplier-logo-div"><img src="assets/img/catring.png" width="40"></div>
           <div class="supplier-name-div">{{ contac.name}}</div>
          </div>
        </a>
      </div>
    </div>

  </div>
  <ng-template pTemplate="footer">
    <p-button  class="p-button p-button-primary" label="{{ 'CREATE_NEW_SUPPLIER' | translate}}" (click)="addContact()"></p-button>
  </ng-template>
</p-dialog>
<p-toast></p-toast>
<p-overlayPanel #op [showCloseIcon]="true" [style]="{width: '450px'}">
  <ng-template pTemplate>
    <p-button styleClass="p-button-text p-button-secondary p-button-sm" (click)="addContact()"
              icon="pi pi-plus"></p-button>
    <p-table styleClass="p-datatable-striped" [value]="invoices" selectionMode="single" [rows]="5" responsiveLayout="scroll">
      <ng-template pTemplate="body" let-rowData let-item let-i="rowIndex">
        <tr>
          <td (click)="changeActiveInvoice(i)">{{ item.supplierName }}</td>
        </tr>
      </ng-template>
    </p-table>
  </ng-template>
</p-overlayPanel>
<p-sidebar [(visible)]="stockDialog" position="right" [style]="{width:'90em'}"><app-create></app-create></p-sidebar>
<p-dialog header="Sipariş Onay Durumu" [(visible)]="displayInvoiceStatus" [style]="{width: '50vw'}">
  <p>Siparişi Direk Olarak Onaylayıp Gönderme Yetkiniz Bulunmamaktadır.Sizin Verdiğiniz Siparişler Onaylandıktan Sonra
    Tedarikçiye Mail
    Olarak Gönderilecektir.</p>
  <p>Sipariş Onay Yetkisi Almak İçin Lütfen Yönetici İle İletişime Geçiniz</p>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="displayInvoiceStatus=false" label="Ok" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>
<p-sidebar [(visible)]="displaySideBar" [style]="{width:'80em'}" position="right" (onHide)="reload()">
  <app-new-material-update *ngIf="displayUpdate" [item]="item" [loading]="true" [type]="null"></app-new-material-update>
  <app-new-material-create *ngIf="displayCreate"></app-new-material-create>
</p-sidebar>
<p-confirmDialog header="Uyarı" icon="pi pi-exclamation-triangle"></p-confirmDialog>
<p-dialog header="Siparişiniz Oluşturuldu" [(visible)]="invoiceInfo" [style]="{width: '50vw'}">
  <a [routerLink]="'/purchases/receive-list/'+ invoiceRouteId">{{ invoiceInfoComment }}</a>
</p-dialog>
