<app-loading-indicator [loading]="loading"></app-loading-indicator>

<p-card *ngIf="!loading">

  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'DAILY_INCOME' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'UNPAID_REPORT'"></app-page-info>
      <p-button icon="pi pi-file-excel" [style]="{marginLeft: '5px', float:'right'}" (onClick)="export()"></p-button>
      <p-button icon="pi pi-file-pdf" (click)="generatePdf()" [style]="{marginLeft: '5px', float:'right'}"></p-button>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-3">
      <p-dropdown
        [style]="{width: '100% !important',border:'none',borderBottom: '2px solid #47bfd4'}"
        [options]="location" appendTo="body" (onChange)="onSelectLocation($event)"
        [ngModel]="parameters.location"
        optionLabel="name" optionValue="id"
        [filter]="true" filterBy="name" placeholder="{{ 'SELECT_LOCATION' | translate }}">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <p-calendar id="start" name="start" selectionMode="range" dateFormat="yy-mm-dd" date [showTime]="false"
                  [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}" appendTo="body"
                  [(ngModel)]="date" (ngModelChange)="onSelectDate($event)"
                  placeholder="{{'SELECT_DATE'| translate}}">
      </p-calendar>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-button [style]="{height: '100%'}" type="submit" (click)="load()" label="{{'SEARCH' | translate}}"></p-button>
    </div>
  </div>
  <app-report-tabs pageName="unpaid-report"></app-report-tabs>
  <app-unpaid-tab page="daily"></app-unpaid-tab>
  <div class="p-grid" id="pdf-content">
    <div class="p-col-12">
      <div class="p-col div-sums">
        <div>
          <div class="inside-div-no-background" style="overflow: auto;padding: 3px !important">
            <p-table [value]="saleDates" styleClass="p-datatable-sm">
              <ng-template pTemplate="header">
                <tr>
                  <th [style]="{width: '13%'}" pSortableColumn="saleDate">
                    <p-sortIcon field="saleDate"></p-sortIcon>
                    {{'DATE' | translate}}</th>
                  <th [style]="{width: '12%'}" pSortableColumn="subTotal">
                    <p-sortIcon field="subTotal"></p-sortIcon>
                    {{'TOTAL' | translate}}</th>
                  <th [style]="{width: '10%'}" pSortableColumn="discount">
                    <p-sortIcon field="discount"></p-sortIcon>
                    {{'DISCOUNT' | translate}}</th>
                  <th [style]="{width: '12%'}" pSortableColumn="total">
                    <p-sortIcon field="total"></p-sortIcon>
                    {{'GROSS_INCOME' | translate}}</th>
                  <th [style]="{width: '12%'}" pSortableColumn="taxTotal">
                    <p-sortIcon field="taxTotal"></p-sortIcon>
                    {{'TAX' | translate}}</th>
                  <th [style]="{width: '10%'}" pSortableColumn="netTotal">
                    <p-sortIcon field="netTotal"></p-sortIcon>{{'NET_INCOME' | translate}}</th>
                  <th [style]="{width: '5%'}" pSortableColumn="saleTotal">
                    <p-sortIcon field="saleTotal"></p-sortIcon>
                    {{'CZECH' | translate}}</th>
                  <th [style]="{width: '5%'}" pSortableColumn="cuver">
                    <p-sortIcon field="cuver"></p-sortIcon>
                    {{'COVER' | translate}}</th>
                  <th [style]="{width: '10%'}" pSortableColumn="averageSale">
                    <p-sortIcon field="averageSale"></p-sortIcon>{{'AVERAGE_CZECH' | translate}}</th>
                  <th [style]="{width: '10%'}" pSortableColumn="averageCuver">
                    <p-sortIcon field="averageCuver"></p-sortIcon>{{'AVERAGE_COVER' | translate}}</th>
                </tr>
              </ng-template>
              <ng-template pTemplate="body" let-dates>
                <tr>
                  <td [style]="{cursor: 'pointer', color: '#47bfd4'}"
                      [routerLink]="['/reports/sale-reports/unpaid-report/by-group']"
                      [queryParams]="{date: dates.saleDate, location: parameters.location}">{{dates.saleDate}}</td>
                  <td [style]="{textAlign:'right'}">{{(dates.subTotal) | countryCurrency}}</td>
                  <td [style]="{textAlign:'right', color: 'red'}">{{dates.discount | countryCurrency}}</td>
                  <td [style]="{textAlign:'right'}">{{(dates.total) | countryCurrency}}</td>
                  <td [style]="{textAlign:'right'}">{{dates.tax | countryCurrency}}</td>
                  <td [style]="{textAlign:'right'}">{{(dates.netTotal) | countryCurrency}}</td>
                  <td [style]="{textAlign:'right'}">{{dates.saleCount}}</td>
                  <td [style]="{textAlign:'right'}">{{dates.cuver}}</td>
                  <td [style]="{textAlign:'right'}">{{(dates.averageSale) | countryCurrency}}</td>
                  <td [style]="{textAlign:'right'}">{{(dates.averageCuver) | countryCurrency}}</td>
                </tr>
              </ng-template>
              <ng-template pTemplate="footer">
                <tr >
                  <td >{{'TOTAL' | translate}}</td>
                  <td [style]="{textAlign:'right', fontSize: '12px'}">{{(sale?.subTotal) | countryCurrency}}</td>
                  <td [style]="{textAlign:'right', fontSize: '12px', color: 'red'}">{{sale?.discount | countryCurrency}}</td>
                  <td [style]="{textAlign:'right', fontSize: '12px'}">{{(sale?.total) | countryCurrency}}</td>
                  <td [style]="{textAlign:'right', fontSize: '12px'}">{{sale?.tax | countryCurrency}}</td>
                  <td [style]="{textAlign:'right', fontSize: '12px'}">{{(sale?.netTotal) | countryCurrency}}</td>
                  <td [style]="{textAlign:'right', fontSize: '12px'}">{{sale?.saleCount}}</td>
                  <td [style]="{textAlign:'right', fontSize: '12px'}">{{sale?.cuver}}</td>
                  <td [style]="{textAlign:'right', fontSize: '12px'}">{{(sale?.averageSale) | countryCurrency}}</td>
                  <td [style]="{textAlign:'right', fontSize: '12px'}">{{(sale?.averageCuver) | countryCurrency}}</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</p-card>

