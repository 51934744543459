<p-card>
  <app-loading-indicator [loading]="loading"></app-loading-indicator>
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6">
      <h3 [style]="{ fontWeight: '600' }">{{ 'RETURN_INVOICES' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'RETURN_INVOICES'"></app-page-info>
      <p-button (click)="export()" icon="pi pi-arrow-down" [style]="{marginLeft: '5px', float:'right'}"></p-button>
      <button pButton pRipple type="button"[routerLink]="['/accounting/return-invoice-detail/0']" [style]="{marginLeft: '5px', float:'right'}"
              label="{{'NEW_RETURN_INVOICE' | translate }}">
      </button>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-2">
      <div class="p-inputgroup" [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}">
        <p-calendar  (onSelect)="closeCalendar('createdAt')" placeholder="{{ 'SELECT_DATE' | translate}}" appendTo="body"
                     (onClose)="load(null, 'search')" selectionMode="range" [(ngModel)]="parameters.createdAt"
                     name="createdAt" dateFormat="yy-mm-dd">
        </p-calendar>
        <span class="p-inputgroup-addon"><i class="pi pi-calendar"></i></span>
      </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-dropdown [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                  inputId="dropdown" placeholder="{{ 'SELECT_STATUS' | translate}}" (onChange)="parameters.status = $event.value; load(null, 'search')" baseZIndex="9999" [options]="status" name="materialStatus" [(ngModel)]="parameters.status" optionLabel="name" optionValue="value" ></p-dropdown>

    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-dropdown [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                  placeholder="{{'SELECT_SUPPLIER' | translate}}" [options]="suppliers"  [(ngModel)]="parameters.contactId" name="supplier"  (onChange)="load(null, 'search')"  optionValue="@id" optionLabel="name" ></p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-6">
      <div class="p-grid grid-div">
        <div class="p-col div-sums">
          <span class="title">Sipariş Sayısı</span>
          <span class="total-price">{{ items.length }} <b
            class="currency">Adet</b></span>
        </div>
        <div class="p-col div-sums">
          <span class="title">Toplam Tutar</span>
          <span class="total-price">{{ totals.total | number: '1.2-2'}}<b
            class="currency">{{ currency}}</b></span>
        </div>
      </div>
    </div>
  </div>

  <div class="p-grid">
    <div class="p-col-12" style="overflow-x: auto;margin-top:20px">
      <form (submit)="load(null , 'search')" *ngIf="!detailed">

        <p-table responsiveLayout="scroll" [loading]="loading" [lazy]="true" [value]="items"
                 (onLazyLoad)="load($event)" [tableStyle]="{'min-width': '50rem'}"
                 [reorderableColumns]="true" (onRowReorder)="onReorder($event)"
                 [rows]="10" [showCurrentPageReport]="true" [resizableColumns]="true"
                 styleClass="p-datatable-striped p-datatable-sm" [style]="{width:'100%'}">
          <ng-template pTemplate="header">
            <tr>
              <th style="width: 5%"></th>
              <th style="width: 7%">{{ (parameters.type == invoiceType.Sales ? 'ORDER_NUMBER' : 'CODE') | translate }} <app-tooltip [description]="'ORDER_NUMBER_TOOLTIP'"></app-tooltip> </th>
              <th style="width: 20%">{{ (parameters.type == invoiceType.Sales ? 'CUSTOMER' : 'SUPPLIER')|translate}} <app-tooltip description="{{ parameters.type == invoiceType.Sales ? 'CUSTOMER_TOOLTIP' : 'SUPPLIER_TOOLTIP'}}"></app-tooltip></th>
              <th style="width: 15%">{{ 'LOCATIONS' | translate}} <app-tooltip description="TOTAL_TOOLTIP"></app-tooltip></th>
              <th style="width: 15%">{{ 'TOTAL' | translate}} <app-tooltip description="TOTAL_TOOLTIP"></app-tooltip></th>
              <th style="width: 15%">{{ 'CREATED_AT' | translate}} <app-tooltip description="CREATED_TOOLTIP"></app-tooltip> </th>
              <th style="width: 15%">{{ 'STATUS' | translate}} <app-tooltip description="COMPLETED_TOOLTIP"></app-tooltip></th>
              <th style="width: 15%">{{ 'ADD_ACCOUNTING' | translate}} <app-tooltip description="COMPLETED_TOOLTIP"></app-tooltip></th>
            </tr>
            <tr>
              <th></th>
              <td><input  [style]="{width: '100%',height:'45px'}" type="text" pInputText [(ngModel)]="parameters.code" name="code"></td>
              <td><input [style]="{width: '100%',height:'45px'}" type="text" pInputText [(ngModel)]="parameters['contact.name']" name="contactName"></td>
              <td>
                <p-dropdown placeholder="{{ 'LOCATION' | translate}}"  appendTo="body" [options]="locations" optionValue="id" (onChange)="parameters.location = $event.value;
                load(null, 'search')" [ngModel]="parameters.location" optionLabel="name">

                </p-dropdown>
              </td>
              <td><input [style]="{width: '100%',height:'45px'}" type="text" pInputText [(ngModel)]="parameters.total" name="total"></td>
              <td><p-calendar #createdAt (onSelect)="closeCalendar('createdAt')" appendTo="body" (onClose)="load()" selectionMode="range" [(ngModel)]="parameters.createdAt" name="createdAt" dateFormat="yy-mm-dd"></p-calendar></td>
              <td><input [style]="{width: '100%',height:'45px'}" type="text" pInputText [(ngModel)]="parameters.isReceived" name="isReceived"></td>
              <td></td>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-row  let-index="rowIndex">
            <tr  [pReorderableRow]="index">
              <td>
                <span class="pi pi-bars" pReorderableRowHandle></span>
                {{ index + 1 }}
              </td>
              <td [style]="{width:'15%'}"><a [style]="{color:'#11b5c4'}" [routerLink]="'/accounting/return-invoice-detail/' + row.id" [state]="{ 'id': row.id}">{{ row.code }}</a></td>
              <td>{{ row.customerName ? row.customerName : (row.contact ? row.contact.name : '-') }}</td>
              <td>{{ row.location.name}}</td>
              <td [style]="{textAlign: 'right'}">{{ row.total | countryCurrency}}</td>
              <td [style]="{textAlign: 'right'}">{{ row.createdAt|date: 'Y/MM/dd' }}</td>
              <td>
                <p-tag [severity]="row.deliveryStatus|statusLabel" class="cursor-pointer"  *ngIf="row.deliveryStatus != deliveryActions.DELIVERED">{{row.deliveryStatus|translate}} </p-tag>
                <p-tag severity="success" *ngIf="row.deliveryStatus == deliveryActions.DELIVERED">{{'RETURNED_TEXT'|translate}}</p-tag>
              </td>
              <td [style]="{textAlign: 'right'}">
                <b *ngIf="row.isInvoiced === true" [style]="{color:'#24a1d7'}">
                  <p-tag severity="success">{{ 'ADDED_TO_ACCOUNT' | translate}}</p-tag>
                </b>
                <b *ngIf="row.isInvoiced === false" [style]="{color:'#f50c0c'}" (click)="sendInvoiceToAccounting(row.id)">
                  <p-tag severity="warning">{{ 'NO_ADDED_TO_ACCOUNT' | translate}}</p-tag>
                </b>
              </td>
            </tr>
          </ng-template>
        </p-table>
        <div class="p-grid" style="padding-top: 20px" *ngIf="moreLoading">
          <div class="p-col-4 p-offset-3" style="">
            <button pButton label="{{ 'MORE' | translate}}" class="p-ml-3 p-mb-3" [style]="{width:'100%'}"
                    [icon]="'pi pi-arrow-down'"
                    (click)="load()"></button>
          </div>
        </div>
        <button type="submit" pButton label="{{'stock.search' | translate}}" style="display: none"></button>
      </form>
      <app-invoice-detailed-list *ngIf="detailed"></app-invoice-detailed-list>
    </div>
  </div>
</p-card>

<p-overlayPanel #op dismissable="true" showCloseIcon="true">
  <ng-template pTemplate>
    <p-table [value]="workOrders" [loading]="workOrdersLoading" style="width: 75px">
      <ng-template pTemplate="header">
        <tr>
          <th>{{'code' | translate}}</th>
          <th>{{'workOrderList.status' | translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-i="rowIndex" let-row>
        <td>
          <a href="" [routerLink]="'/work-orders/' + row.id + '/edit'">{{ row.code }}</a>
        </td>
        <td>
          <p-tag [severity]="row.status|statusLabel">{{ row.status|status }}</p-tag>
        </td>
      </ng-template>
    </p-table>
  </ng-template>
</p-overlayPanel>
<p-overlayPanel #dp>
  <ng-template pTemplate>
    <div *ngFor="let item of changeStatusItems">
      <p-button *ngIf="item.state.status !== deliveryActions.PARTIAL_DELIVERED"  [label]="item.label" styleClass="p-button-text" class="p-d-block" (onClick)="changeStatus(item.state.id, item.state.status); dp.hide()"></p-button>
      <p-button *ngIf="item.state.status === deliveryActions.PARTIAL_DELIVERED"  [label]="item.label" styleClass="p-button-text" class="p-d-block" (onClick)="receive(item.state.id); dp.hide()"></p-button>
    </div>
  </ng-template>
</p-overlayPanel>

<p-confirmDialog #cd [style]="{width: '50vw'}">
  <ng-template pTemplate="header">
    <h3>{{ 'ALERT' | translate}}</h3>
  </ng-template>
  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-times" label="{{ 'NO' | translate}}" (click)="cd.reject()"></button>
    <button type="button" pButton icon="pi pi-check" label="{{ 'YES' | translate}}" (click)="cd.accept()"></button>
  </ng-template>
</p-confirmDialog>
