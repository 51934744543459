<p-card>
  <app-loading-indicator [loading]=loading></app-loading-indicator>

  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6">
      <h3 [style]="{ fontWeight: '600' }">{{ 'COOK_BOOK_STOCKS' | translate}}</h3>
    </div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'COOKBOOK'"></app-page-info>
      <p-fileUpload name="file" class="p-d-inline-block" styleClass="p-button-text" chooseIcon="pi pi-upload"
                    mode="basic" [chooseLabel]="'IMPORT'| translate" #fileUpload auto="true" customUpload="true"
                    multiple="multiple" (uploadHandler)="upload($event, 'cookBook', fileUpload)">

      </p-fileUpload>
    </div>
  </div>

  <div class="p-grid">
    <div class="p-col-12" style="overflow-x: auto;">
      <div class="main-content-div">
        <div>
          <div class="main-content-div-row3">
            <div class="category-div">
              <div class="p-grid">
                <form (submit)="load()">
                <p-table [value]="cookBookStocks" [tableStyle]="{'min-width': '50rem'}" dataKey="id" editMode="row"
                         [paginator]="true" [rows]="20" [totalRecords]="total" [lazy]="true" (onLazyLoad)="load($event)"
                         [rowsPerPageOptions]="[10,25,50]">
                  <ng-template pTemplate="header">
                    <tr>
                      <th [style]="{width:'10%'}" pSortableColumn="code">
                        <p-sortIcon field="code"></p-sortIcon>{{ 'CODE' | translate}}</th>
                      <th [style]="{width:'50%'}" pSortableColumn="name">
                        <p-sortIcon field="name"></p-sortIcon>{{ 'NAME' | translate}}</th>
                      <th [style]="{width:'20%'}" pSortableColumn="name">
                        <p-sortIcon field="name"></p-sortIcon>{{ 'MIN_LINK' | translate}}
                      </th>
                      <th [style]="{width:'20%'}" pSortableColumn="name">
                        <p-sortIcon field="name"></p-sortIcon>{{ 'MAX_LINK' | translate}}
                      </th>
                      <th [style]="{width:'20%'}" pSortableColumn="category.name">
                        <p-sortIcon field="category.name"></p-sortIcon>{{ 'CATEGORY' | translate}}</th>
                      <th [style]="{width:'14%'}" pSortableColumn="totalCost">
                        <p-sortIcon field="totalCost"></p-sortIcon>{{ 'MIN_PRICE' | translate}}</th>
                      <th [style]="{width:'14%'}" pSortableColumn="totalCost">
                        <p-sortIcon field="totalCost"></p-sortIcon>{{ 'MAX_PRICE' | translate}}</th>
                      <th [style]="{width:'14%'}" pSortableColumn="totalCost">
                        <p-sortIcon field="totalCost"></p-sortIcon>{{ 'AVERAGE_PRICE' | translate}}</th>
                      <th [style]="{width:'8%'}">{{ 'UNIT' | translate}}</th>
                      <th [style]="{width:'18%'}">{{ 'ACTIONS' | translate}}</th>
                      <th [style]="{width:'12%'}">{{ '' | translate}}</th>
                    </tr>
                    <tr>
                      <td></td>
                      <td><input name="searcName" id="searcName" pInputText [(ngModel)]="parameters.name"></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-product let-editing="editing" let-ri="rowIndex">
                    <tr [pEditableRow]="product">
                      <td>{{product.code}}</td>
                      <td>
                        <p-cellEditor>
                          <ng-template pTemplate="input">
                            <input type="text" name="name" id="name" pInputText [(ngModel)]="product.name">
                          </ng-template>
                          <ng-template pTemplate="output">
                            {{product.name}}
                          </ng-template>
                        </p-cellEditor>
                      </td>
                      <td>
                        <p-cellEditor>
                          <ng-template pTemplate="input">
                            <input type="text" name="minLink" id="minLink" pInputText [(ngModel)]="product.minLink">
                          </ng-template>
                          <ng-template pTemplate="output">
                            {{product.minLink | slice:0:12}}
                          </ng-template>
                        </p-cellEditor>
                      </td>
                      <td>
                        <p-cellEditor>
                          <ng-template pTemplate="input">
                            <input type="text" name="maxLink" id="maxLink" pInputText [(ngModel)]="product.maxLink">
                          </ng-template>
                          <ng-template pTemplate="output">
                            {{product.maxLink | slice:0:12}}
                          </ng-template>
                        </p-cellEditor>
                      </td>
                      <td>
                        <p-cellEditor>
                          <ng-template pTemplate="input">
                            <p-dropdown [options]="categories" appendTo="body" filter="true" [ngModel]="product.category" [style]="{'width':'100%'}"
                            optionValue="@id" optionLabel="name" (onChange)="changeCategory($event,ri)"
                            ></p-dropdown>
                          </ng-template>
                          <ng-template pTemplate="output">
                            {{product.category?.name}}
                          </ng-template>
                        </p-cellEditor>

                      </td>
                      <td [style]="{textAlign:'right'}">
                        <p-cellEditor>
                          <ng-template pTemplate="input">
                            <input type="number" name="price" id="price" pInputText [(ngModel)]="product.price">
                          </ng-template>
                          <ng-template pTemplate="output">
                            {{product.price | countryCurrency}}
                          </ng-template>
                        </p-cellEditor>
                      </td>
                      <td [style]="{textAlign:'right'}">
                        <p-cellEditor>
                          <ng-template pTemplate="input">
                            <input type="number" name="priceMax" id="priceMax" pInputText [(ngModel)]="product.priceMax">
                          </ng-template>
                          <ng-template pTemplate="output">
                            {{product.priceMax | countryCurrency}}
                          </ng-template>
                        </p-cellEditor>
                      </td>
                      <td> {{(product.priceMax + product.price) / 2 | countryCurrency}}</td>
                      <td [style]="{textAlign:'right'}">
                        <p-cellEditor>
                          <ng-template pTemplate="input">
                            <input type="text" name="unit" id="unit" pInputText [(ngModel)]="product.unit">
                          </ng-template>
                          <ng-template pTemplate="output">
                            {{product.unit}}
                          </ng-template>
                        </p-cellEditor>
                        </td>
                      <td>
                        <p-button label="Güncelle" icon="pi pi-refresh" (onClick)="updatePrice(product)"></p-button>
                      </td>
                      <td>
                        <div class="flex align-items-center justify-content-center gap-2">
                          <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil" class="p-button-rounded p-button-text"></button>
                          <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="saveStock(product ,ri)"  class="p-button-rounded p-button-text p-button-success mr-2"></button>
                          <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times"  class="p-button-rounded p-button-text p-button-danger"></button>
                        </div>
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
                  <button type="submit" pButton label="{{'stock.search' | translate}}" style="display: none"></button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</p-card>
