<p-card>
  <app-loading-indicator [loading]="loading"></app-loading-indicator>

  <div class="p-grid">
    <div class="p-col-5 p-md-5 p-sm-5"><h3 [style]="{ fontWeight: '600' }">
      {{ 'RETURN_INVOICE_DETAIL' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6 p-offset-1">
      <app-page-info [pageName]="'ORDER_DETAIL'"></app-page-info>
      <p-button icon="pi pi-print" [style]="{marginLeft: '5px', float:'right'}"></p-button>
      <button pButton pRipple [routerLink]="['/accounting/return-invoices']" [style]="{marginLeft: '5px', float:'right'}"
              icon="pi pi-arrow-left" class="p-button-secondary"></button>
      <button pButton label="{{ 'SAVE' | translate}}" class="p-ml-3 p-mb-3" [style]="{marginLeft: '5px', float:'right'}"
              [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
              [disabled]="this.submitted || invoice.isInvoiced"
              (click)="save()">
      </button>

    </div>
  </div>
  <!---
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-2"
         *ngIf="invoice.isReceived === false && invoice.status == 'CONFIRMED' || invoice.status == 'CONFIRMED_SUPPLIER' || invoice.status == 'VIEWED_SUPPLIER'">
      <button pButton pRipple [style]="{width:'100%'}" label="{{ 'RECEIVE_WITH_WAYBILL' | translate }}"
              (click)="onshowWaybillDialog()"></button>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2"
         *ngIf="invoice.isReceived === false && invoice.status == 'CONFIRMED' || invoice.status == 'CONFIRMED_SUPPLIER' || invoice.status == 'VIEWED_SUPPLIER'">
      <button pButton pRipple [style]="{width:'100%'}" label="{{ 'RECEIVE_WITH_INVOICE' | translate }}"
              (click)="onshowInvoiceDialog()"></button>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2" *ngIf="invoice.status == 'WAITING_FOR_APPROVAL'">
      <button pButton pRipple [style]="{width:'100%'}" label="{{ 'CONFIRM_ORDER' | translate}}"
              icon="{{ submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'}}" (click)="approveOrder()"></button>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2" *ngIf="invoice.isReceived">
      <button pButton pRipple [style]="{width:'100%'}" label="{{ 'VIEW_INVOICE' | translate}}"
              [routerLink]="['/order/receive-manuel/'+ invoice.id]"></button>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2" *ngIf="invoice.isReceived === false">
      <button pButton pRipple [style]="{width:'100%'}" label="{{ 'CANCEL_ORDER' | translate}}" (click)="confirm()"
              [disabled]="invoice.isReceived"></button>
    </div>
  </div>
  --->
  <div class="p-grid grid-div p-mt-3">
    <div class="p-col div-sums" [style]="{ width: '50%'}">
      <table>
        <tr>
          <td [style]="{width: '30%'}"><b
            class="fw-500">{{ (invoice.status === invoiceStatus.Pending ? 'QUOTE_NUMBER' : 'ORDER_NUMBER')|translate}}</b>
          </td>
          <td [style]="{width: '1%'}">:</td>
          <td [style]="{width: '70%'}"><input [class.border-0]="true" type="text" pInputText
                                              [style]="{boxShadow:'none',width:'50px',height:'45px', float: 'left'}"
                                              [(ngModel)]="invoice.code" [disabled]="invoice.isReceived">
            <small class="p-error">{{ errors['code'] }}</small>
          </td>
        </tr>
        <tr>
          <td><b class="fw-500">{{ 'LOCATION'|translate}}</b></td>
          <td>:</td>
          <td>
            <p-dropdown [ngModel]="invoice.location" [options]="locations" placeholder="{{'SELECT_LOCATION' | translate}}"
                        [style]="{radius: '5px !important',width:'50px',float: 'left'}"
                        [filter]="true" filterBy="name" (onChange)="this.invoice.location = $event.value"
                        name="location" optionLabel="name" optionValue="@id"></p-dropdown>
            <small class="p-error">{{ errors['location'] }}</small>
          </td>
        </tr>
        <tr>
          <td [style]="{width: '30%'}"><b class="fw-500">{{'SUPPLIER' | translate}}:</b></td>
          <td [style]="{width: '1%'}">:</td>
          <td [style]="{width: '70%'}"><b class="fw-500"></b>
            <p-dropdown [ngModel]="invoice.contact" [options]="contacts"
                        [filter]="true" filterBy="name"
                        [style]="{borderRadius: '5px !important',width:'50px',float: 'left'}"
                        placeholder="{{'SELECT_SUPPLIER' | translate}}" (onChange)="this.invoice.contact = $event.value"
                        name="contact" optionLabel="name" optionValue="@id"></p-dropdown>
            <small class="p-error">{{ errors['contact'] }}</small>

          </td>
        </tr>
        <tr>
          <td><b class="fw-500"> {{ 'ITEM_COUNT' | translate}}</b></td>
          <td>:</td>
          <td>{{ invoice.items.length }}</td>
        </tr>
        <tr>
          <td><b class="fw-500"> {{ 'TOTAL_EXL_TAX' | translate}}</b></td>
          <td>:</td>
          <td [style]="{textAlign:'right',fontSize:'35px'}">{{ invoice.total | countryCurrency }}</td>
        </tr>
      </table>
    </div>
  </div>
  <div class="p-grid">
  </div>

  <div class="p-grid p-mt-3">
    <div class="p-col-12" style="overflow-x: auto;">
      <p-table [value]="invoice.items" dataKey="id" responsiveLayout="scroll"
               styleClass="p-datatable-striped p-datatable-sm">
        <ng-template pTemplate="header">
          <tr>
            <th [style]="{width: '2%'}"></th>
            <th [style]="{width: '10%'}">{{ 'CODE' | translate}}
              <app-tooltip [description]="'TOOLTIP_CODE'"></app-tooltip>
            </th>
            <th [style]="{width: '30%'}">{{ 'STOCK' | translate}}
              <app-tooltip [description]="'TOOLTIP_STOCK'"></app-tooltip>
            </th>
            <th [style]="{width: '10%'}">{{ 'NOTES' | translate}}
              <app-tooltip [description]="'TOOLTIP_STOCK'"></app-tooltip>
            </th>
            <th [style]="{width: '10%'}">{{ 'QUANTITY' | translate}}
              <app-tooltip [description]="'TOOLTIP_QUANTITY'"></app-tooltip>
            </th>
            <th [style]="{width: '10%'}">{{ 'UNIT_PRICE' | translate}}
              <app-tooltip [description]="'TOOLTIP_UNIT_PRICE'"></app-tooltip>
            </th>
            <th [style]="{width: '10%'}">{{ 'TOTAL_PRICE' | translate}}
              <app-tooltip [description]="'TOOLTIP_TOTAL_PRICE'"></app-tooltip>
            </th>
            <th [style]="{width: '11%'}">{{ 'TAX' | translate}}
              <app-tooltip [description]="'TOOLTIP_TAX'"></app-tooltip>
            </th>
            <th [style]="{width: '5%'}"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-i="rowIndex">
          <tr>
            <td>{{ i +1 }}</td>
            <td>
              {{ item.purchaseOption === null ? autoCompleteValues[i] ? autoCompleteValues[i].code : '' : item.purchaseOption.code }}
            </td>
            <td pEditableColumn>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-autoComplete [(ngModel)]="autoCompleteValues[i]" [disabled]="item.isReceived"
                                  (onSelect)="onVariantSelect($event, i); setStatus(item.variant, item.quantity, i)"
                                  field="name" [suggestions]="variants" appendTo="body"
                                  (completeMethod)="searchVariant($event)"></p-autoComplete>
                </ng-template>
                <ng-template pTemplate="output">
                  {{ item.purchaseOption !== null ? item.orderType : '' }}
                  <i class="pi pi-link" (click)="edit(autoCompleteValues[i])"></i>
                  {{ item.purchaseOption === null ? autoCompleteValues[i] ? autoCompleteValues[i].name : '' : item.purchaseOption.name }}
                </ng-template>
              </p-cellEditor>

            </td>
            <td pEditableColumn class="{{ hasErrors('notes', i) ? 'has-error': '' }}">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input pInputText [(ngModel)]="item.notes" name="notes"/>
                </ng-template>
                <ng-template pTemplate="output">
                  {{ item.notes}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td pEditableColumn class="{{ hasErrors('quantity', i) ? 'has-error': '' }}">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-inputNumber mode="decimal" minFractionDigits="2" maxFractionDigits="2" [(ngModel)]="item.quantity"
                                 [min]="item.incomingQuantity" [disabled]="item.isReceived"
                                 (ngModelChange)=" setStatus(item.variant, item.quantity, i); calculateTotalPrice(i)"></p-inputNumber>
                </ng-template>
                <ng-template pTemplate="output">
                  {{ item.quantity}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td pEditableColumn class="{{ hasErrors('unitPrice', i) ? 'has-error': '' }}">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-inputNumber [(ngModel)]="item.unitPrice" mode="decimal" minFractionDigits="2" maxFractionDigits="2"
                                 [disabled]="item.isReceived || discountLoading"
                                 (ngModelChange)="item.nonDiscountedUnitPrice = item.unitPrice; calculateTotalPrice(i)"></p-inputNumber>
                </ng-template>
                <ng-template pTemplate="output">
                  {{item.unitPrice|currency: currencyLabel: ''}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td>
              {{item.subtotal|currency: currencyLabel: ''}}
            </td>
            <td pEditableColumn>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-dropdown [options]="taxRates" [(ngModel)]="item.taxRate"
                              (onChange)="calculateTotalPrice(i); setTax(i, $event)" optionLabel="name" id="tax"
                              optionValue="@id"></p-dropdown>
                </ng-template>
                <ng-template pTemplate="output">
                  {{taxes.length > i ? taxes[i]?.name : ''}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td align="right" [style]="{textAlign: 'right'}">
              <button [disabled]="item.isReceived || item.isWorkOrderCreated" pButton label="" icon="pi pi-trash"
                      class="p-button-outlined p-button-danger" (click)="removeItem(i)" iconPos="left"></button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="footer">
          <tr>
            <td colspan="8">
              <small class="p-error">{{ errors['items'] }}</small><br>
              <small class="p-error">{{ errors['price'] }}</small><br>
              <small class="p-error">{{ errors['quantity'] }}</small>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <div class="p-grid" *ngIf="invoice.isReceived === false">
        <div class="p-col-2">
          <button pButton label="{{'ADD_STOCK' | translate}}"
                  class="p-mb-3 add-button p-mt-6" [icon]="'pi pi-plus'"
                  (click)="addItem()" [disabled]="invoice.isInvoiced"></button>
        </div>
      </div>


    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12">
      <table class="total-box p-p-3">
        <tr>
          <th>{{ 'SUBTOTAL' | translate}}</th>
          <td>{{ invoice.subtotal|currency: currencyLabel }}</td>
        </tr>
        <tr>
          <th>{{ 'TAX' | translate}}</th>
          <td>{{ invoice.tax|currency: currencyLabel }}</td>
        </tr>
        <tr>
          <th>{{ 'TOTAL' | translate}}</th>
          <td>{{ invoice.total|currency: currencyLabel }}</td>
        </tr>
      </table>
    </div>
  </div>

</p-card>



