import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpService} from '../../service/http.service';
import {TranslateService} from '@ngx-translate/core';
import {LocationModel} from '../../model/location.model';
import {LocationService} from '../../service/location.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {


  events: any[] = [];
  info: any;
  menuAnalys: any;
  sale = {
    subTotal: 0,
    total: 0,
    netTotal: 0,
    taxTotal: 0,
    discount: 0,
    totalCuver: 0,
    totalCustomer: 0,
    totalCheck: 0,
    totalCost: 0,
    totalCuverAverage: 0,
    totalCustomerAverage: 0,
    totalAverage: 0,
    checkCost: 0,
    customerCost: 0,
  };
  saleDates: any;
  categories: any;
  purchase: any;
  incomeInvoice: any;
  returnInvoice: any;
  transfer: any;
  wasteEvent: any;
  loading: boolean;
  locations: LocationModel[] = [];
  parameters = {
    // date: [],
    startDate: '',
    lastDate: '',
    location: '',
    saleDate: ''
  };
  options: any;
  chartOptions: any;
  points: any[] = [];
  hLineValue: number;
  vLineValue: number;
  dateRange: Date[] = [];
  sumMenuCost = 0;
  sumMenuRevenue = 0;
  sumMenuCM = 0;
  menuFootCost = 0;
  itemCm = 0;
  itemCmAverage = 0;
  percentageMenuMix = 0;
  maxQuantity = 0;
  maxProfit = 0;
  a = 0;
  b = 0;
  c = 0;
  d = 0;
  incomeExpenseAnalys: any[];
  incomeExpenseAnalysTotal: { totalCheck: number; taxTotal: number; totalCuver: number; discount: number; customerCost: number; subTotal: number; totalAverage: number; checkCost: number; total: number; totalCustomer: number; netTotal: number; totalCuverAverage: number; totalCost: number; totalCustomerAverage: number };


  constructor(private http: HttpService, private translate: TranslateService, private locationService: LocationService) {
  }

  ngOnInit(): void {
    this.loading = true;
    this.getThisWeekDates();
    this.locations = this.locationService.getSearchLocation({type: 'supplier'});


    this.load().then();
  }

  async load(): Promise<void> {
    this.loading = true;
    this.sale = {
      subTotal: 0,
      total: 0,
      netTotal: 0,
      taxTotal: 0,
      discount: 0,
      totalCuver: 0,
      totalCustomer: 0,
      totalCheck: 0,
      totalCost: 0,
      totalCuverAverage: 0,
      totalCustomerAverage: 0,
      totalAverage: 0,
      checkCost: 0,
      customerCost: 0,
    };
    this.points = [];
    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));
    await this.http.get('/api/reports/menu_analys_reports', this.parameters).subscribe(response => {
      this.loading = true;
      this.menuAnalys = response;
      response.results.map((item, i) => {
        if (parseFloat(item.quantity) > this.maxQuantity) {
          this.maxQuantity = parseFloat(item.quantity);
        }

        if (parseFloat(item.profit) > this.maxProfit) {
          this.maxProfit = parseFloat(item.profit);
        }
        this.sumMenuCost = this.sumMenuCost + (item.quantity * item.total_cost);
        this.sumMenuRevenue = this.sumMenuRevenue + (item.quantity * item.sale_price);
        this.sumMenuCM = this.sumMenuCM + (item.quantity * (item.sale_price - item.total_cost));
        this.itemCm = this.itemCm + ((item.sale_price - item.total_cost));
      });
      setTimeout(() => {
        response.results.map((item, i) => {
          if (item.profit > 0 && item.total_cost > 0) {
            if (this.a < 5 && this.getBaseLog(10, parseFloat(item.profit)) > (this.getBaseLog(10, this.maxProfit) / 2)
              && this.getBaseLog(10, parseFloat(item.quantity)) > (this.getBaseLog(10, this.maxQuantity) / 2)) {
              this.points.push(
                {
                  x: this.getBaseLog(10, parseFloat(item.profit)),
                  y: this.getBaseLog(10, parseFloat(item.quantity)),
                  z: 10,
                  name: item.stockName,
                  indexLabelFontSize: 8,
                  q: parseFloat(item.quantity).toFixed(2),
                  salePrice: parseFloat(item.sale_price),
                  sale: (parseFloat(item.quantity) * parseFloat(item.sale_price)).toFixed(2),
                  cost: parseFloat(item.total_cost).toFixed(2),
                  totalCost: (parseFloat(item.total_cost) * parseFloat(item.quantity)).toFixed(2),
                  costRate: (((parseFloat(item.total_cost) * parseFloat(item.quantity)) / (parseFloat(item.quantity)
                    * parseFloat(item.sale_price))) * 100).toFixed(2),
                  p: item.profit,
                  currency: this.info ? this.info.currency :'₺'
                }
              );
              this.a = this.a + 1;
            } else if (this.b < 5 && this.getBaseLog(10, parseFloat(item.profit)) > (this.getBaseLog(10, this.maxProfit) / 2)
              && this.getBaseLog(10, parseFloat(item.quantity)) < (this.getBaseLog(10, this.maxQuantity) / 2)) {
              this.points.push(
                {
                  x: this.getBaseLog(10, parseFloat(item.profit)),
                  y: this.getBaseLog(10, parseFloat(item.quantity)),
                  z: 10,
                  name: item.stockName,
                  indexLabelFontSize: 8,
                  q: parseFloat(item.quantity).toFixed(2),
                  salePrice: parseFloat(item.sale_price),
                  sale: (parseFloat(item.quantity) * parseFloat(item.sale_price)).toFixed(2),
                  cost: parseFloat(item.total_cost).toFixed(2),
                  totalCost: (parseFloat(item.total_cost) * parseFloat(item.quantity)).toFixed(2),
                  costRate: (((parseFloat(item.total_cost) * parseFloat(item.quantity)) / (parseFloat(item.quantity)
                    * parseFloat(item.sale_price))) * 100).toFixed(2),
                  p: item.profit,
                  currency: this.info ? this.info.currency : '₺'
                }
              );
              this.b = this.b + 1;
            } else if (this.c < 5 && this.getBaseLog(10, parseFloat(item.profit)) < (this.getBaseLog(10, this.maxProfit) / 2)
              && this.getBaseLog(10, parseFloat(item.quantity)) > (this.getBaseLog(10, this.maxQuantity) / 2)) {
              this.points.push(
                {
                  x: this.getBaseLog(10, parseFloat(item.profit)),
                  y: this.getBaseLog(10, parseFloat(item.quantity)),
                  z: 10,
                  name: item.stockName,
                  indexLabelFontSize: 8,
                  q: parseFloat(item.quantity).toFixed(2),
                  salePrice: parseFloat(item.sale_price),
                  sale: (parseFloat(item.quantity) * parseFloat(item.sale_price)).toFixed(2),
                  cost: parseFloat(item.total_cost).toFixed(2),
                  totalCost: (parseFloat(item.total_cost) * parseFloat(item.quantity)).toFixed(2),
                  costRate: (((parseFloat(item.total_cost) * parseFloat(item.quantity)) / (parseFloat(item.quantity)
                    * parseFloat(item.sale_price))) * 100).toFixed(2),
                  p: item.profit,
                  currency: this.info ? this.info.currency : '₺'

                }
              );
              this.c = this.c + 1;
            } else if (this.d < 5 && this.getBaseLog(10, parseFloat(item.profit)) < (this.getBaseLog(10, this.maxProfit) / 2)
              && this.getBaseLog(10, parseFloat(item.quantity)) < (this.getBaseLog(10, this.maxQuantity) / 2)) {
              this.points.push(
                {
                  x: this.getBaseLog(10, parseFloat(item.profit)),
                  y: this.getBaseLog(10, parseFloat(item.quantity)),
                  z: 10,
                  name: item.stockName,
                  indexLabelFontSize: 8,
                  q: parseFloat(item.quantity).toFixed(2),
                  salePrice: parseFloat(item.sale_price),
                  sale: (parseFloat(item.quantity) * parseFloat(item.sale_price)).toFixed(2),
                  cost: parseFloat(item.total_cost).toFixed(2),
                  totalCost: (parseFloat(item.total_cost) * parseFloat(item.quantity)).toFixed(2),
                  costRate: (((parseFloat(item.total_cost) * parseFloat(item.quantity)) / (parseFloat(item.quantity)
                    * parseFloat(item.sale_price))) * 100).toFixed(2),
                  p: item.profit,
                  currency: this.info ? this.info.currency : '₺'
                }
              );
              this.d = this.d + 1;
            }
          }
        });
      }, 100);
      this.a = 0;
      this.b = 0;
      this.c = 0;
      this.d = 0;
      this.chartOption();

    });
    await this.http.get('/api/dasboard_reports', this.parameters).subscribe(response => {
      this.info = response;
    });

    await this.http.get('/api/reports/daily_income_reports', this.parameters).subscribe(response => {
      let i = 0;
      this.saleDates = response;
      this.incomeExpenseAnalys = [];
      this.incomeExpenseAnalysTotal = {
        subTotal: 0,
        total: 0,
        netTotal: 0,
        taxTotal: 0,
        discount: 0,
        totalCuver: 0,
        totalCustomer: 0,
        totalCheck: 0,
        totalCost: 0,
        totalCuverAverage: 0,
        totalCustomerAverage: 0,
        totalAverage: 0,
        checkCost: 0,
        customerCost: 0,
      };
      this.sale = {
        subTotal: 0,
        total: 0,
        netTotal: 0,
        taxTotal: 0,
        discount: 0,
        totalCuver: 0,
        totalCustomer: 0,
        totalCheck: 0,
        totalCost: 0,
        totalCuverAverage: 0,
        totalCustomerAverage: 0,
        totalAverage: 0,
        checkCost: 0,
        customerCost: 0,
      };
      if (response.length > 5) {
        while (i < 5) {
          this.incomeExpenseAnalys.push(response[i]);
          i = i + 1;
        }
        this.incomeExpenseAnalys.map(item => {
          this.incomeExpenseAnalysTotal.subTotal = this.incomeExpenseAnalysTotal.subTotal + parseFloat(item.subTotal);
          this.incomeExpenseAnalysTotal.total = this.incomeExpenseAnalysTotal.total + parseFloat(item.total);
          this.incomeExpenseAnalysTotal.netTotal = this.incomeExpenseAnalysTotal.netTotal + parseFloat(item.netTotal);
          this.incomeExpenseAnalysTotal.taxTotal = this.incomeExpenseAnalysTotal.taxTotal + parseFloat(item.tax);
          this.incomeExpenseAnalysTotal.discount = this.incomeExpenseAnalysTotal.discount + parseFloat(item.discount);
          this.incomeExpenseAnalysTotal.totalCuver = this.incomeExpenseAnalysTotal.totalCuver + parseFloat(item.cuver);
          this.incomeExpenseAnalysTotal.totalCustomer = this.incomeExpenseAnalysTotal.totalCustomer + parseFloat(item.saleCount);
          this.incomeExpenseAnalysTotal.totalCheck = this.incomeExpenseAnalysTotal.totalCheck + parseFloat(item.saleCount);
          this.incomeExpenseAnalysTotal.totalCost = this.incomeExpenseAnalysTotal.totalCost +  parseFloat(item.totalCost);
        });
        this.incomeExpenseAnalysTotal.totalCuverAverage = this.incomeExpenseAnalysTotal.netTotal / this.incomeExpenseAnalysTotal.totalCuver;
        // tslint:disable-next-line:max-line-length
        this.incomeExpenseAnalysTotal.totalCustomerAverage = this.incomeExpenseAnalysTotal.netTotal / this.incomeExpenseAnalysTotal.totalCustomer;
        this.incomeExpenseAnalysTotal.totalAverage = this.incomeExpenseAnalysTotal.netTotal / this.incomeExpenseAnalysTotal.totalCustomer;
        this.incomeExpenseAnalysTotal.checkCost = this.incomeExpenseAnalysTotal.totalCost / this.incomeExpenseAnalysTotal.totalCheck;
        this.incomeExpenseAnalysTotal.customerCost = this.incomeExpenseAnalysTotal.totalCost / this.incomeExpenseAnalysTotal.totalCustomer;

        this.saleDates.map(item => {
          this.sale.subTotal = this.sale.subTotal + parseFloat(item.subTotal);
          this.sale.total = this.sale.total + parseFloat(item.total);
          this.sale.netTotal = this.sale.netTotal + parseFloat(item.netTotal);
          this.sale.taxTotal = this.sale.taxTotal + parseFloat(item.tax);
          this.sale.discount = this.sale.discount + parseFloat(item.discount);
          this.sale.totalCuver = this.sale.totalCuver + parseFloat(item.cuver);
          this.sale.totalCustomer = this.sale.totalCustomer + parseFloat(item.saleCount);
          this.sale.totalCheck = this.sale.totalCheck + parseFloat(item.saleCount);
          this.sale.totalCost = this.sale.totalCost +  parseFloat(item.totalCost);
        });
        this.sale.totalCuverAverage = this.sale.netTotal / this.sale.totalCuver;
        this.sale.totalCustomerAverage = this.sale.netTotal / this.sale.totalCustomer;
        this.sale.totalAverage = this.sale.netTotal / this.sale.totalCustomer;
        this.sale.checkCost = this.sale.totalCost / this.sale.totalCheck;
        this.sale.customerCost = this.sale.totalCost / this.sale.totalCustomer;
      } else {
      this.incomeExpenseAnalys = response;
      this.saleDates.map(item => {
          this.sale.subTotal = this.sale.subTotal + parseFloat(item.subTotal);
          this.sale.total = this.sale.total + parseFloat(item.total);
          this.sale.netTotal = this.sale.netTotal + parseFloat(item.netTotal);
          this.sale.taxTotal = this.sale.taxTotal + parseFloat(item.tax);
          this.sale.discount = this.sale.discount + parseFloat(item.discount);
          this.sale.totalCuver = this.sale.totalCuver + parseFloat(item.cuver);
          this.sale.totalCustomer = this.sale.totalCustomer + parseFloat(item.saleCount);
          this.sale.totalCheck = this.sale.totalCheck + parseFloat(item.saleCount);
          this.sale.totalCost = this.sale.totalCost +  parseFloat(item.totalCost);
          this.incomeExpenseAnalysTotal.subTotal = this.incomeExpenseAnalysTotal.subTotal + parseFloat(item.subTotal);
          this.incomeExpenseAnalysTotal.total = this.incomeExpenseAnalysTotal.total + parseFloat(item.total);
          this.incomeExpenseAnalysTotal.netTotal = this.incomeExpenseAnalysTotal.netTotal + parseFloat(item.netTotal);
          this.incomeExpenseAnalysTotal.taxTotal = this.incomeExpenseAnalysTotal.taxTotal + parseFloat(item.tax);
          this.incomeExpenseAnalysTotal.discount = this.incomeExpenseAnalysTotal.discount + parseFloat(item.discount);
          this.incomeExpenseAnalysTotal.totalCuver = this.incomeExpenseAnalysTotal.totalCuver + parseFloat(item.cuver);
          this.incomeExpenseAnalysTotal.totalCustomer = this.incomeExpenseAnalysTotal.totalCustomer + parseFloat(item.saleCount);
          this.incomeExpenseAnalysTotal.totalCheck = this.incomeExpenseAnalysTotal.totalCheck + parseFloat(item.saleCount);
          this.incomeExpenseAnalysTotal.totalCost = this.incomeExpenseAnalysTotal.totalCost +  parseFloat(item.totalCost);
        });
      this.sale.totalCuverAverage = this.sale.netTotal / this.sale.totalCuver;
      this.sale.totalCustomerAverage = this.sale.netTotal / this.sale.totalCustomer;
      this.sale.totalAverage = this.sale.netTotal / this.sale.totalCustomer;
      this.sale.checkCost = this.sale.totalCost / this.sale.totalCheck;
      this.sale.customerCost = this.sale.totalCost / this.sale.totalCustomer;
      this.incomeExpenseAnalysTotal.totalCuverAverage = this.incomeExpenseAnalysTotal.netTotal / this.incomeExpenseAnalysTotal.totalCuver;
        // tslint:disable-next-line:max-line-length
      this.incomeExpenseAnalysTotal.totalCustomerAverage = this.incomeExpenseAnalysTotal.netTotal / this.incomeExpenseAnalysTotal.totalCustomer;
      this.incomeExpenseAnalysTotal.totalAverage = this.incomeExpenseAnalysTotal.netTotal / this.incomeExpenseAnalysTotal.totalCustomer;
      this.incomeExpenseAnalysTotal.checkCost = this.incomeExpenseAnalysTotal.totalCost / this.incomeExpenseAnalysTotal.totalCheck;
      this.incomeExpenseAnalysTotal.customerCost = this.incomeExpenseAnalysTotal.totalCost / this.incomeExpenseAnalysTotal.totalCustomer;

      }


    });

    await this.http.get('/api/reports/categories_reports', this.parameters).subscribe(response => {
      this.categories = response;
    });
    await this.http.get('/api/reports/income_invoice_reports', this.parameters).subscribe(response => {
      this.incomeInvoice = response;
    });
    await this.http.get('/api/reports/return_invoice_reports', this.parameters).subscribe(response => {
      this.returnInvoice = response;
    });
    await this.http.get('/api/reports/purchase_info_reports', this.parameters).subscribe(response => {
      this.purchase = response;
    });
    await this.http.get('/api/reports/transfer_reports', this.parameters).subscribe(response => {
      this.transfer = response;
    });
    await this.http.get('/api/reports/waste_event_reports', this.parameters).subscribe(response => {
      this.wasteEvent = response;
    });
  }

  chartOption(): void {
    this.chartOptions = {
      zoomEnabled: true,
      zoomType: 'xy',
      theme: 'light2',
      animationEnabled: true,
      /*
      title: {
        text: 'Waste Generation and Urbanization by Region'
      },
      */
      axisX: {
        title: this.translate.instant('AVERAGE_PROFIT_ITEM'),
        labelFontSize: 0,
        titleFontSize: 12,
        suffix: '',
        interval: 1,
        gridThickness: 0.2,
        minimum: 0 - 0.1,
        maximum: this.getBaseLog(10, this.maxProfit) + 0.1,
        stripLines: [
          {
            value: (this.getBaseLog(10, this.maxProfit) / 2).toFixed(2),
            color: '#002bff',
          }
        ],
        lineColor: 'black', // Sınır rengi
        lineThickness: 1 // Sınır kalınlığı
      },
      axisY: {
        title: this.translate.instant('SOLD_ITEMS'),
        labelFontSize: 0,
        titleFontSize: 12,
        includeZero: true,
        gridThickness: 0.2,
        maximum: this.getBaseLog(10, this.maxQuantity) + 0.1,
        minimum: 0 - 0.1,
        stripLines: [
          {
            value: (this.getBaseLog(10, this.maxQuantity) / 2).toFixed(2),
            color: '#18dde3',
          }
        ],
        lineColor: 'black', // Sınır rengi
        lineThickness: 1 // Sınır kalınlığı
      },
      data: [{
        type: 'scatter',
        markerType: 'circle',  // "circle", "square", "cross", "none"
        markerSize: 10,
        markerMargin: 1,
        indexLabel: '{name}',
        fontSize: 70,
        color: '#18dde3',
        // toolTipContent: '{name}<br>Satış Adedi: {q}<br>Birim Maliyeti: {salePrice}<br>
        // Toplam Maliyeti: {sale}<br>COST: {cost}<br>Toplam COST: {totalCost}',
        toolTipContent:
          '<div style=\'"\'height: 75px; \'"\'>{name} <div style=\'"\'width: 400px;\'"\'> ' +
          '<table style=\'"\'width: 50%; float: left; border-right: 3px solid #18dde3; \'"\'>' +
          '<tr><td style=\'"\'width: 70%;\'"\'>Satış Adedi: {q}</td></tr>' +
          '<tr><td>Satış Fiyatı: {salePrice}{currency}</td></tr>' +
          '<tr><td>Birim Maliyeti: {cost}{currency}</td></tr> ' +
          '</table>' +
          '<table style=\'"\'width: 50%; float: left;\'"\'>' +
          '<tr><td>Toplam Gelir: {sale}{currency}</td></tr>' +
          '<tr><td>Toplam Gider: {totalCost}{currency}</td></tr>' +
          '<tr><td style=\'"\'color: red;\'"\'>Cost: %{costRate} </td></tr> ' +
          '</table>' +
          '</div></div>',
        dataPoints: this.points
      }]
    };
    setTimeout(() => {
      this.loading = false;
    }, 1000);
  }

  getBaseLog(x, y): number {
    return Math.log(y) / Math.log(x);
  }

  onSelectDate(event): void {
    const start = event[0];
    const end = event[1];
    if (event[1] === null) {
      this.parameters.startDate = this.getFormattedDate(event[0]);
      this.parameters.lastDate = this.getFormattedDate(event[0]);
      const dateRange = this.getDateRange(start, start);
    } else {
      this.parameters.startDate = this.getFormattedDate(event[0]);
      this.parameters.lastDate = this.getFormattedDate(event[1]);
      const dateRange = this.getDateRange(start, end);
    }
    // @ts-ignore
    const selectedDate = dateRange.map(date => this.getFormattedDate(date));

    this.parameters.saleDate = selectedDate.join(',');

    this.parameters = {...this.parameters};


  }

  getDateRange(start: Date, end: Date): Date[] {
    const dateRange: Date[] = [];
    const currentDate = new Date(start);

    while (currentDate <= end) {
      dateRange.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return dateRange;
  }

  onSelectLocation(event): void {
    this.parameters.location = event.value ? event.value : '';
    this.parameters = {...this.parameters};
  }

  getProfitRatio(total, profit): string {
    const costOrani = (profit / total) * 100;
    return (costOrani.toFixed(2) + '%');
  }

  getThisWeekDates(): void {
    const currentDate = new Date();
    const currentDay = currentDate.getDay();
    const firstDayOfWeek = new Date(currentDate);
    firstDayOfWeek.setDate(currentDate.getDate() - currentDay); // Haftanın ilk gününü al
    const thisWeekDates: string[] = [];

    for (let i = 0; i < 7; i++) {
      const date = new Date(firstDayOfWeek);
      date.setDate(firstDayOfWeek.getDate() + i);
      thisWeekDates.push(this.getFormattedDate(date));
    }
    this.parameters.startDate = thisWeekDates[0];
    this.parameters.lastDate = thisWeekDates[6];
    this.dateRange = [new Date(thisWeekDates[0]), new Date(thisWeekDates[6])];

    this.parameters.saleDate = thisWeekDates.join(',');

  }

  getFormattedDate(date: Date): string {
    const year = date.getFullYear();
    const month = this.addLeadingZero(date.getMonth() + 1);
    const day = this.addLeadingZero(date.getDate());

    return `${year}-${month}-${day}`;
  }

  addLeadingZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }
}
