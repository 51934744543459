<p-card>
  <app-loading-indicator [loading]="loading"></app-loading-indicator>

  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'WORK_ORDER' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'WORK_ORDER'"></app-page-info>
      <button pButton pRipple [style]="{marginLeft: '5px', float:'right'}" type="button" (click)="createNewWorkOrder()"
              label="{{ 'NEW_WORK_ORDER' | translate}} "> </button>
    </div>
  </div>
  <div class="p-grid">
    <div class="tableDiv-content">
      <div class="tableDiv-sub">
        <div class="show-elements">
          <p-checkbox [(ngModel)]="showCode" [binary]="true" id="code" inputId="binary"></p-checkbox>
          <label for="price"> {{'CODE' | translate}}</label>
        </div>
      </div>
    </div>
    <div class="p-col-12" style="overflow-x: auto;margin-top: 20px">
      <form action="" (submit)="load()">
        <p-table [loading]="tableLoading" [lazy]="true" [value]="items" [totalRecords]="total" (onLazyLoad)="load($event)"
                 [showCurrentPageReport]="true" styleClass="p-datatable-striped"
                 [paginator]="true" [rows]="20">
          <ng-template pTemplate="header">
            <tr>
              <th [style]="{width: '3%'}"></th>
              <th [style]="{width: '3%'}"></th>
              <th [style]="{width: '12%'}">{{'WORK_ORDER_NUMBER' | translate}}
                <app-tooltip description="WORK_ORDER_NUMBER_TOOLTIP"></app-tooltip>
              </th>
              <th [style]="{width: '30%'}">{{'PRODUCT' | translate}}
                <app-tooltip description="WORK_ORDER_PRODUCT_TOOLTIP"></app-tooltip>
              </th>
              <th [style]="{width: '10%'}">{{'PLANNED_QUANTITY' | translate}}
                <app-tooltip description="WORK_ORDER_PLANNED_QUANTITY_TOOLTIP"></app-tooltip>
              </th>
              <th [style]="{width: '10%'}">{{'FINAL_QUANTITY' | translate}}
                <app-tooltip description="WORK_ORDER_FINAL_QUANTITY_TOOLTIP"></app-tooltip>
              </th>
              <th [style]="{width: '10%'}" *ngIf="showCode">{{'BARCODE' | translate}}
                <app-tooltip description="WORK_ORDER_BARCODE_TOOLTIP"></app-tooltip>
              </th>
              <th [style]="{width: '10%'}" *ngIf="showCode">{{'PLANNED_TIME' | translate}}
                <app-tooltip description="WORK_ORDER_PLANNED_TIME_TOOLTIP"></app-tooltip>
              </th>
              <th [style]="{width: '10%'}" *ngIf="showCode">{{'DEADLINE' | translate}}
                <app-tooltip description="WORK_ORDER_DEADLINE_TOOLTIP"></app-tooltip>
              </th>
              <th [style]="{width: '15%'}">{{'PERSONAL' | translate}}
                <app-tooltip description="WORK_ORDER_PERSONAL_TOOLTIP"></app-tooltip>
              </th>
              <th [style]="{width: '15%'}">{{'MATERIAL_STATUS' | translate}}
                <app-tooltip description="WORK_ORDER_MATERIAL_STATUS_TOOLTIP"></app-tooltip>
              </th>
              <th [style]="{width: '15%'}">{{'STATUS' | translate}}
                <app-tooltip description="WORK_ORDER_PRODUCTION_STATUS_TOOLTIP"></app-tooltip>
              </th>
              <th [style]="{width: '10%'}">{{'ACTIONS' | translate}}
                <app-tooltip description="WORK_ORDER_PRODUCTION_ACTIONS_TOOLTIP"></app-tooltip>
              </th>
            </tr>
            <tr>
              <td></td>
              <td></td>
              <td><input type="text" pInputText [(ngModel)]="parameters.code" name="code"></td>
              <td><input type="text" pInputText [(ngModel)]="parameters['variant.name']" name="variantName"></td>
              <td><input type="text" pInputText [(ngModel)]="parameters.plannedQuantity" name="plannedQuantity"></td>
              <td><input type="text" pInputText [(ngModel)]="parameters.finalQuantity" name="finalQuantity"></td>
              <td>

                <p-dropdown id="personal" appendTo="body" placeholder="{{'SELECT' | translate}}"
                            (onChange)="parameters.chef = $event.value; load()"
                            [options]="users" [(ngModel)]="parameters.chef" optionValue="id">
                  <ng-template let-item pTemplate="selectedItem">
                    {{item.firstName}} {{item.lastName}}
                  </ng-template>
                  <ng-template let-item pTemplate="item">
                    {{item.firstName}} {{item.lastName}}
                  </ng-template>
                </p-dropdown>
              </td>
              <td *ngIf="showCode"><input type="text" pInputText [(ngModel)]="parameters.barcode" name="variantName"></td>
              <td *ngIf="showCode"><input type="text" pInputText [(ngModel)]="parameters.plannedTime" name="plannedTime"></td>
              <td *ngIf="showCode">
                <p-calendar #calendar (onSelect)="closeCalendar()" appendTo="body" (onClose)="load()"
                            selectionMode="range" [(ngModel)]="parameters.deadline" name="expectedDate"
                            dateFormat="yy-mm-dd"></p-calendar>
              </td>
              <td>
                <p-dropdown inputId="dropdown" appendTo="body"
                            (onChange)="parameters.materialStatus = $event.value; load()" baseZIndex="9999"
                            [options]="stockStatuses" name="stockStatuses" [(ngModel)]="parameters.materialStatus"
                            optionLabel="name" optionValue="value"></p-dropdown>
              </td>
              <td>
                <p-dropdown inputId="dropdown" appendTo="body" (onChange)="parameters.status = $event.value; load()"
                            baseZIndex="9999" [options]="productionStatuses" name="status"
                            [(ngModel)]="parameters.status" optionLabel="name" optionValue="value"></p-dropdown>
              </td>
              <td>

              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-row let-index="rowIndex">
            <tr [pReorderableRow]="index">
              <td>
                {{ index + 1 }}
              </td>
              <td><i class="pi pi-print" (click)="showDetail(row)"></i> </td>
              <td width="15%">
                <!--<a (click)="editWorkOrder(row)">{{ row.code }}</a>--->
                <p-badge   severity="{{row.plannedQuantity === row.finalQuantity ? 'success': 'warning'}}"></p-badge> {{ row.code }}
              </td>
              <td>{{ row.variant.name }}</td>
              <td [style]="{textAlign: 'right'}">{{ row.plannedQuantity | number: '1.2-2' }} {{ row.stock.unit.name}}</td>
              <td [style]="{textAlign: 'right', color: row.plannedQuantity !== row.finalQuantity ? '#d10700': ''}">{{ row.finalQuantity | number: '1.2-2' }} {{ row.stock.unit.name}}</td>
              <td>{{ row.chef?.firstName }} {{ row.chef?.lastName }}</td>
              <td [style]="{textAlign: 'right'}" *ngIf="showCode">{{ row.barcode }}</td>
              <td [style]="{textAlign: 'right'}" *ngIf="showCode">{{ row.plannedTime }}</td>
              <td [style]="{textAlign: 'right'}" *ngIf="showCode">{{ row.deadline|date: 'dd-MM-Y' }}</td>
              <td>
                <p-tag [severity]="row.materialStatus|statusLabel">{{ row.materialStatus|translate }}</p-tag>
              </td>
              <td>
                <p-tag [severity]="row.status|statusLabel">{{row.status | translate}}</p-tag>
              </td>
              <td>
                <div *ngIf="row.status !== 'FINISHED'">
                  <p-button icon="pi pi-play" *ngIf="row.status === 'NOT_STARTED' || row.status === 'PAUSED'"  [style]="{marginLeft: '5px',background: '#22c55d', border: 'none'}"(onClick)="changeStatus(row.id, 'STARTED', null)"></p-button>
                  <p-button icon="pi pi-check-square" *ngIf="row.status !== 'NOT_STARTED'" [style]="{marginLeft: '5px',background: '#ee3d15', border: 'none'}" (onClick)="changeStatus(row.id, 'FINISHED', row)"></p-button>
                  <p-button icon="pi pi-pause" *ngIf="row.status !== 'NOT_STARTED'" [style]="{marginLeft: '5px',background: '#fbc02d', border: 'none'}" (onClick)="changeStatus(row.id, 'PAUSED', null)"></p-button>

                </div>
               </td>
            </tr>
          </ng-template>
        </p-table>
        <button type="submit" style="display: none" pButton label="{{'workOrderList.search' | translate}}"
                (click)="load()"></button>
      </form>

    </div>
  </div>
</p-card>
<p-sidebar *ngIf="newWorkOrder" [(visible)]="newWorkOrder" [style]="{width: '70vw'}"  position="right" (close)="close()" (onHide)="close()">
  <app-work-order-create [id]="workOrderId"></app-work-order-create>
</p-sidebar>

<p-sidebar *ngIf="detailDisplay" [(visible)]="detailDisplay" [style]="{width: '70vw'}"  position="right">

  <div class="p-grid">
    <div class="p-col-12 p-justify-end">
      <i class="pi pi-print" (click)="print()"></i>
    </div>
    <div class="p-col-12" #printable id="printable">
      <table style="width:100%">
        <tr>
          <td [style]="{width:'20%'}">{{'CODE'| translate}}</td>
          <td [style]="{width:'1%'}">:</td>
          <td [style]="{width:'79%'}"><b [style]="{fontSize:'16px'}">{{ workOrderForDetail.code}}</b></td>
        </tr>
        <tr>
          <td>{{'PRODUCT' | translate}}</td>
          <td>:</td>
          <td>
            <b>{{ workOrderForDetail.stock?.name}}</b>
          </td>
        </tr>
        <tr>
          <td>{{'PLANNED_QUANTITY' | translate}}</td>
          <td>:</td>
          <td>
            {{ workOrderForDetail.plannedQuantity}}
          </td>
        </tr>
        <tr>
          <td>{{'BARCODE' | translate}}</td>
          <td>:</td>
          <td>{{ workOrderForDetail.barcode}}</td>
        </tr>
        <tr>
          <td>{{'STT' | translate}}</td>
          <td>:</td>
          <td>{{ workOrderForDetail.expirationDate}}</td>
        </tr>
        <tr>
          <td>{{'DEADLINE' | translate}}</td>
          <td>:</td>
          <td>{{ workOrderForDetail.deadline}}</td>
        </tr>
        <tr>
          <td>{{ 'SOURCE' | translate}}</td>
          <td>:</td>
          <td>
            {{ workOrderForDetail.source.name}}
          </td>
        </tr>
        <tr>
          <td>{{ 'TARGET' | translate}}</td>
          <td>:</td>
          <td>
            {{ workOrderForDetail.target.name}}
          </td>
        </tr>
      </table>
      <p-table [value]="workOrderForDetail.materials">
        <ng-template pTemplate="header">
          <tr>
            <th scope="col" [style]="{width:'20%'}">{{'STOCK' | translate}}</th>
            <th scope="col" [style]="{width:'12%'}">{{'LOT' | translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{'AMOUNT' | translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{'COST' | translate}}</th>
            <th scope="col" [style]="{width:'12%'}">{{'CREATED_USER' | translate}}</th>
            <th scope="col" [style]="{width:'12%'}">{{'CREATED_AT' | translate}}</th>
            <th scope="col" [style]="{width:'12%'}">{{'UPDATED_USER' | translate}}</th>
            <th scope="col" [style]="{width:'12%'}">{{'UPDATED_AT' | translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-order let-i= "rowIndex">
          <tr>
            <td>{{order.variant.name}}</td>
            <td>{{order.batches[0].barcode}}</td>

            <td [style]="{textAlign: 'right'}">{{order.wastage | number: '1.2-2'}} {{ order.variant?.stock?.unit.name}}</td>
            <td [style]="{textAlign: 'right'}">{{order.cost | countryCurrency}}</td>
            <td>
              {{order.batches[0].batch.createdBy.firstName}}
              {{order.batches[0].batch.createdBy.lastName}}
            </td>
            <td>
              {{order.batches[0].batch.createdAt | date: ('dd-MM-Y')}}
            </td>
            <td>
              {{order.batches[0].batch.updatedBy.firstName}}
              {{order.batches[0].batch.updatedBy.lastName}}
            </td>
            <td>
              {{order.batches[0].batch.updatedAt | date: ('dd-MM-Y')}}
            </td>

          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">{{'NOT_FOUND_RECIPE' | translate}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>

  </div>
</p-sidebar>


<p-dialog [(visible)]="finishedDisplay" *ngIf="finishedDisplay" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}" (close)="close()">
  <ng-template pTemplate="header">
    {{'WORK_ORDER_FINISHED' | translate}}
  </ng-template>
  <div class="p-grid">
    <div class="p-col-12"><label for="integeronly" class="p-mt-4">{{'HOW_MUCH' |translate }}
      <b>{{ selectedWorkOrder.stock?.unit.name}} {{ selectedWorkOrder.stock?.name}}</b>
      {{'PRODUCED' |translate }} ({{'PLANNED_QUANTITY' | translate}} : {{ selectedWorkOrder.plannedQuantity}})
    </label>
      <input [style]="{ width: '100%',height:'45px'}" id="optionprice" type="number" pInputText
             [(ngModel)]="selectedWorkOrder.plannedQuantity" (change)="setFinalQuantity($event)"></div>
    <div class="p-col-12 alert-div" *ngIf="isPackSavilable">
      {{ 'PACKET_QUANTITY_NOT_BIG_FINAL_QUANTITY' | translate}}</div>
    <div class="p-col-12" *ngIf="packStocks.length > 0 ">
      <button pButton pRipple type="button" label="{{'PACK_OPTIONS'| translate }}" (click)="onRecipeOrPackOption('pack')" class="p-button-rounded p-mb-4"></button>

      <p-table [value]="packStocks" *ngIf="packOption" dataKey="id" styleClass="p-datatable-sm" [loading]="submitted">
        <ng-template pTemplate="header">
          <tr>
            <th [style]="{width:'5%'}" scope="col">{{ 'No'}}</th>
            <th [style]="{width:'80%'}" scope="col">{{ 'STOCK' | translate}}</th>
            <th [style]="{width:'15%'}" scope="col">{{ 'UNIT_QUANTITY' | translate}}</th>
            <th [style]="{width:'15%'}" scope="col">{{ 'QUANTITY' | translate}}</th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-i="rowIndex">
          <tr>
            <td>{{i +1}} </td>
            <td>{{item.packStock.name }}</td>
            <td>{{item.quantity }} {{item.packStock.unit?.name }}</td>
            <td>
              <input [style]="{ width: '100%',height:'45px'}" id="optionprice" type="number" pInputText
                     [(ngModel)]="item.finalQuantity" (change)="produceControl()" min="1">
            </td>
          </tr>
        </ng-template>
      </p-table></div>
  </div>
  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-times" label="{{'CANCEL' | translate }}" (click)="close()"></button>
    <button type="button" pButton icon="pi pi-check" label="{{'SAVE' | translate }}" *ngIf="!isPackSavilable" (click)="workOrderFinishSave()"></button>
  </ng-template>
</p-dialog>

<p-confirmDialog #cd [style]="{width: '50vw'}">
  <ng-template pTemplate="header">
    <h3>{{ 'ALERT' | translate}}</h3>
  </ng-template>
  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-times" label="{{'NO' | translate }}" (click)="cd.reject()"></button>
    <button type="button" pButton icon="pi pi-check" label="{{'YES' | translate }}" (click)="cd.accept()"></button>
  </ng-template>
</p-confirmDialog>

