<app-loading-indicator [loading]="loading"></app-loading-indicator>
<div class="p-grid">
  <div class="p-sm-12 p-md-12 p-lg-9 p-xl-9">
    <p-card>
      <div class="p-grid">
        <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'NEW_ORDER' | translate}}</h3></div>
        <div class="p-col-6 p-md-6 p-sm-6">
          <app-page-info [pageName]="'NEW_ORDER'"></app-page-info>
          <p-splitButton label="{{ 'ACTIONS' | translate}}" [model]="this.actionItems" [style]="{float:'right'}"
                         styleClass="p-button-outlined mr-2 mb-2"></p-splitButton>
        </div>
      </div>
      <div class="p-grid">
        <div class="p-col-12 p-md-6 p-lg-3">
          <p-dropdown
            [style]="{width: '100%',border:'none',borderBottom: '2px solid #47bfd4'}"
            [options]="searchCategories" [(ngModel)]="parameters['category.id']"
            placeholder="{{ 'SELECT_CATEGORY'| translate}}"
            (onChange)="changeCategory()" optionLabel="name" optionValue="id" [filter]="true" filterBy="name">
          </p-dropdown>
        </div>
        <div class="p-col-12 p-md-6 p-lg-3">
          <p-dropdown
            [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
            [options]="searchContacts" [(ngModel)]="parameters['supplier.id']" (onChange)="changeContact($event)"
            placeholder="{{'SELECT_SUPPLIER'| translate}}"
            optionLabel="name" optionValue="id" [filter]="true" filterBy="name">
          </p-dropdown>
        </div>
        <div class="p-col-12 p-md-6 p-lg-3">
          <p-dropdown
            [style]="{width: '100%',border:'none',borderBottom: '2px solid #47bfd4'}"
            [options]="locations" [(ngModel)]="parameters.locations" (onChange)="changeLocation($event)"
            optionLabel="name" optionValue="id" placeholder="{{ 'SELECT_LOCATION'| translate}}"
            [filter]="true" filterBy="name">
          </p-dropdown>
        </div>
        <div class="p-col-12 p-md-6 p-lg-3">
          <form (ngSubmit)="onLoadStock('search')">
            <span class="p-input-icon-left" [style]="{width:'100%'}">
              <i class="pi pi-search"></i>
              <input name="globalSearch" type="text"
                     [style]="{width: '100%',height: '45px',border:'none', borderBottom: '2px solid #47bfd4'}"
                     pInputText placeholder="{{ 'GLOBAL_SEARCH'| translate }}"
                     [(ngModel)]="parameters.name" (change)="changeNameInput($event)"/>
                  <button pButton type="submit" icon="pi pi-search" label="{{'search' | translate}}"
                          class="p-d-none p-button-success p-mr-2 p-mb-4"></button>
          </span>
          </form>
        </div>
      </div>
      <div class="p-grid">
        <div class="p-col-12" style="overflow-x: auto;margin-top:20px">

          <p-table #table responsiveLayout="stack" [tableStyle]="{'min-width': '45rem'}"
                   [loading]="tableLoading" [lazy]="true" [value]="stocks"
                   [rows]="parameters.itemsPerPage" [showCurrentPageReport]="true" [resizableColumns]="true"
                   dataKey="id"
                   styleClass="p-datatable-striped p-datatable-sm" columnResizeMode="expand">
            <ng-template pTemplate="header">
              <tr>
                <th [style]="{width: '30px'}" id=""></th>
                <th [style]="{textAlign: 'center',width:'25%'}" id="STOCK">{{ 'STOCK'| translate }}</th>
                <th [style]="{textAlign: 'left',width:'15%'}" id="MIN_ORDER">{{ 'MIN_ORDER'| translate }}</th>
                <th [style]="{textAlign: 'center',width:'10%'}" id="PRICE">{{ 'PRICE'| translate }}</th>
                <th [style]="{textAlign: 'center',width: '30%'}" id="ON_HAND_PAR">{{ 'ON_HAND_PAR'| translate }}</th>
                <th [style]="{width: '5%'}" id="null"></th>
                <th [style]="{width: '20%'}" id="QUANTITY">{{ 'QUANTITY'| translate }}</th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-row let-i="rowIndex" let-expanded="expanded">
              <tr>
                <td [style]="{padding : '0px'}">
                  <button *ngIf="row.purchaseOptions?.length > 0" type="button" pButton pRipple [pRowToggler]="row"
                          class="p-button-text p-button-rounded p-button-plain"
                          [icon]="expanded ? 'pi pi-minus' : 'pi pi-plus'"></button>
                </td>
                <td><a href="javascript:void(0)" *ngIf="checkActionPermission('EDIT_STOCK', 'ORDERS')" (click)="edit(row)">
                  <i class="pi pi-pencil" style="font-size: 10px"></i>
                </a> {{ row.variants[0].name}}
                  <br>
                  {{ row.supplier?.name| slice:0:14  }}...
                </td>
                <td [style]="{textAlign: 'center'}">{{ row.variants[0].minOrder}} {{ row.unit.name}}</td>
                <td [style]="{textAlign: 'center'}" [ngClass]="priceCheck(row.variants[0].price, row)">
                  {{ row.variants[0].price | countryCurrency }}


                </td>
                <td>
                  <div class="on-hand-div">
                    <div class="on-hand-div-row1">{{ row.variants[0].amount}} /</div>
                    <div class="on-hand-div-row2">
                      <p-inplace closable="closable" [style]="{float: 'left'}">
                        <ng-template pTemplate="display">
                          {{ row.variants[0].alertLevel !== 0 ? row.variants[0].alertLevel : 'set'}}
                        </ng-template>
                        <ng-template pTemplate="content">
                          <div class="p-inputgroup">
                            <input name="alertLevel" type="number" id="{{i}}" pInputText placeholder="AlertLevel"
                                   [(ngModel)]="row.variants[0].alertLevel" [style]="{width: '75px !important'}">
                            <button type="button" (click)="changeAlertLevel(row.variants[0].alertLevel, row , i)"
                                    pButton
                                    pRipple icon="pi pi-check" styleClass="p-button-success"></button>
                          </div>
                        </ng-template>
                      </p-inplace>
                    </div>
                  </div>
                </td>
                <td [style]="{padding: '0px'}">
                  <div class="on-hand-div-row3">
                    <button
                      *ngIf="row.variants[0].orderQuantity === (row.variants[0].alertLevel - row.variants[0].amount)"
                      pButton pRipple type="button"
                      icon="pi pi-check-circle"
                      class="p-button-rounded"
                      [style]="{float:'right',marginTop: '-5px',background:'none',border: 'none',color:'black'}">
                    </button>
                    <button
                      *ngIf="row.variants[0].alertLevel > 0 && row.variants[0].alertLevel > row.variants[0].amount && row.variants[0].orderQuantity !== (row.variants[0].alertLevel - row.variants[0].amount) "
                      pButton pRipple type="button"
                      icon="pi pi-arrow-right"
                      class="p-button-rounded"
                      [style]="{float:'right',marginTop: '-5px',background:'none',border: 'none',color:'black'}"
                      (click)="onChangeQuantityTotal(row.variants[0].alertLevel - row.variants[0].amount, row)">
                    </button>
                  </div>
                </td>
                <td>
                  <p-inputNumber [(ngModel)]="row.variants[0].orderQuantity" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5"
                                 min="{{row.variants[0].minOrder}}" [showButtons]="true"
                                 (onInput)="onChangeQuantity($event, row , i)"
                                 decrementButtonClass="p-button-secondary" incrementButtonClass="p-button-secondary">
                  </p-inputNumber>
                </td>
              </tr>
            </ng-template>
            //////////
            <ng-template pTemplate="rowexpansion" let-row>
              <tr>
                <td colspan="7" [style]="{padding: '0px'}">
                  <div class="p-3" [style]="{padding: '0px !important'}">
                    <p-table [value]="row.purchaseOptions" dataKey="id" [style]="{marginTop: '0px !important'}">
                      <ng-template pTemplate="body" let-order let-i="orderIndex">
              <tr [style]="{background:'none !important'}">
                <td [style]="{width: '30px'}"></td>
                <td [style]="{width: '25%'}">{{order.name}} <br>{{ order.supplier.name | slice:0:14 }}...</td>
                <td [style]="{width: '12%'}">
                  {{ orderTypeText(order, row.unit.name)}}
                  <p *ngIf="order.baseUnitQuantity"
                     [style]="{marginTop: '0px'}">{{ order.baseUnitQuantity}} {{ row.unit.name}}
                  </p>
                </td>
                <td [style]="{width: '10%',padding:'0px'}">{{order.price | countryCurrency }} <br>
                  <p *ngIf="order.baseUnitQuantity"
                     [ngClass]="priceCheckOption(row.variants[0].price, (order.price / order.baseUnitQuantity))"
                     [style]="{marginTop: '0px'}">{{ (order.price / order.baseUnitQuantity) | countryCurrency }}
                    / {{ row.unit.name}}</p>
                  <p *ngIf="!order.baseUnitQuantity"
                     [style]="{marginTop: '0px'}"
                     [ngClass]="priceCheckOption(row.variants[0].price, (order.price / order.packQuantity))">
                    {{ (order.price / order.packQuantity) | countryCurrency }}
                    / {{ row.unit.name}}</p>
                </td>
                <td [style]="{width: '220px'}">
                  <div class="on-hand-div">
                    <div *ngIf="order.packageName == null && order.packPerCase == null || order.baseUnitQuantity == null" class="p-grid">
                      <div class="on-hand-div-row1">{{ order.onHand  }} /</div>
                      <div class="on-hand-div-row2">
                        <p-inplace closable="closable" [style]="{float: 'left'}">
                          <ng-template pTemplate="display">
                            {{ order.alertLevel }}
                          </ng-template>
                          <ng-template pTemplate="content">
                            <div class="p-inputgroup">
                              <input name="alertLevel" type="number" id="{{b}}" pInputText placeholder="AlertLevel"
                                     [(ngModel)]="order.alertLevel" [style]="{width: '75px !important'}">
                              <button type="button"
                                      (click)="changeAlertLevel(row.variants[0].alertLevel, row , b, order.packQuantity , order.alertLevel)"
                                      pButton pRipple icon="pi pi-check" styleClass="p-button-success"></button>
                            </div>
                          </ng-template>
                        </p-inplace>
                      </div>
                    </div>

                    <div *ngIf="order.baseUnitQuantity" class="p-grid">
                      <div class="on-hand-div-row1">{{ order.onHand  }} /</div>
                      <div class="on-hand-div-row2">
                        <p-inplace closable="closable" [style]="{float: 'left'}">
                          <ng-template pTemplate="display">
                            {{ order.alertLevel }}
                          </ng-template>
                          <ng-template pTemplate="content">
                            <div class="p-inputgroup">
                              <input name="alertLevel" type="number" id="{{ b }}" pInputText placeholder="AlertLevel"
                                     [(ngModel)]="order.alertLevel" [style]="{width: '75px !important'}">
                              <button type="button"
                                      (click)="changeAlertLevel(row.variants[0].alertLevel, row , b, order.baseUnitQuantity, order.alertLevel )"
                                      pButton pRipple icon="pi pi-check" styleClass="p-button-success"></button>
                            </div>
                          </ng-template>
                        </p-inplace>
                      </div>

                    </div>

                  </div>
                </td>
                <td [style]="{width: '50px',padding:'0px'}">
                  <div *ngIf="order.packageName == null && order.packPerCase == null && order.baseUnitQuantity == null">
                    <div class="on-hand-div-row3">
                      <button
                        *ngIf="row.variants[0].orderQuantity === (row.variants[0].alertLevel - row.variants[0].amount)"
                        pButton pRipple type="button" icon="pi pi-check-circle" class="p-button-rounded"
                        [style]="{float:'right',marginTop: '-5px',background:'none',border: 'none',color:'black'}">
                      </button>
                      <button
                        *ngIf="row.variants[0].alertLevel > 0 && (row.variants[0].amount / order.packQuantity) < (row.variants[0].alertLevel  / order.packQuantity) && row.variants[0].orderQuantity !== ((row.variants[0].alertLevel  / order.packQuantity) - row.variants[0].amount)  "
                        pButton pRipple type="button" icon="pi pi-arrow-right" class="p-button-rounded"
                        [style]="{float:'right',marginTop: '-5px',background:'none',border: 'none',color:'black'}"
                        (click)="onChangeQuantityTotal((order.alertLevel - order.onHand), row, order)">
                      </button>
                    </div>
                  </div>
                  <div *ngIf="order.baseUnitQuantity">
                    <div class="on-hand-div-row3">
                      <button
                        *ngIf="order.orderQuantity === order.alertLevel"
                        pButton pRipple type="button"
                        icon="pi pi-check-circle"
                        class="p-button-rounded"
                        [style]="{float:'right',marginTop: '-5px',background:'none',border: 'none',color:'black'}">
                      </button>
                      <button
                        *ngIf="row.variants[0].alertLevel > 0 && (row.variants[0].amount / order.baseUnitQuantity) < (row.variants[0].alertLevel  / order.baseUnitQuantity) && order.orderQuantity !== order.alertLevel "
                        pButton pRipple type="button"
                        icon="pi pi-arrow-right"
                        class="p-button-rounded"
                        [style]="{float:'right',marginTop: '-5px',background:'none',border: 'none',color:'black'}"
                        (click)="onChangeQuantityTotal((order.alertLevel - order.onHand), row ,order)">
                      </button>
                    </div>
                  </div>
                </td>
                <td>
                  <p-inputNumber [(ngModel)]="order.orderQuantity" mode="decimal" min="1" step="1" [showButtons]="true"
                                 (onInput)="onChangeQuantity($event, row , b, order )"
                                 [minFractionDigits]="2" [maxFractionDigits]="5"
                                 decrementButtonClass="p-button-secondary"
                                 incrementButtonClass="p-button-secondary">
                  </p-inputNumber>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        </td>
        </tr>
        </ng-template>
        </p-table>
      </div>
  </div>
  <div class="p-grid" style="padding-top: 20px" *ngIf="moreLoading">
    <div class="p-col-4 p-offset-4" style="">
      <button pButton label="{{ 'MORE' | translate}}" class="p-ml-3 p-mb-3" [style]="{width:'100%'}"
              [icon]="'pi pi-arrow-down'"
              (click)="onLoadStock()"></button>
    </div>
  </div>
  </p-card>
</div>
<div class="p-col-12 p-md-3 p-lg-3" *ngIf="supplierCount > 0">
  <p-card>
    <div class="p-grid">
      <div class="p-col-8 p-lg-10"><h3
        [style]="{ fontWeight: '600' }">{{ invoices[activeInvoice] ? invoices[activeInvoice].supplierName : '' }}</h3>
      </div>
      <div class="p-col-4 p-lg-2" style="cursor: pointer">
        <i class="pi pi-shopping-cart" style="font-size: 2rem;margin-top: 10px;" (click)="op.toggle($event)"></i>
        <span class="CountNotification ng-binding">{{supplierCount }}</span>
      </div>

    </div>

    <div class="p-grid">
      <div class="p-col-12">
        <p-divider></p-divider>
        <p-table [value]="invoices[activeInvoice].data.items ? invoices[activeInvoice].data.items : selectInvoicesItems"
                 styleClass="p-datatable-striped" responsiveLayout="scroll">
          <ng-template pTemplate="body" let-item let-i="rowIndex">
            <tr style="background: #E6F7FA;border-radius: 6px" class="hoverable" (click)="onInvoiceItemClick(i)">
              <td  [style]="{padding:'0px'}">
                <p-avatar label="{{ item.quantity}}" size="large"
                          [style]="{'background-color':'#00B4D2', 'color': '#ffffff',fontSize:'16px'}"></p-avatar>
              </td>
              <td [style]="{padding:'0px 10px',fontSize:'12px'}">
                {{ item.name}}<br>
                {{ item.totalOrder }} {{ item.unit}} / <b
                [style]="{fontSize:'14px'}">{{ item.unitPrice | countryCurrency}}</b></td>
              <td [style]="{padding:'0px'}">
                <button
                  [style]="{marginLeft:'10px', width:'20px', height:'20px',marginLeft: '10px',position: 'relative',right: '-2px',top: '-3px',scale: '0.7',float:'right'}"
                  pButton pRipple type="button" icon="pi pi-trash"
                  class="p-button-rounded p-button-danger p-button-text" *ngIf="itemClickValues === i"
                  (click)="removeInvoiceItem(i, invoices[activeInvoice].key,item.stock)">
                </button>
                <b [style]="{float: 'right'}">{{ item.subtotal | countryCurrency}}</b>
              </td>
            </tr>
            <tr *ngIf="itemClickValues === i">
              <td colspan="3" [style]="{padding: '0px'}">
                <p-inputNumber [style]="{scale: '0.6'}" [(ngModel)]="item.quantity" [showButtons]="true"
                               buttonLayout="horizontal"
                               inputId="horizontal" spinnerMode="horizontal" [step]="1"
                               (onInput)="getLastQuantity($event, item, i)"
                               decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success"
                               incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal">
                </p-inputNumber>
              </td>
            </tr>
          </ng-template>
        </p-table>

      </div>
      <div class="p-col-12">
        <p-divider></p-divider>
        <div class="p-fluid">
          <div class="p-field">
            <label for="comment">{{'COMMENT' | translate}}</label>
            <input name="comment" id="comment" [ngModelOptions]="{standalone: true}"
                   [(ngModel)]="invoices[activeInvoice].data.description"
                   (change)="setDescription($event)" type="text" pInputText autofocus>
          </div>
          <div class="p-field">
            <label for="deliveryDate">{{'DATE' | translate}}</label>
            <p-calendar id="deliveryDate" [(ngModel)]="invoices[activeInvoice].data.expectedDate" [baseZIndex]="99999"
                        (onSelect)="setDate($event)" dateFormat="yy-mm-dd"
            ></p-calendar>
          </div>
          <div class="p-field">
            <label for="location">{{'LOCATION' | translate}}</label>
            <p-dropdown id="location" [options]="locations" [(ngModel)]="invoices[activeInvoice].data.location"
                        (onChange)="setLocation($event)" placeholder="{{ 'SELECT_LOCATION'| translate }}"
                        optionLabel="name"
                        optionValue="@id" [filter]="true" filterBy="name"></p-dropdown>

          </div>

          <div class="p-field">
            <h3>{{'STATUS' | translate}}<b
              style="float: right;color: #64748B;font-weight: 400;">{{ invoices[activeInvoice].data.status | translate}}
              <i (click)="onDisplayInvoiceStatus()" class="pi pi-info"
                 style="border: 1px solid;border-radius: 50%;padding: 3px;color: #00b3d1"></i>
            </b></h3>
          </div>
        </div>
        <p-divider></p-divider>
        <div class="p-grid">
          <div class="p-col-6" [style]="{width : '100%',float: 'left'}">
            <h3>{{ 'TOTAL'| translate }}: <b
              style="float: right;color: #00b3d1">{{ (invoices[activeInvoice] ? invoices[activeInvoice].data.subtotal : '000.00') | countryCurrency }}</b>
            </h3>
          </div>
          <div class="p-col-6" [style]="{width : '100%',float: 'left'}">
            <div class="p-col-6" [style]="{width : '48%',float: 'left'}">
              <button pButton label="{{ 'CANCEL' | translate}}"
                      [style]="{width:'100%',background: 'none',color: '#444444',borderColor: '#444444'}"
                      icon="pi pi-times" class="p-button-raised p-button-secondary"
                      (click)="removeInvoice(invoices[activeInvoice].key)"></button>
            </div>
            <div class="p-col-6" [style]="{width : '48%',float: 'left'}">
              <button pButton label="{{ 'SAVE' | translate}}" class="p-ml-3 p-mb-3" [style]="{width:'100%'}"
                      [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" [disabled]="this.submitted"
                      (click)="save(invoices[activeInvoice].key)"></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </p-card>
</div>

<p-dialog [(visible)]="displaySuppliers" [style]="{width: '43vw'}" [closable]="false">
  <ng-template pTemplate="header">
    <div style="width: 50%">
      <h3>{{ 'SELECT_LOCATION_FOR_ORDER'| translate }}</h3>
    </div>
  </ng-template>
  <div class="dialogDiv">
    <div *ngFor="let location of locations">
      <div class="hoverable"
           style="padding: 20px 10px 7px 15px; border-radius: 5px;background: #ffffff;border:1px solid #f4f3f3">
        <a href="javascript:void(0)" style="color: #444444" (click)="changeLocation($event, location.id)">
          <div class="p-grid">
            <div class="p-col-2" style="padding: 0px;width: 50px">
              <p-avatar label="{{ location.name| slice:0:1}}"
                        [style]="{width:'32px',heigt:'32px','background-color':'#47bfd4', 'color': '#ffffff',fontSize:'25px',borderRadius:'50%'}"></p-avatar>
            </div>
            <div class="p-col-10" style="align-self:baseline"><b
              style="margin-top:5px;font-size: 14px">{{ location.name}}</b></div>
          </div>
        </a>
      </div>
    </div>
  </div>
</p-dialog>
<p-toast></p-toast>
<p-overlayPanel #op [showCloseIcon]="true" [style]="{overlayArrowLeft:'0px'}" [appendTo]="'body'">
  <ng-template pTemplate>
    <p-button styleClass="p-button-text p-button-secondary p-button-sm" (click)="addContact()"
              icon="pi pi-plus"></p-button>
    <p-table [value]="invoices" selectionMode="single" [rows]="5" responsiveLayout="scroll">
      <ng-template pTemplate="body" let-rowData let-item let-i="rowIndex">
        <tr>
          <td (click)="changeActiveInvoice(i)">{{ item.supplierName }}</td>
        </tr>
      </ng-template>
    </p-table>
  </ng-template>
</p-overlayPanel>
<p-sidebar [(visible)]="stockDialog" position="right" [style]="{width:'80em'}">
  <app-create></app-create>
</p-sidebar>

<p-dialog header="Sipariş Onay Durumu" [(visible)]="displayInvoiceStatus" [style]="{width: '50vw'}">
  <p>Siparişi Direk Olarak Onaylayıp Gönderme Yetkiniz Bulunmamaktadır.Sizin Verdiğiniz Siparişler Onaylandıktan Sonra
    Tedarikçiye Mail
    Olarak Gönderilecektir.</p>
  <p>Sipariş Onay Yetkisi Almak İçin Lütfen Yönetici İle İletişime Geçiniz</p>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="displayInvoiceStatus=false" label="Ok" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>

<p-sidebar position="right"  [(visible)]="displaySideBar" [style]="{width:'80em'}" (onHide)="reload()">
  <app-inventory-item-create *ngIf="displaySideBar === true"  [type]="'material'" [item]="item" appendto="body"></app-inventory-item-create>
</p-sidebar>

<p-dialog [(visible)]="displayAddContact" [style]="{width:'50em'}" (onHide)="onLoad()">
  <app-contact-create-dialog></app-contact-create-dialog>
</p-dialog>

<p-confirmDialog #cd icon="pi pi-exclamation-triangle">
  <ng-template pTemplate="header">
    <h3>{{'WARNING' | translate}}</h3>
  </ng-template>
  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-times" label="{{'NO'| translate}}" (click)="cd.reject()"></button>
    <button type="button" pButton icon="pi pi-check" label="{{'YES' | translate}}" (click)="cd.accept()"></button>
  </ng-template>
</p-confirmDialog>

<p-dialog header="Siparişiniz Oluşturuldu" [(visible)]="invoiceInfo" [style]="{width: '50vw'}">
  <a href="{{'/orders/order-details/' + invoiceRouteId}}">{{ invoiceInfoComment }}</a>
</p-dialog>

<p-sidebar [(visible)]="dataImportDialog" [style]="{width:'90em'}" position="right" (onHide)="reload()">
  <app-data-import></app-data-import>
</p-sidebar>

