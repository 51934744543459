import {ApiResourceModel} from './api-resource.model';
import {LocationModel} from './location.model';
import {StockModel} from './stock.model';
import {StockVariantModel} from './stock-variant.model';

export class StockQuantityModel extends  ApiResourceModel{
  public static  IRI = '/api/stock_quantities';
  public amount: number;
  public expectedAmount: number;
  public committedAmount: number;
  public missingAmount: number;
  public valueInStock: number;
  public location: LocationModel | string;
  public stock: StockModel[] = [];
  public stockVariant: StockVariantModel[] = [];
  public usableAmount: number;
  public averagePrice: number;
  public lastPurchasePrice: number;
  public alertLevel: any;
  public id: any;
}
