import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {StockModel} from '../../../../model/stock.model';
import {ContactModel} from '../../../../model/contact.model';
import {TaxRateModel} from '../../../../model/tax-rate.model';
import {CategoryModel} from '../../../../model/category.model';
import {UnitModel} from '../../../../model/unit.model';
import {CurrencyModel} from '../../../../model/currency.model';
import {Subscription} from 'rxjs';
import {LocationModel} from '../../../../model/location.model';
import {ConfirmationService, LazyLoadEvent, MenuItem} from 'primeng/api';
import {ProductRecipeModel} from '../../../../model/product-recipe.model';
import {ProductRecipeItemModel} from '../../../../model/product-recipe-item.model';
import {StockTransactionModel} from '../../../../model/stock-transaction.model';
import {StockVariantOptionModel} from '../../../../model/stock-variant-option.model';
import {ShipmentModel} from '../../../../model/shipment.model';
import {StockTransfersModel} from '../../../../model/stock-transfers.model';
import {WasteEvents} from '../../../../model/waste-events.model';
import {HttpService} from '../../../../service/http.service';
import {ActivatedRoute, Router} from '@angular/router';
import {DialogService} from 'primeng/dynamicdialog';
import {TranslateService} from '@ngx-translate/core';
import {FlashMessageService} from '../../../../service/flash-message.service';
import {CurrencyService} from '../../../../service/currency.service';
import {ValidationService} from '../../../../service/validation.service';
import {CategoryService} from '../../../../service/category.service';
import {TaxService} from '../../../../service/tax.service';
import {UnitService} from '../../../../service/unit.service';
import {ContactService} from '../../../../service/contact.service';
import {environment} from '../../../../../environments/environment';
import {VariantRecipeItemModel} from '../../../../model/variant-recipe-item.model';
import {FlashMessage, FlashMessageModel} from '../../../../model/flash-message.model';
import {CreateCategoryComponent} from '../../../settings/category/create-category/create-category.component';
import {CreateTaxComponent} from '../../../settings/tax/create-tax/create-tax.component';
import {CreateUnitComponent} from '../../../settings/unit/create-unit/create-unit.component';
import {ContactCreateDialogComponent} from '../../../contact/contact-create-dialog/contact-create-dialog.component';
import {PurchaseOptionModel} from '../../../../model/purchase-option.model';
import {StockPriceChanges} from '../../../../model/stock-price-changes.model';
import {VariantRecipeModel} from '../../../../model/variant-recipe.model';
import {SidebarService} from '../../../../service/sidebar.service';
import {WorkOrderOperationModel} from '../../../../model/work-order-operation.model';
import {StockVariantModel} from '../../../../model/stock-variant.model';
import {PackOptionModel} from '../../../../model/pack-option.model';
import {delay} from 'rxjs/operators';
import {UserPageInformationModel} from '../../../../model/user-page-information.model';
import {UserPageInformationService} from '../../../../service/user-page-information.service';
import {UserModel} from '../../../../model/user.model';

@Component({
  selector: 'app-inventory-item-create',
  templateUrl: './inventory-item-create.component.html',
  styleUrls: ['./inventory-item-create.component.scss']
})
export class InventoryItemCreateComponent implements OnInit, OnDestroy {

  @Input() type: string;
  @Input() item: StockModel = new StockModel();
  locationId: any;
  id: number;
  loading: boolean;
  submitted: boolean;
  suppliers: ContactModel[] = [];
  taxes: TaxRateModel[] = [];
  categories: CategoryModel[] = [];
  units: UnitModel[] = [];
  currencies: CurrencyModel[] = [];
  trackTypes: any[] = [];
  currency: string;
  categoryCode: any;
  validationSubscriber: Subscription;
  contactTypes: [{ name: any; value: any }, { name: any, value: any }];
  locations: LocationModel[] = [];
  stockCount: any;
  tabMenuItems: MenuItem[];
  activeTabMenuItem: MenuItem;
  activeTab: number;
  recipe: ProductRecipeModel[] = [];
  recipeItem: ProductRecipeItemModel[] = [];
  stockTransactions: StockTransactionModel[] = [];
  allergens = [];
  parameters = {
    'stock.id': '',
    'location.id': '',
    id: '',
    date: '',
    page: 1,
    itemsPerPage: 20,
    newRecipe: ''
  };
  errors = {
    name: '',
    category: '',
    unit: '',
    tax: '',
    currency: '',
    locations: '',
    purchaseOptions: '',
    supplier: '',
    code: '',
    'price.0': '',
    'price.1': '',
    'price.2': '',
    'price.3': '',
    'price.4': '',
    'supplier.0': '',
    'supplier.1': '',
    'supplier.2': '',
    'supplier.3': '',
    'supplier.4': '',
    'name.0': '',
    'name.1': '',
    'name.2': '',
    'name.3': '',
    'name.4': '',
  };
  readyTime: string;
  options: boolean;
  purchaseOptions: boolean;
  nameEditable: boolean;
  variantOptionsDisplay: boolean;
  stockMoviesValues: number;
  imageLoading: boolean;
  imageDeleting: boolean;
  stockTransactionLoad: boolean;
  transactionDetailShow: boolean;
  apiUrl: string;
  stockName: string;
  staticsDisplay: boolean;
  data: any;
  chartOptions: any;
  stockCodeLoading: boolean;
  variantOptions: StockVariantOptionModel[] = [];
  noInfo: boolean;
  contains: boolean;
  mayContain: boolean;
  doesNotContain: boolean;
  stockMovementTypes: any[] = [];
  stockMovingParameters = {
    variantId: '',
    relatedType: '',
    createdAt: '',
    location: 0,
    pagination: true,
    page: 1,
    itemsPerPage: 10,
  };
  stockMovementTotal: number;
  shipment: ShipmentModel[] = [];
  transfer: StockTransfersModel[] = [];
  waste: WasteEvents[] = [];
  info: any[] = [];
  information = {
    code: '',
    date: '',
    user: '',
    location: '',
    destination: ''
  };
  selectedCode: string;
  activeOptions: number;
  countItems: any[] = [];
  packStocks: PackOptionModel[] = [];
  statusChangeDisplay: boolean;
  recipeChangeDialog: boolean;
  products: StockVariantModel[] = [];
  isCompleted: boolean;
  isCanSave: boolean;
  isProducer: boolean;
  infoTextShowDisplay: boolean;
  infoTextData: UserPageInformationModel[] = [];
  user: UserModel[] = [];
  userId: number;

  constructor(private http: HttpService, private router: Router, private activatedRoute: ActivatedRoute,
              private dialogService: DialogService, private translate: TranslateService,
              private flashMessageService: FlashMessageService, private currencyService: CurrencyService,
              private validationService: ValidationService, private categoryService: CategoryService,
              private taxService: TaxService, private unitService: UnitService, private contactService: ContactService,
              private sidebarService: SidebarService, private confirmationService: ConfirmationService,
              private userPageInformationService: UserPageInformationService) {
  }


  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user'));
    if (this.user) {
      // @ts-ignore
      this.userId = this.user.id;
    }
    this.loading = true;
    this.parameters['location.id'] = window.localStorage.getItem('locationID');
    const company = JSON.parse(window.localStorage.getItem('company'));
    // @ts-ignore
    this.isProducer = company.producer;
    this.stockMovingParameters.location = parseFloat(window.localStorage.getItem('locationID'));
    this.recipe = [];
    this.apiUrl = environment.apiUrl;
    this.stockTransactions = [];
    this.item.isActive = true;
    this.item.isTrackByLot = false;
    this.item.isMaking = false;
    this.item.isBuying = true;
    this.item.type = 'material';
    this.item.allergen = this.allergens;
    this.tabMenuLoad();
    if (this.item.supplier === null && this.item.purchaseOptions.length > 0) {
      this.item.supplier = this.item.purchaseOptions[0].supplier['@id'];
      this.item.purchaseOptions[0].isMainContact = true;
    }
    if (this.item.id) {
      this.id = this.item.id;
      this.options = true;
      this.nameEditable = false;
    } else {
      this.nameEditable = true;
    }
    if (this.item.category === null) {
      this.options = true;
    }
    this.item.currency = this.currencyService.getCurrency()['@id'];
    this.readyTime = this.item.prepTime + this.item.cookTime + ' ' + this.translate.instant('MIN');
    setTimeout(() => {

      this.loadFormData().then();
      this.contactTypes = [
        {name: 'Tedarikçi', value: 'supplier'},
        {name: 'Müşteri', value: 'customer'},
      ];
      this.purchaseOptions = false;
      this.infoTextData = [];
      this.userPageInformationService.getItemsAction({user: this.userId, name: 'InventoryItemCreate'});
      this.userPageInformationService.getItems().subscribe(response => {
        if (response !== null) {
          this.infoTextData = response;
          this.infoTextShowDisplay = this.infoTextData[0].isActive;
        } else {
          this.infoTextShowDisplay = true;
        }
      });
    }, 500);
    this.validationService.errorsData.subscribe(response => {
      if (response) {
        this.errors = response;
      }
    });
    this.validationSubscriber = this.validationService.errorsData.subscribe(errors => {
      if (errors) {
        this.errors = errors;
      }
    });

    this.allergens = [
      {
        name: this.translate.instant('CEREALS_CONTAINING_GLUTEN'),
        noInfo: false,
        contains: false,
        mayContain: false,
        doesNotContain: false,
      },
      {
        name: this.translate.instant('CRUSTACEAN_SHELLFISH'),
        noInfo: false,
        contains: false,
        mayContain: false,
        doesNotContain: false,
      },
      {
        name: this.translate.instant('EGGS'),
        noInfo: false,
        contains: false,
        mayContain: false,
        doesNotContain: false,
      },
      {
        name: this.translate.instant('FISH'),
        noInfo: false,
        contains: false,
        mayContain: false,
        doesNotContain: false,
      },
      {
        name: this.translate.instant('MILK'),
        noInfo: false,
        contains: false,
        mayContain: false,
        doesNotContain: false,
      },
      {
        name: this.translate.instant('NUTS'),
        noInfo: false,
        contains: false,
        mayContain: false,
        doesNotContain: false,
      },
      {
        name: this.translate.instant('PEANUTS'),
        noInfo: false,
        contains: false,
        mayContain: false,
        doesNotContain: false,
      },
      {
        name: this.translate.instant('SOYBEANS'),
        noInfo: false,
        contains: false,
        mayContain: false,
        doesNotContain: false,
      }
    ];
    this.variantOptionsDisplay = this.item.type !== 'material';
    this.purchaseOptions = false;

    this.loading = false;
  }

  tabMenuLoad(): void {
    this.recipe = [];
    this.tabMenuItems = [
      {
        label: this.translate.instant('PURCHASING_INVENTORY'),
        title: 'PURCHASING_INVENTORY',
        command: (event) => {
          this.tabMenuSelect(0);
        },
        visible: this.type === 'material'
      },
      {
        label: this.translate.instant('IN_RECIPES'),
        title: 'IN_RECIPES',
        command: (event) => {
          this.tabMenuSelect(1);
          this.onLoadRecipes();
        },
        visible: this.item.id !== undefined
      },
      {
        label: this.translate.instant('ALLERGENS'),
        title: 'ALLERGENS',
        command: (event) => {
          this.tabMenuSelect(2);
        }
      },
      {
        label: this.translate.instant('STOCK_MOVEMENTS'),
        title: 'STOCK_MOVEMENTS',
        command: (event) => {
          this.stockTransactions = [];
          this.tabMenuSelect(3);
        },
        visible: this.item.id !== undefined
      }
      /*,
      {
        label: this.translate.instant('PACK_OPTIONS'),
        title: 'PACK_OPTIONS',
        command: (event) => {
          this.tabMenuSelect(4);
        },
        visible: this.item.id !== undefined
      }*/
    ];
    this.activeTabMenuItem = this.tabMenuItems[0];
  }

  tabMenuSelect(id): void {

    this.activeTabMenuItem = this.tabMenuItems[id];
    this.activeTab = id;
    if (id === 2) {
      if (this.item.allergen.length === 0) {
        this.item.allergen = this.allergens;
      }
    }
    if (id === 3) {
      this.stockTransactions = [];
      this.loading = true;
      this.loadStockChanges();

    }
    if (id === 4) {
      this.loading = true;
      this.onLoadPackStock();

    }
    /*
    if (id === 1) {
      this.loading = true;
      this.loadVariantOptions().then();
    }
     */
  }

  onLoadRecipes(): void {
    this.http.get(`${VariantRecipeModel.IRI}/stockInRecipes/${this.item.variants[0].id}`).subscribe((response) => {
      this.recipe = response;
      this.loading = false;
    });
  }

  onLoadPackStock(): void {
    this.http.get(PackOptionModel.IRI, {
      stock: this.item.id
    }).subscribe((response) => {
      this.packStocks = response;
      this.loading = false;
    });
  }

  deleteRecipe(recipeId): void {
    this.http.delete(`${VariantRecipeModel.IRI}/stockInRecipes/${recipeId}`);
    this.onLoadRecipes();
  }

  openRecipeChange(): void {
    this.recipeChangeDialog = true;
    this.parameters.itemsPerPage = 1000;
    delete this.parameters.page;
    this.parameters['stock.type'] = 'material';
    this.http.get(StockVariantModel.IRI, this.parameters).subscribe(response => {
      this.products = response['hydra:member'];
    });
  }

  onChangeRecipeItem(event): void {
    this.parameters.newRecipe = event.value;
  }

  onChangeRecipe(): void {
    console.log(this.parameters.newRecipe);
    // tslint:disable-next-line:max-line-length
    this.http.put(`${VariantRecipeModel.IRI}/stockInRecipes/${this.item.variants[0].id}`, {newRecipe: this.parameters.newRecipe}).then(response => {
      this.recipeChangeDialog = false;
      this.onLoadRecipes();
    });
  }

  loadStockChanges(event: LazyLoadEvent = null): void {
    this.stockTransactions = [];
    this.stockMovementTypes = [
      {name: this.translate.instant('ALL'), value: ''},
      {name: this.translate.instant('PURCHASE'), value: 'Shipment'},
      {name: this.translate.instant('STOCK_TRANSFERS'), value: 'Transfer'},
      {name: this.translate.instant('WASTE_EVENTS'), value: 'Waste'},
      {name: this.translate.instant('SALES'), value: 'Sale'},
      {name: this.translate.instant('WORKORDER'), value: 'Workorder'},
      {name: this.translate.instant('WORKORDER_MATERIAL'), value: 'WorkorderMaterial'},
      {name: this.translate.instant('WORKORDER_PACKED_NEGATIF'), value: 'WorkorderPacked'},
      {name: this.translate.instant('WORKORDER_PACKED_POSITIVE'), value: 'WorkorderPacked'},
    ];
    this.stockTransactionLoad = true;
    if (event) {
      this.stockMovingParameters.page = event.first / this.stockMovingParameters.itemsPerPage + 1;
    }

    if (event && event.rows) {
      this.stockMovingParameters.itemsPerPage = event.rows;
    }

    this.stockMovingParameters.variantId = this.item.variants[0].id;

    if (this.stockMovingParameters.relatedType === undefined) {
      delete this.stockMovingParameters.relatedType;
    }
    if (event?.sortField) {
      // @ts-ignore
      this.stockMovingParameters = Object.fromEntries(Object.entries(this.parameters).filter(([k, v]) => k.indexOf('order') < 0));
      this.stockMovingParameters[`order[${event.sortField}]`] = event.sortOrder > 0 ? 'ASC' : 'DESC';
    } else {
      this.stockMovingParameters[`order[updatedAt]`] = 'DESC';
    }
    // @ts-ignore
    this.stockMovingParameters = Object.fromEntries(Object.entries(this.stockMovingParameters).filter(([_, v]) => v !== ''));
    // tslint:disable-next-line:max-line-length
    this.http.get(StockTransactionModel.IRI, this.stockMovingParameters).subscribe(data => {
      this.stockMovementTotal = data['hydra:totalItems'];
      this.stockTransactions = data['hydra:member'];
      this.stockTransactionLoad = false;
      this.loading = false;
    });
  }

  async loadFormData(): Promise<void> {
    this.loading = true;
    /*
    if (this.id){
      await this.http.get(`${StockModel.IRI}/${this.id}`).subscribe((response) => {
        this.item = response['hydra:member'];
      });
      this.http.get(ProductRecipeModel.IRI, {'items.stock.id' : this.id }).subscribe(res => {
        this.recipe = res['hydra:member'];
        this.loading = false;
      });
    }

     */

    await this.http.get(`${CategoryModel.IRI}/category-type/stock?search=yes`).subscribe(category => {
      this.categories = category['hydra:member'];
    });

    await this.taxService.getTaxesAction();
    this.taxService.getTaxes().subscribe(response => {
      this.taxes = response;
    });

    await this.unitService.getUnitsAction();
    this.unitService.getUnits().subscribe(response => {
      this.units = response;
    });

    await this.contactService.getContactsAction({pagination: false});
    this.contactService.getContacts().subscribe(response => {
      if (response) {
        this.suppliers = response;
      }
    });

    await this.http.get(CurrencyModel.IRI).subscribe((response: CurrencyModel[]) => {
      this.currencies = response['hydra:member'];
    });

    await this.http.get(LocationModel.IRI + '/subscriptionControl').subscribe((response) => {
      this.locations = response;
      this.loading = false;
    });

  }

  onEditableName(boolen): void {
    this.nameEditable = boolen;
  }


  saveControl(): void {
    this.errors = {
      name: '',
      category: '',
      unit: '',
      tax: '',
      currency: '',
      locations: '',
      purchaseOptions: '',
      supplier: '',
      code: '',
      'price.0': '',
      'price.1': '',
      'price.2': '',
      'price.3': '',
      'price.4': '',
      'supplier.0': '',
      'supplier.1': '',
      'supplier.2': '',
      'supplier.3': '',
      'supplier.4': '',
      'name.0': '',
      'name.1': '',
      'name.2': '',
      'name.3': '',
      'name.4': '',
    };
    this.isCanSave = true;
    if (this.item.name === undefined) {
      this.isCanSave = false;
      this.errors.name = this.translate.instant('THIS_AREA_IS_NOT_NULL');
    }
    if (this.item.variants[0].code === undefined) {
      this.isCanSave = false;
      this.errors.code = this.translate.instant('THIS_AREA_IS_NOT_NULL');
    }
    if (this.item.category === undefined) {
      this.isCanSave = false;
      this.errors.category = this.translate.instant('THIS_AREA_IS_NOT_NULL');
    }
    if (this.item.unit === undefined) {
      this.isCanSave = false;
      this.errors.unit = this.translate.instant('THIS_AREA_IS_NOT_NULL');
    }
    if (this.item.tax === undefined) {
      this.isCanSave = false;
      this.errors.tax = this.translate.instant('THIS_AREA_IS_NOT_NULL');
    }
    if (this.item.currency === undefined) {
      this.isCanSave = false;
      this.errors.currency = this.translate.instant('THIS_AREA_IS_NOT_NULL');
    }
    if (this.item.locations.length === 0) {
      this.isCanSave = false;
      this.errors.locations = this.translate.instant('YOU_HAVE_TO_SELECT_AT_LEAST_ONE_LOCATION');
    }
    if (this.item.purchaseOptions.length === 0) {
      this.isCanSave = false;
      this.errors.purchaseOptions = this.translate.instant('YOU_HAVE_TO_SELECT_AT_LEAST_ONE_PURCHASE_OPTION');
    } else {
      if (this.item.supplier === undefined) {
        this.isCanSave = false;
        this.errors.supplier = this.translate.instant('YOU_HAVE_TO_SELECT_AT_LEAST_ONE_CONTACT_FROM_PURCHASE_OPTION_FOR_MAIN_SUPPLIER');
      }
      this.item.purchaseOptions.map((option, i) => {
        if (option.price === 0 || option.price === undefined || option.price === null) {
          this.isCanSave = false;
          this.errors['price.' + i] = this.translate.instant('THIS_PRICE_IS_NOT_ZERO');
        }
        if (option.name === undefined || option.name === null || option.name === '') {
          this.isCanSave = false;
          this.errors['name.' + i] = this.translate.instant('THIS_AREA_IS_NOT_NULL');
        }
        if (option.supplier === undefined) {
          this.isCanSave = false;
          this.errors['supplier.' + i] = this.translate.instant('YOU_HAVE_TO_SELECT_AT_LEAST_SUPPLIER');
        }
      });
    }
  }

  async saveStock(): Promise<void> {
    this.saveControl();
    delay(100);
    if (this.isCanSave) {
      this.submitted = true;
      this.loading = true;
      this.isCompleted = false;

      const currencies = JSON.parse(window.localStorage.getItem('currency'));
      if (this.item.id) {
        this.save();
      } else {
        await this.http.post(StockModel.IRI, {
          name: this.item.name,
          type: this.activatedRoute.snapshot.data.type,
          isBuying: this.activatedRoute.snapshot.data.type === 'material',
          isMaking: this.activatedRoute.snapshot.data.type !== 'material',
          currency: currencies['@id'],
          allergen: this.allergens
        }).then((response: StockModel) => {
          if (response) {
            this.item.id = response.id;
            this.id = response.id;
            this.item.variants[0].stock = response['@id'];
            this.item.variants[0].id = response.variants[0].id;
            this.item.stockQuantities = response.stockQuantities;
            this.item.modifier = response.modifier;
            this.item.extra = response.extra;
            this.save();
            /*
            this.item = response;
            this.item.variants = response.variants;
            this.item.currency = response.currency ? response.currency['@id'] : null;
            this.item.stockQuantities.map((quantity, i) => {
              this.item.stockQuantities[i] = quantity['@id'];
            });
            this.id = response.id;
            // this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
            if (this.item.category === null) {
              this.options = true;
            }

             */
          }
          this.tabMenuLoad();
        });
      }
    }
  }

  async save(): Promise<void> {
    this.submitted = true;
    this.item.variants[0].name = this.item.name;
    if (this.item.packOptions) {
      this.item.packOptions?.map((value, i) => {
        this.item.packOptions[i] = value['@id'];
      });
    }
    this.item.stockQuantities.map((quantity, i) => {
      this.item.stockQuantities[i] = quantity['@id'];
    });
    this.item.variants[0].variantRecipes?.map((value, i) => {
      this.item.variants[0].variantRecipes[i] = value['@id'];
    });
    /*
    this.item.variants.map(variant => {
      variant.variantRecipes = variant.variantRecipes['@id'];
    });
     */
    delete this.item.stockPriceChanges;
    await this.http.put(`${StockModel.IRI}/${this.item.id}`, this.item).then((response: StockModel) => {
      if (response) {
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
        this.sidebarService.toggle();
      }
    });
    this.submitted = false;
    this.loading = false;
  }

  async onChangeCategory(event: any): Promise<void> {
    await this.http.get(StockModel.IRI, {'category.id': event.value}).subscribe((response) => {
      this.categoryCode = response['hydra:totalItems'];
      this.stockCount = response['hydra:totalItems'] + 1;
      this.http.get(CategoryModel.IRI, {id: event.value}).subscribe((res) => {
        this.categoryCode = res['hydra:member'][0].code;
        if (this.stockCount < 10) {
          this.stockCount = '0' + this.stockCount;
        }
        this.item.variants[0].code = this.categoryCode ? this.categoryCode : 0 + '-' + this.stockCount;
      });
    });
  }

  addCategory(): void {
    this.dialogService.open(CreateCategoryComponent, {
      width: '50vw',
      header: this.translate.instant('CATEGORY_CREATE'),
      data: 'stock',
    });
  }

  addTax(): void {
    this.dialogService.open(CreateTaxComponent, {
      width: '50vw',
      header: this.translate.instant('TAX_RATE_CREATE'),
    });
  }

  addUnit(): void {
    this.dialogService.open(CreateUnitComponent, {
      width: '50vw',
      header: this.translate.instant('UNIT_CREATE'),
    });
  }

  addContact(): void {
    this.dialogService.open(ContactCreateDialogComponent, {
      width: '50vw',
      header: this.translate.instant('CONTACT_CREATE'),
      data: 'supplier',
    });
  }

  changeCurrency(event): void {
    this.currencies.map((item) => {
      if (item['@id'] === event.value) {
        this.currency = item.code;
      }
    });
  }

  onVariantOptions(): void {
    if (this.variantOptionsDisplay === false) {
      this.variantOptionsDisplay = true;
    } else {
      this.variantOptionsDisplay = false;
    }
  }

  openPriceDialog(row): void {
    this.stockName = row.name;
    this.staticsDisplay = true;
  }

  onPurchaseOptions(id = null): void {
    if (this.purchaseOptions === true) {
      this.purchaseOptions = false;
    } else {
      this.purchaseOptions = true;
    }
    this.activeOptions = id;
  }

  addItem(): void {
    if (this.purchaseOptions === false) {
      this.purchaseOptions = false;
    }
    const options = new PurchaseOptionModel();
    options.packQuantity = 1;
    options.baseUnitQuantity = 1;
    options.price = 0;
    options.supplier = this.item.supplier;
    options.stock = this.item['@id'];
    options.name = this.item.name;
    options.code = this.item.variants[0].code + (this.item.purchaseOptions.length + 1);
    options.unit = this.item.unit;
    options.isActive = true;
    options.isMainContact = false;
    // @ts-ignore
    this.item.purchaseOptions.push(options);
  }

  removeItem(i, id): void {
    this.item.purchaseOptions.splice(i, 1);
    this.onPurchaseOptions(this.item.purchaseOptions.length - 1);
    if (id) {
      this.http.delete(`${PurchaseOptionModel.IRI}/${id}`);
      this.onPurchaseOptions(i);
      this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUPPLIER_DELETED'));
    }
  }

  changeUnit(row, i, type, event): void {
    console.log(event);
    this.item.purchaseOptions[i].orderingUnit = row.unitName;
    this.item.purchaseOptions[i].packPerCase = null;
    if (type === 'unit') {
      // tslint:disable-next-line:variable-name radix
      const number = parseInt(row.unit.match(/\d+/)[0]);
      this.http.get(UnitModel.IRI, {id: number}).subscribe(response => {
        this.item.purchaseOptions[i].unitName = response['hydra:member'][0].name;
        if (row.packPerCase === null && row.packName === '') {
          this.item.purchaseOptions[i].orderingUnit = row.packQuantity + ' ' + response['hydra:member'][0].name;
        } else if (row.packPerCase !== null && row.packName === '') {
          this.item.purchaseOptions[i].orderingUnit = 'Tanımsız';
        } else {
          // tslint:disable-next-line:max-line-length
          this.item.purchaseOptions[i].orderingUnit = row.packName + '(' + row.packPerCase !== null ? row.packPerCase : '' + '*' + row.packQuantity !== null ? row.packQuantity : '' + ' ' + response['hydra:member'][0].name + ')';
        }
      });
      if (this.item.unit !== row.unit) {
        this.item.purchaseOptions[i].baseUnitQuantity = 1;
      } else {
        this.item.purchaseOptions[i].baseUnitQuantity = null;
      }

    } else if (row.packPerCase === null && row.packName === '') {
      this.item.purchaseOptions[i].orderingUnit = row.packQuantity + ' ' + row.unitName;
    } else if (row.packPerCase !== null && row.packName === '') {
      this.item.purchaseOptions[i].orderingUnit = 'Tanımsız';
    } else {
      // tslint:disable-next-line:max-line-length
      this.item.purchaseOptions[i].orderingUnit = row.packName + ' ' + row.packPerCase !== null ? row.packPerCase : '' + '*' + row.packQuantity !== null ? row.packQuantity : '' + ' ' + row.unitName;
    }
    this.item.purchaseOptions[i].orderingUnit = row.unitName;

  }

  changePackPerCase(row, i, type): void {
    this.item.purchaseOptions[i].baseUnitQuantity = 1;
  }

  change(event, i, noInfo, contains, mayContain, doesNotContain): void {

    // @ts-ignore
    this.item.allergen[i].noInfo = noInfo;
    // @ts-ignore
    this.item.allergen[i].contains = contains;
    // @ts-ignore
    this.item.allergen[i].mayContain = mayContain;
    // @ts-ignore
    this.item.allergen[i].doesNotContain = doesNotContain;
  }

  openTransactionDetail(item, code): void {
    this.loading = true;
    this.shipment = [];
    this.transfer = [];
    this.waste = [];
    this.selectedCode = code;
    if (item.type === 'PURCHASE') {
      this.http.get(ShipmentModel.IRI, {id: item.relatedById}).subscribe(data => {
        this.shipment = data['hydra:member'];
        this.loading = false;
      });
    }
    if (item.type === 'MISSING_STOCK_TRANSFER' || item.type === 'ADD_STOCK_TRANSFER') {
      this.http.get(StockTransfersModel.IRI, {id: item.relatedById}).subscribe(data => {
        this.transfer = data['hydra:member'];
        this.loading = false;
      });
    }
    if (item.type === 'MISSING_WASTE_EVENT') {
      this.http.get(WasteEvents.IRI, {id: item.relatedById}).subscribe(data => {
        this.waste = data['hydra:member'];
        this.loading = false;
      });
    }
    if (item.type === 'SALE') {
      this.loading = false;
    }
    this.transactionDetailShow = true;
  }

  getChartOptions(event): void {
    const dates = [];
    const prices = [];
    this.http.get(StockPriceChanges.IRI, {'stock.id': this.id, 'location.id': event.value}).subscribe(response => {
      response['hydra:member'].map(item => {
        prices.push(item.price);
        const date = new Date(item.createdAt);
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        dates.push(month + '-' + year);
      });
      setTimeout(() => {
        this.data = {
          labels: dates,
          datasets: [{
            type: 'line',
            label: this.translate.instant('PRICE_CHANGE_GRAPHICS'),
            borderColor: '#42A5F5',
            borderWidth: 2,
            fill: false,
            data: prices
          }]
        };
      }, 100);
    });
  }

  addCountItem(orderUnit): void {
    if (this.countItems.length >= 4) {

    } else {
      const index = this.countItems.findIndex(obj => obj === orderUnit);
      if (index !== -1) {
        this.countItems.push(orderUnit);
      } else {
        this.countItems.splice(index, 1);
      }
    }
  }

  setActive(index): void {
    if (this.item.purchaseOptions[index].isActive === false) {
      this.item.purchaseOptions[index].isActive = true;
    } else {
      if (this.item.purchaseOptions[index].isMainContact === false) {
        this.item.purchaseOptions[index].isActive = false;
      } else {
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Error, 'MAIN_CATEGORY_NOT_PASSIVE'));
      }
    }
  }

  setCountableItem(index, row): void {
    if (row.unit === this.item.unit) {
      this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Error, 'UNIT_NOT_SAME_FOR_COUNT'));
    } else {
      let countValue = 0;
      this.item.purchaseOptions.map((item, i) => {
        if (item.isCount === true) {
          countValue = countValue + 1;
        }
      });
      if (countValue >= 3) {
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Error, 'UNIT_NOT_GREATER_THAN_THREE'));
      } else {
        this.item.purchaseOptions[index].isCount = this.item.purchaseOptions[index].isCount === false;
      }
    }
  }

  findUnitName(unit): void {
    this.units.map(obj => {
      if (obj['@id'] === unit) {
        return obj.name;
      }
    });
  }

  isBuyingFunction(e): void {
    this.item.isBuying = e.checked;
  }

  isMakingFunction(e): void {
    this.item.isMaking = e.checked;
  }

  isTrackLotFunction(e): void {
    this.item.isTrackByLot = e.checked;
    this.item.trackType = e.checked ? 'lot' : 'default';
  }

  deleteItem(item): void {
    console.log(item);
    this.confirmationService.confirm({
      message: this.translate.instant('ALERT_COMPLETE_OPERATION'),
      accept: () => {
        this.loading = true;
        this.http.put(`${StockVariantModel.IRI}/${item.variants[0].id}`, {isDeletable: true}).then((response) => {
        });
      }
    });
  }

  changeStatus(id, event): void {
    if (event.checked === false) {
      this.http.get(`${VariantRecipeModel.IRI}/stockInRecipes/${this.item.variants[0].id}`).subscribe((response) => {
        this.recipe = response;
        if (this.recipe.length > 0) {
          this.item.isActive = true;
          this.statusChangeDisplay = true;
        } else {
          this.http.put(`${StockModel.IRI}/${id}`, {isActive: event.checked}).then((res) => {
            this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS'));
          });
        }
        this.loading = false;
      });
    } else {
      this.http.put(`${StockModel.IRI}/${id}`, {isActive: event.checked}).then((response) => {
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS'));
      });
    }
  }

  infoTextShow(): void {
    const item = new UserPageInformationModel();
    item.user = '/api/users/' + this.userId;
    item.name = 'InventoryItemCreate';
    if (this.infoTextShowDisplay === true) {
      this.infoTextShowDisplay = false;
      item.isActive = false;
    } else {
      this.infoTextShowDisplay = true;
      item.isActive = true;
    }
    if (this.infoTextData.length > 0) {
      this.infoTextData[0].isActive = this.infoTextShowDisplay;
      this.userPageInformationService.update(this.infoTextData[0]).then(response => {
      });
    } else {
      this.userPageInformationService.save(item).then(res => {
        this.userPageInformationService.getItemsAction({user: this.userId, name: 'InventoryItemCreate'});
        this.userPageInformationService.getItems().subscribe(response => {
          this.infoTextData = response;
          this.infoTextShowDisplay = this.infoTextData[0].isActive;
        });
      });
    }
  }

  changeOptionContact(event, isMainContact): void {
    if (isMainContact === true) {
      this.item.supplier = event.value;
    }
  }
  setMainContact(index): void {
    this.item.purchaseOptions.map((item, i ) => {
      this.item.purchaseOptions[i].isMainContact = false;
    });
    this.item.purchaseOptions[index].isMainContact = true;
    this.item.supplier = this.item.purchaseOptions[index].supplier;
  }

  ngOnDestroy(): void {
    console.log('InventoryItemCreateComponent destroyed');
  }
}
