<p-card>
  <app-loading-indicator [loading]="loading"></app-loading-indicator>
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'RECEIVE_ORDERS' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'RECEIVE_ORDERS'"></app-page-info>
      <p-button icon="pi pi-arrow-down" [style]="{marginLeft: '5px', float:'right'}"></p-button>
      <button pButton [style]="{marginLeft: '5px', float:'right'}" label="{{'INFO' | translate}}" class="p-mb-3"
              [icon]="'pi pi-check'" (click)="infoTextShow()"></button>
      <button pButton [style]="{marginLeft: '5px', float:'right'}" label="{{'QUICK_RECEIVE_ORDER' | translate}}" class="p-mb-3"
              [icon]="'pi pi-shopping-cart'" (click)="quickReceive()"></button>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 information" *ngIf="infoTextShowDisplay">
      <app-recive-order-page-info></app-recive-order-page-info>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-2 p-xl-2">
      <div class="p-inputgroup" [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}">
        <p-calendar (onSelect)="closeCalendar('createdAt')" placeholder="{{ 'SELECT_DATE' | translate}}" appendTo="body"
                    selectionMode="range" [(ngModel)]="parameters.createdAt"
                    name="createdAt" dateFormat="yy-mm-dd">
        </p-calendar>
        <span class="p-inputgroup-addon"><i class="pi pi-calendar"></i></span>
      </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2 p-xl-2">
      <p-dropdown [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                  [options]="suppliers" [(ngModel)]="parameters.contact" name="supplier"
                  (onChange)="onSelectContact($event)" placeholder="{{'SELECT_SUPPLIER' | translate}}" optionValue="id"
                  optionLabel="name">

      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-2 p-lg-1 p-xl-1">
      <p-button type="submit" (click)="load(null, 'search')" label="{{'SEARCH' | translate}}"></p-button>
    </div>
    <div class="p-col-12 p-md-10 p-lg-7 p-xl-6 p-xl-offset-1">
      <div class="p-grid grid-div">
        <div class="p-col div-sums">
          <span class="title">{{'ORDER_COUNT' |translate}}</span>
          <span class="total-price">{{ total }} <b
            class="currency">{{'PIECE' | translate}}</b></span>
        </div>
        <div class="p-col div-sums">
          <span class="title">{{'GRAND_TOTAL'| translate}}</span>
          <span class="total-price">{{ sumTotal | number: '1.2-2'}}<b
            class="currency">{{ currency}}</b></span>
        </div>
      </div>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12" style="overflow-x: auto;margin-top:20px">
      <form (submit)="load()" *ngIf="!detailed">

        <p-table responsiveLayout="scroll" [loading]="tableLoading" [lazy]="true" [value]="items"
                 (onLazyLoad)="load($event, 'SEARCH')" [tableStyle]="{'min-width': '50rem'}"
                 [reorderableColumns]="true" (onRowReorder)="onReorder($event)" [rows]="20"
                 [showCurrentPageReport]="true" [totalRecords]="total" [paginator]="true"
                 styleClass="p-datatable-striped" columnResizeMode="expand" (onColResize)="onResize($event)"
        >
          <ng-template pTemplate="header">
            <tr >
              <th [style]="{width:'5%'}" id="empty"></th>
              <th [style]="{width:'7%'}" id="code">{{ 'CODE' | translate }}
                <app-tooltip [description]="'ORDER_NUMBER_TOOLTIP'"></app-tooltip>
              </th>
              <th [style]="{width:'36%'}" id="SUPPLIER">{{'SUPPLIER'|translate}}
                <app-tooltip
                  description="{{ parameters.type == invoiceType.Sales ? 'CUSTOMER_TOOLTIP' : 'SUPPLIER_TOOLTIP'}}"></app-tooltip>
              </th>
              <th [style]="{width:'15%'}" id="LOCATION">{{ 'LOCATION' | translate}}
                <app-tooltip description="TOTAL_TOOLTIP"></app-tooltip>
              </th>
              <th [style]="{width:'10%'}" id="TOTAL">{{ 'TOTAL' | translate}}
                <app-tooltip description="TOTAL_TOOLTIP"></app-tooltip>
              </th>
              <th [style]="{width:'12%'}" id="CREATED_AT">{{ 'CREATED_AT' | translate}}
                <app-tooltip description="CREATED_TOOLTIP"></app-tooltip>
              </th>
              <th [style]="{width:'15%'}" id="STATUS"
                  *ngIf="parameters.type === invoiceType.Purchase">{{ 'STATUS' | translate}}
                <app-tooltip description="COMPLETED_TOOLTIP"></app-tooltip>
              </th>
              <th [style]="{width:'12%'}" id="IS_RECEIVED"
                  *ngIf="parameters.type === invoiceType.Purchase">{{ 'IS_RECEIVED' | translate}}</th>
            </tr>
            <tr>
              <td [style]="{width:'15%'}"></td>
              <td><input [style]="{width: '100%'}" type="text" pInputText [(ngModel)]="parameters.code"
                         name="code"></td>
              <td><input [style]="{width: '100%'}" type="text" pInputText
                         [(ngModel)]="parameters['contact.name']" name="contactName"></td>
              <td>
                <p-dropdown appendTo="body" [options]="locations" optionValue="id" (onChange)="parameters.location = $event.value;
                load(null, 'search')" [ngModel]="parameters.location" optionLabel="name" [ngModelOptions]="{standalone: true}" >

                </p-dropdown>
              </td>
              <td><input [style]="{width: '100%'}" type="text" pInputText [(ngModel)]="parameters.total"
                         name="total"></td>
              <td>
                <p-calendar #createdAt (onSelect)="closeCalendar('createdAt')" appendTo="body" (onClose)="load()"
                            selectionMode="range" [(ngModel)]="parameters.createdAt" name="createdAt"
                            dateFormat="yy-mm-dd"></p-calendar>
              </td>
              <td *ngIf="parameters.type === invoiceType.Purchase">
                <p-dropdown [options]="confirmedStatus" [ngModel]="this.parameters.status" appendTo="body"
                            (onChange)="parameters.status = $event.value; load(null, 'search') " name="status"
                            optionLabel="name" optionValue="value"></p-dropdown>
              </td>
              <td *ngIf="parameters.type === invoiceType.Purchase">
                <p-dropdown [options]="isReceivedStatus" [ngModel]="this.parameters.isReceived" appendTo="body"
                            (onChange)="parameters.isReceived = $event.value; load(null, 'search') " name="isReceived"
                            optionLabel="name" optionValue="value"></p-dropdown>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-row let-index="rowIndex">
            <tr [pReorderableRow]="index">
              <td>
                <span class="pi pi-bars" pReorderableRowHandle></span>
                {{ index + 1 }}
              </td>
              <td [style]="{width:'15%'}">
                <a style="cursor: pointer" *ngIf="row.isReceived === false" (click)="goToPage('/orders/order-details/' + row.id)">{{ row.code }}</a>
                <a style="cursor: pointer" *ngIf="row.isReceived === true" (click)="goToPage('/order/receive-manuel/' + row.id)">{{ row.code }}</a>

              </td>
              <td>{{ row.contact}}</td>
              <td>{{ row.location }}</td>
              <td
                style="text-align: right">{{ row.total | countryCurrency }}</td>
              <td [style]="{textAlign: 'center'}">{{ row.createdAt['date'] | date: 'Y-MM-dd' }}</td>
              <td *ngIf="parameters.type === invoiceType.Purchase">
                <p-tag [severity]="row.status|statusLabel" (click)="row.status === 'WAITING_FOR_APPROVAL'? goToPage('/orders/order-details/' + row.id) : ''"
                [style]="{cursor: row.status === 'WAITING_FOR_APPROVAL'? 'pointer' : ''}"
                >{{ row.status | translate }}</p-tag>
              </td>
              <td *ngIf="parameters.type === invoiceType.Sales">
                <p-tag [severity]="row.itemStatus|statusLabel">{{ row.itemStatus|status: row.itemExpectedDate }}</p-tag>
              </td>
              <td *ngIf="parameters.type === invoiceType.Purchase && activeInventory" [style]="{padding: '0px'}">
                <div *ngIf="row.status !== 'WAITING_FOR_APPROVAL' && row.status !== 'DECLINED' ">
                  <p-tag [severity]="row.isReceived ? 'success' : 'warning'"  [style]="{cursor: !row.isReceived? 'pointer' : ''}"
                         (click)="!row.isReceived ? goToPage('/order/receive-manuel/' + row.id) : ''">{{ (row.isReceived ? 'RECEIVED' : 'RECEIVE')|translate }}</p-tag>
                  <!--
                  <button *ngIf="row.isReceived === false"
                          type="button" pButton class="p-button-text"
                          [label]="(row.isReceived ? 'RECEIVED' : 'RECEIVE')|translate " (click)="goToPage('/orders/order-details/' + row.id)"></button>
                  <button *ngIf="row.isReceived === true"
                          type="button"
                          pButton class="p-button-text" (click)="goToPage('/order/receive-manuel/' + row.id)"
                          [label]="(row.isReceived ? 'RECEIVED' : 'RECEIVE')|translate "></button>
                          -->
                </div>

              </td>
              <td *ngIf="!activeInventory">

                <button *ngIf="row.isReceived === false"
                        type="button" pButton class="p-button-text"
                        [label]="(row.isReceived ? 'RECEIVED' : 'RECEIVE')|translate " (click)="inventoryAlert()"></button>
                <button *ngIf="row.isReceived === true"
                        type="button"
                        pButton class="p-button-text" (click)="inventoryAlert()"
                        [label]="(row.isReceived ? 'RECEIVED' : 'RECEIVE')|translate "></button>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate = "footer">
            <tr *ngIf = "!items.length">
              <td colspan="9">
              <h3>{{'NO_RECORDS' | translate}}</h3>
            </td>
            </tr>
          </ng-template>
        </p-table>

        <button type="submit" pButton label="{{'stock.search' | translate}}" style="display: none"></button>
      </form>
      <app-invoice-detailed-list *ngIf="detailed"></app-invoice-detailed-list>
    </div>
  </div>
</p-card>

<p-overlayPanel #op dismissable="true" showCloseIcon="true">
  <ng-template pTemplate>
    <p-table [value]="workOrders" [loading]="workOrdersLoading" style="width: 75px">
      <ng-template pTemplate="header">
        <tr>
          <td>{{'code' | translate}}</td>
          <td>{{'workOrderList.status' | translate}}</td>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-i="rowIndex" let-row>
        <td>
          <a href="" [routerLink]="'/work-orders/' + row.id + '/edit'">{{ row.code }}</a>
        </td>
        <td>
          <p-tag [severity]="row.status|statusLabel">{{ row.status|status }}</p-tag>
        </td>
      </ng-template>
    </p-table>
  </ng-template>
</p-overlayPanel>
<p-overlayPanel #dp>
  <ng-template pTemplate>
    <div *ngFor="let item of changeStatusItems">
      <p-button *ngIf="item.state.status !== deliveryActions.PARTIAL_DELIVERED" [label]="item.label"
                styleClass="p-button-text" class="p-d-block"
                (onClick)="changeStatus(item.state.id, item.state.status); dp.hide()"></p-button>
      <p-button *ngIf="item.state.status === deliveryActions.PARTIAL_DELIVERED" [label]="item.label"
                styleClass="p-button-text" class="p-d-block" (onClick)="receive(item.state.id); dp.hide()"></p-button>
    </div>
  </ng-template>
</p-overlayPanel>

<p-dialog [(visible)]="displayAction" [style]="{width: '50vw'}">
  <div style="display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;">
    <i class="pi pi-ban" [style]="{color:'#fe8a8a', fontSize:'80px'}"></i>
    <p [style]="{width: '300px', color:'#fe8a8a', textAlign: 'center'}">{{'UNAUTHORIZED_TRANSACTION'| translate}}</p>
  </div>
</p-dialog>

<p-dialog  [(visible)]="displayLocations" [style]="{width: '50vw'}" [closable]="false">
  <ng-template pTemplate="header">
    <div style="width: 50%">
      <h3>{{ 'SELECT_LOCATION'| translate }}</h3>
    </div>
  </ng-template>
  <div class="dialogDiv">
    <div *ngFor="let location of locations">
      <div class="hoverable"
           style="padding: 20px 10px 7px 15px; border-radius: 5px;background: #ffffff;border:1px solid #f4f3f3">
        <a href="javascript:void(0)" style="color: #444444" (click)="changeLocation(location.id)">
          <div class="p-grid">
            <div class="p-col-2" style="padding: 0px;width: 50px">
              <p-avatar label="{{ location.name| slice:0:1}}"
                        [style]="{width:'32px',heigt:'32px','background-color':'#47bfd4', 'color': '#ffffff',fontSize:'25px',borderRadius:'50%'}"></p-avatar>
            </div>
            <div class="p-col-10" style="align-self:baseline"><b
              style="margin-top:5px;font-size: 14px">{{ location.name}}</b></div>
          </div>
        </a>
      </div>
    </div>
  </div>
</p-dialog>

<p-dialog  [(visible)]="activeInventoryDisplay" [style]="{width: '70vw'}" [closable]="false">
  <ng-template pTemplate="header">
      <h3>{{ 'ALERT'| translate }}</h3>
  </ng-template>
  <ng-template pTemplate="body">
    <p-message severity="warn" text="{{'CREATE_INVENTORY_NEED_DESCRIPTION' | translate}}" styleClass="mr-2"></p-message>
    <br>
    <div class="p-grid">
      <h3>{{'WHY_OPEN_INVENTORY' | translate}}</h3>
      <p>{{'WHY_OPEN_INVENTORY_DESCRIPTION' | translate}}</p>
      <div class="p-col-12">
        1- <b>{{'WHY_OPEN_TITLE_1' | translate}} </b>
        {{'WHY_OPEN_TITLE_1_DESCRIPTION' | translate}}
      </div>
      <div class="p-col-12">
        2- <b>{{'WHY_OPEN_TITLE_2' | translate}} </b>
        {{'WHY_OPEN_TITLE_2_DESCRIPTION' | translate}}
      </div>
      <div class="p-col-12">
        3- <b>{{'WHY_OPEN_TITLE_3' | translate}} </b>
        {{'WHY_OPEN_TITLE_3_DESCRIPTION' | translate}}
      </div>
      <div class="p-col-12">
        4- <b>{{'WHY_OPEN_TITLE_4' | translate}} </b>
        {{'WHY_OPEN_TITLE_4_DESCRIPTION' | translate}}
      </div>
      <div class="p-col-12">
        5- <b>{{'WHY_OPEN_TITLE_5' | translate}} </b>
        {{'WHY_OPEN_TITLE_5_DESCRIPTION' | translate}}
      </div>
      <div class="p-col-12">
        6- <b>{{'WHY_OPEN_TITLE_6' | translate}} </b>
        {{'WHY_OPEN_TITLE_6_DESCRIPTION' | translate}}
      </div>
      <div class="p-col-12">
        <a class="p-mt-4" href="" [routerLink]="'/inventory'">{{ 'CREATE_INVENTORY' | translate}}</a>
      </div>
    </div>
  </ng-template>

</p-dialog>

<p-sidebar [(visible)]="quickReceiveDisplay" *ngIf="quickReceiveDisplay" position="right" [style]="{width:'80em'}">
  <app-quick-receive-order></app-quick-receive-order>
</p-sidebar>
