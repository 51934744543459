<p-card>
  <app-loading-indicator [loading]="loading"></app-loading-indicator>
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'LAST_PURCHASE_PRICE_REPORTS' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <p-button icon="pi pi-question" [style]="{marginLeft: '5px', float:'right'}"></p-button>
      <p-button icon="pi pi-arrow-down" [disabled]="parameters.location === '' " (onClick)="export()" [style]="{marginLeft: '5px', float:'right'}"></p-button>
    </div>
  </div>
  <form (ngSubmit)="load()">
  <div class="p-grid">
      <div class="p-col-12 p-md-6 p-lg-3">
        <span class="p-input-icon-left" [style]="{width:'100%'}">
              <i class="pi pi-search"></i>
              <input type="text"
                     [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                     name="search" id="search" pInputText placeholder="{{ 'GLOBAL_SEARCH'| translate }}"
                     [(ngModel)]="parameters.name" (change)="changeNameInput($event)"/>
        </span>
      </div>
      <div class="p-col-12 p-md-6 p-lg-3">
        <p-dropdown [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                    [options]="searchContacts" [(ngModel)]="parameters.contact" (onChange)="changeContact($event)"
                    placeholder="{{ 'SELECT_SUPPLIER' | translate}}"
                    optionLabel="name" optionValue="id" [filter]="true" filterBy="name">
        </p-dropdown>
      </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <p-dropdown [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                  [options]="locations" [(ngModel)]="parameters.contact" (onChange)="changeLocation($event)"
                  placeholder="{{ 'SELECT_LOCATION' | translate}}"
                  optionLabel="name" optionValue="id" [filter]="true" filterBy="name">
      </p-dropdown>
    </div>
      <div class="p-col-12 p-md-6 p-lg-3" [style]="{display: 'flex'}">
        <button pButton type="submit" icon="pi pi-search" [disabled]="parameters.location === '' " [style]="{marginLeft: '5px'}" label="{{'SEARCH' | translate}}"></button>
      </div>
  </div>
  </form>
  <div class="p-grid">
    <div class="p-col-12" style="overflow-x: auto;">
      <p-table #table responsiveLayout="stack" [tableStyle]="{'min-width': '45rem'}"
               [value]="report" [loading]="tableLoading"
               styleClass="p-datatable-striped" columnResizeMode="expand">
        <ng-template pTemplate="header">
          <tr>
            <th scope="col" [style]="{width:'40%'}">{{ 'STOCK'| translate }}</th>
            <th scope="col" [style]="{width:'40%'}">{{ 'SUPPLIER'| translate }}</th>
            <th scope="col" [style]="{width:'10%'}">{{ 'AVERAGE_PRICE'| translate }}</th>
            <th scope="col" [style]="{width:'10%'}">{{ 'LAST_PRICE'| translate }}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row let-i="rowIndex">
          <tr>
            <td>{{ row.stock}}</td>
            <td>{{ row.supplier }}</td>
            <td [style]="{textAlign:'right'}">{{ row.averagePrice | countryCurrency}}</td>
            <td [style]="{textAlign:'right'}">{{ row.lastPrice | countryCurrency}}</td>
          </tr>
        </ng-template>
      </p-table>

    </div>
  </div>
</p-card>


