<app-loading-indicator [loading]="loading"></app-loading-indicator>
<div *ngIf="loading === false">
<div class="p-grid">
  <div class="p-col-6">
    <h4>{{'QUICK_RECEIVE_ORDER' | translate}}</h4>
  </div>
  <div class="p-col-6">
    <button pButton [style]="{marginLeft: '5px', float:'right'}" label="{{'SAVE' | translate}}" class="p-mb-3"
            [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-save'" (click)="save()"></button>
  </div>
  <p-message *ngIf="!isCanBeSend && isCanBeSelectDate" [style]="{marginTop: '10px', marginBottom:'10px'}" severity="info"
             text="Seçtiğiniz Tarih Açık Olan Envanter Başlangıç Tarihin {{ isCanBeSelectDate | date : 'Y-M-dd' }}'den Daha Eski Olamaz"
             styleClass="mr-2"></p-message>

  <div class="p-col-12 alert-div " *ngIf="documentNumberIsValid"> Uyarı ! Bu Döküman Numarası Daha Önce Girilmiş</div>
  <div class="p-col-12 p-md-6 p-lg-6">
    <label [style]="{fontSize: '14px'}" class="p-pb-2">{{'DOCUMENT_TYPE' | translate}}</label>
    <p-dropdown dataKey="getDocument" [placeholder]="'SELECT'|translate" [(ngModel)]="shipment.documentType"
                [options]="documentType" optionLabel="name" optionValue="value"></p-dropdown>
    <small class="p-error">{{ errors['documentType'] }}</small>
  </div>
  <div class="p-col-12 p-md-6 p-lg-6">
    <label for="documentNumber" [style]="{fontSize: '14px'}">{{'DOCUMENT_NUMBER' | translate}}</label>
    <input id="documentNumber" [style]="{width:'100% !important'}" (change)="documentNumberControl($event)"
           [(ngModel)]="shipment.documentNumber" type="text" pInputText autofocus>
    <small class="p-error">{{ errors['documentNumber'] }}</small>
  </div>
  <div class="p-col-12 p-md-6 p-lg-6">
    <label for="documentDate" [style]="{fontSize: '14px'}">{{'DOCUMENT_DATE' | translate}}</label>
    <p-calendar id="documentDate" [style]="{height: '30px'}" (onSelect)="shipmentDateControl($event)"
                [(ngModel)]="shipment.documentDate" [minDate]="minDate" [maxDate]="today" dateFormat="dd-mm-yy"
                inputId="dateformat" appendTo="body"></p-calendar>
    <br>
    <small class="p-error">{{ errors['documentDate'] }}</small>
  </div>
  <div class="p-col-12 p-md-6 p-lg-6">
    <label for="documentDate" [style]="{fontSize: '14px'}">{{'LOCATION' | translate}}</label>
    <p-dropdown
      [options]="locations" [(ngModel)]="invoice.location" appendTo="body"
      optionLabel="name" optionValue="@id" placeholder="{{ 'SELECT_LOCATION'| translate}}"
      [filter]="true" filterBy="name">
    </p-dropdown>
    <small class="p-error">{{ errors['location'] }}</small>
  </div>
  <div class="p-col-12">
    <label for="documentDate" [style]="{fontSize: '14px'}">{{'SUPPLIER' | translate}}</label>
    <p-dropdown
      [options]="contacts" filter="true" [(ngModel)]="invoice.contact" appendTo="body"
      optionLabel="name" optionValue="@id" placeholder="{{ 'SELECT_SUPPLIER'| translate}}"
      [filter]="true" filterBy="name">
    </p-dropdown>
    <small class="p-error">{{ errors['contact'] }}</small>
  </div>
</div>
<div class="p-grid">
  <div class="p-col-12">
    <p-table [value]="invoice.items" [loading]="submitted" dataKey="id" responsiveLayout="scroll"
             styleClass="p-datatable-striped p-datatable-sm" #table [tableStyle]="{'min-width': '65rem'}">
      <ng-template pTemplate="header">
        <tr>
          <th scope="col" [style]="{width: '10%'}">{{ 'CODE' | translate}}
            <app-tooltip [description]="'TOOLTIP_CODE'"></app-tooltip>
          </th>
          <th scope="col" [style]="{width: '25%'}">{{ 'STOCK' | translate}}
            <app-tooltip [description]="'TOOLTIP_STOCK'"></app-tooltip>
          </th>
          <th scope="col" [style]="{width: '10%'}">{{ 'QUANTITY' | translate}}
            <app-tooltip [description]="'TOOLTIP_QUANTITY'"></app-tooltip>
          </th>
          <th scope="col" [style]="{width: '10%'}">{{ 'UNIT_PRICE' | translate}}
            <app-tooltip [description]="'TOOLTIP_UNIT_PRICE'"></app-tooltip>
          </th>
          <th scope="col" [style]="{width: '10%'}">{{ 'DISCOUNT_RATE' | translate}}
            <app-tooltip [description]="'TOOLTIP_UNIT_PRICE'"></app-tooltip>
          </th>
          <th scope="col" [style]="{width: '10%'}">{{ 'DISCOUNT' | translate}}
            <app-tooltip [description]="'TOOLTIP_UNIT_PRICE'"></app-tooltip>
          </th>
          <th scope="col" [style]="{width: '10%'}">{{ 'TOTAL_PRICE' | translate}}
            <app-tooltip [description]="'TOOLTIP_TOTAL_PRICE'"></app-tooltip>
          </th>
          <th scope="col" [style]="{width: '8%'}">{{ 'TAX_RATE' | translate}}
            <app-tooltip [description]="'TOOLTIP_TAX'"></app-tooltip>
          </th>
          <th scope="col" [style]="{width: '8%'}">{{ 'TAX' | translate}}
            <app-tooltip [description]="'TOOLTIP_TAX'"></app-tooltip>
          </th>
          <th scope="col" [style]="{width: '5%'}"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item let-i="rowIndex">
        <tr>
          <td>
            {{ autoCompleteValues[i]?.code}}
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-autoComplete id="ecwcwecwecwcwe" [(ngModel)]="autoCompleteValues[i]" [disabled]="item.isReceived"
                                (onSelect)="onVariantSelect($event, i)"
                                field="name" [suggestions]="variants" appendTo="body"
                                (completeMethod)="searchVariant($event)"></p-autoComplete>
              </ng-template>
              <ng-template pTemplate="output">
                {{ autoCompleteValues[i]?.name}}
              </ng-template>
            </p-cellEditor>

          </td>
          <td [style]="{textAlign: 'right'}" pEditableColumn class="{{ hasErrors('quantity', i) ? 'has-error': '' }}">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-inputNumber mode="decimal" minFractionDigits="2" maxFractionDigits="2" [(ngModel)]="item.quantity"
                               [min]="item.incomingQuantity" [disabled]="item.isReceived"
                               (ngModelChange)="calculateTotalPrice(i)"></p-inputNumber>
              </ng-template>
              <ng-template pTemplate="output">
                {{ item.quantity}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td  [style]="{textAlign: 'right'}" pEditableColumn class="{{ hasErrors('unitPrice', i) ? 'has-error': '' }}">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-inputNumber [(ngModel)]="item.unitPrice" mode="decimal" minFractionDigits="3" maxFractionDigits="3"
                               (ngModelChange)="item.nonDiscountedUnitPrice = item.unitPrice; calculateTotalPrice(i)"></p-inputNumber>
              </ng-template>
              <ng-template pTemplate="output">
                {{item.unitPrice|countryCurrency}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td [style]="{textAlign: 'right'}" pEditableColumn>

            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-inputNumber [(ngModel)]="item.discountRate" mode="decimal" minFractionDigits="2" maxFractionDigits="2"
                               (ngModelChange)="item.nonDiscountedUnitPrice = item.unitPrice; calculateTotalPrice(i)"></p-inputNumber>
              </ng-template>
              <ng-template pTemplate="output">
                % {{item.discountRate}}
              </ng-template>
            </p-cellEditor>

          </td>
          <td class="p-text-right">
            {{item.discount| countryCurrency}}
          </td>

          <td class="p-text-right">
            {{item.subtotal| countryCurrency}}
          </td>
          <td pEditableColumn class="p-text-right">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-dropdown [options]="taxRates" [(ngModel)]="item.taxRate" appendTo="body"
                            (onChange)="calculateTotalPrice(i); setTax(i, $event)" optionLabel="name" id="tax"
                            optionValue="@id"></p-dropdown>
              </ng-template>
              <ng-template pTemplate="output">
                % {{taxes.length > i ? taxes[i]?.name : ''}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td class="p-text-right">
            {{item.tax| countryCurrency}}
          </td>
          <td class="p-text-right">
            <button [disabled]="item.isReceived || item.isWorkOrderCreated" pButton label="" icon="pi pi-trash"
                    class="p-button-outlined p-button-danger" (click)="removeItem(i)" iconPos="left"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="p-fluid  p-pt-3">
      <!---
      <button *ngIf="invoice.type === invoiceTypes.Sales" pButton [label]="'CREATE_PRODUCT'|translate"
              style="width: 150px; display: block; padding: 0 0 10px 0; text-align: left" class="p-button-text"
              (click)="createProduct()" iconPos="left"></button>
              --->
      <button pButton label="" icon="pi pi-plus" class="p-button-outlined" (click)="addItem()" iconPos="left"
              *ngIf="invoice.location"></button>
      <br> <small class="p-error">{{ errors['items'] }}</small>
    </div>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-8">

      </div>
      <div class="p-field p-col-4">
        <table  style="width: 100%">
          <tr>
            <th scope="col" class="p-text-right">{{ 'TOTAL' | translate}}</th>
            <td class="p-text-right">{{ invoice.subtotal|countryCurrency }}</td>
          </tr>
          <tr>
            <th scope="col" class="p-text-right">{{ 'DISCOUNT' | translate}}</th>
            <td class="p-text-right">{{ invoice.discount|countryCurrency}}</td>
          </tr>
          <tr>
            <th scope="col" class="p-text-right">{{ 'SUBTOTAL' | translate}}</th>
            <td class="p-text-right">{{ invoice.subtotal - invoice.discount|countryCurrency }}</td>
          </tr>
          <tr>
            <th scope="col" class="p-text-right">{{ 'TAX' | translate}}</th>
            <td class="p-text-right">{{ invoice.tax|countryCurrency}}</td>
          </tr>
          <tr>
            <th scope="col" class="p-text-right">{{ 'GRAND_TOTAL' | translate}}</th>
            <td class="p-text-right">{{ invoice.total|countryCurrency }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</div>
</div>
