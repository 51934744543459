<app-loading-indicator [loading]="loading"></app-loading-indicator>
<p-card class="cardarea" *ngIf="loading === false">
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6">
      <h3 [style]="{ fontWeight: '600'}">
        <button pButton pRipple [routerLink]="['/orders/receive-order']" icon="pi pi-arrow-left">
        </button>
        {{ (shipment.documentType | translate) }}
        {{ shipment.documentNumber ? shipment.documentNumber : ''}}
        {{ shipment?.id}}
      </h3>
    </div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'ORDER_DETAIL'"></app-page-info>
      <p-splitButton *ngIf="editAction === true || notSave === true" [style]="{marginLeft: '5px', float:'right'}"
                     [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" label="{{ 'SAVE' | translate }}"
                     (onClick)="save()" [disabled]="this.submitted || documentNumberIsValid"></p-splitButton>

      <p-button *ngIf="editAction === false && invoice.isReceived === false"
                [style]="{marginLeft: '5px', float:'right'}" label="{{ 'EDIT' | translate }}"
                (onClick)="edit()"></p-button>
    </div>
  </div>
  <div class="p-grid" *ngIf="editAction === true && notSave === true">
    <div class="p-col-12 p-md-12 p-lg-6">
      <div class="warning-div">
        {{ 'RECEIVE_DESCRIPTION' | translate}}
      </div>
    </div>
  </div>
  <div class="p-grid" *ngIf="notSave === false">
    <div class="p-col-12 p-md-12 p-lg-12">
      <div class="warning-div alert-div">
        {{ 'RECEIVE_NOT_SAVE_INVENTORY_DESCRIPTION' | translate}}
      </div>
    </div>
  </div>
  <p-message *ngIf="!isCanBeSend" [style]="{marginTop: '10px', marginBottom:'10px'}" severity="info"
             text="Seçtiğiniz Tarih Açık Olan Envanter Başlangıç Tarihin {{ isCanBeSelectDate | date : 'Y-M-dd' }}'den Daha Eski Olamaz"
             styleClass="mr-2"></p-message>

  <div class="p-grid" *ngIf="editAction === false" style="margin-top: 30px;">
    <div class="p-col-12 p-md-6 p-lg-3">
      <b [style]="{fontSize: '14px'}">{{'DOCUMENT_DATE'| translate}}</b>
      <span [style]="{float: 'right'}">{{ shipment.documentDate | date: 'Y/MM/dd' }}</span>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <b [style]="{fontSize: '14px'}">{{'PAYMENT_DUE_DATE'| translate}}</b><span
      [style]="{float: 'right'}">{{ shipment.paymentDate | date }}</span>
    </div>
  </div>
  <div class="p-grid" *ngIf="editAction === false">
    <div class="p-col-12 p-md-6 p-lg-3">
      <b [style]="{fontSize: '14px'}">{{'TOTAL_INCL_TAX' | translate}}</b>
      <span [style]="{float: 'right'}">{{ shipment.invoice.total | countryCurrency}}</span>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <b [style]="{fontSize: '14px'}">{{'TOTAL_EXCL_TAX' | translate}}</b>
      <span
        [style]="{float: 'right'}">{{ shipment.invoice.subtotal | countryCurrency}}</span>
    </div>
    <div class="p-col-12 p-md-6 p-lg-4">
      <b [style]="{fontSize: '14px'}">{{'DOCUMENT_TYPE'| translate}}</b>
      <span [style]="{float: 'right'}">{{ shipment.documentType | translate }} {{ shipment.documentNumber }} </span>
    </div>
  </div>
  <div class="p-grid" *ngIf="editAction === true" style="margin-top: 30px;">
    <div class="p-col-12 alert-div " *ngIf="documentNumberIsValid"> Uyarı ! Bu Döküman Numarası Daha Önce Girilmiş</div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <label [style]="{fontSize: '14px'}" class="p-pb-2">{{'DOCUMENT_TYPE' | translate}}</label>
      <div class="p-grid">
        <div class="p-col-12">
          <p-dropdown dataKey="getDocument" [placeholder]="'SELECT'|translate" [(ngModel)]="shipment.documentType"
                      [options]="documentType" optionLabel="name" optionValue="value" [disabled]="isIncomingInvoice"></p-dropdown>
          <small class="p-error">{{ errors['documentType'] }}</small>
        </div>
      </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <label for="documentNumber" [style]="{fontSize: '14px'}">{{'DOCUMENT_NUMBER' | translate}}</label>
      <input id="documentNumber" [style]="{width:'100% !important'}" (change)="documentNumberControl($event)"
             [(ngModel)]="shipment.documentNumber" type="text" pInputText autofocus [disabled]="isIncomingInvoice">
      <small class="p-error">{{ totalError.length > 0 ? totalError[0].documentNumber : '' }}</small>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <label for="documentDate" [style]="{fontSize: '10px'}">{{'DOCUMENT_DATE' | translate}}</label>
      <p-calendar id="documentDate" [style]="{height: '30px'}" (onSelect)="shipmentDateControl($event)"
                  [(ngModel)]="shipment.documentDate" [minDate]="minDate" [maxDate]="today" dateFormat="dd-mm-yy"
                  inputId="dateformat" [disabled]="isIncomingInvoice"></p-calendar>
      <br>
      <div [style]="{marginTop:'20px'}"><small class="p-error p-mt-1">{{ totalError.length > 0 ? totalError[0].documentDate : '' }}</small></div>
    </div>
  </div>
  <div class="p-grid" *ngIf="editAction === true">
    <div class="p-col-12 p-md-6 p-lg-3">
      <label for="totalIncTax" [style]="{fontSize: '14px'}">{{'TOTAL_INCL_TAX' | translate}}</label>
      <p-inputNumber id="totalIncTax" [style]="{width:'100%'}" [(ngModel)]="shipment.total"
                     mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="5"></p-inputNumber>
      <small>{{ 'GRAND_TOTAL_CODE' | translate}}: {{ invoice.total | countryCurrency}}</small>
      <br>
      <small class="p-error">{{ totalError.length > 0 ? totalError[0].shipmentTotal : '' }}</small>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <label for="paymentDueDate" [style]="{fontSize: '14px'}">{{'PAYMENT_DUE_DATE' | translate}}</label>
      <p-calendar id="paymentDueDate" [(ngModel)]="shipment.paymentDate" dateFormat="mm-dd-yy"
                  inputId="dateformat"></p-calendar>
      <small class="p-error"></small>
    </div>
  </div>
  <div class="p-grid" style="margin-top: 40px">
    <div class="p-col-12 p-md-6 p-lg-3"></div>
    <div class="p-col-12 p-md-6 p-lg-3">
      <div class="field-checkbox">
        <p-checkbox [(ngModel)]="discountRateChecked" [binary]="true" id="rate"></p-checkbox>
        <label for="rate">{{'SHOW_DISCOUNT_COLUMN'|translate}}</label>
      </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3" [style]="{display: 'none'}">
      <div class="field-checkbox">
        <p-checkbox [(ngModel)]="discountValueChecked" [binary]="true" id="value"></p-checkbox>
        <label for="value">2. {{'SHOW_DISCOUNT_COLUMN'|translate}}</label>
      </div>
    </div>
  </div>
  <div class="p-grid" style="margin-top: 5px">
    <div class="p-col-12" style="overflow-x: auto;">
      <p-table [value]="invoice.items" [loading]="tableLoading" appendto="body">
        <ng-template pTemplate="header">
          <tr>
            <th [style]="{width:'18%',textAlign:'center'}">{{ 'PRODUCT_NAME' | translate}}</th>
            <th [style]="{width:'15%',textAlign:'center'}" *ngIf="lotTrackingView">{{ 'BARCODE' | translate}}</th>
            <th [style]="{width:'30%',textAlign:'center'}" *ngIf="lotTrackingView">{{ 'STT' | translate}}</th>
            <th [style]="{width:'5%',textAlign:'center'}">{{ 'UNIT' | translate}}</th>
            <th [style]="{width:'5%',textAlign:'center'}">{{ 'ORDER_QUANTITY' | translate}}</th>
            <th [style]="{width:'8%',textAlign:'center'}">{{ 'DOCUMENT_QUANTITY' | translate}}</th>
            <th [style]="{width:'8%',textAlign:'center'}">{{ 'RECIVED' | translate}}</th>
            <th [style]="{width:'5%',textAlign:'center'}" *ngIf="actionCredit">{{ 'ACTION' | translate}}</th>
            <th [style]="{width:'10%',textAlign:'center'}">{{ 'PRICE' | translate}}</th>
            <th [style]="{width:'10%',textAlign:'center'}" *ngIf="actionPrice">{{ 'ACTION' | translate}}</th>
            <th [style]="{width:'10%',textAlign:'center'}"  *ngIf="discountRateChecked">%Discount Rate</th>
            <th [style]="{width:'10%',textAlign:'center'}"  *ngIf="discountRateChecked">DisCount</th>
            <th [style]="{width:'10%',textAlign:'center'}">{{ 'GRAND_TOTAL' | translate}}</th>
            <th [style]="{width:'8%',textAlign:'center'}">{{ 'TAX_RATE' | translate}}</th>
            <th [style]="{width:'10%',textAlign:'center'}">{{ 'TAX_VALUE' | translate}}</th>
            <th [style]="{width:'4%',textAlign:'center'}">{{ 'MARK' | translate}}</th>
            <th [style]="{width:'3%',textAlign:'center'}"></th>
            <!---<th width="5%">{{ 'DELETE' | translate}}</th>--->
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-i="rowIndex" let-row>
          <tr [class.selectedRow]="row.marked">
            <td [style]="{padding: '10px 0'}">{{ row.purchaseOption === null ? row.variant.name : row.purchaseOption.name}}</td>
            <td *ngIf="lotTrackingView">
              <input [value]="row.barcode" pInputText name="barcode" id="barcode" *ngIf="row.stock.isTrackByLot === true"></td>
            <td *ngIf="lotTrackingView">
              <p-calendar id="stt{{i}}" [(ngModel)]="row.stockExpectedDate" dateFormat="dd-mm-yy" *ngIf="row.stock.isTrackByLot === true"
                            inputId="dateformat" appendto="body"></p-calendar>
            </td>
            <!---Unit--->
            <td [style]="{padding: '10px 0',textAlign:'right'}">
              <span *ngIf="row.purchaseOption?.packName === null">
                {{row.purchaseOption?.unit?.name}}
              </span>
              <span *ngIf="row.purchaseOption?.packName !== null">
                {{row.purchaseOption?.packName}}
              </span>

              <span *ngIf="row.purchaseOption == null">
                {{row.stock.unit?.name}}
              </span></td>
            <td [style]="{padding: '10px 0',textAlign:'center'}"> {{ row.documentQuantity }}</td>
            <!----Fatura Miktarı---->
            <td [style]="{padding: '10px 0',textAlign:'center'}" pEditableColumn>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input type="number" min="0" [style]="{height: '30px',border:'none'}" [(ngModel)]="row.quantity"
                                 (change)="changeInInvoiceItemQuantity(row, i, $event)">
                </ng-template>
                <ng-template pTemplate="output">
                  <b
                    [class.alertTextQuantity]="row.incomingQuantity === 0 ? row.remainingQuantity : row.incomingQuantity !== row.quantity">
                    {{row.quantity}}</b>

                </ng-template>
              </p-cellEditor>
            </td>

            <!----Incoming Quanty--->
            <td pEditableColumn [style]="{padding: '0px',textAlign:'center'}">
              <p-cellEditor [style]="{padding: '0px'}">
                <ng-template pTemplate="input" >
                  <input type="number" min="1" max="{{ row.quantity}}" name="incomingQuantity"
                                 [style]="{width: '70px',border:'none',height: '30px'}"
                                 [(ngModel)]="row.incomingQuantity"
                                 (change)="changeInInvoiceItemIncomingQuantity(row, i, $event)">
                </ng-template>
                <ng-template pTemplate="output">
                  <b *ngIf="row.incomingQuantity < row.quantity" [style]="{color: '#ff2525'}">
                    {{ row.incomingQuantity }}
                  </b>
                  <b *ngIf="row.incomingQuantity == row.quantity">
                    {{ row.incomingQuantity }}
                  </b>
                </ng-template>
              </p-cellEditor>
            </td>
            <td *ngIf="actionCredit">
                <div class="price-change" [style]="{padding: '1px',scale:'0.8'}" *ngIf="row.incomingQuantity < row.quantity">
                  <div class="p-col-12" [style]="{padding: '1px'}">
                    <p-checkbox name="groupname"  [(ngModel)]="row.credit" (click)="creditItemControl(row, i)"
                                 label="Credit" [binary]="true"></p-checkbox>
                  </div>
                </div>
            </td>
            <!--- Price---->
            <td [style]="{padding: '10px 0',textAlign:'center'}" pEditableColumn *ngIf="isDiscount == false">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-inputNumber [(ngModel)]="row.firstUnitPrice" inputId="withoutgrouping" [useGrouping]="false"
                                 (onBlur)="invoicePriceControl()"
                                 [minFractionDigits]="2" [maxFractionDigits]="5">
                  </p-inputNumber>
                </ng-template>
                <ng-template pTemplate="output">
                  {{ row.firstUnitPrice | countryCurrency }}
                </ng-template>

              </p-cellEditor>
            </td>
            <!--priceChance--->
            <td [style]="{padding: '10px 0',textAlign:'center'}" *ngIf="actionPrice">
              <div class="price-change" [style]="{padding: '1px'}" *ngIf="row.firstUnitPrice != row.unitPrice">
                  <div class="p-col-12" [style]="{padding: '1px'}">
                    <p-checkbox name="priceChangeAction" [(ngModel)]="row.priceChangeAction"
                                value="update" (onChange)="row.priceChangeAction = 'update';invoicePriceControl()"
                                label="Gün.">
                    </p-checkbox>
                  </div>
                  <div class="p-col-12" [style]="{padding: '1px',textAlign:'center'}">
                    <p-checkbox name="priceChangeAction" [(ngModel)]="row.priceChangeAction"
                                value="credit" (onChange)="row.priceChangeAction = 'credit';invoicePriceControl()"
                                [disabled]="!disabledValues"
                                label="F.F.F"></p-checkbox>
                  </div>
                </div>

            </td>
            <td [style]="{padding: '10px 0',textAlign:'center'}" *ngIf="discountRateChecked" pEditableColumn>
              <!---İndirim Oranı--->
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-inputNumber name="firstDiscountRateValues" [ngModel]="row.discountRate"
                                 [minFractionDigits]="2" [maxFractionDigits]="5"
                                 (onBlur)="onChangeDiscountRate($event, i)"></p-inputNumber>
                </ng-template>
                <ng-template pTemplate="output">
                  %{{ row.discountRate | number: '1.1-2'}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td [style]="{padding: '10px 0',textAlign:'center'}" *ngIf="discountRateChecked" pEditableColumn>
              {{ row.discount | countryCurrency}}
            </td>
            <!---Sub Total--->
            <td [style]="{padding: '10px 0',textAlign:'center'}" pEditableColumn>
             <!--- <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-inputNumber [ngModel]="row.subtotal" mode="decimal" inputId="withoutgrouping" [useGrouping]="false"
                                 [minFractionDigits]="2" [maxFractionDigits]="5"
                                 (onBlur)="changeInInvoiceItemSubTotal(row, i, $event.target.value)" ></p-inputNumber>
                </ng-template>
                <ng-template pTemplate="output">
                  {{ row.subtotal | countryCurrency}}
                </ng-template>
              </p-cellEditor>---->
              {{ row.subtotal | countryCurrency}}
            </td>
            <td [style]="{padding: '10px 0',textAlign:'right'}">{{ row.taxRate.name}}</td>
            <td [style]="{padding: '10px 0',textAlign:'right'}">{{ row.tax | countryCurrency}}</td>
            <td [style]="{padding: '10px 0',textAlign:'center'}">
              <p-checkbox name="groupname" value="Test" [binary]="true" [(ngModel)]="row.marked"></p-checkbox>
            </td>
            <td [style]="{padding: '10px 0'}">
              <button pButton pRipple [style]="{color:'#ff2525'}" (click)="deleteItem(i)" type="button"
                      icon="pi pi-trash" class="p-button-rounded p-button-text"></button>
            </td>
             </tr>
        </ng-template>
        <!---
        <ng-template pTemplate="footer">
          <tr>
            <td></td>
            <td>
              <p-autoComplete class="autoComplete" placeholder="{{'NEW_ITEM_ADD' | translate}}"
                              (onSelect)="onVariantSelect($event)" field="name" [suggestions]="variants"
                              (completeMethod)="searchVariant($event)">
              </p-autoComplete>
            </td>
            <td colspan="3"></td>
            <td *ngIf="discountRateChecked"></td>
            <td *ngIf="discountValueChecked"></td>
            <td colspan="6"></td>

          </tr>
        </ng-template>
        ---->
      </p-table>
      <br>
      <div *ngIf="creditRemember === true && credit.items.length > 0">
        <hr>
        <div class="p-fluid p-formgrid p-grid" style="margin-top: 20px">
          <div class="p-field p-col-8"><h5>{{'CREDIT_REMINDER'|translate}}</h5></div>
          <div class="p-field p-col-4">
            <p-button label="{{'SEND_CREDIT_REMINDER'|translate}}" icon="pi pi-check"></p-button>
          </div>
        </div>
        <p-table [value]="credit.items" [style]="{width: '100%'}">
          <ng-template pTemplate="header">
            <tr>
              <th [style]="{width: '10%'}">{{ 'CODE' | translate}}</th>
              <th [style]="{width: '20%'}">{{ 'PRODUCT_NAME' | translate}}</th>
              <th [style]="{width: '10%'}">{{ 'QUANTITY' | translate}}</th>
              <th [style]="{width: '10%'}">{{ 'PRICE' | translate}}</th>
              <th [style]="{width: '10%'}">{{ 'DISCOUNT_RATE' | translate}}</th>
              <th [style]="{width: '10%'}">{{ 'DISCOUNT' | translate}}</th>
              <th [style]="{width: '10%'}">{{ 'GRAND_TOTAL' | translate}}</th>
              <th [style]="{width: '10%'}">{{ 'TAX_RATE' | translate}}</th>
              <th [style]="{width: '10%'}">{{ 'TAX_VALUE' | translate}}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-i="rowIndex" let-creditItem>
            <tr>
              <td>{{ creditItem.variant.code }}</td>
              <td>{{ creditItem.stock.name }}</td>
              <td>{{ creditItem.quantity }}</td>
              <td>{{ creditItem.price | countryCurrency}}</td>
              <td>{{ creditItem.discountRate}}</td>
              <td>{{ creditItem.discount | countryCurrency}}</td>
              <td>{{ creditItem.quantity * creditItem.price | countryCurrency }}</td>
              <td>{{ creditItem.stock.tax.name }}</td>
              <td>{{ creditItem.tax | countryCurrency }}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>

      <div class="p-grid p-mt-6">
        <div class="p-col-8">
          <textarea pInputTextarea style="width: 100%" placeholder="{{'COMMENT' | translate}}"
                    [style]="{minHeight:'200px'}"></textarea><br>
          <p-fileUpload name="myfile[]" url="./upload.php"></p-fileUpload>
        </div>
        <div class="p-col-4">
          <div class="tableDiv-content">
            {{'PRICING_SECTION'| translate}} <span [style]="{float:'right'}">{{'SUM' |translate}}</span>
            <div class="tableDiv-sub" [style]="{marginTop:'10px'}">
              {{'TOTAL'| translate}} <span [style]="{float:'right'}">{{ firstSubTotal | countryCurrency}}</span>
            </div>
            <hr [style]="{border: 'none'}">
            <div class="tableDiv-sub" [style]="{marginTop:'3px'}">
              {{'DISCOUNT'| translate}} %
              <span [style]="{float:'right'}">
                <input name="discountRateValue" [(ngModel)]="invoice.discountRate"
                       (change)="reCalculateInvoiceByRate($event)"
                       [style]="{height:'30px',width: '100px',border: 'none',background: '#f8f9fa'}">
               </span>
            </div>
            <hr [style]="{border: 'none'}">
            <div class="tableDiv-sub" [style]="{marginTop:'10px'}">
              {{'DISCOUNT_TOTAL'| translate}} <span
              [style]="{float:'right'}">{{ invoice.discount | countryCurrency}}</span>
            </div>
            <hr [style]="{border: 'none'}">
            <div class="tableDiv-sub" [style]="{marginTop:'10px'}">
              {{'SUBTOTAL'| translate}} <span
              [style]="{float:'right'}">{{ invoice.subtotal- invoice.discount | countryCurrency}}</span>
            </div>
            <hr [style]="{border: 'none'}">
            <div class="tableDiv-sub" [style]="{marginTop:'3px'}">
              {{'TAX'| translate}} <span [style]="{float:'right'}">{{ invoice.tax | countryCurrency}}</span>
            </div>
            <hr [style]="{border: 'none'}">
            <div class="tableDiv-sub" [style]="{marginTop:'3px'}">
              {{'GRAND_TOTAL'| translate}} <span
              [style]="{float:'right'}">{{ invoice.total | countryCurrency}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12" style="overflow-x: auto;">
      <h6>{{'HISTORY'| translate}}</h6>
      <p-table [value]="shipments">
        <ng-template pTemplate="body" let-item>
          <tr>
            <td>{{ item.documentDate | date: 'd-M-Y'}} /
              {{ item.documentType}}{{ item.documentNumber}} /
              {{ item.createdBy.firstName}} {{ item.createdBy.lastName }}
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</p-card>

<p-toast></p-toast>
<p-confirmDialog #cd header="{{'WARNING'| translate}}" [breakpoints]="{'960px': '75vw', '640px': '100vw'}"
                 [style]="{width: '50vw'}">
  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-times" label="{{'NO'| translate}}" (click)="cd.reject()"></button>
    <button type="button" pButton icon="pi pi-check" label="{{'YES' | translate}}" (click)="cd.accept()"></button>
  </ng-template>
</p-confirmDialog>
