<app-loading-indicator [loading]="loading"></app-loading-indicator>
<div class="p-grid">
  <div class="p-col-12 p-md-6 p-sm-6 ">
    <h5>{{ 'WORK_ORDER_CREATE' | translate}}</h5>
  </div>
  <div class="p-col-12 p-md-6 p-sm-6 ">
    <button pButton label="{{'SAVE' | translate}}" *ngIf="workOrder.status === 'NOT_STARTED'" [style]="{marginLeft: '5px', float:'right'}"
            [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" [disabled]="this.submitted"
            (click)="save()"></button>

    <!--
    <a *ngIf="this.id && !loading" pButton [label]="'PRINT'|translate" style="float: right"
       [icon]="printing ? 'pi pi-spin pi-spinner': 'pi pi-print'" (click)="print()"
       class="p-button-secondary p-mb-2 p-float-right"></a>
    <a *ngIf="this.id && !loading" pButton [label]="'PRINT_PICK_LIST'|translate" style="float: right" icon="pi pi-print"
       [routerLink]="['/work-orders', workOrder.id, 'pick-list']"
       class="p-button-secondary p-mr-2 p-mb-2 p-float-right"></a>
       --->
    <p-button *ngIf="this.id && !loading && workOrder.status=== 'NOT_STARTED'" [label]="'DELETE'|translate"
              [icon]="deleting ? 'pi pi-spin pi-spinner' : 'pi pi-trash'" (click)="deleteWorkOrder()" [style]="{marginLeft: '5px', float:'right'}"
              styleClass="p-button-danger p-mr-2 p-mb-2 p-float-right"></p-button>

    <button pButton [style]="{marginLeft: '5px', float:'right'}" label="{{'INFO' | translate}}" class="p-mb-3"
            [icon]="'pi pi-info-circle'" (click)="infoTextShow()"></button>

  </div>
</div>
<div class="p-grid grid-div p-mt-3 p-mb-3" *ngIf="isSaved ">
  <div class="p-col div-sums alert-div">
    <b> {{ 'ALERT' | translate}}!</b> {{'PRODUCT_RECIPE_MATERIAL_STATUS_NOT_YET'| translate }}
  </div>
</div>
<div class="p-grid grid-div p-mt-3 p-mb-3" *ngIf="canSave ">
  <div class="p-col div-sums alert-div">
    <b> {{ 'ALERT' | translate}}!</b> {{'PRODUCT_RECIPE_MATERIAL_LOT_NOT_YET'| translate }}
  </div>
</div>
<div class="p-grid">
  <div class="p-col-12">
    <table style="width:100%">
      <tr>
        <td [style]="{width:'20%'}">{{'CODE'| translate}}</td>
        <td [style]="{width:'1%'}">:</td>
        <td [style]="{width:'790%'}"><b [style]="{fontSize:'16px'}">{{ workOrder.code}}</b>
          <p *ngIf="infoTextShowDisplay" class="information">{{'WORK_ORDER_CODE_DESCRIPTION' | translate}}</p>
        </td>
      </tr>
      <tr>
        <td>{{'PRODUCT' | translate}}</td>
        <td>:</td>
        <td>
          <b *ngIf="workOrder.id" [style]="{fontSize:'16px'}">{{ workOrder.stock?.name}}</b>
          <p-autoComplete *ngIf="!workOrder.id" id="product" [(ngModel)]="variant" name="productVariant"
                          (completeMethod)="searchVariant($event)"
                          (onSelect)="onVariantSelect($event); getStockStatus()" [suggestions]="variants"
                          field="name">
          </p-autoComplete>
          <small class="p-error">{{ errors['stock'] }}</small>
          <p *ngIf="infoTextShowDisplay" class="information">{{'WORK_ORDER_STOCK_DESCRIPTION' | translate}}</p>
        </td>
      </tr>
      <tr>
        <td>{{'PLANNED_QUANTITY' | translate}}</td>
        <td>:</td>
        <td>
          <p-inputNumber id="plannedQuantity" name="plannedQuantity" mode="decimal" inputId="withoutgrouping"
                         [useGrouping]="false" [style]="{width:'189px !important'}"
                         [(ngModel)]="workOrder.plannedQuantity"
                         (onBlur)="recalculateMaterials($event.target.value)"></p-inputNumber>
          <b style="font-size: 18px;margin-left: 10px;">{{ unitName}}</b>
          <p *ngIf="infoTextShowDisplay" class="information">{{'WORK_ORDER_QUANTITY_DESCRIPTION' | translate}}</p>
        </td>
      </tr>
      <tr *ngIf="lotTrackingView">
        <td>{{'BARCODE' | translate}}</td>
        <td>:</td>
        <td><input [value]="workOrder.barcode" pInputText name="barcode" id="barcode">
          <p *ngIf="infoTextShowDisplay" class="information">{{'WORK_ORDER_BARCODE_DESCRIPTION' | translate}}</p>
        </td>
      </tr>
      <tr *ngIf="lotTrackingView">
        <td>{{'STT' | translate}}</td>
        <td>:</td>
        <td>
        <p-calendar [(ngModel)]="workOrder.expirationDate" appendTo="body" id="expirationDate" name="expirationDate" dateFormat="dd-mm-yy"></p-calendar>
          <p *ngIf="infoTextShowDisplay" class="information">{{'WORK_ORDER_EXPIRATION_DATE_DESCRIPTION' | translate}}</p>
        </td>
      </tr>
      <tr>
        <td>{{'DEADLINE' | translate}}</td>
        <td>:</td>
        <td>
          <p-calendar [(ngModel)]="workOrder.deadline" appendTo="body" id="deadline" name="deadlinne" dateFormat="dd-mm-yy"></p-calendar>
          <p *ngIf="infoTextShowDisplay" class="information">{{'WORK_ORDER_DEADLINE_DESCRIPTION' | translate}}</p>
        </td>
      </tr>
      <tr>
        <td>{{ 'SOURCE' | translate}}</td>
        <td>:</td>
        <td>
          <p-dropdown appendTo="body" id="from" placeholder="{{'SELECT' | translate}}" [options]="locations" [(ngModel)]="workOrder.source"
                      optionLabel="name" (onChange)="recipeItemLocationQuantity($event)" change
                      optionValue="@id"></p-dropdown>
          <p *ngIf="infoTextShowDisplay" class="information">{{'WORK_ORDER_SOURCE_LOCATION_DESCRIPTION' | translate}}</p>
        </td>
      </tr>
      <tr>
        <td>{{ 'TARGET' | translate}}</td>
        <td>:</td>
        <td>
          <p-dropdown id="to" appendTo="body" placeholder="{{'SELECT' | translate}}" [options]="locations" [(ngModel)]="workOrder.target" optionLabel="name"
                      optionValue="@id" ></p-dropdown>
          <p *ngIf="infoTextShowDisplay" class="information">{{'WORK_ORDER_SOURCE_TARGET_DESCRIPTION' | translate}}</p>
        </td>
      </tr>
      <tr>
        <td>{{ 'PERSONAL' | translate}}</td>
        <td>:</td>
        <td>
          <p-dropdown id="personal" appendTo="body" placeholder="{{'SELECT' | translate}}" [options]="users" [(ngModel)]="workOrder.chef" optionValue="@id">
            <ng-template let-item pTemplate="selectedItem">
              {{item.firstName}} {{item.lastName}}
            </ng-template>
            <ng-template let-item pTemplate="item">
              {{item.firstName}} {{item.lastName}}
            </ng-template>
          </p-dropdown>

          <p *ngIf="infoTextShowDisplay" class="information">{{'WORK_ORDER_SOURCE_TARGET_DESCRIPTION' | translate}}</p>
        </td>
      </tr>
    </table>
  </div>
  <div class="p-grid">
    <div class="p-col-12">
      <p-divider></p-divider>
      <h3><b>{{'MATERIALS_FOR_PRODUCE'| translate}}</b></h3>
      <p-table [value]="workOrder.materials">
        <ng-template pTemplate="header">
          <tr>
            <th scope="col" [style]="{width:'30%'}">{{'STOCK' | translate}}</th>
            <th scope="col" [style]="{width:'30%'}">{{'LOT' | translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{'AMOUNT' | translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{'COST' | translate}}</th>
            <th scope="col" [style]="{width:'12%'}">{{'REQUIRE_AMOUNT' | translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{'STOCK_QUANTITY' | translate}}</th>
            <th scope="col" [style]="{width:'12%'}">{{'STOCK_STATUS' | translate}}</th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-order let-i= "rowIndex">
          <tr>
            <td>{{order.variant.name}}</td>
            <td >

              <p-multiSelect *ngIf="order.batchesData && order.batchesData?.length > 0"  appendTo="body" id="from" [options]="order.batchesData" placeholder="{{ 'SELECT' | translate}}"
                          optionLabel="barcode" [(ngModel)]="order.batches" (onChange)="createBatch($event, i, order)"
                          >
                <ng-template let-item pTemplate="item">
                  {{item.barcode}} / {{item.amount | number: '1.2-3'}} {{ order.variant?.stock?.unit.name}}
                </ng-template>
              </p-multiSelect>
            </td>
            <td>{{order.wastage | number: '1.2-2'}} {{ order.variant?.stock?.unit.name}}</td>
            <td>{{order.cost | countryCurrency}}</td>
            <td>{{order.totalQuantity | number: '1.2-2'}} {{ order.variant?.stock?.unit.name}}</td>
            <td>{{order.stockQuantity | number: '1.2-2'}} {{ order.variant?.stock?.unit.name}}</td>
            <td>
              <p-tag
                [severity]="order.itemStatus|statusLabel">{{ order.itemStatus|status: order.expectedDate }}</p-tag>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">{{'NOT_FOUND_RECIPE' | translate}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

</div>

<!---
<p-card>
  <button (click)="saveAndCloseSidebar()">Kaydet ve Kapat</button>
  <a *ngIf="this.id && !loading" pButton [label]="'PRINT'|translate" style="float: right"
     [icon]="printing ? 'pi pi-spin pi-spinner': 'pi pi-print'" (click)="print()"
     class="p-button-secondary p-mb-2 p-float-right"></a>
  <a *ngIf="this.id && !loading" pButton [label]="'PRINT_PICK_LIST'|translate" style="float: right" icon="pi pi-print"
     [routerLink]="['/work-orders', workOrder.id, 'pick-list']"
     class="p-button-secondary p-mr-2 p-mb-2 p-float-right"></a>
  <p-button *ngIf="this.id && !loading" [label]="'DELETE'|translate" style="float: right"
            [icon]="deleting ? 'pi pi-spin pi-spinner' : 'pi pi-trash'" (click)="deleteWorkOrder()"
            styleClass="p-button-danger p-mr-2 p-mb-2 p-float-right"></p-button>
  <p-splitter [panelSizes]="[20,80]" [style]="{'border': 'none'}">
    <ng-template pTemplate>
      <h5>{{ 'WORK_ORDER_CREATE' | translate}}</h5>
    </ng-template>
    <ng-template pTemplate>
      <p class="p-ml-3 page-sub-title">{{ 'WORK_ORDER_CREATE_DESCRIPTION' | translate }}</p>
    </ng-template>
  </p-splitter>
  <app-loading-indicator [loading]="loading"></app-loading-indicator>
  <div *ngIf="!loading">
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col-8">
        <label for="code">{{'CODE' | translate}}
          <a [routerLink]="['/sales/', invoiceId, 'edit']" *ngIf="invoiceCode">( {{ invoiceCode }} )</a>
        </label>
        <input id="code" type="text" pInputText [(ngModel)]="workOrder.code" name="code" autofocus>
        <small class="p-error">{{ errors['code'] }}</small>
      </div>
      <div class="p-field p-col-4">
        <label for="deadline">{{'DEADLINE' | translate}}</label>

        <small class="p-error">{{ errors['deadline'] }}</small>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col">
        <label for="product">{{'PRODUCT' | translate}}</label>

      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col">
        <label for="plannedQuantity">{{'PLANNED_QUANTITY' | translate}}</label>
        <p-inputNumber mode="decimal" id="plannedQuantity" name="plannedQuantity"
                       [(ngModel)]="workOrder.plannedQuantity" minFractionDigits="2" maxFractionDigits="2"
                       (ngModelChange)="calculateItemAmount(); calculateTotalCost(); getStockStatus()"></p-inputNumber>
        <small class="p-error">{{ errors['plannedQuantity'] }}</small>
      </div>
      <div class="p-field p-col">
        <label for="subtotal">{{'COST' | translate}}</label>
        <p-inputNumber id="subtotal" [(ngModel)]="workOrder.subtotal" minFractionDigits="2" maxFractionDigits="2"
                       name="subtotal"></p-inputNumber>
        <small class="p-error">{{ errors['cost'] }}</small>
      </div>
    </div>
    <div class="p-fluid p-formgrid p-grid">
      <div class="p-field p-col">
        <label for="from">{{ 'SOURCE' | translate}}</label>
        <p-dropdown id="from" [options]="locations" [(ngModel)]="workOrder.source" optionLabel="name"
                    optionValue="@id"></p-dropdown>
        <small class="p-error">{{ errors['source'] }}</small>
      </div>
      <div class="p-field p-col">
        <label for="to">{{ 'TARGET' | translate}}</label>
        <p-dropdown id="to" [options]="locations" [(ngModel)]="workOrder.target" optionLabel="name"
                    optionValue="@id"></p-dropdown>
        <small class="p-error">{{ errors['target'] }}</small>
      </div>
    </div>
    <p-table [value]="workOrder.materials" dataKey="id" responsiveLayout="scroll" [loading]="loadingRecipeItems"
             styleClass="p-datatable-striped">
      <ng-template pTemplate="header">
        <tr>
          <th scope="col" [style]="{width:'30%'}">{{ 'STOCK' | translate}}
            <app-tooltip description="CREATE_WORK_ORDER_MATERIAL_ITEM_TOOLTIP"></app-tooltip>
          </th>
          <th scope="col" [style]="{width:'20%'}">{{ 'NOTE' | translate}}
            <app-tooltip description="CREATE_WORK_ORDER_MATERIAL_NOTES_TOOLTIP"></app-tooltip>
          </th>
          <th scope="col" [style]="{width:'10%'}">{{ 'AMOUNT' | translate}}
            <app-tooltip description="CREATE_WORK_ORDER_MATERIAL_AMOUNT_TOOLTIP"></app-tooltip>
          </th>
          <th scope="col" [style]="{width:'10%'}">{{ 'CURRENCY' | translate}}
            <app-tooltip description="CURRENCY"></app-tooltip>
          </th>
          <th scope="col" [style]="{width:'10%'}">{{ 'COST' | translate}}
            <app-tooltip description="CREATE_WORK_ORDER_MATERIAL_COST_TOOLTIP"></app-tooltip>
          </th>
          <th scope="col" [style]="{width:'15%'}">{{ 'STATUS' | translate}}
            <app-tooltip description="CREATE_WORK_ORDER_MATERIAL_STATUS_TOOLTIP"></app-tooltip>
          </th>
          <th [style]="{width:'5%'}"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item let-i="rowIndex">
        <tr>
          <td pEditableColumn #input [pEditableColumnRowIndex]="1">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-autoComplete field="name" [(ngModel)]="autoCompleteValues[i]" name="variant"
                                (onSelect)="item.variant = $event['@id']; onMaterialSelect($event, i)"
                                [suggestions]="variants" (completeMethod)="searchVariant($event)"></p-autoComplete>
              </ng-template>
              <ng-template pTemplate="output">
                {{ autoCompleteValues[i] ? autoCompleteValues[i].name : '' }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText [(ngModel)]="item.notes" name="notes"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{ item.notes }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-inputNumber mode="decimal" [(ngModel)]="item.totalQuantity" name="itemCost"
                               (ngModelChange)="setMaterialPrice(i); calculateTotalCost();"></p-inputNumber>
              </ng-template>
              <ng-template pTemplate="output">
                {{ item.totalQuantity | number: '1.2-2' }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>{{ currencies[i] }}</td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-inputNumber [(ngModel)]="item.cost" name="totalCost" disabled></p-inputNumber>
              </ng-template>
              <ng-template pTemplate="output">
                {{item.cost | countryCurrency}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <p-tag *ngIf="workOrder.plannedQuantity > 0 && item.status !== ''"
                   [severity]="item.status|statusLabel">{{ item.status|status: item.expectedDate }}</p-tag>
          </td>
          <td align="right" [style]="{textAlign: 'right'}">
            <button pButton (click)="buy(autoCompleteValues[i]['stock']['id'])" icon="pi pi-shopping-cart"
                    class="p-button-outlined p-pr-1" *ngIf="item.status === 'NOT_AVAILABLE' "
                    [(ngModel)]="autoCompleteValues[i]['isBuying']"></button>
            <button pButton (click)="make(autoCompleteValues[i]['stock']['id'])" icon="pi pi-cog"
                    class="p-button-outlined p-pr-1"
                    *ngIf="autoCompleteValues[i] && autoCompleteValues[i]['stock'] && autoCompleteValues[i]['stock']['isMaking']"
                    [(ngModel)]="autoCompleteValues[i]['isMaking']"></button>
            <button pButton label="" icon="pi pi-trash" class="p-button-outlined p-button-danger" iconPos="left" (click)="removeMaterial(i)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <p-message *ngIf="recipeItemsNotAvailable" class="p-d-block" [style]="{width: '100%', padding: '10px 0'}"
               severity="info" [text]="'PRODUCT_RECIPE_NOT_AVAILABLE'|translate"></p-message>
    <div class="p-fluid p-pt-3 p-mb-3">
      <button pButton label="" icon="pi pi-plus" class="p-button-outlined" (click)="addMaterial()"
              iconPos="left"></button>
    </div>

    <p-table [value]="workOrder.operations" dataKey="id" [loading]="loadingOperationItems" responsiveLayout="scroll"
             styleClass="p-datatable-striped" class="p-d-block p-mt-5">
      <ng-template pTemplate="header">
        <tr>
          <th>{{ 'OPERATION' | translate}}
            <app-tooltip description="CREATE_WORK_ORDER_OPERATION_TOOLTIP"></app-tooltip>
          </th>
          <th>{{ 'RESOURCE' | translate}}
            <app-tooltip description="CREATE_WORK_ORDER_RESOURCE_TOOLTIP"></app-tooltip>
          </th>
          <th>{{ 'DEPENDENCIES' | translate}}
            <app-tooltip [description]="'DEPENDENCIES_DESCRIPTION'"></app-tooltip>
          </th>
          <th>{{ 'NOTE' | translate}}
            <app-tooltip description="CREATE_WORK_ORDER_MATERIAL_NOTES_TOOLTIP"></app-tooltip>
          </th>
          <th>{{ 'CURRENCY' | translate}}
            <app-tooltip description="CURRENCY"></app-tooltip>
          </th>
          <th>{{ 'COST' | translate}}
            <app-tooltip description="CREATE_WORK_ORDER_COST_TOOLTIP"></app-tooltip>
          </th>
          <th>{{ 'TIME' | translate}}</th>
          <th>{{ 'TOTAL_COST' | translate}}
            <app-tooltip description="CREATE_WORK_ORDER_TOTAL_COST_TOOLTIP"></app-tooltip>
          </th>
          <th width="10%"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item let-i="rowIndex">
        <tr>
          <td pEditableColumn #input [pEditableColumnRowIndex]="1">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-dropdown optionValue="@id" optionLabel="name" name="operation" [(ngModel)]="item.operation"
                            [options]="operations"></p-dropdown>
              </ng-template>
              <ng-template pTemplate="output">
                <span
                  *ngFor="let operation of operations">{{ operation['@id'] === item.operation ? operation.name : '' }}</span>
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-dropdown optionValue="@id" optionLabel="name" [(ngModel)]="item.resource" name="resource"
                            [options]="resources"></p-dropdown>
              </ng-template>
              <ng-template pTemplate="output">
                <span
                  *ngFor="let resource of resources">{{ resource['@id'] === item.resource ? resource.name : '' }}</span>
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-multiSelect optionValue="@id" optionLabel="name" [(ngModel)]="item.dependencies"
                               [options]="operations"></p-multiSelect>
              </ng-template>
              <ng-template pTemplate="output">
                <span
                  *ngFor="let r of operations">{{ item.dependencies && item.dependencies.indexOf(r['@id']) > -1 ? r.name + ' ' : '' }}</span>
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText [(ngModel)]="item.notes" name="notes"/>
              </ng-template>
              <ng-template pTemplate="output">
                {{ item.notes }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            {{ currency }}
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-inputNumber [(ngModel)]="item.cost" name="operationCost"
                               (ngModelChange)="item.totalCost = item.cost * item.plannedTime; calculateTotalCost()"></p-inputNumber>
              </ng-template>
              <ng-template pTemplate="output">
                {{item.cost|currency: currency: ''}}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-inputNumber mode="decimal" [(ngModel)]="item.plannedTime" name="plannedTime"
                               (ngModelChange)="item.totalCost = item.cost * item.plannedTime; calculateTotalCost()"></p-inputNumber>
              </ng-template>
              <ng-template pTemplate="output">
                {{ item.plannedTime }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            {{ item.totalCost|currency: currency }}
          </td>
          <td align="right" [style]="{textAlign: 'right'}">
            <button pButton label="" icon="pi pi-trash" class="p-button-outlined p-button-danger" iconPos="left"
                    (click)="removeOperation(i)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <small class="p-error p-d-block">{{ errors['operations'] }}</small>

    <p-message *ngIf="operationItemsNotAvailable" class="p-d-block" [style]="{width: '100%', padding: '10px 0'}"
               severity="info" [text]="'PRODUCT_OPERATION_NOT_AVAILABLE'|translate"></p-message>
    <div class="p-fluid p-pt-3 p-mb-3">
      <button pButton label="" icon="pi pi-plus" class="p-button-outlined" (click)="addOperation()"
              iconPos="left"></button>
    </div>

  </div>
</p-card>

--->
