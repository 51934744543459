<app-loading-indicator [loading]="loading"></app-loading-indicator>
<div *ngIf="loading === false">
  <div class="p-grid">
    <div class="p-col-12 p-md-3 p-sm-3">
      <h3 *ngIf="nameEditable === false">{{ item.name}}
        <i class="pi pi-pencil"
           [style]="{marginLeft:'10px',color:'#00b4d2'}"
           (click)="onEditableName(true)"></i></h3>
      <input *ngIf="nameEditable === true"
             [style]="{width: '200px',height:'45px',border:'none',borderBottom:'2px solid #00b4d2'}"
             id="name" type="text" placeholder="{{ 'NAME' | translate }}"
             pInputText [(ngModel)]="item.name" (blur)="!item.id ? saveStock() : null" autofocus>
      <small *ngIf="nameEditable === true" id="username1-help" [style]="{color:'#b9b9b9',marginTop:'5px'}"
             class="block">{{'SAMPLE_TEXT_INVENTORY_ITEM_NAME'| translate}}</small>
      <small class="p-error">{{ errors['name'] }}</small>
    </div>
    <div class="p-col-12 p-md-9 p-sm-9">
      <app-page-info [pageName]="'MENU_ITEM_CREATE'"></app-page-info>
      <button pButton [style]="{marginLeft: '5px', float:'right'}" label="{{'SAVE' | translate}}" class="p-mb-3"
              [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" (click)="save()"></button>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-4">
      <span>{{ 'CATEGORY' | translate}}</span>
      <div class="p-inputgroup">
          <span class="p-float-label">
            <p-dropdown [filter]="true" [placeholder]="'SELECT_CATEGORY'|translate"
                        (onChange)="onChangeCategory($event)" [options]="categories" [(ngModel)]="item.category"
                        optionLabel="name" id="category" optionValue="@id"></p-dropdown>
          </span>
        <span class="p-inputgroup-addon" (click)="addCategory()"><i class="pi pi-plus"></i></span>
      </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <span>{{ 'UNIT' | translate}}</span>
      <div class="p-inputgroup">
                  <span class="p-float-label">
                    <p-dropdown [style]="{ width: '100%'}" [filter]="true" [placeholder]="'SELECT_UNIT'|translate"
                                [options]="units" [(ngModel)]="item.unit" optionLabel="name"
                                class="p-inputtext-sm" appendTo="body"
                                optionValue="@id"></p-dropdown>
                    <small class="p-error">{{ errors['unit'] }}</small>
                  </span>
        <span class="p-inputgroup-addon" (click)="addUnit()"><i class="pi pi-plus"></i></span>
      </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <span>{{ 'TAX' | translate}}</span>
      <div class="p-inputgroup">
                  <span class="p-float-label">
                    <p-dropdown [style]="{ width: '100%'}" [filter]="true" [placeholder]="'SELECT_TAX'|translate"
                                [options]="taxes" [(ngModel)]="item.tax" optionLabel="name"
                                class="p-inputtext-sm" appendTo="body"
                                optionValue="@id"></p-dropdown>
                    <small class="p-error">{{ errors['tax'] }}</small>
                  </span>
        <span class="p-inputgroup-addon" (click)="addTax()"><i class="pi pi-plus"></i></span>
      </div>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <span>{{ 'CURRENCY' | translate}}</span>
      <p-dropdown [options]="currencies" [(ngModel)]="item.currency" (onChange)="changeCurrency($event)"
                  optionLabel="code" class="p-inputtext-sm" appendTo="body"
                  optionValue="@id"></p-dropdown>
      <small class="p-error">{{ errors['currency'] }}</small>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12">
      <p-tabMenu [model]="tabMenuItems" [activeItem]="activeTabMenuItem"></p-tabMenu>
    </div>
  </div>
  <div [style]="{background:'#f6f6f6',marginLeft:'-23px',marginRight:'-9px',padding:'1px 15px 1px 20px'}">

    <!--- Menu Items and Recipes Informatıon --->
    <div *ngIf="activeTabMenuItem.title === 'MENU_ITEMS'">
      <div class="p-grid">
        <div class="p-col-3">
          <h4>{{ 'UNIT' | translate}}</h4>
          <div class="p-inputgroup">
                  <span class="p-float-label">
                    <p-dropdown [style]="{ width: '100%'}" [filter]="true" [placeholder]="'SELECT_UNIT'|translate"
                                [options]="units" [(ngModel)]="item.unit" optionLabel="name" id="unit" appendTo="body"
                                optionValue="@id"></p-dropdown>
                    <small class="p-error">{{ errors['unit'] }}</small>
                  </span>
            <span class="p-inputgroup-addon" (click)="addUnit()"><i class="pi pi-plus"></i></span>
          </div>
        </div>
        <div class="p-col-3">
          <h4>{{ 'TAX' | translate}}</h4>
          <div class="p-inputgroup" [style]="{marginTop:'-5px'}">
                  <span class="p-float-label">
                    <p-dropdown [style]="{ width: '100%'}" [filter]="true" [placeholder]="'SELECT_TAX'|translate"
                                [options]="taxes" [(ngModel)]="item.tax" optionLabel="name" id="taxRate" appendTo="body"
                                optionValue="@id"></p-dropdown>
                    <small class="p-error">{{ errors['tax'] }}</small>
                  </span>
            <span class="p-inputgroup-addon" (click)="addTax()"><i class="pi pi-plus"></i></span>
          </div>
        </div>
        <div class="p-col-3">
          <h4>{{ 'CURRENCY' | translate}}</h4>
          <p-dropdown [options]="currencies" [(ngModel)]="item.currency" (onChange)="changeCurrency($event)"
                      optionLabel="code" id="currency" optionValue="@id" appendTo="body"
          ></p-dropdown>
          <small class="p-error">{{ errors['currency'] }}</small>
        </div>
        <div class="p-col-3">
          <h4>{{ 'LOCATION' | translate}}</h4>
          <p-multiSelect [style]="{width: '100%',height: '45px'}" [options]="locations" [(ngModel)]="item.locations"
                         appendTo="body" optionLabel="name" id="location" optionValue="@id"></p-multiSelect>
          <small class="p-error">{{ errors['locations'] }}</small>
        </div>
      </div>
    </div>
    <!----Size--->
    <div *ngIf="activeTabMenuItem.title === 'SIZE'">
      <p-table [value]="item.variants" dataKey="id" responsiveLayout="scroll" styleClass="p-datatable-striped">
        <ng-template pTemplate="header">
          <tr>
            <th [style]="{width: '12%'}">{{ 'SKU' | translate}}
              <app-tooltip description="SKU_TOOLTIP"></app-tooltip>
            </th>
            <th [style]="{width: '30%'}">{{ 'NAME' | translate}}</th>
            <th [style]="{width: '10%'}">{{ 'MENUID' | translate}}</th>
            <th [style]="{width: '10%'}">{{ 'SIZEID' | translate}}</th>
            <th [style]="{width: '15%'}">{{ 'BARCODE' | translate}}
              <app-tooltip description="PRODUCTS_CURRENCY_TOOLTIP"></app-tooltip>
            </th>
            <th [style]="{width: '15%'}">{{ 'SALE_PRICE' | translate}}
              <app-tooltip description="STOCK_SALES_PRICE_TOOLTIP"></app-tooltip>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-variant let-i="rowIndex">
          <tr>
            <td pEditableColumn #input [pEditableColumnRowIndex]="1">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input type="text" name="variantCode" pInputText [(ngModel)]="variant.code">
                </ng-template>
                <ng-template pTemplate="output">
                  <span>{{ variant.code }}</span>
                </ng-template>
              </p-cellEditor>
            </td>
            <td pEditableColumn>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input id="variantName" type="text" pInputText [(ngModel)]="variant.name">
                </ng-template>
                <ng-template pTemplate="output">
                  {{ variant.name }}
                </ng-template>
              </p-cellEditor>
            </td>
            <td pEditableColumn>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input id="menuİd" type="text" pInputText [(ngModel)]="variant.menuId">
                </ng-template>
                <ng-template pTemplate="output">
                  {{ variant.menuId }}
                </ng-template>
              </p-cellEditor>
            </td>
            <td pEditableColumn>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <input id="sizeId" type="text" pInputText [(ngModel)]="variant.sizeId">
                </ng-template>
                <ng-template pTemplate="output">
                  {{ variant.sizeId }}
                </ng-template>
              </p-cellEditor>
            </td>
            <td pEditableColumn #input [pEditableColumnRowIndex]="1">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-inputNumber [(ngModel)]="variant.barcode"></p-inputNumber>
                </ng-template>
                <ng-template pTemplate="output">
                  {{ variant.barcode}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td pEditableColumn #input [pEditableColumnRowIndex]="1">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-inputNumber [(ngModel)]="variant.salePrice" [minFractionDigits]="2"
                                 [maxFractionDigits]="5"></p-inputNumber>
                </ng-template>
                <ng-template pTemplate="output">
                  {{ variant.salePrice | countryCurrency}}
                </ng-template>
              </p-cellEditor>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <hr>
      <div class="p-grid">
        <!--
        <div class="p-col-6">
          <button pButton label="{{'ADD_SIZE' | translate}}" class="p-mb-3 add-button " [icon]="'pi pi-plus'"
                  (click)="addItem()"></button>
        </div>
        -->
        <div class="p-col-12" >
          <h4><b>{{'ADVANCED_ITEM_DEFINITION' |translate}}</b></h4>

          <div class="p-field-3">
            <label for="location">{{ 'LOCATIONS'| translate}}</label>
            <p-multiSelect [style]="{width: '100%',height: '45px'}" [options]="locations" [(ngModel)]="item.locations"
                           appendTo="body" optionLabel="name" id="locations" optionValue="@id"></p-multiSelect>
            <small class="p-error">{{ errors['locations'] }}</small>
          </div>
          <div class="p-field-3">
            <label for="location">{{ 'MODIFIERS'| translate}}</label>
            <p-dropdown [style]="{width: '100%',height: '45px'}" [options]="modifiers" [(ngModel)]="item.modifier"
                        placeholder="{{ 'SELECT_MODIFIER'| translate }}"
                           appendTo="body" optionLabel="name" optionValue="@id"></p-dropdown>
            <small class="p-error">{{ errors['modifierGroup'] }}</small>
          </div>
          <div class="p-field-3">
            <label for="location">{{ 'EXTRAS'| translate}}</label>
            <p-dropdown [style]="{width: '100%',height: '45px'}" [options]="modifiers" [(ngModel)]="item.extra"
                        placeholder="{{ 'SELECT_EXTRAS'| translate }}" (onChange)="test()"
                        appendTo="body" optionLabel="name"  optionValue="@id"></p-dropdown>
            <small class="p-error">{{ errors['extraGroup'] }}</small>
          </div>
          <div class="p-field-3">
            <label for="location">{{ 'MENUID'| translate}}</label>
            <input [style]="{width: '200px',height:'45px',border:'none',borderBottom:'2px solid #00b4d2'}"
                   id="menuid" type="text"
                   pInputText [(ngModel)]="item.posId"  autofocus>
            <small class="p-error">{{ errors['extraGroup'] }}</small>
          </div>
        </div>
      </div>
    </div>
    <!--Allergens -->
    <div *ngIf="activeTabMenuItem.title === 'ALLERGENS' ">
      <p-table [value]="item.allergen" class="p-datatable-striped" responsiveLayout="scroll">
        <ng-template pTemplate="header">
          <tr>
            <th scope="col" [style]="{width: '40%'}">{{ 'NAME'| translate}}</th>
            <th scope="col">{{'NO_INFO' | translate}}</th>
            <th scope="col">{{'CONTAINS' | translate}}</th>
            <th scope="col">{{'MAY_CONTAIN' | translate}}</th>
            <th scope="col">{{'DOES_NOT_CONTAIN' | translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row let-i="rowIndex">
          <tr>
            <td>{{ row.name | translate }} </td>
            <td>
              <p-checkbox [(ngModel)]="row.noInfo"
                          (onChange)="change($event, i, noInfo = true,contains= false,mayContain=false,doesNotContain= false)"
                          binary="true"></p-checkbox>
            </td>
            <td>
              <p-checkbox [(ngModel)]="row.contains"
                          (onChange)="change($event, i, noInfo = false,contains= true,mayContain=false,doesNotContain= false)"
                          binary="true"></p-checkbox>
            </td>
            <td>
              <p-checkbox [(ngModel)]="row.mayContain"
                          (onChange)="change($event, i, noInfo = false,contains= false,mayContain=true,doesNotContain= false)"
                          binary="true"></p-checkbox>
            </td>
            <td>
              <p-checkbox [(ngModel)]="row.doesNotContain"
                          (onChange)="change($event, i, noInfo = false,contains= false,mayContain=false,doesNotContain= true)"
                          binary="true"></p-checkbox>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    <!-- Prosedure --->
    <div *ngIf="activeTabMenuItem.title === 'PROCEDURE' ">
      <div class="tableDiv-sub">
        <div class="p-grid" [style]="{marginTop: '20px'}">
          <div class="p-col-3" [style]="{borderLeft: '1px solid #fbfbfb '}">
            <div [style]="{minHeight:'320px'}">
              <img *ngIf="item.image" height="320px" class="p-d-block"
                   [src]="item.image? apiUrl + '/' + item.image : '/assets/img/avatar.png'" alt="">
            </div>
            <hr>
            <p-fileUpload mode="basic" class="p-mt-2 p-mr-2 p-d-inline-block"
                          #fileUpload [chooseIcon]="imageLoading ? 'pi pi-spin pi-spinner' : 'pi pi-plus'"
                          [chooseLabel]="'SELECT_FILE'|translate" name="file" accept="image/*" maxFileSize="1000000"
                          [auto]="true" [customUpload]="true"
                          (uploadHandler)="upload($event, fileUpload)"></p-fileUpload>
            <p-button [icon]="imageDeleting ?  'pi pi-spin pi-spinner': 'pi pi-trash'" class="p-d-inline-block"
                      (onClick)="deleteImage()" [label]="'DELETE'|translate"
                      [style]="{background: 'none',border: 'none',color: '#444444'}"
            ></p-button>

          </div>
          <div class="p-col-9">
            <div class="p-grid" [style]="{marginTop: '20px', display:'flex'}">
              <div class="p-col-12 p-md-6 p-lg-4">
          <span class="p-float-label">
                <p-inputNumber id="prepTime" [style]="{ width: '100%'}" (onBlur)="changeTime(item)"
                               [(ngModel)]="item.prepTime"></p-inputNumber>
                <label for="prepTime">{{ 'PREP_TIME' | translate}}</label>
          </span>
              </div>
              <div class="p-col-12 p-md-6 p-lg-4">
          <span class="p-float-label">
                <p-inputNumber id="cookTime" [style]="{ width: '100%'}" (onBlur)="changeTime(item)"
                               [(ngModel)]="item.cookTime"></p-inputNumber>
                <label for="cookTime">{{ 'COOK_TIME' | translate}}</label>
          </span>
              </div>
              <div class="p-col-12 p-md-6 p-lg-4">
          <span class="p-float-label">
            <input id="readyTime" *ngIf="readyTime" type="text" pInputText disabled="true" [(ngModel)]="readyTime"
                   [style]="{width:'100%',  border: 'none',fontSize:'40px',marginTop: '-15px'}">
            <label for="readyTime">{{ 'READY_TIME' | translate}}</label>
          </span>
              </div>
            </div>
            <div class="p-clearfix"></div>
            <div class="p-grid p-pt-5">
              <div class="p-col-12">
          <span class="p-float-label">
            <textarea id="COOKING_INSTRUCTIONS" placeholder="{{ 'COOKING_INSTRUCTIONS' | translate}}"
                      [(ngModel)]="item.cookingInstruction" rows="5" cols="30" pInputTextarea
                      [style]="{width: '100%'}"></textarea>
          </span>
              </div>
            </div>
            <div class="p-grid p-pt-5">
              <div class="p-col-12">
          <span class="p-float-label">
            <textarea id="ABOUT_THE_ITEM" placeholder="{{ 'ABOUT_THE_ITEM' | translate}}" [(ngModel)]="item.about"
                      rows="5" cols="30" pInputTextarea [style]="{width: '100%'}"></textarea>
          </span>
              </div>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
</div>

<p-sidebar [(visible)]="transactionDetailShow" position="right" [style]="{width: '50vw'}">
  <h3>{{ 'INFORMATION' | translate}}</h3>
  <div class="p-grip p-mt-3" *ngIf="shipment.length > 0 ">
    <div *ngFor="let item of shipment">
      <div *ngIf="item.invoice.code === selectedCode">
        <div class="row-div">
          <div class="p-col-3"><b>{{ 'CODE' | translate }} </b></div>
          <div class="p-col-9">: {{ item.invoice.code }}</div>
        </div>
        <div class="row-div">
          <div class="p-col-3"><b>{{ 'TYPE' | translate }} </b></div>
          <div class="p-col-9">: {{ 'PURCHASE' | translate }}</div>
        </div>
        <div class="row-div">
          <div class="p-col-3"><b>{{ 'DATE' | translate }} </b></div>
          <div class="p-col-9">: {{ item.documentDate | date: 'Y-M-dd H:M:s' }}</div>
        </div>
        <div class="row-div">
          <div class="p-col-3"><b>{{ 'LOCATION' | translate }} </b></div>
          <div class="p-col-9">: {{ item.invoice.location.name}}</div>
        </div>
        <div class="row-div">
          <div class="p-col-3"><b>{{ 'SUPPLIER' | translate }} </b></div>
          <div class="p-col-9">: {{ item.invoice.contact.name}}</div>
        </div>
        <div class="row-div">
          <div class="p-col-3"><b>{{ 'CREATED_USER' | translate }} </b></div>
          <div class="p-col-9">: {{ item.createdBy.firstName}} {{ item.createdBy.lastName}}</div>
        </div>
        <div class="row-div">
          <div class="p-col-3"><b>{{ 'DESCRIPTION' | translate }} </b></div>
          <div class="p-col-9">: {{ item.invoice.description}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="p-grip p-mt-3" *ngIf="transfer.length > 0 ">
    <div *ngFor="let item of transfer">
      <div *ngIf="item.code === selectedCode">
        <div class="row-div">
          <div class="p-col-2"><b>{{ 'CODE' | translate }} </b></div>
          <div class="p-col-10">: {{ item.code }}</div>
        </div>
        <div class="row-div">
          <div class="p-col-2"><b>{{ 'TYPE' | translate }} </b></div>
          <div class="p-col-10">: {{ 'STOCK_TRANSFERS' | translate }}</div>
        </div>
        <div class="row-div">
          <div class="p-col-2"><b>{{ 'DATE' | translate }} </b></div>
          <div class="p-col-10">: {{ item.transferDate | date: 'Y-M-dd H:M:s' }}</div>
        </div>
        <div class="row-div">
          <div class="p-col-2"><b>{{ 'TRANSFER_ORIGIN' | translate }} </b></div>
          <div class="p-col-10">: {{ item.origin.name}}</div>
        </div>
        <div class="row-div">
          <div class="p-col-2"><b>{{ 'TRANSFER_DESTINATION' | translate }} </b></div>
          <div class="p-col-10">: {{ item.destination.name}}</div>
        </div>
        <div class="row-div">
          <div class="p-col-2"><b>{{ 'CREATED_USER' | translate }} </b></div>
          <div class="p-col-10">: {{ item.createdBy.firstName}} {{ item.createdBy.lastName}}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="p-grip p-mt-3" *ngIf="waste.length > 0 ">
    <div *ngFor="let item of waste">
      <div *ngIf="item.code === selectedCode">
        <div class="row-div">
          <div class="p-col-2"><b>{{ 'CODE' | translate }} </b></div>
          <div class="p-col-10">: {{ item.code }}</div>
        </div>
        <div class="row-div">
          <div class="p-col-2"><b>{{ 'TYPE' | translate }} </b></div>
          <div class="p-col-10">: {{ 'WASTE_EVENTS' | translate }}</div>
        </div>
        <div class="row-div">
          <div class="p-col-2"><b>{{ 'DATE' | translate }} </b></div>
          <div class="p-col-10">: {{ item.date | date: 'Y-M-dd H:M:s'}}</div>
        </div>
        <div class="row-div">
          <div class="p-col-2"><b>{{ 'DESCRIPTION' | translate }} </b></div>
          <div class="p-col-10">: {{ item.description | translate}}</div>
        </div>
        <div class="row-div">
          <div class="p-col-2"><b>{{ 'LOCATION' | translate }} </b></div>
          <div class="p-col-10">: {{ item.location.name}}</div>
        </div>
        <div class="row-div">
          <div class="p-col-2"><b>{{ 'CREATED_USER' | translate }} </b></div>
          <div class="p-col-10">: {{ item.createdBy.firstName}} {{ item.createdBy.lastName}}</div>
        </div>
      </div>
    </div>
  </div>
</p-sidebar>

<p-dialog [(visible)]="staticsDisplay">
  <h4>{{ stockName }}</h4>
  <p-dropdown [options]="locations" appendTo="body" placeholder="{{ 'SELECT_LOCATION' | translate}}" optionLabel="name"
              optionValue="id" (onChange)="getChartOptions($event)">
  </p-dropdown>
  <br>
  <p-chart type="bar" [data]="data" [options]="chartOptions" width="700px" height="400px"></p-chart>
</p-dialog>
