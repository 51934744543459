<app-loading-indicator [loading]="loading"></app-loading-indicator>
<p-card>
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'PLANNING' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'PLANNING'"></app-page-info>
      <p-button icon="pi pi-arrow-down" [style]="{marginLeft: '5px', float:'right'}"></p-button>
      <p-button icon="pi pi-arrow-down" label="{{'SKT_PRODUCTS' | translate}}" (onClick)="getStockBatches()"
                [style]="{marginLeft: '5px', float:'right'}"></p-button>
      <button pButton [style]="{marginLeft: '5px', float:'right'}" label="{{'INFO' | translate}}" class="p-mb-3"
              [icon]="'pi pi-info-circle'" (click)="infoTextShow()"></button>
    </div>
  </div>
  <div class="p-grid" style="padding: 10px">
    <div class="p-col-12 information" *ngIf="infoTextShowDisplay">
      <app-planning-page-info></app-planning-page-info>
    </div>
  </div>
  <div class="p-grid">
    <div class="{{supplierCount > 0 ? 'p-col-9': 'p-col-12'}}">

      <p-table [loading]="loading" [lazy]="true" [value]="worksOrders" dataKey="id"
               [paginator]="false" [resizableColumns]="true" [style]="{lineHeight: '0px'}"
               styleClass="p-datatable-gridlines p-datatable-striped p-datatable-lg"
      >
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="10">
              <div class="alert-div" [style]="{padding: '20px'}">{{'NOT_FOUND_PLANNING_ITEM' | translate}}</div>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="header">
          <tr>
            <th scope="col" [style]="{width:'3%'}"></th>
            <th scope="col" [style]="{width:'10%'}">{{ 'NAME'| translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{ 'QUANTITY'| translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{ 'STOCK_QUANTITY'| translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{ 'PRODUCE_QUANTITY'| translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{ 'COST'| translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{ 'STATUS'| translate}}</th>
            <th scope="col" [style]="{width:'12%'}">{{ 'RECIPE'| translate}}</th>
            <th scope="col" [style]="{width:'5%'}">{{ ''| translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{ 'STATUS'| translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-row let-i="rowIndex" let-expanded="expanded" let-expandedRecipe="expanded">
          <tr>
            <td id="expanded{{i}}">
              <button type="button" pButton pRipple [pRowToggler]="row"
                      class="p-button-text p-button-rounded p-button-plain" (click)="tableType = 'invoices'"
                      [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
            </td>
            <td>{{ row.stock.name}}</td>

            <td>{{ row.orderQuantity | number: '1.2-2'}} {{ row.stock?.unit?.name}}</td>

            <td>{{ row.amount | number: '1.2-2'}} {{ row.stock?.unit?.name}}</td>
            <td>{{ row.plannedQuantity | number: '1.2-2'}} {{ row.stock?.unit?.name}}</td>
            <td>{{row.variant?.totalCost | countryCurrency}}</td>
            <td id="itemStatus{{i}}">
              <p-tag [severity]="row.itemStatus|statusLabel">{{ row.itemStatus|status: row.itemExpectedDate }}</p-tag>
            </td>
            <td id="materialStatus{{i}}">
              <p-tag (click)="onLoadRecipes(row.variant.id)"
                     [severity]="row.materialStatus|statusLabel">{{ row.materialStatus|status: row.materialExpectedDate }}</p-tag>
            </td>
            <td id="recipe{{i}}">
              <button type="button" pButton pRipple [pRowToggler]="row"
                      class="p-button-text p-button-rounded p-button-plain" (click)="tableType = 'recipes'"
                      [icon]="expandedRecipe ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
            </td>
            <td id="make{{i}}">
              <p-button label="{{ 'MAKE' | translate}}"
                        *ngIf="row.materialStatus !== 'NOT_AVAILABLE' && row.materialStatus !== 'NOT_RECIPE'"
                        (onClick)="produce(row)" icon="pi pi-cog"></p-button>
            </td>

          </tr>
        </ng-template>
        <!---Invoices ---->
        <ng-template pTemplate="rowexpansion" let-row>
          <tr>
            <td colspan="10" [style]="{padding: '0px'}">
              <div class="p-3">
                <p-table [value]="row.invoices" dataKey="id" *ngIf="tableType === 'invoices'">
                  <ng-template pTemplate="header">
          <tr>
            <th scope="col" [style]="{width:'8%'}">{{'SALES_INVOICE_CODE' | translate}}</th>
            <th scope="col" [style]="{width:'20%'}">{{'CONTACT' | translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{'LOCATION' | translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{'SALES_INVOICE_CREATED_BY' | translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{'SALES_INVOICE_DATE' | translate}}</th>
            <!--
             <th scope="col" [style]="{width:'10%'}">{{'PURCHASE_INVOICE_CODE' | translate}}</th>
             <th scope="col" [style]="{width:'10%'}">{{'PURCHASE_INVOICE_DATE' | translate}}</th>
             <th scope="col" [style]="{width:'10%'}">{{'PURCHASE_INVOICE_CREATED_BY' | translate}}</th>

              <th scope="col" [style]="{width:'10%'}">{{'QUANTITY' | translate}}</th>
              <th scope="col" [style]="{width:'5%'}">{{'UNIT' | translate}}</th>
              -->
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-invoice>
          <tr>
            <td>{{invoice.code}}</td>
            <td>{{invoice.contact?.name}}</td>
            <td>{{invoice.location?.name}}</td>
            <td>{{invoice.createdBy.firstName}} {{invoice.createdBy.lastName}}</td>
            <td>{{invoice.createdAt | date: 'd-MM-Y'}}</td>
            <!--
            <td>{{invoice.purchaseInvoice}}</td>
            <td>{{order.purchaseInvoiceDate.date | date: 'd-MM-Y'}}</td>
            <td>{{order.purchaseFirstName}} {{order.purchaseLastName}}</td>
            <td [style]="{textAlign:'right'}">{{order.quantity | number: '1.2-2'}}</td>
            <td>{{order.unitName}}</td>

            <td>{{invoice.amount}}</td>
             -->
          </tr>
        </ng-template>
      </p-table>

      <p-table [value]="row.materials" dataKey="id" *ngIf="tableType === 'recipes'">
        <ng-template pTemplate="header">
          <tr>
            <th scope="col" [style]="{width:'30%'}">{{'STOCK' | translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{'AMOUNT' | translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{'COST' | translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{'REQUIRE_AMOUNT' | translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{'STOCK_QUANTITY' | translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{'STOCK_STATUS' | translate}}</th>
            <th scope="col" [style]="{width:'12%'}"></th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-order>
          <tr>
            <td>{{order.variant.name}}</td>
            <td>{{order.wastage | number: '1.2-2'}} {{ order.variant?.stock?.unit.name}}</td>
            <td>{{order.cost | countryCurrency}}</td>
            <td>{{order.totalQuantity | number: '1.2-2'}} {{ order.variant?.stock?.unit.name}}</td>
            <td>{{order.stockQuantity | number: '1.2-2'}} {{ order.variant?.stock?.unit.name}}</td>
            <td>
              <p-tag
                [severity]="order.itemStatus|statusLabel">{{ order.itemStatus|status: order.materialExpectedDate }}</p-tag>
            </td>
            <td>
              <p-button (onClick)="createPurchase(order)" label="{{'PLACE_ORDERS' | translate}}"
                        *ngIf="order.itemStatus === 'NOT_AVAILABLE'"
                        icon="pi pi-shopping-cart"></p-button>
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">{{'NOT_FOUND_RECIPE' | translate}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
    </td>
    </tr>
    </ng-template>
    <!---- Invoices End ----->
    </p-table>

  </div>
  <div class="p-col-12 p-md-3 p-lg-3" *ngIf="supplierCount > 0">
    <p-card>
      <div class="p-grid">
        <div class="p-col-8 p-lg-10"><h3
          [style]="{ fontWeight: '600' }">{{ invoices[activeInvoice] ? invoices[activeInvoice].supplierName : '' }}</h3>
        </div>
        <div class="p-col-4 p-lg-2" style="cursor: pointer">
          <i class="pi pi-shopping-cart" style="font-size: 2rem;margin-top: 10px;" (click)="op.toggle($event)"></i>
          <span class="CountNotification ng-binding">{{supplierCount }}</span>
        </div>
      </div>

      <div class="p-grid">
        <div class="p-col-12">
          <p-divider></p-divider>
          <p-table
            [value]="invoices[activeInvoice].data.items ? invoices[activeInvoice].data.items : selectInvoicesItems"
            styleClass="p-datatable-striped" responsiveLayout="scroll">
            <ng-template pTemplate="body" let-item let-i="rowIndex">
              <tr style="background: #E6F7FA;border-radius: 6px" class="hoverable" (click)="onInvoiceItemClick(i)">
                <td [style]="{padding:'0px',width: '18%'}">
                  <p-avatar label="{{ item.quantity}}" size="large"
                            [style]="{'background-color':'#00B4D2', 'color': '#ffffff',fontSize:'16px'}"></p-avatar>
                </td>
                <td [style]="{padding:'0px 10px',fontSize:'12px'}">
                  {{ item.name}}<br>
                  {{ item.totalOrder }} {{ item.unit}} / <b
                  [style]="{fontSize:'14px'}">{{ item.unitPrice | countryCurrency}}</b></td>
                <td [style]="{padding:'0px'}">
                  <button
                    [style]="{marginLeft:'10px', width:'20px', height:'20px',marginLeft: '10px',position: 'relative',right: '-2px',top: '-3px',scale: '0.7',float:'right'}"
                    pButton pRipple type="button" icon="pi pi-trash"
                    class="p-button-rounded p-button-danger p-button-text" *ngIf="itemClickValues === i"
                    (click)="removeInvoiceItem(i, invoices[activeInvoice].key,item.stock)">
                  </button>
                  <b [style]="{float: 'right'}">{{ item.subtotal | countryCurrency}}</b>
                </td>
              </tr>
              <tr *ngIf="itemClickValues === i">
                <td colspan="3" [style]="{padding: '0px'}">
                  <p-inputNumber [style]="{scale: '0.6'}" [(ngModel)]="item.quantity" [showButtons]="true"
                                 buttonLayout="horizontal"
                                 inputId="horizontal" spinnerMode="horizontal" [step]="1"
                                 (onInput)="getLastQuantity($event, item, i)"
                                 decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success"
                                 incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="decimal">
                  </p-inputNumber>
                </td>
              </tr>
            </ng-template>
          </p-table>

        </div>
        <div class="p-col-12">
          <p-divider></p-divider>
          <div class="p-fluid">
            <div class="p-field">
              <label for="comment">{{'COMMENT' | translate}}</label>
              <input name="comment" id="comment" [ngModelOptions]="{standalone: true}"
                     [(ngModel)]="invoices[activeInvoice].data.description"
                     (change)="setDescription($event)" type="text" pInputText autofocus>
            </div>
            <div class="p-field">
              <label for="deliveryDate">{{'DATE' | translate}}</label>
              <p-calendar id="deliveryDate" [(ngModel)]="invoices[activeInvoice].data.expectedDate" [baseZIndex]="99999"
                          (onSelect)="setDate($event)" dateFormat="yy-mm-dd"
              ></p-calendar>
            </div>
            <div class="p-field">
              <label for="location">{{'LOCATION' | translate}}</label>
              <p-dropdown id="location" [options]="locations" [(ngModel)]="invoices[activeInvoice].data.location"
                          (onChange)="setLocation($event)" placeholder="{{ 'SELECT_LOCATION'| translate }}"
                          optionLabel="name"
                          optionValue="@id" [filter]="true" filterBy="name"></p-dropdown>

            </div>

            <div class="p-field">
              <h3>{{'STATUS' | translate}}<b
                style="float: right;color: #64748B;font-weight: 400;">{{ invoices[activeInvoice].data.status | translate}}
                <i (click)="onDisplayInvoiceStatus()" class="pi pi-info"
                   style="border: 1px solid;border-radius: 50%;padding: 3px;color: #00b3d1"></i>
              </b></h3>
            </div>
          </div>
          <p-divider></p-divider>
          <div class="p-grid">
            <div class="p-col-6" [style]="{width : '100%',float: 'left'}">
              <h3>{{ 'TOTAL'| translate }}: <b
                style="float: right;color: #00b3d1">{{ (invoices[activeInvoice] ? invoices[activeInvoice].data.subtotal : '000.00') | countryCurrency }}</b>
              </h3>
            </div>
            <div class="p-col-6" [style]="{width : '100%',float: 'left'}">
              <div class="p-col-6" [style]="{width : '50%',float: 'left'}">
                <button pButton label="{{ 'CANCEL' | translate}}"
                        [style]="{width:'100%',background: 'none',color: '#444444',borderColor: '#444444'}"
                        icon="pi pi-times" class="p-button-raised p-button-secondary"
                        (click)="removeInvoice(invoices[activeInvoice].key)"></button>
              </div>
              <div class="p-col-6" [style]="{width : '48%',float: 'left'}">
                <button pButton label="{{ 'SAVE' | translate}}" class="p-ml-3 p-mb-3" [style]="{width:'100%'}"
                        [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" [disabled]="this.submitted"
                        (click)="save(invoices[activeInvoice].key)"></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </p-card>
  </div>
  </div>
</p-card>

<p-sidebar [(visible)]="recipeDialog" [style]="{width: '70vw'}" position="right" (onHide)="load()">
  <app-variant-recipe *ngIf="recipeDialog" variantId="{{ recipeId }}"
                      [location]="selectedLocation"></app-variant-recipe>
</p-sidebar>

<app-assistant [page]="'PLANNING'" [style]="{float: 'left',marginLeft: '45px'}"></app-assistant>

<p-sidebar [(visible)]="display" *ngIf="display === true" [style]="{width: '70vw'}" position="right">
  <div class="p-grid">
    <div class="p-col-6">
      <h5>{{ 'WORK_ORDER_CREATE' | translate}}</h5>
    </div>
    <div class="p-col-6">
      <p-button styleClass="p-button-secondary p-mt-2" [icon]="submitted ? 'pi pi-spin pi-spinner': 'pi pi-check'"
                [style]="{float: 'right'}" [disabled]="isSaved" (onClick)="saveWorkOrder()"
                [label]="'SAVE'|translate"></p-button>
    </div>
  </div>
  <div class="p-grid grid-div p-mt-3 p-mb-3" *ngIf="isSaved ">
    <div class="p-col div-sums alert-div">
      <b> {{ 'ALERT' | translate}}!</b> {{'PRODUCT_RECIPE_MATERIAL_STATUS_NOT_YET'| translate }}
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12">
      <table style="width:100%">
        <tr>
          <td [style]="{width:'20%'}">{{'CODE'| translate}}</td>
          <td [style]="{width:'1%'}">:</td>
          <td [style]="{width:'790%'}"><b [style]="{fontSize:'16px'}">{{ workOrder.code}}</b></td>
        </tr>
        <tr>
          <td>{{'PRODUCT' | translate}}</td>
          <td>:</td>
          <td><b [style]="{fontSize:'16px'}">{{ workOrder.stock?.name}}</b></td>
        </tr>
        <tr *ngIf="lotTrackingView">
          <td>{{'LOT_NUMBER' | translate}}</td>
          <td>:</td>
          <td><input [value]="workOrder.barcode" pInputText name="barcode" id="barcode"></td>
        </tr>
        <tr *ngIf="lotTrackingView">
          <td>{{'STT' | translate}}</td>
          <td>:</td>
          <td>
            <p-calendar [(ngModel)]="workOrder.expirationDate" appendTo="body" id="expirationDate" name="expirationDate"
                        dateFormat="dd-mm-yy"></p-calendar>
          </td>
        </tr>
        <tr>
          <td>{{'PLANNED_QUANTITY' | translate}}</td>
          <td>:</td>
          <td>
            <p-inputNumber id="plannedQuantity" name="plannedQuantity" mode="decimal" inputId="withoutgrouping"
                           [useGrouping]="false"
                           [(ngModel)]="workOrder.plannedQuantity"
                           (onBlur)="recalculateMaterials($event)"></p-inputNumber>
            {{ workOrder.stock?.unit.name}}</td>
        </tr>
        <tr>
          <td>{{'DEADLINE' | translate}}</td>
          <td>:</td>
          <td>
            <p-calendar [(ngModel)]="workOrder.deadline" appendTo="body" id="deadline" name="deadlinne"
                        dateFormat="mm-dd-yy"></p-calendar>
          </td>
        </tr>
        <tr>
          <td>{{ 'SOURCE' | translate}}</td>
          <td>:</td>
          <td>
            <p-dropdown appendTo="body" id="from" [options]="locations" [(ngModel)]="workOrder.source"
                        optionLabel="name"
                        optionValue="@id"></p-dropdown>
          </td>
        </tr>
        <tr>
          <td>{{ 'TARGET' | translate}}</td>
          <td>:</td>
          <td>
            <p-dropdown id="to" appendTo="body" [options]="locations" [(ngModel)]="workOrder.target" optionLabel="name"
                        optionValue="@id" (onChange)="onChangeTargetLocation($event)"></p-dropdown>
          </td>
        </tr>
      </table>
    </div>
    <div class="p-grid">
      <div class="p-col-12">
        <p-divider></p-divider>
        <h3><b>{{'MATERIALS_FOR_PRODUCE'| translate}}</b></h3>
        <p-table [value]="workOrder.materials">
          <ng-template pTemplate="header">
            <tr>
              <th scope="col" [style]="{width:'30%'}">{{'STOCK' | translate}}</th>
              <th scope="col" [style]="{width:'30%'}">{{'LOT' | translate}}</th>
              <th scope="col" [style]="{width:'10%'}">{{'AMOUNT' | translate}}</th>
              <th scope="col" [style]="{width:'10%'}">{{'COST' | translate}}</th>
              <th scope="col" [style]="{width:'10%'}">{{'REQUIRE_AMOUNT' | translate}}</th>
              <th scope="col" [style]="{width:'10%'}">{{'STOCK_QUANTITY' | translate}}</th>
              <th scope="col" [style]="{width:'12%'}">{{'STOCK_STATUS' | translate}}</th>

            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-order>
            <tr>
              <td>{{order.variant.name}}</td>
              <td>
                <p-dropdown appendTo="body" id="from" [options]="order.batchesData"
                            *ngIf="order.variant?.stock?.isTrackByLot"
                            placeholder="{{ 'SELECT' | translate}}"
                            optionLabel="barcode" [(ngModel)]="order.batches" (onChange)="createBatch($event, order)"
                >
                  <ng-template let-item pTemplate="item" *ngIf="order.variant?.stock?.isTrackByLot">
                    {{item.barcode}} / {{item.amount}} {{ order.variant?.stock?.unit.name}}
                  </ng-template>
                </p-dropdown>
              </td>
              <td>{{order.wastage | number: '1.2-2'}} {{ order.variant?.stock?.unit.name}}</td>
              <td>{{order.cost | countryCurrency}}</td>
              <td>{{order.totalQuantity | number: '1.2-2'}} {{ order.variant?.stock?.unit.name}}</td>
              <td>{{order.stockQuantity | number: '1.2-2'}} {{ order.variant?.stock?.unit.name}}</td>
              <td>
                <p-tag
                  [severity]="order.itemStatus|statusLabel">{{ order.itemStatus|status: order.materialExpectedDate }}</p-tag>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="7">{{'NOT_FOUND_RECIPE' | translate}}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

  </div>

</p-sidebar>
<p-confirmDialog #cd icon="pi pi-exclamation-triangle">
  <ng-template pTemplate="header">
    <h3>{{'WARNING' | translate}}</h3>
  </ng-template>
  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-times" label="{{'NO'| translate}}" (click)="cd.reject()"></button>
    <button type="button" pButton icon="pi pi-check" label="{{'YES' | translate}}" (click)="cd.accept()"></button>
  </ng-template>
</p-confirmDialog>

<p-dialog header="Siparişiniz Oluşturuldu" [(visible)]="invoiceInfo" [style]="{width: '50vw'}">
  <a href="{{'/orders/order-details/' + invoiceRouteId}}">{{ invoiceInfoComment }}</a>
</p-dialog>


<p-sidebar [(visible)]="batchesDisplay" *ngIf="batchesDisplay === true" [style]="{width: '70vw'}" position="right">
  <div class="p-grid">
    <div class="p-col-6">
      <h5>{{ 'STOCK_BATCHES' | translate}}</h5>
    </div>
    <div class="p-col-6">

    </div>
  </div>
  <div class="p-grid grid-div p-mt-3 p-mb-3" *ngIf="isSaved ">
    <div class="p-col div-sums alert-div">
      <b> {{ 'ALERT' | translate}}!</b> {{'PRODUCT_RECIPE_MATERIAL_STATUS_NOT_YET'| translate }}
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12">
      <form (submit)="getStockBatches(null )">
        <p-table [value]="batches">
          <ng-template pTemplate="header">
            <tr>
              <th scope="col" [style]="{width:'10%'}">{{'CODE' | translate}}</th>
              <th scope="col" [style]="{width:'30%'}">{{'NAME' | translate}}</th>
              <th scope="col" [style]="{width:'10%'}">{{'LOCATION' | translate}}</th>
              <th scope="col" [style]="{width:'10%'}">{{'QUANTITY' | translate}}</th>
              <th scope="col" [style]="{width:'10%'}">{{'EXPIRATION_DATE' | translate}}</th>
              <th scope="col" [style]="{width:'20%'}">{{'STATUS' | translate}}</th>
              <th scope="col" [style]="{width:'12%'}">{{'ACTIONS' | translate}}</th>

            </tr>
            <tr>
              <td></td>
              <td>
                <input [style]="{width: '100%',height:'45px',  border:'none', borderBottom: '2px solid #47bfd4'}"
                       type="text" pInputText [(ngModel)]="batchParameters['variant.name']" name="documentNumber"
                       id="documentNumber"
                       placeholder="{{ 'NAME' | translate}}"/>
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item>
            <tr>
              <td>{{item.variant.code}}</td>
              <td>{{item.variant.name}}</td>
              <td></td>
              <td
                [style]="{ textAlign: 'right'}">{{item.amount | number: '1.2-2' }} {{ item.variant.stock?.unit.name}}</td>
              <td>{{item.expirationDate | date: 'y-MM-dd'}}</td>
              <td>
                <p-tag *ngIf="calculateDay(item.expirationDate) > 3" severity="success"
                       value="{{'TO_LAST_DAY' | translate }} {{calculateDay(item.expirationDate)}} {{'DAY' | translate }}"></p-tag>
                <p-tag *ngIf="calculateDay(item.expirationDate) < 3" severity="danger"
                       value="{{'LAST' | translate }} {{calculateDay(item.expirationDate)}} {{'DAY' | translate }}"></p-tag>

              </td>
              <td>
                <p-button styleClass="p-button-secondary p-mt-2"
                          [style]="{float: 'right'}" (onClick)="loadRecipes(item.variant.id)"
                          [label]="'RECIPES'|translate"></p-button>
              </td>

            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="7">{{'NOT_FOUND_RECIPE' | translate}}</td>
            </tr>
          </ng-template>
        </p-table>
        <button type="submit" pButton label="{{'stock.search' | translate}}" style="display: none"></button>
      </form>
    </div>
  </div>

</p-sidebar>


<p-dialog header="{{'IN_RECIPES' | translate}}" [(visible)]="loadRecipeDisplay" *ngIf="loadRecipeDisplay"
          [style]="{width: '60vw'}">
  <p-table [value]="loadRecipesData" responsiveLayout="scroll">
    <ng-template pTemplate="header">
      <tr>
        <th scope="col" [style]="{width:'3%'}"></th>
        <th scope="col">{{'NAME'| translate}}</th>
        <th scope="col" [style]="{width:'15%'}">{{'COST'| translate}}</th>
        <th scope="col" [style]="{width:'15%'}">{{'QUANTITY'| translate}}</th>
        <th scope="col" [style]="{width:'15%'}"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row let-i="rowIndex">
      <tr>
        <td>{{i + 1}}</td>
        <td>{{ row.name }}/{{row.vrName}}</td>
        <td [style]="{textAlign:'right'}">{{ row.cost | number : '1.2-4' }}{{row.syhshah}} </td>
        <td [style]="{textAlign:'right'}">{{ row.amountWithWastage | number : '1.1-3'}} {{row.unit}} </td>
        <td [style]="{textAlign:'center'}">
          <button pButton pRipple [style]="{marginLeft: '5px', float:'right'}" type="button"
                  (click)="createNewWorkOrder()"
                  label="{{ 'NEW_WORK_ORDER' | translate}} ">
          </button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>

<p-sidebar *ngIf="newWorkOrder" [(visible)]="newWorkOrder" [style]="{width: '70vw'}" position="right" (close)="close()"
           (onHide)="close()">
  <app-work-order-create [id]="workOrderId"></app-work-order-create>
</p-sidebar>

<p-dialog header="{{'ALERT' | translate }}" [(visible)]="manufacturingLocationDisplay" [style]="{width: '50vw'}">
  <div class="p-grid">
    <div class="p-col-12">
      Üretim Deposu Seçiniz.
    </div>
  </div>
  <div class="p-grid" style="height: 200px">
    <div class="p-col-12">
      <p-dropdown [style]="{marginTop:'10px'}" placeholder="{{'SELECT_LOCATION' | translate}}" [options]="locations"
                  [(ngModel)]="company.defaultManufacturingLocation" optionLabel="name" appendTo="body"
                  id="defaultManufacturingLocation" name="defaultManufacturingLocation"
                  optionValue="@id"></p-dropdown>
      <small class="p-error">{{ errors['defaultManufacturingLocation'] }}</small>
    </div>
    <div class="p-col-2 p-md-2 p-lg-2">
      <button *ngIf="!loading" pButton label="{{'SAVE' | translate}}"
              type="submit" class="p-ml-3 p-mb-3" [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
              [disabled]="this.submitted" (click)="saveCompany()">
      </button>
    </div>
  </div>

</p-dialog>
