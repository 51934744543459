<app-loading-indicator [loading]="loading"></app-loading-indicator>
<p-card *ngIf="!loading">
  <div class="p-grid">
    <div class="p-col-2 p-md-2 p-sm-2" [style]="{padding: '0 0 0 10px !important'} ">
      <h3 [style]="{ fontWeight: '600'}">{{ 'MENU_ANALYSIS' | translate}}</h3>
    </div>
    <div class="p-col-9 p-md-9 p-sm-9">
      <div class="p-grid">
        <div class="p-col-12 p-md-5 p-lg-3">
          <p-dropdown
            [style]="{width: '100% !important',border:'none',borderBottom: '2px solid #47bfd4'}"
            [options]="location" appendTo="body" (onChange)="onSelectLocation($event)"
            [ngModel]="parameters.location"
            optionLabel="name" optionValue="id" placeholder="{{ 'SELECT_LOCATION'| translate}}"
            [filter]="true" filterBy="name">
          </p-dropdown>
        </div>
        <div class="p-col-12 p-md-5 p-lg-3">
          <p-calendar selectionMode="range" dateFormat="yy-mm-dd" date [showTime]="false"
                      [style]="{width: '100% !important',border:'none',borderBottom: '2px solid #47bfd4'}"
                      appendTo="body"
                      [(ngModel)]="dateRange" (ngModelChange)="onSelectDate($event)"
                      placeholder="{{'SELECT_DATE'| translate}}">
          </p-calendar>
        </div>
        <div class="p-col-12 p-md-5 p-lg-3">
          <p-autoComplete [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                          appendTo="body" autofocus [suggestions]="variants" name="stock"
                          placeholder="{{ 'STOCK_SEARCH'| translate }}"
                          (completeMethod)="searchStock($event)" (onSelect)="onVariantSelect($event);"
                          field="name">
          </p-autoComplete>
        </div>
        <div class="p-col-12 p-md-1 p-lg-2">
          <p-button [style]="{height: '100%'}" type="submit" label="{{'SEARCH' | translate}}"
                    (click)="load()"></p-button>
        </div>
      </div>
    </div>
    <div class="p-col-1 p-md-1 p-sm-1">
      <app-page-info [pageName]="'daily-income'"></app-page-info>
    </div>
  </div>
  <div class="p-grid" [style]="{marginBottom: '15px'}">
    <div class="p-col-12">
      <div class="p-col div-sums" style="padding: 0px !important">
        <div>
          <div class="inside-div-no-background"
               style="overflow: auto;max-height: 600px; padding: 3px !important;position: relative;">
            <div style="position: absolute;right: -30px;top:0;z-index: 1;margin-right: 45px;margin-top: 15px">
              <b>Star</b></div>
            <div style="position: absolute;right: -30px;bottom:0;z-index: 1;margin-right: 45px;margin-bottom: 50px"><b>Puzzle</b>
            </div>
            <div style="position: absolute;left: 0;top:0;z-index: 1;margin-left: 45px;margin-top: 15px">
              <b>PlowHourse</b></div>
            <div style="position: absolute;left: 0;bottom:0;z-index: 1;margin-left: 45px;margin-bottom: 50px"><b>Dog</b>
            </div>
            <div
              style="position: absolute;left: 3px;top:403px;z-index: 1;width: 100px; color: white; background: white">.
            </div>
            <div
              style="position: absolute;right: 3px;top:403px;z-index: 1;width: 100px; color: white; background: white">.
            </div>
            <canvasjs-chart [options]="chartOptions" [styles]="{width: '100%', height:'420px'}"></canvasjs-chart>
          </div>
        </div>
      </div>
    </div>
    <div class="p-col-12 p-md-12 p-lg-6 p-xl-4 div-sums">
      <div>
        <div class="inside-div-no-background" [style]="{height: '215px'}" *ngIf="categories">
          <table class="table-section" style="border-collapse: collapse;">
            <tr>
              <td [style]="{fontSize:'14px',width:'40%'}"><b
                class="fw-500">{{'' | translate}}</b></td>
              <td [style]="{textAlign:'right',fontSize:'17px', padding: '12px 0',width:'20%'}"><b
                class="fw-500">Gelir{{'' | translate}}</b></td>
              <td [style]="{textAlign:'right',fontSize:'17px', padding: '12px 0',width:'20%'}"><b
                class="fw-500">Gider{{'' | translate}}</b></td>
              <td [style]="{textAlign:'right',fontSize:'17px', padding: '12px 0',width:'20%'}"><b
                class="fw-500">Cost%{{'' | translate}}</b></td>
            </tr>
            <tr [style]="{borderBottom: '1px solid #ffffff'}">
              <td [style]="{fontSize:'12px', padding: '12px 0', lineHeight: '11px'}"><b
                class="fw-500">TOPLAM GELiR</b></td>
              <td [style]="{textAlign:'right',fontSize:'12px', padding: '12px 0', color: 'green'}"><b
                class="fw-500"></b>{{sale?.netTotal | countryCurrency}}</td>
              <td [style]="{textAlign:'right',fontSize:'12px', padding: '12px 0', color: 'blue'}"><b
                class="fw-500"></b>{{sale?.cost | countryCurrency}}</td>
              <td [style]="{textAlign:'right',fontSize:'12px', padding: '12px 0', color: 'red'}"><b
                class="fw-500"></b>%{{(sale?.cost / sale?.netTotal) * 100 | number : "1.2-2"}}</td>
            </tr>
          </table>
          <div style="height: 115px;overflow-y: auto;">
          <table class="table-section" style="border-collapse: collapse;">
            <tbody *ngFor=" let category of categories.categories">
            <tr [style]="{borderBottom: '1px solid #ffffff'}">
              <td [style]="{fontSize:'12px', padding: '12px 0', lineHeight: '11px',width:'40%'}"><b
                class="fw-500">{{ category.name}}</b></td>
              <td [style]="{textAlign:'right',fontSize:'12px', padding: '12px 0', color: 'green',width:'20%'}"><b
                class="fw-500"></b>{{category.sumSales.total | countryCurrency}}</td>
              <td [style]="{textAlign:'right',fontSize:'12px', padding: '12px 0', color: 'blue',width:'20%'}"><b
                class="fw-500"></b>{{category.sumSales.totalCOST | countryCurrency}}</td>
              <td [style]="{textAlign:'right',fontSize:'12px', padding: '12px 0', color: 'red',width:'20%'}"><b
                class="fw-500"></b>%{{(category.sumSales.totalCOST / category.sumSales.total) * 100 | number : "1.2-2"}}
              </td>
            </tr>
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </div>
    <div class="p-col-12 p-md-12 p-lg-6 p-xl-4 div-sums ">
      <div class="inside-div-no-background" [style]="{height: '215px'}">
        <p-chart type="bar" [data]="basicData" [options]="basicOptions" [style]="{height: '200px !important'}"></p-chart>
      </div>
    </div>
    <div class="p-col-12 p-md-12 p-lg-6 p-xl-4 div-sums">
      <div>
        <div class="inside-div-no-background" [style]="{height: '215px'}">
          <table class="table-section" style="border-collapse: collapse;">
            <tr>
              <td [style]="{fontSize:'14px',padding: '8px 0', lineHeight: '14px'}"><b
                class="fw-500">Toplam Çek Sayısı{{'' | translate}}</b></td>
              <td [style]="{textAlign:'right',fontSize:'14px', padding: '8px 0'}"><b
                class="fw-500">{{sale?.saleCount}}</b></td>
            </tr>
            <tr [style]="{borderBottom: '1px solid #ffffff'}">
              <td [style]="{fontSize:'14px', padding: '8px 0', lineHeight: '14px'}">Ort. Çek Geliri{{'' | translate}}</td>
              <td [style]="{textAlign:'right',fontSize:'14px', padding: '8px 0'}"><b
                class="fw-500"></b>{{sale?.averageSale | countryCurrency}}</td>
            </tr>
            <tr [style]="{borderBottom: '1px solid #ffffff'}">
              <td [style]="{fontSize:'14px', padding: '8px 0', lineHeight: '14px'}">Ort. Çek
                Maliyet{{'' | translate}}</td>
              <td [style]="{textAlign:'right',fontSize:'14px', padding: '8px 0'}"><b
                class="fw-500"></b>{{(sale?.averageCost) | countryCurrency}}</td>
            </tr>
            <tr>
              <td [style]="{fontSize:'14px', padding: '8px 0'}"><b
                class="fw-500">Toplam Müşteri Sayısı{{'' | translate}}</b></td>
              <td [style]="{textAlign:'right',fontSize:'14px', padding: '8px 0'}"><b
                class="fw-500">{{sale?.cuver}}</b></td>
            </tr>
            <tr>
              <td [style]="{fontSize:'14px', padding: '8px 0'}"><b
                class="fw-500"></b>Kişi Başı Gelir{{'' | translate}}</td>
              <td [style]="{textAlign:'right',fontSize:'14px', padding: '8px 0'}"><b
                class="fw-500"></b>{{sale?.averageCuver | countryCurrency}}</td>
            </tr>
            <tr>
              <td [style]="{fontSize:'14px', padding: '8px 0'}"><b
                class="fw-500"></b>Kişi Başı Maliyet{{'' | translate}}</td>
              <td [style]="{textAlign:'right',fontSize:'14px', padding: '8px 0'}"><b
                class="fw-500"></b>{{(sale?.averageCuverCost) | countryCurrency}}</td>
            </tr>
          </table>

        </div>
      </div>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12">
      <p-table [value]="menuAnalys.results" styleClass="p-datatable-sm">
        <ng-template pTemplate="header">
          <tr>
            <th [style]="{width: '5%'}" > {{'NUMBER_ORDER' | translate}}</th>
            <th [style]="{width: '15%'}" scope="col">{{'CATEGORY' | translate}}</th>
            <th [style]="{width: '30%'}" scope="col" pSortableColumn="stockName">
              <p-sortIcon field="stockName"></p-sortIcon>
              {{'NAME' | translate}}
            </th>
            <th [style]="{width: '10%'}" scope="col" pSortableColumn="unit">
              <p-sortIcon field="unit"></p-sortIcon>
              {{'UNIT' | translate}}
            </th>
            <th [style]="{width: '10%'}" scope="col" pSortableColumn="quantity">
              <p-sortIcon field="quantity"></p-sortIcon>
              {{'SALE_QUANTITY' | translate}}
            </th>
            <th [style]="{width: '13%'}" scope="col" pSortableColumn="MenuMix">
              <p-sortIcon field="MenuMix"></p-sortIcon>
              Menu Mix
            </th>
            <th [style]="{width: '10%'}" scope="col" pSortableColumn="total_cost">
              <p-sortIcon field="total_cost"></p-sortIcon>
              {{'COST' | translate}}
            </th>
            <th [style]="{width: '15%'}" scope="col" pSortableColumn="sale_price">
              <p-sortIcon field="sale_price"></p-sortIcon>
              {{'SALE_PRICE' | translate}}
            </th>
            <th [style]="{width: '15%'}" scope="col" pSortableColumn="costRate">
              <p-sortIcon field="costRate"></p-sortIcon>
              %{{'COST' | translate}}
            </th>
            <th [style]="{width: '15%'}" scope="col" pSortableColumn="profit">
              <p-sortIcon field="profit"></p-sortIcon>
              Item CM
            </th>
            <th [style]="{width: '15%'}">Menu Cost</th>
            <th [style]="{width: '18%'}">Menu Revenue</th>
            <th [style]="{width: '18%'}">Menu CM</th>
            <th [style]="{width: '8%'}">CM Cat.</th>
            <th [style]="{width: '8%'}">MM Cat.</th>
            <th [style]="{width: '14%'}">Menu Item Class</th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-menu let-i="rowIndex">
          <tr [style]="{cursor: 'pointed'}">
            <td>{{i+1}}</td>
            <td>{{ menu.mainCategory}}<br>{{ menu.category}}</td>
            <td>{{menu.stockName}}</td>
            <td>{{menu.unit}}</td>
            <td [style]="{textAlign: 'right'}">{{menu.quantity | number: '1.1-2'}}</td>
            <td [style]="{textAlign: 'right'}">{{ (menu.quantity / menuAnalys.totalQuantity) * 100 | number: '1.2-2' }}
              %
            </td>
            <td [style]="{textAlign: 'right'}">{{menu.total_cost | countryCurrency}}</td>
            <td [style]="{textAlign: 'right'}">{{menu.sale_price | countryCurrency}}</td>
            <td [style]="{textAlign: 'right', color: 'red'}">%{{menu.costRate | number : "1.2-2"}}</td>
            <td [style]="{textAlign: 'right'}">{{(menu.sale_price - menu.total_cost) | countryCurrency}}</td>
            <td [style]="{textAlign: 'right'}">{{(menu.quantity * menu.total_cost) | countryCurrency}}</td>
            <td [style]="{textAlign: 'right'}">{{(menu.quantity * menu.sale_price) | countryCurrency}}</td>
            <td
              [style]="{textAlign: 'right'}">{{(menu.quantity * (menu.sale_price - menu.total_cost)) | countryCurrency}}</td>
            <td>{{ (menu.sale_price - menu.total_cost) > this.itemCmAverage ? 'HIGH': 'LOW'}}</td>
            <td>{{ menu.costRate > percentageMenuMix ? 'HIGH': 'LOW'}}</td>

            <td>{{ menuItemClass(((menu.sale_price - menu.total_cost) > this.itemCmAverage ? 'HIGH': 'LOW') , (menu.costRate > percentageMenuMix ? 'HIGH': 'LOW')) }}</td>
          </tr>
        </ng-template>

        <ng-template pTemplate="footer">
          <tr [style]="{cursor: 'pointed'}">
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td [style]="{fontSize:'12px', textAlign:'right'}">{{menuAnalys.totalQuantity | number: '1.2-2'}}</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td [style]="{fontSize:'12px', textAlign:'right'}">{{ sumMenuCost | countryCurrency}}</td>
            <td [style]="{fontSize:'12px', textAlign:'right'}">{{ sumMenuRevenue | countryCurrency}}</td>
            <td [style]="{fontSize:'12px', textAlign:'right'}">{{ sumMenuCM | countryCurrency}}</td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <div class="p-grid p-mt-6">
    <div class="p-col-8">
      <table>
        <tr>
          <td [style]="{width:'200px', background:'#47bfd4 !important',padding: '10px',color: '#fbfbfbfb'}">Menu Item
          </td>
          <td [style]="{width:'200px', background:'#f1f1f1',padding: '10px'}">{{menuAnalys.results.length}}</td>
        </tr>
        <tr>
          <td [style]="{width:'200px', background:'#47bfd4 !important',padding: '10px',color: '#fbfbfbfb'}">Menu Food
            Cost
          </td>
          <td [style]="{width:'200px', background:'#f1f1f1',padding: '10px'}">{{menuFootCost | number: '1.2-2'}}%</td>
        </tr>
        <tr>
          <td [style]="{width:'200px', background:'#47bfd4 !important',padding: '10px',color: '#fbfbfbfb'}">Avarage CM
          </td>
          <td
            [style]="{width:'200px', background:'#f1f1f1',padding: '10px'}">{{itemCmAverage | countryCurrency}}</td>
        </tr>
        <tr>
          <td [style]="{width:'200px', background:'#47bfd4 !important',padding: '10px',color: '#fbfbfbfb'}">Percentage
            Menu Mix
          </td>
          <td [style]="{width:'200px', background:'#f1f1f1',padding: '10px'}">{{percentageMenuMix | number: '1.2-5'}}%
          </td>
        </tr>
      </table>
    </div>
    <div class="p-col-4">
      <table>
        <tr>
          <td [style]="{width:'200px', background:'#47bfd4 !important',padding: '10px',color: '#fbfbfbfb'}">CM/MM
            Category
          </td>
          <td [style]="{width:'200px', background:'#47bfd4 !important',padding: '10px',color: '#fbfbfbfb'}">Menu Item
            Class
          </td>
        </tr>
        <tr>
          <td [style]="{width:'200px', background:'#f1f1f1 !important',padding: '2px'}">HIGH / HIGH</td>
          <td [style]="{width:'200px', background:'#f1f1f1',padding: '10px'}">STAR</td>
        </tr>
        <tr>
          <td [style]="{width:'200px', background:'#f1f1f1 !important',padding: '2px'}">HIGH / LOW</td>
          <td [style]="{width:'200px', background:'#f1f1f1',padding: '10px'}">PUZZLE</td>
        </tr>
        <tr>
          <td [style]="{width:'200px', background:'#f1f1f1 !important',padding: '2px'}">LOW / HIGH</td>
          <td [style]="{width:'200px', background:'#f1f1f1',padding: '10px'}">PLOWHORSE</td>
        </tr>
        <tr>
          <td [style]="{width:'200px', background:'#f1f1f1 !important',padding: '2px'}">LOW / LOW</td>
          <td [style]="{width:'200px', background:'#f1f1f1',padding: '10px'}">DOG</td>
        </tr>
      </table>
    </div>
  </div>
</p-card>
