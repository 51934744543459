<app-loading-indicator [loading]="loading"></app-loading-indicator>
<p-card>
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'STOCK_BATCHES' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'STOCK_BATCHES'"></app-page-info>
      <p-button icon="pi pi-arrow-down" [style]="{marginLeft: '5px', float:'right'}"></p-button>
      <button pButton [style]="{marginLeft: '5px', float:'right'}" label="{{'INFO' | translate}}" class="p-mb-3"
              [icon]="'pi pi-info-circle'" (click)="infoTextShow()"></button>
    </div>
  </div>
  <div class="p-grid" style="padding: 10px">
    <div class="p-col-12 information" *ngIf="infoTextShowDisplay">
      <app-planning-page-info></app-planning-page-info>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12"></div>
    <form (submit)="getStockBatches(null )">
      <p-table [value]="batches" [lazy]="true" (onLazyLoad)="getStockBatches($event)">
        <ng-template pTemplate="header">
          <tr>
            <th scope="col" [style]="{width:'6%'}">{{'CODE' | translate}}</th>
            <th scope="col" [style]="{width:'30%'}">{{'NAME' | translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{'LOCATION' | translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{'QUANTITY' | translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{'EXPIRATION_DATE' | translate}}</th>
            <th scope="col" [style]="{width:'13%'}">{{'STATUS' | translate}}</th>
            <th scope="col" [style]="{width:'7%'}">{{'ACTIONS' | translate}}</th>

          </tr>
          <tr>
            <td></td>
            <td>
              <input [style]="{width: '100%',height:'45px',  border:'none', borderBottom: '2px solid #47bfd4'}"
                     type="text" pInputText [(ngModel)]="batchParameters['variant.name']" name="documentNumber"
                     id="documentNumber"
                     placeholder="{{ 'NAME' | translate}}"/>
            </td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td>{{item.variant.code}}</td>
            <td>{{item.variant.name}}</td>
            <td></td>
            <td
              [style]="{ textAlign: 'right'}">{{item.amount | number: '1.2-2' }} {{ item.variant.stock?.unit.name}}</td>
            <td style="text-align: right;">{{item.expirationDate | date: 'y-MM-dd'}}</td>
            <td>
              <p-tag *ngIf="calculateDay(item.expirationDate) > 3" severity="success"
                     value="{{'TO_LAST_DAY' | translate }} {{calculateDay(item.expirationDate)}} {{'DAY' | translate }}"></p-tag>
              <p-tag *ngIf="calculateDay(item.expirationDate) < 3" severity="danger"
                     value="{{'LAST' | translate }} {{calculateDay(item.expirationDate)}} {{'DAY' | translate }}"></p-tag>

            </td>
            <td>
              <p-button styleClass="p-button-secondary p-mt-2"
                        [style]="{float: 'right'}" (onClick)="loadRecipes(item.variant.id)"
                        [label]="'RECIPES'|translate"></p-button>
            </td>

          </tr>
        </ng-template>
        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="7">{{'NOT_FOUND_RECIPE' | translate}}</td>
          </tr>
        </ng-template>
      </p-table>
      <button type="submit" pButton label="{{'stock.search' | translate}}" style="display: none"></button>
    </form>
  </div>
</p-card>


<app-assistant [page]="'STOCK_BATCHES'" [style]="{float: 'left',marginLeft: '45px'}"></app-assistant>

<p-sidebar [(visible)]="display" *ngIf="display === true" [style]="{width: '70vw'}" position="right">
  <div class="p-grid">
    <div class="p-col-6">
      <h5>{{ 'WORK_ORDER_CREATE' | translate}}</h5>
    </div>
    <div class="p-col-6">
      <p-button styleClass="p-button-secondary p-mt-2" [icon]="submitted ? 'pi pi-spin pi-spinner': 'pi pi-check'"
                [style]="{float: 'right'}" [disabled]="isSaved" (onClick)="saveWorkOrder()"
                [label]="'SAVE'|translate"></p-button>
    </div>
  </div>
  <div class="p-grid grid-div p-mt-3 p-mb-3" *ngIf="isSaved ">
    <div class="p-col div-sums alert-div">
      <b> {{ 'ALERT' | translate}}!</b> {{'PRODUCT_RECIPE_MATERIAL_STATUS_NOT_YET'| translate }}
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12">
      <table style="width:100%">
        <tr>
          <td [style]="{width:'20%'}">{{'CODE'| translate}}</td>
          <td [style]="{width:'1%'}">:</td>
          <td [style]="{width:'790%'}"><b [style]="{fontSize:'16px'}">{{ workOrder.code}}</b></td>
        </tr>
        <tr>
          <td>{{'PRODUCT' | translate}}</td>
          <td>:</td>
          <td><b [style]="{fontSize:'16px'}">{{ workOrder.stock?.name}}</b></td>
        </tr>
        <tr *ngIf="lotTrackingView">
          <td>{{'LOT_NUMBER' | translate}}</td>
          <td>:</td>
          <td><input [value]="workOrder.barcode" pInputText name="barcode" id="barcode"></td>
        </tr>
        <tr *ngIf="lotTrackingView">
          <td>{{'STT' | translate}}</td>
          <td>:</td>
          <td>
            <p-calendar [(ngModel)]="workOrder.expirationDate" appendTo="body" id="expirationDate" name="expirationDate"
                        dateFormat="dd-mm-yy"></p-calendar>
          </td>
        </tr>
        <tr>
          <td>{{'PLANNED_QUANTITY' | translate}}</td>
          <td>:</td>
          <td>
            <p-inputNumber id="plannedQuantity" name="plannedQuantity" mode="decimal" inputId="withoutgrouping"
                           [useGrouping]="false"
                           [(ngModel)]="workOrder.plannedQuantity"
                           (onBlur)="recalculateMaterials($event)"></p-inputNumber>
            {{ workOrder.stock?.unit.name}}</td>
        </tr>
        <tr>
          <td>{{'DEADLINE' | translate}}</td>
          <td>:</td>
          <td>
            <p-calendar [(ngModel)]="workOrder.deadline" appendTo="body" id="deadline" name="deadlinne"
                        dateFormat="mm-dd-yy"></p-calendar>
          </td>
        </tr>
        <tr>
          <td>{{ 'SOURCE' | translate}}</td>
          <td>:</td>
          <td>
            <p-dropdown appendTo="body" id="from" [options]="locations" [(ngModel)]="workOrder.source"
                        optionLabel="name"
                        optionValue="@id"></p-dropdown>
          </td>
        </tr>
        <tr>
          <td>{{ 'TARGET' | translate}}</td>
          <td>:</td>
          <td>
            <p-dropdown id="to" appendTo="body" [options]="locations" [(ngModel)]="workOrder.target" optionLabel="name"
                        optionValue="@id" (onChange)="onChangeTargetLocation($event)"></p-dropdown>
          </td>
        </tr>
      </table>
    </div>
    <div class="p-grid">
      <div class="p-col-12">
        <p-divider></p-divider>
        <h3><b>{{'MATERIALS_FOR_PRODUCE'| translate}}</b></h3>
        <p-table [value]="workOrder.materials">
          <ng-template pTemplate="header">
            <tr>
              <th scope="col" [style]="{width:'30%'}">{{'STOCK' | translate}}</th>
              <th scope="col" [style]="{width:'30%'}">{{'LOT' | translate}}</th>
              <th scope="col" [style]="{width:'10%'}">{{'AMOUNT' | translate}}</th>
              <th scope="col" [style]="{width:'10%'}">{{'COST' | translate}}</th>
              <th scope="col" [style]="{width:'10%'}">{{'REQUIRE_AMOUNT' | translate}}</th>
              <th scope="col" [style]="{width:'10%'}">{{'STOCK_QUANTITY' | translate}}</th>
              <th scope="col" [style]="{width:'12%'}">{{'STOCK_STATUS' | translate}}</th>

            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-order>
            <tr>
              <td>{{order.variant.name}}</td>
              <td>
                <p-dropdown appendTo="body" id="from" [options]="order.batchesData"
                            *ngIf="order.variant?.stock?.isTrackByLot"
                            placeholder="{{ 'SELECT' | translate}}"
                            optionLabel="barcode" [(ngModel)]="order.batches" (onChange)="createBatch($event, order)"
                >
                  <ng-template let-item pTemplate="item" *ngIf="order.variant?.stock?.isTrackByLot">
                    {{item.barcode}} / {{item.amount}} {{ order.variant?.stock?.unit.name}}
                  </ng-template>
                </p-dropdown>
              </td>
              <td>{{order.wastage | number: '1.2-2'}} {{ order.variant?.stock?.unit.name}}</td>
              <td>{{order.cost | countryCurrency}}</td>
              <td>{{order.totalQuantity | number: '1.2-2'}} {{ order.variant?.stock?.unit.name}}</td>
              <td>{{order.stockQuantity | number: '1.2-2'}} {{ order.variant?.stock?.unit.name}}</td>
              <td>
                <p-tag
                  [severity]="order.itemStatus|statusLabel">{{ order.itemStatus|status: order.materialExpectedDate }}</p-tag>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="7">{{'NOT_FOUND_RECIPE' | translate}}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

  </div>

</p-sidebar>
<p-dialog header="{{'IN_RECIPES' | translate}}" [(visible)]="loadRecipeDisplay" *ngIf="loadRecipeDisplay"
          [style]="{width: '60vw'}">
  <p-table [value]="loadRecipesData" responsiveLayout="scroll">
    <ng-template pTemplate="header">
      <tr>
        <th scope="col" [style]="{width:'3%'}"></th>
        <th scope="col">{{'NAME'| translate}}</th>
        <th scope="col" [style]="{width:'15%'}">{{'COST'| translate}}</th>
        <th scope="col" [style]="{width:'15%'}">{{'QUANTITY'| translate}}</th>
        <th scope="col" [style]="{width:'15%'}"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-row let-i="rowIndex">
      <tr>
        <td>{{i + 1}}</td>
        <td>{{ row.name }}/{{row.vrName}}</td>
        <td [style]="{textAlign:'right'}">{{ row.cost | number : '1.2-4' }}{{row.syhshah}} </td>
        <td [style]="{textAlign:'right'}">{{ row.amountWithWastage | number : '1.1-3'}} {{row.unit}} </td>
        <td [style]="{textAlign:'center'}">
          <button pButton pRipple [style]="{marginLeft: '5px', float:'right'}" type="button"
                  (click)="createNewWorkOrder()"
                  label="{{ 'NEW_WORK_ORDER' | translate}} ">
          </button>
        </td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>

