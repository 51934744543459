import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './component/security/login/login.component';
import {HomeComponent} from './component/home/home.component';
import {AuthGuard} from './auth/auth.guard';
import {SettingsComponent} from './component/settings/settings.component';
import {GeneralComponent} from './component/settings/general/general.component';
import {OperationComponent} from './component/settings/operation/operation.component';
import {ResourceComponent} from './component/settings/resource/resource.component';
import {LocationComponent} from './component/settings/location/location.component';
import {UnitComponent} from './component/settings/unit/unit.component';
import {TaxComponent} from './component/settings/tax/tax.component';
import {ContactComponent} from './component/contact/contact.component';
import {StockComponent} from './component/stock/stock.component';
import {StockCreateComponent} from './component/stock/stock-create/stock-create.component';
import {StockUpdateComponent} from './component/stock/stock-update/stock-update.component';
import {InvoiceCreateComponent} from './component/invoice/invoice-create/invoice-create.component';
import {ProductRecipeComponent} from './component/product/product-recipe/product-recipe.component';
import {ProductOperationComponent} from './component/product/product-operation/product-operation.component';
import {InvoiceListComponent} from './component/invoice/invoice-list/invoice-list.component';
import {WorkOrderCreateComponent} from './component/work-order/work-order-create/work-order-create.component';
import {WorkOrderListComponent} from './component/work-order/work-order-list/work-order-list.component';
import {DashboardComponent} from './component/dashboard/dashboard.component';
import {UsersComponent} from './component/settings/users/users.component';
import {ForgotPasswordComponent} from './component/security/forgot-password/forgot-password.component';
import {ResetPasswordComponent} from './component/security/reset-password/reset-password.component';
import {IntegrationsComponent} from './component/settings/integrations/integrations.component';
import {PickListComponent} from './component/work-order/pick-list/pick-list.component';
import {InvoiceType} from './model/invoice.model';
import {CreateAccountComponent} from './component/create-account/create-account.component';
import {ProductListComponent} from './component/product/product-list/product-list.component';
import {DataImportComponent} from './component/settings/data-import/data-import.component';
import {StockAdjustmentComponent} from './component/stock/stock-adjustment/stock-adjustment.component';
import {StockAdjustmentListComponent} from './component/stock/stock-adjustment-list/stock-adjustment-list.component';
import {StockTransfersComponent} from './component/stock/stock-transfers/stock-transfers.component';
import {StockTransfersItemsComponent} from './component/stock/stock-transfers-items/stock-transfers-items.component';
import {StockRequestComponent} from './component/stock/stock-request/stock-request.component';
import {StockRequestItemsComponent} from './component/stock/stock-request-items/stock-request-items.component';
import {ProductStatisticComponent} from './component/product/product-statistic/product-statistic.component';
import {CategoryComponent} from './component/settings/category/category.component';
import {StockMovementsComponent} from './component/reports/stock-movements/stock-movements.component';
import {InventoryComponent} from './component/inventory/inventory.component';
import {InventoryStockComponent} from './component/inventory/inventory-stock/inventory-stock.component';
import { InventoryCategoryComponent} from './component/inventory/inventory-category/inventory-category.component';
import { ContactStocksComponent} from './component/contact/contact-stocks/contact-stocks.component';
import {InvoiceCreateBasketComponent} from './component/invoice/invoice-create-basket/invoice-create-basket.component';
import {InvoiceReceiveListComponent} from './component/invoice/invoice-receive-list/invoice-receive-list.component';
import {InvoiceReceiveManuelComponent} from './component/order/invoice-receive-manuel/invoice-receive-manuel.component';
import {SettingInventoryItemsComponent} from './component/settings/setting-inventory-items/setting-inventory-items.component';
import {RoleComponent} from './component/settings/role/role.component';
import {OrderHistoryComponent} from './component/order/order-history/order-history.component';
import {ReceiveOrdersComponent} from './component/order/receive-orders/receive-orders.component';
import {WeekleySchedulesComponent} from './component/order/weekley-schedules/weekley-schedules.component';
import {PlaceOrdersComponent} from './component/order/place-orders/place-orders.component';
import {OrderDetailsComponent} from './component/order/order-details/order-details.component';
import {AllergensComponent} from './component/inventory/allergens/allergens.component';
import {CookBookComponent} from './component/inventory/cook-book/cook-book.component';
import {WasteEventsComponent} from './component/inventory/waste-events/waste-events.component';
import {SalesSummaryComponent} from './component/inventory/sales-summary/sales-summary.component';
import {MenuItemsComponent} from './component/inventory/menu-items/menu-items.component';
import {InvoiceDeliveryNotesComponent} from './component/accounting/invoice-delivery-notes/invoice-delivery-notes.component';
import {ScannedInvoicesComponent} from './component/accounting/scanned-invoices/scanned-invoices.component';
import {CreditRemembersComponent} from './component/accounting/credit-remembers/credit-remembers.component';
import {IrregularPricesComponent} from './component/accounting/irregular-prices/irregular-prices.component';
import {PriceReportComponent} from './component/reports/price-report/price-report.component';
import {PriceChangeReportComponent} from './component/reports/price-change-report/price-change-report.component';
import {OrdersBySupplierComponent} from './component/reports/orders-by-supplier/orders-by-supplier.component';
import {WasteReportComponent} from './component/reports/waste-report/waste-report.component';
import {PaymentComponent} from './component/payment/payment.component';
import {InventoryReportComponent} from './component/reports/inventory-report/inventory-report.component';
import {InventoryItemsComponent} from './component/inventory/inventory-items/inventory-items.component';
import {InventoryLocationComponent} from './component/inventory/inventory-location/inventory-location.component';
import {IncomingInvoiceReceiveComponent} from './component/order/incoming-invoice-receive/incoming-invoice-receive.component';
import {IncomingInvoiceListComponent} from './component/order/incoming-invoice-list/incoming-invoice-list.component';
import {ReturnInvoiceComponent} from './component/accounting/return-invoice/return-invoice.component';
import {ReturnInvoiceDetailComponent} from './component/accounting/return-invoice/detail/detail.component';
import {DailyIncomeComponent} from './component/reports/sale-reports/daily-income/daily-income.component';
import {ProductGroupComponent} from './component/reports/sale-reports/product-group/product-group.component';
import {MenuAnalysisComponent} from './component/reports/sale-reports/menu-analysis/menu-analysis.component';
import {NewOrderComponent} from './component/order/new-order/new-order.component';
import {OrderReceiveComponent} from './component/order/order-receive/order-receive.component';
import {PreparationItemsComponent} from './component/inventory/preparation-items/preparation-items.component';
import {ChatComponent} from './component/chat/chat.component';
import {PurchaseReportComponent} from './component/reports/purchase-report/purchase-report.component';
import {IntegrationLogsComponent} from './component/log/integration-logs/integration-logs.component';
import {InitialSetupComponent} from './component/create-account/initial-setup/initial-setup.component';
import {SelectPosComponent} from './component/create-account/initial-setup/select-pos/select-pos.component';
import {SelectAccountComponent} from './component/create-account/initial-setup/select-account/select-account.component';
import {StockImportComponent} from './component/create-account/initial-setup/stock-import/stock-import.component';
import {AddLocationComponent} from './component/create-account/initial-setup/add-location/add-location.component';
import {AddUnitComponent} from './component/create-account/initial-setup/add-unit/add-unit.component';
import {AddTaxComponent} from './component/create-account/initial-setup/add-tax/add-tax.component';
import {AddCategoryComponent} from './component/create-account/initial-setup/add-category/add-category.component';
import {CompanyInformationComponent} from './component/create-account/initial-setup/company-information/company-information.component';
import {OkayAndContinueComponent} from './component/create-account/initial-setup/okay-and-continue/okay-and-continue.component';
import {SalesListComponent} from './component/sales/sales-list/sales-list.component';
import {LastPurchaseReportsComponent} from './component/reports/last-purchase-reports/last-purchase-reports.component';
import {MenuGroupComparisonComponent} from './component/reports/sale-reports/menu-group-comparison/menu-group-comparison.component';
import {MenuItemGroupComponent} from './component/reports/sale-reports/menu-item-group/menu-item-group.component';
import {PlanningViewComponent} from './component/planning/planning-view/planning-view.component';
import {WorkOrderDashboardComponent} from './component/work-order/work-order-dashboard/work-order-dashboard.component';
import {UnpaidReportComponent} from './component/reports/sale-reports/unpaid-report/unpaid-report.component';
import {ByGroupComponent} from './component/reports/sale-reports/unpaid-report/by-group/by-group.component';
import {ByItemComponent} from './component/reports/sale-reports/unpaid-report/by-item/by-item.component';
import {ByDailyComponent} from './component/reports/sale-reports/unpaid-report/by-daily/by-daily.component';
import {ByDetailComponent} from './component/reports/sale-reports/unpaid-report/by-detail/by-detail.component';
import {DailyProductionReportComponent} from './component/work-order/work-order-reports/daily-production-report/daily-production-report.component';
import {
  ProductProductionReportComponent
} from './component/work-order/work-order-reports/product-production-report/product-production-report.component';
import { OrderReportComponent } from './component/work-order/work-order-reports/order-report/order-report.component';
import {
  DepartmentProductionReportComponent
} from './component/work-order/work-order-reports/department-production-report/department-production-report.component';
import {
  UnpaidProductionReportComponent
} from './component/work-order/work-order-reports/unpaid-production-report/unpaid-production-report.component';
import {PaymentPacketsComponent} from './component/payment/payment-packets/payment-packets.component';
import {PaymentBasketComponent} from './component/payment/payment-basket/payment-basket.component';
import {LocationSubscriptionComponent} from './component/settings/location/subscription/subscription.component';
import {LocationExpensesComponent} from './component/planning/location-expenses/location-expenses.component';
import {
  IncomeExpenseAnalysisComponent
} from './component/reports/income-expense-analysis/income-expense-analysis.component';
import {CookBookCategoryModel} from './model/cook-book-category.model';
import {CookBookCategoryComponent} from './component/inventory/cook-book-category/cook-book-category.component';
import {CookBookStocksComponent} from './component/inventory/cook-book-stocks/cook-book-stocks.component';
import {ExpirationDateStocksComponent} from './component/planning/expiration-date-stocks/expiration-date-stocks.component';


const routes: Routes = [
  { path: '', component: DashboardComponent, canActivate: [AuthGuard] },
  { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard], children: [
      {
        path: '', redirectTo: 'general', pathMatch: 'full'
      },
      {
        path: 'general',
        component: GeneralComponent
      },
      {
        path: 'categories',
        component: CategoryComponent
      },
      {
        path: 'operations',
        component: OperationComponent
      },
      {
        path: 'resources',
        component: ResourceComponent
      },
      {
        path: 'locations',
        component: LocationComponent
      },
      {
        path: 'subscriptions',
        component: LocationSubscriptionComponent
      },
      {
        path: 'units',
        component: UnitComponent
      },
      {
        path: 'tax-rates',
        component: TaxComponent
      },
      {
        path: 'users',
        component: UsersComponent
      },
      {
        path: 'integrations',
        component: IntegrationsComponent
      },
      {
        path: 'import',
        component: DataImportComponent
      },
      {
        path: 'setting-inventory-items',
        component: SettingInventoryItemsComponent
      },
      {
        path: 'roles',
        component: RoleComponent
      }
    ] },
  { path: 'contacts', component: ContactComponent, canActivate: [AuthGuard] },
  { path: 'stocks', component: StockComponent, canActivate: [AuthGuard] },
  { path: 'stocks/create', component: StockCreateComponent, canActivate: [AuthGuard], data: {type: 'material'} },
  { path: 'products/create', component: StockCreateComponent, canActivate: [AuthGuard], data: {type: 'product'} },
  { path: 'semi-product/create', component: StockCreateComponent, canActivate: [AuthGuard], data: {type: 'semi_product'} },
  { path: 'stocks/:id/edit', component: StockUpdateComponent, canActivate: [AuthGuard] },
  { path: 'purchase/create', component: InvoiceCreateComponent, canActivate: [AuthGuard], data: {type: InvoiceType.Purchase} },
  { path: 'purchase/:id/edit', component: InvoiceCreateComponent, canActivate: [AuthGuard], data: {type: InvoiceType.Purchase} },
  { path: 'purchases', component: InvoiceListComponent, canActivate: [AuthGuard], data: {type: InvoiceType.Purchase} },
  { path: 'sales', component: InvoiceListComponent, canActivate: [AuthGuard], data: {type: InvoiceType.Sales} },
  { path: 'sales/create', component: InvoiceCreateComponent, canActivate: [AuthGuard], data: {type:  InvoiceType.Sales}},
  { path: 'sales/:id/edit', component: InvoiceCreateComponent, canActivate: [AuthGuard], data: {type:  InvoiceType.Sales}},
  { path: 'stock-adjustments', component: StockAdjustmentListComponent, canActivate: [AuthGuard] },
  { path: 'stock-adjustments/:id/edit', component: StockAdjustmentComponent, canActivate: [AuthGuard] },
  { path: 'stock-adjustments/create', component: StockAdjustmentComponent, canActivate: [AuthGuard] },
  { path: 'stock-transfers', component: StockTransfersComponent, canActivate: [AuthGuard]},
  { path: 'stock-transfers/create', component: StockTransfersItemsComponent, canActivate: [AuthGuard] },
  { path: 'stock-transfers/:id/edit', component: StockTransfersItemsComponent, canActivate: [AuthGuard] },
  { path: 'stock-requests', component: StockRequestComponent, canActivate: [AuthGuard]},
  { path: 'stock-requests/create', component: StockRequestItemsComponent, canActivate: [AuthGuard] },
  { path: 'stock-requests/:id/edit', component: StockRequestItemsComponent, canActivate: [AuthGuard] },
  { path: 'products', component: ProductListComponent, canActivate: [AuthGuard]},
  { path: 'products/:id/recipe', component: ProductRecipeComponent, canActivate: [AuthGuard]},
  { path: 'products/:id/operations', component: ProductOperationComponent, canActivate: [AuthGuard]},
  { path: 'products/:id/stats/:variantId', component: ProductStatisticComponent, canActivate: [AuthGuard]},
  { path: 'work-orders/create', component: WorkOrderCreateComponent, canActivate: [AuthGuard]},
  { path: 'work-orders/scheduler', component: HomeComponent, canActivate: [AuthGuard]},
  { path: 'work-orders/:id/edit', component: WorkOrderCreateComponent, canActivate: [AuthGuard]},
  { path: 'work-orders/:id/pick-list', component: PickListComponent, canActivate: [AuthGuard]},
  { path: 'work-orders', component: WorkOrderListComponent, canActivate: [AuthGuard]},
  { path: 'planning', component: PlanningViewComponent, canActivate: [AuthGuard]},
  { path: 'planning/expiration_date_stocks', component: ExpirationDateStocksComponent, canActivate: [AuthGuard]},
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'reset-password/:token', component: ResetPasswordComponent },
  { path: 'registration', component: CreateAccountComponent },
  { path: 'initial-setup', component: InitialSetupComponent, canActivate: [AuthGuard] },
  { path: 'initial-setup/select-pos', component: SelectPosComponent, canActivate: [AuthGuard] },
  { path: 'initial-setup/select-account', component: SelectAccountComponent, canActivate: [AuthGuard] },
  { path: 'initial-setup/stock-import', component: StockImportComponent, canActivate: [AuthGuard] },
  { path: 'initial-setup/add-location', component: AddLocationComponent, canActivate: [AuthGuard] },
  { path: 'initial-setup/add-unit', component: AddUnitComponent, canActivate: [AuthGuard] },
  { path: 'initial-setup/add-tax', component: AddTaxComponent, canActivate: [AuthGuard] },
  { path: 'initial-setup/add-category', component: AddCategoryComponent, canActivate: [AuthGuard] },
  { path: 'initial-setup/company-information', component: CompanyInformationComponent, canActivate: [AuthGuard] },
  { path: 'initial-setup/okay-and-continue', component: OkayAndContinueComponent, canActivate: [AuthGuard] },
  { path: 'stock-movements', component: StockMovementsComponent, canActivate: [AuthGuard]},
  { path: 'inventory', component: InventoryComponent, canActivate: [AuthGuard] },
  { path: 'inventory/create', component: InventoryItemsComponent, canActivate: [AuthGuard]},
  { path: 'inventory/:id/edit', component: InventoryLocationComponent, canActivate: [AuthGuard]},
  { path: 'inventory-category/:id', component: InventoryCategoryComponent, canActivate: [AuthGuard]},
  { path: 'inventory-stock/:category/:location/:inventoryItem', component: InventoryStockComponent, canActivate: [AuthGuard]},
  { path: 'contact/stock-prices/:id', component: ContactStocksComponent, canActivate: [AuthGuard]},
  { path: 'purchases/new-order', component: InvoiceCreateBasketComponent, canActivate: [AuthGuard]},
  { path: 'purchases/receive-list/:id', component: InvoiceReceiveListComponent, canActivate: [AuthGuard]},
  { path: 'order/receive-manuel/:id', component: OrderReceiveComponent , canActivate: [AuthGuard]},
  { path: 'order/receive-manuel-a/:id', component: InvoiceReceiveManuelComponent, canActivate: [AuthGuard]},
  // new routes
  { path: 'orders/new-order', component: NewOrderComponent, canActivate: [AuthGuard]},
  { path: 'orders/order-history', component: OrderHistoryComponent, canActivate: [AuthGuard], data: {type: InvoiceType.Purchase}},
  { path: 'orders/receive-order', component: ReceiveOrdersComponent, canActivate: [AuthGuard], data: {type: InvoiceType.Purchase}},
  { path: 'orders/weekly-schedules', component: WeekleySchedulesComponent, canActivate: [AuthGuard]},
  { path: 'orders/order-details/:id', component: OrderDetailsComponent, canActivate: [AuthGuard]},
  { path: 'inventory/allergens', component: AllergensComponent, canActivate: [AuthGuard]},
  { path: 'inventory/inventory-items', component: InventoryItemsComponent, canActivate: [AuthGuard], data: {type: 'material'}},
  // tslint:disable-next-line:max-line-length
  { path: 'inventory/recipe-preparation-items', component: PreparationItemsComponent, canActivate: [AuthGuard], data: {type: 'semi_product'}},
  { path: 'inventory/menu-items', component: MenuItemsComponent, canActivate: [AuthGuard], data: {type: 'product'}},
  { path: 'inventory/cook-book', component: CookBookComponent, canActivate: [AuthGuard]},
  { path: 'inventory/cook-book/category', component: CookBookCategoryComponent, canActivate: [AuthGuard]},
  { path: 'inventory/cook-book/stocks', component: CookBookStocksComponent, canActivate: [AuthGuard]},
  { path: 'inventory/work-orders', component: WorkOrderListComponent, canActivate: [AuthGuard]},
  { path: 'inventory/waste-events', component: WasteEventsComponent, canActivate: [AuthGuard]},
  { path: 'inventory/sales-summary', component: SalesSummaryComponent, canActivate: [AuthGuard]},
  { path: 'accounting/invoice-delivery-notes', component: InvoiceDeliveryNotesComponent, canActivate: [AuthGuard]},
  { path: 'accounting/return-invoices', component: ReturnInvoiceComponent, canActivate: [AuthGuard], data: {type:  InvoiceType.Return}},
  // tslint:disable-next-line:max-line-length
  { path: 'accounting/return-invoice-detail/:id', component: ReturnInvoiceDetailComponent, canActivate: [AuthGuard], data: {type: InvoiceType.Return}},
  { path: 'accounting/scanned-invoices', component: ScannedInvoicesComponent, canActivate: [AuthGuard]},
  { path: 'accounting/credit-reminders', component: CreditRemembersComponent, canActivate: [AuthGuard]},
  { path: 'accounting/irregular-prices', component: IrregularPricesComponent, canActivate: [AuthGuard]},
  { path: 'reports/price-reports', component: PriceReportComponent, canActivate: [AuthGuard]},
  { path: 'reports/price-change-reports', component: PriceChangeReportComponent, canActivate: [AuthGuard]},
  { path: 'reports/orders-by-suppliers', component: OrdersBySupplierComponent, canActivate: [AuthGuard]},
  { path: 'reports/waste-reports', component: WasteReportComponent, canActivate: [AuthGuard]},
  { path: 'reports/inventory-reports', component: InventoryReportComponent, canActivate: [AuthGuard]},
  { path: 'reports/sale-reports/daily-income', component: DailyIncomeComponent, canActivate: [AuthGuard]},
  { path: 'reports/sale-reports/product-group', component: ProductGroupComponent, canActivate: [AuthGuard]},
  { path: 'reports/sale-reports/menu-analysis', component: MenuAnalysisComponent, canActivate: [AuthGuard]},
  { path: 'reports/purchase-report', component: PurchaseReportComponent, canActivate: [AuthGuard]},
  { path: 'payment', component: PaymentComponent, canActivate: [AuthGuard]},
  { path: 'payment/packets', component: PaymentPacketsComponent, canActivate: [AuthGuard]},
  { path: 'payment/basket', component: PaymentBasketComponent, canActivate: [AuthGuard]},
  { path: 'orders/incoming-invoice-list', component: IncomingInvoiceListComponent, canActivate: [AuthGuard]},
  { path: 'orders/incoming-invoice-receive/:id', component: IncomingInvoiceReceiveComponent, canActivate: [AuthGuard]},
  { path: 'orders/new-order-old', component: PlaceOrdersComponent, canActivate: [AuthGuard]},
  { path: 'chat', component: ChatComponent, canActivate: [AuthGuard]},
  { path: 'logs', component: IntegrationLogsComponent, canActivate: [AuthGuard]},
  { path: 'in-company-orders', component: SalesListComponent, canActivate: [AuthGuard], data: {type: InvoiceType.Purchase}},
  { path: 'reports/last-purchase-reports', component: LastPurchaseReportsComponent, canActivate: [AuthGuard]},
  { path: 'reports/sale-reports/menu-item-group', component: MenuItemGroupComponent, canActivate: [AuthGuard]},
  { path: 'reports/sale-reports/menu-group-comparison', component: MenuGroupComparisonComponent, canActivate: [AuthGuard]},
  { path: 'work-orders/dashboard', component: WorkOrderDashboardComponent, canActivate: [AuthGuard]},
  { path: 'reports/sale-reports/unpaid-report', component: UnpaidReportComponent, canActivate: [AuthGuard]},
  { path: 'reports/sale-reports/unpaid-report/by-group', component: ByGroupComponent, canActivate: [AuthGuard]},
  { path: 'reports/sale-reports/unpaid-report/by-item', component: ByItemComponent, canActivate: [AuthGuard]},
  { path: 'reports/sale-reports/unpaid-report/by-daily', component: ByDailyComponent, canActivate: [AuthGuard]},
  { path: 'reports/sale-reports/unpaid-report/by-detail', component: ByDetailComponent, canActivate: [AuthGuard]},
  { path: 'work-order/work-order-reports/daily-production-report', component: DailyProductionReportComponent, canActivate: [AuthGuard]},
  { path: 'work-order/work-order-reports/product-production-report', component: ProductProductionReportComponent, canActivate: [AuthGuard]},
  { path: 'work-order/work-order-reports/order-report', component: OrderReportComponent, canActivate: [AuthGuard]},
  { path: 'work-order/work-order-reports/department-production-report', component: DepartmentProductionReportComponent,
    canActivate: [AuthGuard]},
  { path: 'work-order/work-order-reports/unpaid-production-report', component: UnpaidProductionReportComponent,
    canActivate: [AuthGuard]},
  { path: 'location-expenses', component: LocationExpensesComponent, canActivate: [AuthGuard]},
  { path: 'reports/income-expense-analysis', component: IncomeExpenseAnalysisComponent, canActivate: [AuthGuard]},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
