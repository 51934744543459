import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import {LOCALE_ID, NgModule} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './component/security/login/login.component';
import { InputTextModule } from 'primeng/inputtext';
import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import { HomeComponent } from './component/home/home.component';
import {AuthGuard} from './auth/auth.guard';
import {MenubarModule} from 'primeng/menubar';
import {SplitButtonModule} from 'primeng/splitbutton';
import { SettingsComponent } from './component/settings/settings.component';
import {MegaMenuModule} from 'primeng/megamenu';
import { GeneralComponent } from './component/settings/general/general.component';
import {ConfirmationService, MessageService} from 'primeng/api';
import {TableModule} from 'primeng/table';
import {SidebarModule} from 'primeng/sidebar';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { OperationComponent } from './component/settings/operation/operation.component';
import { ResourceComponent } from './component/settings/resource/resource.component';
import {InputNumberModule} from 'primeng/inputnumber';
import { UnitComponent } from './component/settings/unit/unit.component';
import { TaxComponent } from './component/settings/tax/tax.component';
import { LocationComponent } from './component/settings/location/location.component';
import { ContactComponent } from './component/contact/contact.component';
import { SelectButtonModule } from 'primeng/selectbutton';
import { StockComponent } from './component/stock/stock.component';
import {CheckboxModule} from 'primeng/checkbox';
import {DropdownModule} from 'primeng/dropdown';
import {EditorModule} from 'primeng/editor';
import {DividerModule} from 'primeng/divider';
import { StockVariantComponent } from './component/stock-variant/stock-variant.component';
import { DialogModule} from 'primeng/dialog';
import { StockCreateComponent } from './component/stock/stock-create/stock-create.component';
import { StockUpdateComponent} from './component/stock/stock-update/stock-update.component';
import { ProgressSpinnerModule} from 'primeng/progressspinner';
import { ChipsModule} from 'primeng/chips';
import { InvoiceCreateComponent } from './component/invoice/invoice-create/invoice-create.component';
import { CalendarModule} from 'primeng/calendar';
import { AutoCompleteModule} from 'primeng/autocomplete';
import { ProductRecipeComponent } from './component/product/product-recipe/product-recipe.component';
import { MultiSelectModule} from 'primeng/multiselect';
import { ProductOperationComponent } from './component/product/product-operation/product-operation.component';
import { InvoiceListComponent } from './component/invoice/invoice-list/invoice-list.component';
import { InvoiceReceiveComponent } from './component/invoice/invoice-receive/invoice-receive.component';
import { QuickInvoiceCreateComponent } from './component/invoice/quick-invoice-create/quick-invoice-create.component';
import { TagModule} from 'primeng/tag';
import { StockTransactionsComponent } from './component/stock/stock-transactions/stock-transactions.component';
import { QuickStockCreateComponent } from './component/stock/quick-stock-create/quick-stock-create.component';
import { TabMenuModule} from 'primeng/tabmenu';
import { StatusLabelPipe, StatusPipe} from './pipe/status.pipe';
import { WorkOrderCreateComponent } from './component/work-order/work-order-create/work-order-create.component';
import { WorkOrderListComponent } from './component/work-order/work-order-list/work-order-list.component';
import { StockImagesComponent } from './component/stock/stock-images/stock-images.component';
import { FileUploadModule} from 'primeng/fileupload';
import { TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import { TranslateHttpLoader} from '@ngx-translate/http-loader';
import { ProductMenuComponent } from './component/product/product-menu/product-menu.component';
import { FlashMessagesComponent } from './component/flash-messages/flash-messages.component';
import { ToastModule} from 'primeng/toast';
import { OverlayPanelModule} from 'primeng/overlaypanel';
import { QuickAddComponent } from './component/quick-add/quick-add.component';
import { QuickWorkOrderCreateComponent } from './component/work-order/quick-work-order-create/quick-work-order-create.component';
import { DashboardComponent } from './component/dashboard/dashboard.component';
import { ChartModule} from 'primeng/chart';
import { InplaceModule} from 'primeng/inplace';
import { UsersComponent } from './component/settings/users/users.component';
import { PasswordModule} from 'primeng/password';
import { ForgotPasswordComponent } from './component/security/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './component/security/reset-password/reset-password.component';
import { AddressComponent } from './component/address/address.component';
import { SplitterModule} from 'primeng/splitter';
import { MenuModule} from 'primeng/menu';
import { MessageModule} from 'primeng/message';
import { MessagesModule} from 'primeng/messages';
import { InvoiceDetailedListComponent } from './component/invoice/invoice-detailed-list/invoice-detailed-list.component';
import { SyncComponent } from './component/sync/sync.component';
import { IntegrationsComponent } from './component/settings/integrations/integrations.component';
import { LucaComponent } from './component/settings/integrations/luca/luca.component';
import { PickListComponent } from './component/work-order/pick-list/pick-list.component';
import { PickListTableComponent } from './component/work-order/pick-list-table/pick-list-table.component';
import { LoadingIndicatorComponent } from './component/loading-indicator/loading-indicator.component';
import { TooltipComponent } from './component/tooltip/tooltip.component';
import { TooltipModule} from 'primeng/tooltip';
import { PlaceholderComponent } from './component/placeholder/placeholder.component';
import { ProductRecipeShowComponent } from './component/product/product-recipe-show/product-recipe-show.component';
import { CreateAccountComponent } from './component/create-account/create-account.component';
import { CaptchaModule} from 'primeng/captcha';
import { ProductListComponent } from './component/product/product-list/product-list.component';
import { FullCalendarModule} from '@fullcalendar/angular';
import { DataImportComponent } from './component/settings/data-import/data-import.component';
import { InputSwitchModule} from 'primeng/inputswitch';
import { PlanningListComponent } from './component/planning/planning-list/planning-list.component';
import { ColumnWidthPipe } from './pipe/column-width.pipe';
import { StockAdjustmentComponent } from './component/stock/stock-adjustment/stock-adjustment.component';
import { StockAdjustmentListComponent } from './component/stock/stock-adjustment-list/stock-adjustment-list.component';
import { StockListMenuComponent } from './component/stock/stock-list-menu/stock-list-menu.component';
import { StockTransfersComponent } from './component/stock/stock-transfers/stock-transfers.component';
import { StockTransfersItemsComponent } from './component/stock/stock-transfers-items/stock-transfers-items.component';
import { StockRequestComponent } from './component/stock/stock-request/stock-request.component';
import { StockRequestItemsComponent } from './component/stock/stock-request-items/stock-request-items.component';
import { ProductRecipeTableComponent } from './component/product/product-recipe-table/product-recipe-table.component';
import { ProductStatisticComponent } from './component/product/product-statistic/product-statistic.component';
import { CreateCategoryComponent } from './component/settings/category/create-category/create-category.component';
import { CategoryComponent} from './component/settings/category/category.component';
import { CreateTaxComponent } from './component/settings/tax/create-tax/create-tax.component';
import { CreateUnitComponent } from './component/settings/unit/create-unit/create-unit.component';
import { ContactCreateDialogComponent } from './component/contact/contact-create-dialog/contact-create-dialog.component';
import { TreeTableModule} from 'primeng/treetable';
import { LocationService} from './service/location.service';
import { StockMovementsComponent } from './component/reports/stock-movements/stock-movements.component';
import { InventoryComponent } from './component/inventory/inventory.component';
import { InventoryItemsComponent } from './component/inventory/inventory-items/inventory-items.component';
import { InventoryStockComponent } from './component/inventory/inventory-stock/inventory-stock.component';
import { InventoryCategoryComponent } from './component/inventory/inventory-category/inventory-category.component';
import { KolaybiComponent } from './component/settings/integrations/kolaybi/kolaybi.component';
import { InvoiceProductionsComponent } from './component/invoice/invoice-productions/invoice-productions.component';
import { TabViewModule } from 'primeng/tabview';
import { ContactStocksComponent } from './component/contact/contact-stocks/contact-stocks.component';
import { InvoiceCreateBasketComponent } from './component/invoice/invoice-create-basket/invoice-create-basket.component';
import { DatePipe} from '@angular/common';
import { InvoiceReceiveListComponent } from './component/invoice/invoice-receive-list/invoice-receive-list.component';
import { InvoiceReceiveManuelComponent } from './component/order/invoice-receive-manuel/invoice-receive-manuel.component';
import { SettingInventoryItemsComponent } from './component/settings/setting-inventory-items/setting-inventory-items.component';
import { RoleComponent } from './component/settings/role/role.component';
import { NewMaterialCreateComponent } from './component/stock/new-material-create/new-material-create.component';
import { NewMaterialUpdateComponent } from './component/stock/new-material-update/new-material-update.component';
import {TriStateCheckboxModule} from 'primeng/tristatecheckbox';
import {PanelMenuModule} from 'primeng/panelmenu';
import {PanelModule} from 'primeng/panel';
import { OrderHistoryComponent } from './component/order/order-history/order-history.component';
import { ReceiveOrdersComponent } from './component/order/receive-orders/receive-orders.component';
import { WeekleySchedulesComponent } from './component/order/weekley-schedules/weekley-schedules.component';
import { PlaceOrdersComponent } from './component/order/place-orders/place-orders.component';
import { OrderDetailsComponent } from './component/order/order-details/order-details.component';
import { AllergensComponent } from './component/inventory/allergens/allergens.component';
import { SubRecipesComponent } from './component/inventory/sub-recipes/sub-recipes.component';
import { MenuItemsComponent } from './component/inventory/menu-items/menu-items.component';
import { CookBookComponent } from './component/inventory/cook-book/cook-book.component';
import { ProductionsEventsComponent } from './component/inventory/productions-events/productions-events.component';
import { WasteEventsComponent } from './component/inventory/waste-events/waste-events.component';
import { SalesSummaryComponent } from './component/inventory/sales-summary/sales-summary.component';
import { ParasutComponent } from './component/settings/integrations/parasut/parasut.component';
import { AvatarModule } from 'primeng/avatar';
import {RadioButtonModule} from 'primeng/radiobutton';
import { InvoiceDeliveryNotesComponent } from './component/accounting/invoice-delivery-notes/invoice-delivery-notes.component';
import { ScannedInvoicesComponent } from './component/accounting/scanned-invoices/scanned-invoices.component';
import { CreditRemembersComponent } from './component/accounting/credit-remembers/credit-remembers.component';
import { IrregularPricesComponent } from './component/accounting/irregular-prices/irregular-prices.component';
import {ChipModule} from 'primeng/chip';
import { PageInfoComponent } from './component/information/page-info/page-info.component';
import { PriceReportComponent } from './component/reports/price-report/price-report.component';
import { PriceChangeReportComponent } from './component/reports/price-change-report/price-change-report.component';
import { OrdersBySupplierComponent } from './component/reports/orders-by-supplier/orders-by-supplier.component';
import { WasteReportComponent } from './component/reports/waste-report/waste-report.component';
import { PaymentComponent } from './component/payment/payment.component';
import {InputMaskModule} from 'primeng/inputmask';
import {StepsModule} from 'primeng/steps';
import {CarouselModule} from 'primeng/carousel';
import { CountryCurrencyPipe } from './pipe/country-currency.pipe';
import { InventoryReportComponent } from './component/reports/inventory-report/inventory-report.component';
import {BadgeModule} from 'primeng/badge';
import { LingaComponent } from './component/settings/integrations/linga/linga.component';
import { VariantRecipeComponent } from './component/stock/variant-recipe/variant-recipe.component';
import {SkeletonModule} from 'primeng/skeleton';
import {DialogService} from 'primeng/dynamicdialog';
import { InventoryLocationComponent } from './component/inventory/inventory-location/inventory-location.component';
import { LogoComponent } from './component/settings/integrations/logo/logo.component';
import { IncomingInvoiceReceiveComponent } from './component/order/incoming-invoice-receive/incoming-invoice-receive.component';
import { IncomingInvoiceListComponent } from './component/order/incoming-invoice-list/incoming-invoice-list.component';
import { ReturnInvoiceComponent } from './component/accounting/return-invoice/return-invoice.component';
import { ReturnInvoiceDetailComponent} from './component/accounting/return-invoice/detail/detail.component';
import { DailyIncomeComponent } from './component/reports/sale-reports/daily-income/daily-income.component';
import { ProductGroupComponent } from './component/reports/sale-reports/product-group/product-group.component';
import { MenuAnalysisComponent } from './component/reports/sale-reports/menu-analysis/menu-analysis.component';
import * as CanvasJSAngularChart from '../assets/canvasjs.angular.component';
import { EfaturaComponent } from './component/settings/integrations/efatura/efatura.component';
import { DenemeComponent } from './component/settings/integrations/deneme/deneme.component';
import { ReportTabsComponent } from './component/dashboard/report-tabs/report-tabs.component';
import { InventoryItemCreateComponent } from './component/inventory/inventory-items/inventory-item-create/inventory-item-create.component';
import { NewOrderComponent } from './component/order/new-order/new-order.component';
import { OrderReceiveComponent } from './component/order/order-receive/order-receive.component';
import { PreparationItemsComponent } from './component/inventory/preparation-items/preparation-items.component';
import {RippleModule} from 'primeng/ripple';
import { MenuItemCreatePageInfoComponent } from './component/information/menu-item-create-page-info/menu-item-create-page-info.component';
import { ChatComponent } from './component/chat/chat.component';
import { PurchaseReportComponent } from './component/reports/purchase-report/purchase-report.component';
import { GuestUserComponent } from './component/guest-user/guest-user.component';
import { IntegrationLogsComponent } from './component/log/integration-logs/integration-logs.component';
import { PointComponent } from './component/tooltip/point/point.component';
import { InitialSetupComponent } from './component/create-account/initial-setup/initial-setup.component';
import { SelectPosComponent } from './component/create-account/initial-setup/select-pos/select-pos.component';
import { SelectAccountComponent } from './component/create-account/initial-setup/select-account/select-account.component';
import { StockImportComponent } from './component/create-account/initial-setup/stock-import/stock-import.component';
import { CompanyInformationComponent } from './component/create-account/initial-setup/company-information/company-information.component';
import { AddLocationComponent } from './component/create-account/initial-setup/add-location/add-location.component';
import { AddUnitComponent } from './component/create-account/initial-setup/add-unit/add-unit.component';
import { AddTaxComponent } from './component/create-account/initial-setup/add-tax/add-tax.component';
import { AddCategoryComponent } from './component/create-account/initial-setup/add-category/add-category.component';
import { OkayAndContinueComponent } from './component/create-account/initial-setup/okay-and-continue/okay-and-continue.component';
import { AssistantComponent } from './component/settings/assistant/assistant.component';
import { SalesListComponent } from './component/sales/sales-list/sales-list.component';
import {LastPurchaseReportsComponent} from './component/reports/last-purchase-reports/last-purchase-reports.component';
import { PlanningViewComponent } from './component/planning/planning-view/planning-view.component';
import { MenuItemGroupComponent } from './component/reports/sale-reports/menu-item-group/menu-item-group.component';
import { MenuGroupComparisonComponent } from './component/reports/sale-reports/menu-group-comparison/menu-group-comparison.component';
import { WorkOrderDashboardComponent } from './component/work-order/work-order-dashboard/work-order-dashboard.component';
import {ToolbarModule} from 'primeng/toolbar';
import { UnpaidReportComponent } from './component/reports/sale-reports/unpaid-report/unpaid-report.component';
import { UnpaidTabComponent } from './component/reports/sale-reports/unpaid-report/unpaid-tab/unpaid-tab.component';
import { ByGroupComponent } from './component/reports/sale-reports/unpaid-report/by-group/by-group.component';
import { ByDailyComponent } from './component/reports/sale-reports/unpaid-report/by-daily/by-daily.component';
import { ByItemComponent } from './component/reports/sale-reports/unpaid-report/by-item/by-item.component';
import { ByDetailComponent } from './component/reports/sale-reports/unpaid-report/by-detail/by-detail.component';
import {
  DailyProductionReportComponent
} from './component/work-order/work-order-reports/daily-production-report/daily-production-report.component';
import { ProductionReportTabComponent } from './component/work-order/work-order-reports/production-report-tab/production-report-tab.component';
import { ProductProductionReportComponent } from './component/work-order/work-order-reports/product-production-report/product-production-report.component';
import { OrderReportComponent } from './component/work-order/work-order-reports/order-report/order-report.component';
import { DepartmentProductionReportComponent } from './component/work-order/work-order-reports/department-production-report/department-production-report.component';
import { UnpaidProductionReportComponent } from './component/work-order/work-order-reports/unpaid-production-report/unpaid-production-report.component';
import { PaymentPacketsComponent } from './component/payment/payment-packets/payment-packets.component';
import { PaymentBasketComponent } from './component/payment/payment-basket/payment-basket.component';
import {LocationSubscriptionComponent} from './component/settings/location/subscription/subscription.component';
import { LocationExpensesComponent } from './component/planning/location-expenses/location-expenses.component';
import { CreateExpensComponent } from './component/planning/location-expenses/create-expens/create-expens.component';
import {PaginatorModule} from 'primeng/paginator';
import { RecipeChangeComponent } from './component/inventory/inventory-items/inventory-item-create/recipe-change/recipe-change.component';
import {DataViewModule} from 'primeng/dataview';
import { TikoHesapComponent } from './component/settings/integrations/tiko-hesap/tiko-hesap.component';
import { PoscanaComponent } from './component/settings/integrations/poscana/poscana.component';
import { IncomeExpenseAnalysisComponent } from './component/reports/income-expense-analysis/income-expense-analysis.component';
import { ManuelSaleComponent } from './component/sales/manuel-sale/manuel-sale.component';
import { BizimHesapComponent } from './component/settings/integrations/bizim-hesap/bizim-hesap.component';
import { SquareComponent } from './component/settings/integrations/square/square.component';
import { AdisyoComponent } from './component/settings/integrations/adisyo/adisyo.component';
import { CookBookCategoryComponent } from './component/inventory/cook-book-category/cook-book-category.component';
import { CookBookStocksComponent } from './component/inventory/cook-book-stocks/cook-book-stocks.component';
import { PaycellComponent } from './component/settings/integrations/paycell/paycell.component';
import { SimpraComponent } from './component/settings/integrations/simpra/simpra.component';
import { IsBasiComponent } from './component/settings/integrations/is-basi/is-basi.component';
import { CreateSectorComponent } from './component/inventory/cook-book/create-sector/create-sector.component';
import { CreateCookBookStockComponent } from './component/inventory/cook-book/create-cook-book-stock/create-cook-book-stock.component';
import { CreateCookBookCategoryComponent } from './component/inventory/cook-book/create-cook-book-category/create-cook-book-category.component';
import {ImageCropperComponent, ImageCropperModule} from 'ngx-image-cropper';
import {SliderModule} from 'primeng/slider';
import { InventoryListPageInfoComponent } from './component/information/inventory-list-page-info/inventory-list-page-info.component';
import { MenuluxComponent } from './component/settings/integrations/menulux/menulux.component';
import { PlanningPageInfoComponent } from './component/information/planning-page-info/planning-page-info.component';
import { ExpirationDateStocksComponent } from './component/planning/expiration-date-stocks/expiration-date-stocks.component';
import { ReciveOrderPageInfoComponent } from './component/information/recive-order-page-info/recive-order-page-info.component';
const CanvasJSChart = CanvasJSAngularChart.CanvasJSChart;
import { registerLocaleData } from '@angular/common';
import localeTr from '@angular/common/locales/tr';
import { QuickReceiveOrderComponent } from './component/order/quick-receive-order/quick-receive-order.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    SettingsComponent,
    GeneralComponent,
    CategoryComponent,
    OperationComponent,
    ResourceComponent,
    LocationComponent,
    UnitComponent,
    TaxComponent,
    ContactComponent,
    StockComponent,
    StockVariantComponent,
    StockUpdateComponent,
    StockCreateComponent,
    InvoiceCreateComponent,
    ProductRecipeComponent,
    ProductOperationComponent,
    InvoiceListComponent,
    InvoiceReceiveComponent,
    QuickInvoiceCreateComponent,
    StockTransactionsComponent,
    StatusPipe,
    StatusLabelPipe,
    WorkOrderCreateComponent,
    WorkOrderListComponent,
    StockImagesComponent,
    ProductMenuComponent,
    FlashMessagesComponent,
    QuickAddComponent,
    QuickWorkOrderCreateComponent,
    DashboardComponent,
    QuickStockCreateComponent,
    UsersComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    AddressComponent,
    InvoiceDetailedListComponent,
    SyncComponent,
    IntegrationsComponent,
    LucaComponent,
    PickListComponent,
    PickListTableComponent,
    LoadingIndicatorComponent,
    TooltipComponent,
    PlaceholderComponent,
    ProductRecipeShowComponent,
    CreateAccountComponent,
    ProductListComponent,
    DataImportComponent,
    PlanningListComponent,
    ColumnWidthPipe,
    StockAdjustmentComponent,
    StockAdjustmentListComponent,
    StockListMenuComponent,
    StockTransfersComponent,
    StockTransfersItemsComponent,
    StockRequestComponent,
    StockRequestItemsComponent,
    ProductRecipeTableComponent,
    ProductStatisticComponent,
    CreateCategoryComponent,
    CreateTaxComponent,
    CreateUnitComponent,
    ContactCreateDialogComponent,
    StockMovementsComponent,
    InventoryComponent,
    InventoryItemsComponent,
    InventoryStockComponent,
    InventoryCategoryComponent,
    KolaybiComponent,
    InvoiceProductionsComponent,
    ContactStocksComponent,
    InvoiceCreateBasketComponent,
    InvoiceReceiveListComponent,
    InvoiceReceiveManuelComponent,
    SettingInventoryItemsComponent,
    RoleComponent,
    NewMaterialCreateComponent,
    NewMaterialUpdateComponent,
    OrderHistoryComponent,
    ReceiveOrdersComponent,
    WeekleySchedulesComponent,
    PlaceOrdersComponent,
    OrderDetailsComponent,
    AllergensComponent,
    SubRecipesComponent,
    MenuItemsComponent,
    CookBookComponent,
    ProductionsEventsComponent,
    WasteEventsComponent,
    SalesSummaryComponent,
    ParasutComponent,
    InvoiceDeliveryNotesComponent,
    ScannedInvoicesComponent,
    CreditRemembersComponent,
    IrregularPricesComponent,
    PageInfoComponent,
    PriceReportComponent,
    PriceChangeReportComponent,
    OrdersBySupplierComponent,
    WasteReportComponent,
    PaymentComponent,
    CountryCurrencyPipe,
    InventoryReportComponent,
    LingaComponent,
    VariantRecipeComponent,
    InventoryLocationComponent,
    LogoComponent,
    IncomingInvoiceReceiveComponent,
    IncomingInvoiceListComponent,
    ReturnInvoiceComponent,
    ReturnInvoiceDetailComponent,
    DailyIncomeComponent,
    ProductGroupComponent,
    MenuAnalysisComponent,
    CanvasJSChart,
    EfaturaComponent,
    DenemeComponent,
    ReportTabsComponent,
    InventoryItemCreateComponent,
    NewOrderComponent,
    OrderReceiveComponent,
    PreparationItemsComponent,
    MenuItemCreatePageInfoComponent,
    ChatComponent,
    PurchaseReportComponent,
    GuestUserComponent,
    IntegrationLogsComponent,
    PointComponent,
    InitialSetupComponent,
    SelectPosComponent,
    SelectAccountComponent,
    StockImportComponent,
    CompanyInformationComponent,
    AddLocationComponent,
    AddUnitComponent,
    AddTaxComponent,
    AddCategoryComponent,
    OkayAndContinueComponent,
    AssistantComponent,
    SalesListComponent,
    LastPurchaseReportsComponent,
    PlanningViewComponent,
    MenuItemGroupComponent,
    MenuGroupComparisonComponent,
    WorkOrderDashboardComponent,
    UnpaidReportComponent,
    UnpaidTabComponent,
    ByGroupComponent,
    ByDailyComponent,
    ByItemComponent,
    ByDetailComponent,
    DailyProductionReportComponent,
    ProductionReportTabComponent,
    ProductProductionReportComponent,
    OrderReportComponent,
    DepartmentProductionReportComponent,
    UnpaidProductionReportComponent,
    PaymentPacketsComponent,
    PaymentBasketComponent,
    LocationSubscriptionComponent,
    LocationExpensesComponent,
    CreateExpensComponent,
    RecipeChangeComponent,
    TikoHesapComponent,
    PoscanaComponent,
    IncomeExpenseAnalysisComponent,
    ManuelSaleComponent,
    BizimHesapComponent,
    SquareComponent,
    AdisyoComponent,
    CookBookCategoryComponent,
    CookBookStocksComponent,
    PaycellComponent,
    SimpraComponent,
    IsBasiComponent,
    CreateSectorComponent,
    CreateCookBookStockComponent,
    CreateCookBookCategoryComponent,
    InventoryListPageInfoComponent,
    MenuluxComponent,
    PlanningPageInfoComponent,
    ExpirationDateStocksComponent,
    ReciveOrderPageInfoComponent,
    QuickReceiveOrderComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    InputTextModule,
    CardModule,
    ButtonModule,
    HttpClientModule,
    FormsModule,
    MenubarModule,
    SplitButtonModule,
    MegaMenuModule,
    TableModule,
    SidebarModule,
    ConfirmPopupModule,
    ConfirmDialogModule,
    BrowserAnimationsModule,
    InputNumberModule,
    SelectButtonModule,
    CheckboxModule,
    DropdownModule,
    EditorModule,
    DividerModule,
    DialogModule,
    ProgressSpinnerModule,
    ChipsModule,
    CalendarModule,
    AutoCompleteModule,
    MultiSelectModule,
    TagModule,
    TabMenuModule,
    FileUploadModule,
    ToastModule,
    OverlayPanelModule,
    ChartModule,
    InplaceModule,
    PasswordModule,
    SplitterModule,
    MessageModule,
    MessagesModule,
    CaptchaModule,
    FullCalendarModule,
    InputSwitchModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      defaultLanguage: 'tr'
    }),
    MenuModule,
    TooltipModule,
    TreeTableModule,
    TabViewModule,
    TriStateCheckboxModule,
    PanelMenuModule,
    MenuModule,
    PanelModule,
    AvatarModule,
    RadioButtonModule,
    ChipModule,
    InputMaskModule,
    StepsModule,
    CarouselModule,
    BadgeModule,
    SkeletonModule,
    RippleModule,
    FullCalendarModule,
    ToolbarModule,
    PaginatorModule,
    DataViewModule,
    ImageCropperModule,
    SliderModule
  ],
  providers: [TranslateService, AuthGuard, MessageService,
    ConfirmationService, TranslateService, LocationService,
    DatePipe, DialogService, { provide: LOCALE_ID, useValue: 'tr-TR' } ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    registerLocaleData(localeTr); // Türkçe yerel veriyi kaydet
  }
}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
