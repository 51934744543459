<app-loading-indicator [loading]="loading"></app-loading-indicator>
<p-card>
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6">
      <h3 [style]="{ fontWeight: '600' }">{{ 'INCOMING_INVOICE' | translate }}</h3>

    </div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'INCOMING_INVOICE'"></app-page-info>
      <a target="_blank" href="{{apiUrl + '/api/stocks/pdf'}}">
        <p-button icon="pi pi-file-pdf" [style]="{marginLeft: '5px', float:'right'}"></p-button>
      </a>
      <p-splitButton label="{{'UPDATE' | translate}}"
                     [icon]="invoiceLoading ? 'pi pi-spin pi-spinner' : 'pi pi-spinner'" [style]="{'float': 'right' }"
                     [model]="this.eInvoiceSynchroMenus" styleClass="p-button-sm mr-2 mb-2"></p-splitButton>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-3" >
      <p-dropdown *ngIf="searchSuppliers.length > 0" [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                  appendTo="body" [(ngModel)]="parameters.contact" placeholder="{{ 'SELECT_SUPPLIER' | translate }}"
                  [filter]="true" filterBy="name"
                  (onChange)="changeParameters($event,'contact')" [options]="searchSuppliers"
                  optionLabel="name" optionValue="id">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-3" >
      <p-dropdown  *ngIf="locations.length > 0" [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                  appendTo="body" [(ngModel)]="parameters.location" placeholder="{{ 'SELECT_SUPPLIER' | translate }}"
                  [filter]="true" filterBy="name"
                  (onChange)="changeParameters($event,'location')" [options]="locations"
                  optionLabel="name" optionValue="id">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-2 p-lg-1 p-xl-1">
      <p-button type="submit" (click)="load(null)" label="{{'SEARCH' | translate}}"></p-button>
    </div>
    <div class="p-col-12 p-md-2 p-lg-1 p-xl-2">
      <p-button type="submit" (click)="openDialog()" label="{{'SHOW_HIDE' | translate}}"></p-button>
    </div>
  </div>
  <div class="p-grid grid-div p-mt-3 p-mb-3" *ngIf="errorMessageShow">
    <div class="p-col div-sums alert-div">
      <b>{{ errorMessage}}</b>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12" style="margin-top:20px">
      <p-table [loading]="loading" [resizableColumns]="true" [rowsPerPageOptions]="[10,25,50]"
               [tableStyle]="{'min-width': '50rem'}" [lazy]="true" (onLazyLoad)="load($event)"
               [rows]="20" [showCurrentPageReport]="true" [totalRecords]="total" [paginator]="true"
               [value]="incomingInvoices"
               styleClass="p-datatable-striped p-datatable-sm">
        <ng-template pTemplate="header">
          <tr>
            <th scope="col" [style]="{width: '3%'}">
              <app-tooltip description="{{'CLICK_BUTTON_FOR_HIDE_INVOICE' | translate}}"></app-tooltip>
            </th>
            <th scope="col" [style]="{width: '10%'}" pSortableColumn="invoiceDate">
              <p-sortIcon field="invoiceDate"></p-sortIcon>
              {{'DATE' | translate}}</th>
            <th scope="col" [style]="{width: '30%'}" pSortableColumn="name">
              <p-sortIcon field="name"></p-sortIcon>
              {{'SUPPLIER' | translate}}</th>
            <th scope="col" [style]="{width: '20%'}" pSortableColumn="invoiceNumber">
              <p-sortIcon field="invoiceNumber"></p-sortIcon>
              {{'DOCUMENT_NUMBER' | translate}}</th>
            <th scope="col" [style]="{width: '10%'}" pSortableColumn="subtotal">
              <p-sortIcon field="subtotal"></p-sortIcon>
              {{ 'SUBTOTAL'|translate }} </th>
            <th scope="col" [style]="{width: '10%'}" pSortableColumn="discount">
              <p-sortIcon field="discount"></p-sortIcon>
              {{'DISCOUNT' | translate}}</th>
            <th scope="col" [style]="{width: '10%'}" pSortableColumn="tax">
              <p-sortIcon field="tax"></p-sortIcon>
              {{'TAX' | translate}}</th>
            <th scope="col" [style]="{width: '10%'}" pSortableColumn="total">
              <p-sortIcon field="total"></p-sortIcon>
              {{'TOTAL' | translate}}</th>
            <th scope="col" [style]="{width: '3%'}"></th>
            <th scope="col" [style]="{width: '3%'}"></th>
            <th scope="col" [style]="{width: '10%'}"></th>

          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-i="rowIndex">
          <tr [style]="selectedRow === i ? ' background: #ced4da' : ''" *ngIf="item.contact?.hideInvoice === false">
            <td><i class="pi pi-eye-slash" [pTooltip]="message" (mouseenter)="onMessage(item.name)"
                                    (click)="hideSameInvoice(item.contact?.id, item.contact?.email)"></i></td>
            <td>{{ item.invoiceDate | date: 'Y-MM-dd'  }}</td>
            <td>{{item.name}}</td>
            <td [style]="{textAlign: 'center'}">{{ item.invoiceNumber}}</td>
            <td [style]="{textAlign: 'right'}">{{ item.subtotal | countryCurrency}}</td>
            <td [style]="{textAlign: 'right'}">{{ item.discount | countryCurrency}}</td>
            <td [style]="{textAlign: 'right'}">{{ item.tax | countryCurrency}}</td>
            <td [style]="{textAlign: 'right'}">{{ item.total | countryCurrency}}</td>
            <td><i class="pi pi-eye" (click)="viewInvoiceItems(item)"></i></td>
            <td><i class="pi pi-file-pdf" *ngIf="item.ettn !== null" (click)="downloadPdf(item.ettn)"></i></td>
            <td>
              <a [style]="{textAlign: 'right', color:'#10c5cc'}"
                 (click)="goToPage('/orders/incoming-invoice-receive/' + item.id)">
                {{ 'RECEIVE' | translate}}
              </a>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</p-card>

<p-dialog header="{{'INVOICE_DETAIL' | translate}}" [(visible)]="invoiceItemsShow" [style]="{width: '60vw'}">
  <div class="p-grid">
    <div class="col-12 p-mb-2 p-mt-2">
      <p-button label="{{'HIDE_INVOICE' | translate }}" icon="pi pi-eye-slash" (click)="hideThisInvoice(id)"></p-button>
    </div>
    <div class="col-12">
      <p-table [value]="invoiceItems" *ngIf="invoiceItemsShow" [tableStyle]="{ 'min-width': '50rem' }">
        <ng-template pTemplate="header">
          <tr>
            <th scope="col" [style]="{width:'30%'}">{{'NAME' | translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{'QUANTITY' | translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{'PRICE' | translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{'SUBTOTAL' | translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{'DISCOUNT_RATE' | translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{'DISCOUNT_TOTAL' | translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{'TOTAL' | translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td>{{ item.name }} {{ item.description }}</td>
            <td>{{ item.quantity}} {{ item.unit}}</td>
            <td>{{ item.price | number: '1.2-2'}}</td>
            <td>{{ item.subTotal | number: '1.2-2'}}</td>
            <td>{{ item.tax | number: '1.2-2'}}</td>
            <td>{{ item.taxRate | number: '1.2-2'}}</td>
            <td>{{ item.total | number: '1.2-2'}}</td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>

</p-dialog>

<p-confirmDialog #cd header="" icon="pi pi-exclamation-triangle">
  <ng-template pTemplate="header">
    <h3>{{'ALERT' | translate}}</h3>
  </ng-template>
  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-times" label="{{ 'NO' | translate}}" (click)="cd.reject()"></button>
    <button type="button" pButton icon="pi pi-check" label="{{ 'YES' | translate}}" (click)="cd.accept()"></button>
  </ng-template>
</p-confirmDialog>


<p-dialog [(visible)]="selectLocationShow" [style]="{width:'50vw'}" [closable]="false">
  <ng-template pTemplate="header">
    <div style="width: 50%">
      <h3>{{ 'SELECT_LOCATION'| translate }}</h3>
    </div>
  </ng-template>
  <div class="dialogDiv">
    <div *ngFor="let location of locations">
      <div class="hoverable"
           style="padding: 20px 10px 7px 15px; border-radius: 5px;background: #ffffff; border:1px solid #f4f3f3">
        <a href="javascript:void(0)" style="color: #444444"
           (click)="parameters.location = location.id; load(null);selectLocationShow = false">
          <div class="p-grid">
            <div class="p-col-2" style="padding: 0px;width: 50px">
              <p-avatar label="{{ location.name| slice:0:1}}"
                        [style]="{width:'32px',heigt:'32px','background-color':'#47bfd4', 'color': '#ffffff',fontSize:'25px',borderRadius:'50%'}"></p-avatar>
            </div>
            <div class="p-col-10" style="align-self:baseline"><b
              style="margin-top:5px;font-size: 14px">{{ location.name}}</b></div>
          </div>
        </a>
      </div>
    </div>
  </div>
</p-dialog>

<p-dialog [(visible)]="dateRangeDisplay" [style]="{width: '50vw'}">
  <div class="p-fluid grid formgrid">
    <div class="field col-12 md:col-4">
      <label for="basic">{{'SELECT_DATE' | translate}}</label>
      <p-calendar inputId="basic" appendTo="body" (onSelect)="getIncomingEinvoices($event)"
                  dateFormat="yy-mm-dd"></p-calendar>
    </div>
  </div>

</p-dialog>

<p-sidebar position="right" *ngIf="hideInvoiceAndSupplierDisplay" [(visible)]="hideInvoiceAndSupplierDisplay" [style]="{width: '50vw'}" (onHide)="reload()">
  <p-tabMenu [model]="items" [activeItem]="activeItem"></p-tabMenu>
  <div *ngIf="activeItem.title === 'SUPPLIERS'">
    <div class="p-grid">
      <div class="p-col-12" style="margin-top:20px">
        <p-table
                 [tableStyle]="{'min-width': '40rem'}"
                 [showCurrentPageReport]="true"
                 [value]="searchSuppliers"
                 styleClass="p-datatable-striped p-datatable-sm">
          <ng-template pTemplate="header">
            <tr>
              <th scope="col" [style]="{width: '5%'}">
                <app-tooltip description="{{'CLICK_BUTTON_FOR_HIDE_INVOICE' | translate}}"></app-tooltip>
              </th>
              <th scope="col" [style]="{width: '95%'}" pSortableColumn="name">
                <p-sortIcon field="name"></p-sortIcon>
                {{'SUPPLIER' | translate}}</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item let-i="rowIndex">
            <tr [style]="selectedRow === i ? ' background: #ced4da' : ''" *ngIf="item.hide_invoice === '1'">
              <td><i class="pi pi-eye" [pTooltip]="message2" (mouseenter)="onMessage(item.name)"
                                     (click)="showSameInvoice(item.id, item.email)"></i></td>
              <td>{{item.name}}</td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
  <div *ngIf="activeItem.title === 'INVOICES'">
    <div class="p-grid">
      <div class="p-col-12" style="margin-top:20px">
        <p-table
          [tableStyle]="{'min-width': '40rem'}"
          [showCurrentPageReport]="true"
          [value]="hideInvoices"
          styleClass="p-datatable-striped p-datatable-sm">
          <ng-template pTemplate="header">
            <tr>
              <th scope="col" [style]="{width: '5%'}">
                <app-tooltip description="{{'CLICK_BUTTON_FOR_HIDE_INVOICE' | translate}}"></app-tooltip>
              </th>
              <th scope="col" [style]="{width: '20%'}" pSortableColumn="invoiceDate">
                <p-sortIcon field="invoiceDate"></p-sortIcon>
                {{'DATE' | translate}}</th>
              <th scope="col" [style]="{width: '30%'}" pSortableColumn="name">
                <p-sortIcon field="name"></p-sortIcon>
                {{'DOCUMENT_NUMBER' | translate}}</th>
              <th scope="col" [style]="{width: '50%'}" pSortableColumn="name">
                <p-sortIcon field="name"></p-sortIcon>
                {{'SUPPLIER' | translate}}</th>
              <th scope="col" [style]="{width: '5%'}"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item let-i="rowIndex">
            <tr [style]="selectedRow === i ? ' background: #ced4da' : ''">
              <td><i class="pi pi-eye" [pTooltip]="message2" (mouseenter)="onMessage(item.name)"
                                                           (click)="showInvoice(item.id, item.email)"></i>
              </td>
              <td>{{ item.invoiceDate | date: 'Y-MM-dd'  }}</td>
              <td>{{item.invoiceNumber}}</td>
              <td>{{item.name}}</td>
              <td><i class="pi pi-file-pdf" *ngIf="item.ettn !== null" (click)="downloadPdf(item.ettn)"></i></td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</p-sidebar>
<p-dialog [(visible)]="displayAction" [style]="{width: '50vw'}">
  <div style="display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;">
    <i class="pi pi-ban" [style]="{color:'#fe8a8a', fontSize:'80px'}"></i>
    <p [style]="{width: '300px', color:'#fe8a8a', textAlign: 'center'}">{{'UNAUTHORIZED_TRANSACTION'| translate}}</p>
  </div>
</p-dialog>
