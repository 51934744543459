import {Component, Input, OnInit} from '@angular/core';
import {CategoryModel} from '../../../model/category.model';
import {HttpService} from '../../../service/http.service';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {TranslateService} from '@ngx-translate/core';
import {range} from 'rxjs';
import {SaleModel} from '../../../model/sale.model';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-manuel-sale',
  templateUrl: './manuel-sale.component.html',
  styleUrls: ['./manuel-sale.component.scss']
})
export class ManuelSaleComponent implements OnInit {
  @Input() location: string;
  categories: any[] = [];
  selectedCategories: any[] = [];
  mainCategories: CategoryModel[] = [];
  loading: boolean;
  submitted: boolean;
  mainCategory: number;
  subCategory: number;
  parameters = {
    'stock.category.id': '',
    'stock.type': 'product',
    pagination: false
  };
  products: StockVariantModel[] = [];
  saleList: any[] = [];
  sale: any[] = [];
  keyDisplay: boolean;
  constructor(private http: HttpService, private translate: TranslateService) { }

  ngOnInit(): void {
    this.http.get(`${CategoryModel.IRI}/main-categories`, {categoryType: 'product'})
      .subscribe(response => {
        this.mainCategories = response['hydra:member'];
      });

    this.http.get(`${CategoryModel.IRI}/category-type/product?search=no`).subscribe(category => {
      this.categories = category['hydra:member'];
    });
  }

  save(): void {
    this.http.post('/api/V1/addSaleAndLocation', this.sale).then(response => {
      this.sale = [];
      this.saleList = [];
    });
  }

  addItem(product): void {
    const item = {
      saleDate: this.formatSaleDate(),
      stock: product.stock,
      name: product.name,
      price: product.price,
      quantity: 1,
      discount: 0,
      tax: (product.price * product.stock.tax.rate) / 100,
      amount: product.price * 1,
      menuId: product.menuId ,
      sizeId: product.sizeId,
      uniqueId: product.id.toString(),
      category: product.stock.category.name,
      addReport: false,
      isCalculate: false,
      modifiers: null,
      extras: null,
      modifierId: '',
      product: {
        category: {
          name: product.stock.category.name
        }
      }
    };
    this.saleList.push(item);
    this.calculate();
  }
  selectMainCategory(id): void {
    this.products = [];
    this.mainCategory = id;
    const filterData = this.categories.filter(obj => parseFloat(obj.parent_id) === id);
    this.selectedCategories = filterData;
  }
  selectSubCategory(id): void {
    this.products = [];
    this.subCategory = id;
    this.parameters['stock.category.id'] = id;
    this.http.get(StockVariantModel.IRI, this.parameters).subscribe(response => {
      this.products = response['hydra:member'];
    });
  }
  showKeyDisplay(item): void {
    this.keyDisplay = true;
  }
  calculate(): void {
    this.sale = [];
    let subTotal = 0;
    let discount = 0;
    let totalTax = 0;
    let grandTotal = 0;
    this.saleList.map(item => {
      subTotal = subTotal + item.amount;
      discount = discount + item.discount;
      totalTax = totalTax + item.tax;
      grandTotal = (subTotal - discount );
    });
    const sale = {
      saleId: (Math.floor(Math.random() * (908998 - 100 + 1)) + 100).toString(),
      location: 'f1513321-f032-49e6-8423-0e7892c8b4cb',
      saleDate: this.formatSaleDate(),
      subTotal,
      discount,
      totalTax,
      total: grandTotal,
      grandTotal,
      cuver: 1,
      items: this.saleList
    };
    this.sale.push(sale);
  }

  formatSaleDate(): string {
    const saleDate = new Date();

    const year = saleDate.getFullYear();
    const month = String(saleDate.getMonth() + 1).padStart(2, '0'); // Aylar 0-11 aralığında olduğu için +1 ekleniyor
    const day = String(saleDate.getDate()).padStart(2, '0');
    const hours = String(saleDate.getHours()).padStart(2, '0');
    const minutes = String(saleDate.getMinutes()).padStart(2, '0');
    const seconds = String(saleDate.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  }

  removeRow(i): void {
    this.saleList.splice(i, 1);
  }
}
