import {ApiResourceModel} from './api-resource.model';
import {StockModel} from './stock.model';
import {StockVariantModel} from './stock-variant.model';
import {WorkOrderMaterialModel} from './work-order-material.model';
import {WorkOrderOperationModel} from './work-order-operation.model';
import {InvoiceModel} from './invoice.model';
import {InvoiceItemModel} from './invoice-item.model';
import {LocationModel} from './location.model';
import {UserModel} from './user.model';

export class WorkOrderModel extends ApiResourceModel{
  public static IRI = '/api/work_orders';
  public id: number;
  public code: string;
  public series: string;
  public number: number;
  public stock: StockModel|string;
  public variant: StockVariantModel;
  public deadline: Date|string;
  public plannedQuantity = 1;
  public subtotal = 0;
  public tax: number;
  public total: number;
  public description: string;
  public status: string;
  public materialStatus: string;
  public itemStatus: string;
  public productionStatus: string;
  public deliveryStatus: string;
  public itemExpectedDate: Date|string;
  public materialExpectedDate: Date|string;
  public materials: WorkOrderMaterialModel[] = [];
  public operations: WorkOrderOperationModel[] = [];
  public invoices: InvoiceModel[] = [];
  public invoiceItems: InvoiceItemModel [] = [];
  public children: WorkOrderModel[] = [];
  public chef: UserModel;
  public source: LocationModel;
  public target: LocationModel|string;
  public rank: number;
  public barcode: string;
  public expirationDate: Date;
  public recipes: any;
  public invoicesList: any;
  public planning: boolean;
  public finalQuantity: number;
}
