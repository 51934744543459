import {Component, ViewChild, QueryList, ViewChildren, AfterViewInit, ChangeDetectorRef, ElementRef} from '@angular/core';
import {InvoiceModel, InvoiceType} from '../../../model/invoice.model';
import {StockModel} from '../../../model/stock.model';
import {ContactModel} from '../../../model/contact.model';
import {InventoryModel} from '../../../model/inventory.model';
import {ContactService} from '../../../service/contact.service';
import {HttpService} from '../../../service/http.service';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {promise} from 'protractor';
import {FlashMessageService} from '../../../service/flash-message.service';
import {ShipmentModel} from '../../../model/shipment.model';
import {LocationModel} from '../../../model/location.model';
import {CategoryModel} from '../../../model/category.model';
import {InvoiceItemModel} from '../../../model/invoice-item.model';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {delay} from 'rxjs/operators';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {SidebarService} from '../../../service/sidebar.service';
import {ShipmentItemModel} from '../../../model/shipment-item.model';
import {TaxRateModel} from '../../../model/tax-rate.model';
import {AutoComplete} from 'primeng/autocomplete';


@Component({
  selector: 'app-quick-receive-order',
  templateUrl: './quick-receive-order.component.html',
  styleUrls: ['./quick-receive-order.component.scss']
})

export class QuickReceiveOrderComponent implements AfterViewInit {

  stocks: StockModel[] = [];
  invoice: InvoiceModel;
  contacts: ContactModel[] = [];
  locations: LocationModel[] = [];
  taxRates: TaxRateModel[] = [];
  taxes: any[] = [];
  activeInventory: InventoryModel;
  loading: boolean;
  submitted: boolean;
  today: Date = new Date();
  minDate: Date;
  maxDate: Date;
  isCanBeSelectDate: Date | string;
  shipment = new ShipmentModel();
  totalError = [];
  errors = {
    location: '',
    documentNumber: '',
    documentDate: '',
    documentType: '',
    contact: '',
    items: '',
  };
  documentNumberIsValid: boolean;
  isCanBeSend: boolean;
  tableLoading: boolean;
  documentType: any[] = [];
  searchContacts: ContactModel[] = [];
  autoCompleteValues: StockVariantModel[] = [];
  variants: StockVariantModel[] = [];
  currency: any;
  isCanSave: any;
  @ViewChildren('autoCompleteRef') autoCompleteInputs!: QueryList<ElementRef>; // Şablon referansı

  constructor(private contactService: ContactService,
              private translate: TranslateService,
              private sidebarService: SidebarService,
              private flashMessageService: FlashMessageService,
              private http: HttpService,
              private cd: ChangeDetectorRef) {
  }


  ngAfterViewInit(): void {
    this.currency = JSON.parse(window.localStorage.getItem('currency'));
    this.documentType = [
      {name: this.translate.instant('INVOICE'), value: 'Invoice'},
      {name: this.translate.instant('DELIVERY_NOTE'), value: 'DeliveryNote'},
      {name: this.translate.instant('RECEIPT'), value: 'Receipt'},
    ];
    this.getInvoiceNumber();
    this.onLoad();
  }

  async getActiveInventory(): Promise<any> {
    await this.http.get(`${InventoryModel.IRI}/active_inventory`).toPromise().then((response: InventoryModel) => {
      if (response !== null) {
        this.activeInventory = response;
        this.minDate = new Date(this.activeInventory.startDate);
        this.maxDate = new Date(this.activeInventory.endDate);
      }
    });
  }

  getInvoiceNumber(): void {
    this.http.get(`${InvoiceModel.IRI}/invoice-number?type=PURCHASE`).subscribe(response => {
      this.invoice = response;
      this.invoice.type = 'PURCHASE';
      this.invoice.status = 'CONFIRMED';
      this.shipment.invoice = response;
    });
  }

  async onLoad(): Promise<void> {
    this.loading = true;
    await this.http.get(`${LocationModel.IRI}/subscriptionControl`).subscribe(response => {
        this.locations = response;
        this.loading = false;
        if (response.length === 1) {
          this.invoice.location = response[0];
        }
      }
    );
    await this.http.get(`${ContactModel.IRI}/get-contact-by-type/supplier`).subscribe(contact => {
      this.contacts = contact['hydra:member'];
    });
    this.http.get(TaxRateModel.IRI).subscribe((response: TaxRateModel[]) => {
      this.taxRates = response['hydra:member'];
      this.invoice.items.map((item, i) => {
        if (item.taxRate === undefined) {
          this.taxes.push(this.taxRates[0]);
          this.invoice.items[i].taxRate = this.taxRates[0]['@id'];
          this.invoice.items[i].tax = this.taxRates[0].rate;
        }
      });
    });
  }

  setTax(i, event): void {
    this.taxRates.map(item => {
      if (item['@id'] === event.value) {
        this.taxes[i] = item;
      }
    });
  }

  documentNumberControl(event): void {
    this.documentNumberIsValid = false;
    this.http.get(ShipmentModel.IRI, {documentNumber: event.target.value}).subscribe(data => {
      if (data['hydra:totalItems'] > 0) {
        this.documentNumberIsValid = true;
      }
    });
  }

  shipmentDateControl(event): void {
    this.getActiveInventory().then(() => {
      if (this.activeInventory.startDate) {
        const selectedDate = new Date(event);
        const InventoryStartDate = new Date(this.activeInventory.startDate);
        if (selectedDate.getTime() < InventoryStartDate.getTime()) {
          this.shipment.documentDate = this.today;
          this.isCanBeSend = false;
          this.isCanBeSelectDate = this.activeInventory.startDate;
        } else {
          this.minDate = InventoryStartDate;
          this.isCanBeSend = true;
        }
      } else {
        this.minDate = this.today;
      }
    });
  }

  addItem = () => {
    const invoiceItem = new InvoiceItemModel();
    this.invoice.items.push(invoiceItem);
  }
  removeItem = (i: number) => {
    this.invoice.items.splice(i, 1);
  }

  onVariantSelect(event: StockVariantModel, i: number): void {

    this.invoice.items[i].variant = event['@id'];
    this.invoice.items[i].taxRate = event.stock.tax;
    this.taxes.push(event.stock.tax);
    this.invoice.items[i].purchaseOption = event.stock.purchaseOptions[0]['@id'];
    this.invoice.items[i].stock = event.stock['@id'];
    this.invoice.items[i].currency = this.currency['@id'];
    console.log(event.stock.stockQuantities, this.invoice.location);
    const findIndex = event.stock.stockQuantities.findIndex(obj => obj.location = this.invoice.location);
    this.invoice.items[i].unitPrice = event.stock.stockQuantities[findIndex].lastPurchasePrice;
    this.invoice.items[i].quantity = 1;
    this.invoice.items[i].remainingQuantity = 0;
    this.invoice.items[i].subtotal = this.invoice.items[i].unitPrice;
    this.invoice.items[i].discount = 0;
    this.invoice.items[i].discountRate = 0;
    this.invoice.items[i].tax = (this.invoice.items[i].unitPrice * this.invoice.items[i].taxRate.rate) / 100;

    this.calculateTotalPrice(0);

    console.log(this.taxes);
  }

  searchVariant = event => {
    this.http.get(StockVariantModel.IRI, {
      name: event.query,
      'stock.isActive': 1,
      type: 'material'
    }).subscribe((response: StockVariantModel) => {
      this.variants = response['hydra:member'];
    });
  }

  hasErrors(name: string, key: number): boolean {
    return this.errors[`items[${key}].${name}`] !== undefined;
  }

  calculateTotalPrice(i: number): void {
    let totalPrice = 0;
    let tax = 0;
    let discount = 0;

    if (this.invoice.items[i].quantity !== undefined && this.invoice.items[i].unitPrice !== undefined) {
      this.invoice.items[i].subtotal = this.invoice.items[i].quantity * this.invoice.items[i].unitPrice;
      this.invoice.items[i].discount = this.invoice.items[i].quantity * this.invoice.items[i].unitPrice *
        (this.invoice.items[i].discountRate / 100);
      // tslint:disable-next-line:max-line-length
      // @ts-ignore
      // tslint:disable-next-line:max-line-length
      this.taxRates.map(taxItem => {
        if (this.invoice.items[i].taxRate === taxItem['@id']) {
          this.invoice.items[i].tax = ((this.invoice.items[i].subtotal - this.invoice.items[i].discount) / 100) * taxItem.rate;
        }
      });
    }

    this.invoice.items.map(item => {
      if (item.subtotal !== undefined && item.tax !== undefined) {
        totalPrice += item.subtotal;
        tax += item.tax;
        discount += item.discount;
      }
    });

    this.invoice.subtotal = totalPrice;
    this.invoice.discount = discount;
    this.invoice.tax = tax;
    this.invoice.total = this.invoice.subtotal + this.invoice.tax - this.invoice.discount;
  }

  saveControl(): void {
    this.errors = {
      location: '',
      documentNumber: '',
      documentDate: '',
      documentType: '',
      contact: '',
      items: '',
    };
    this.isCanSave = true;
    if (this.invoice.location === undefined || this.invoice.location === null) {
      this.isCanSave = false;
      this.errors.location = this.translate.instant('SELECT_LOCATION');
    }
    if (this.invoice.contact === undefined || this.invoice.contact === null) {
      this.isCanSave = false;
      this.errors.contact = this.translate.instant('SELECT_SUPPLIER');
    }
    if (this.invoice.items.length === 0) {
      this.isCanSave = false;
      this.errors.items = this.translate.instant('THIS_AREA_IS_NOT_NULL');
    }
    if (this.shipment.documentNumber === undefined) {
      this.isCanSave = false;
      this.errors.documentNumber = this.translate.instant('THIS_AREA_IS_NOT_NULL');
    }
    if (this.shipment.documentDate === undefined) {
      this.isCanSave = false;
      this.errors.documentDate = this.translate.instant('THIS_AREA_IS_NOT_NULL');
    }
    if (this.shipment.documentType === undefined) {
      this.isCanSave = false;
      this.errors.documentType = this.translate.instant('THIS_AREA_IS_NOT_NULL');
    }

  }

  async save(): Promise<void> {
    this.saveControl();
    delay(100);
    if (this.isCanSave) {
      this.submitted = true;
      this.invoice.expectedDate = this.shipment.documentDate;
      this.invoice.currency = this.currency['@id'];
      delete this.invoice['@context'];
      delete this.invoice['@id'];
      delete this.invoice['@type'];
      delete this.invoice.id;

      this.invoice.items.map((item, i) => {
        this.invoice.items[i].taxRate = this.invoice.items[i].taxRate['@id'];
      });
      await this.http.post(InvoiceModel.IRI, this.invoice).then((res: InvoiceModel) => {
        if (res !== undefined) {
          res.items.map(item => {
            const shipmentItem = new ShipmentItemModel();
            shipmentItem.quantity = item.quantity;
            shipmentItem.invoiceItem = item['@id'];
            this.shipment.items.push(shipmentItem);
          });
          this.shipment.invoice = res['@id'];
          this.shipment.total = this.invoice.total;
          this.http.post(ShipmentModel.IRI, this.shipment).then((response: ShipmentModel) => {
            this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
            this.submitted = false;
            setTimeout(() => {
              this.sidebarService.toggle(false);
            }, 2000);
          });
        }
      }).catch(err => {
      });
    }
  }


}
