<p-card>
  <app-loading-indicator [loading]="loading"></app-loading-indicator>
<div class="p-grid">
  <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'PRICE_REPORTS' | translate}}</h3></div>
  <div class="p-col-6 p-md-6 p-sm-6">
    <p-button icon="pi pi-question" [style]="{marginLeft: '5px', float:'right'}"></p-button>
    <p-button icon="pi pi-arrow-down" [style]="{marginLeft: '5px', float:'right'}"></p-button>
  </div>
</div>
<div class="p-grid">
  <div class="p-col-12 p-md-6 p-lg-2">
    <form (ngSubmit)="load(null, 'search')">
            <span class="p-input-icon-left" [style]="{width:'100%'}">
              <i class="pi pi-search"></i>
              <input type="text" [style]="{width: '100%',height: '45px',border:'none', borderBottom: '2px solid #47bfd4'}"
                     name="search" pInputText placeholder="{{ 'GLOBAL_SEARCH'| translate }}"  [(ngModel)]="parameters.name" (change)="changeNameInput($event)" />
                  <button pButton type="submit" icon="pi pi-search" label="{{'search' | translate}}" class="p-d-none p-button-success p-mr-2 p-mb-4"></button>
          </span>
    </form>
  </div>
  <div class="p-col-12 p-md-6 p-lg-2">
    <p-dropdown [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                [options]="searchContacts" [(ngModel)]="parameters['supplier.id']" (onChange)="changeContact($event)"
                placeholder="{{ 'SELECT_SUPPLIER' | translate}}"
                optionLabel="name" optionValue="id"[filter]="true" filterBy="name">
    </p-dropdown>
  </div>
  <div class="p-col-12 p-md-6 p-lg-2">

  </div>
</div>
<div class="p-grid">
  <div class="p-col-12" style="overflow-x: auto;">
    <p-table #table responsiveLayout="stack" [tableStyle]="{'min-width': '45rem'}"
             [value]="stocks"
             [rows]="parameters.itemsPerPage" [showCurrentPageReport]="true" [resizableColumns]="true"
             styleClass="p-datatable-striped"  columnResizeMode="expand">
      <ng-template pTemplate="header">
        <tr>
          <th>{{ 'STOCK'| translate }}</th>
          <th>{{ 'SUPPLIER'| translate }}</th>
          <th>{{ 'PRICE'| translate }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-row let-i="rowIndex" >
        <tr (click)="openPriceDialog(row)" [style]="{cursor: 'pointer'}">
          <td>{{ row.variants[0].name}}</td>
          <td>{{ row.supplier?.name}}</td>
          <td>{{ row.variants[0].price | countryCurrency}}</td>
        </tr>
      </ng-template>
    </p-table>

  </div>
</div>
</p-card>
<p-dialog [(visible)]="staticsDisplay">
  <h4>{{ stockName }}</h4>
  <p-chart type="bar" [data]="data" [options]="chartOptions" width="700px" height="400px"></p-chart>
</p-dialog>
