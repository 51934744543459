import { Pipe, PipeTransform } from '@angular/core';
import { formatNumber } from '@angular/common'; // Import formatNumber from Angular common

@Pipe({
  name: 'countryCurrency'
})
export class CountryCurrencyPipe implements PipeTransform {

  transform(value: number): string {

    if (value === null || value === undefined) {
      return '';
    }

    // Get the currency data from local storage
    const currencyData = JSON.parse(localStorage.getItem('currency'));
    if (!currencyData) {
      return value.toString(); // Fallback if no currency data is found
    }



    const symbol = currencyData.symbol;
    const code = currencyData.code;
    if (code === 'TRY'){
      const formattedValue = formatNumber(value, 'tr-TR', '1.2-2');
      return `${formattedValue}${symbol}  `;
    }else {
      const formattedValue = formatNumber(value, 'en-US', '1.2-2');
      return `${symbol}${formattedValue} `;
    }
  }
}
