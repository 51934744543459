<p-card>
  <app-loading-indicator [loading]="loading"></app-loading-indicator>
  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'LOCATIONS' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'LOCATIONS'"></app-page-info>
      <button pButton [style]="{marginLeft: '5px', float:'right'}" label="{{'NEW' | translate}}" icon="pi pi-plus"
              (click)="openForm()"></button>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-2">
      <div class="p-inputgroup" [style]="{width: '100%'}">
        <form (ngSubmit)="load()">
            <span class="p-input-icon-left" [style]="{width:'100%'}">
              <i class="pi pi-search"></i>
              <input type="text" name="globalSearch" [style]="{width:'100%'}" pInputText
                     placeholder="{{ 'GLOBAL_SEARCH'| translate }}"
                     [(ngModel)]="parameters.name"/>
              <button pButton type="submit" icon="pi pi-search" label="{{'search' | translate}}"
                      class="p-d-none p-button-success p-mr-2 p-mb-4"></button>
          </span>
        </form>
      </div>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12" style="overflow-x: auto;margin-top:20px">
      <p-treeTable [loading]="loading" [lazy]="true" [value]="data" *ngIf="!loading"
                   [tableStyle]="{'min-width': '50rem'}" styleClass="p-datatable-striped">
        <ng-template pTemplate="header">
          <tr>
            <th [style]="{width:'3%'}">
            </th>
            <th [style]="{width:'8%'}">{{'CODE' | translate}}
              <app-tooltip description="LOCATION_NAME_TOOLTIP"></app-tooltip>
            </th>
            <th [style]="{width:'25%'}">{{'LOCATION' | translate}}
              <app-tooltip description="LOCATION_NAME_TOOLTIP"></app-tooltip>
            </th>
            <th [style]="{width:'15%'}">{{'ACCOUNTING_CODE' | translate}}
              <app-tooltip description="LOCATION_NAME_TOOLTIP"></app-tooltip>
            </th>
            <th [style]="{width:'25%'}">{{'LOCATION_ID' | translate}}
              <app-tooltip description="LOCATION_NAME_TOOLTIP"></app-tooltip>
            </th>
            <th [style]="{width:'10%'}">{{'ADDRESS' | translate}}
              <app-tooltip description="LOCATION_ADDRESS_TOOLTIP"></app-tooltip>
            </th>
            <th [style]="{width:'12%'}">{{'INTEGRATIONS' | translate}}
              <app-tooltip description="LOCATION_FUNCTIONS_TOOLTIP"></app-tooltip>
            </th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowNode let-rowData="rowData">
          <tr [ttRow]="rowNode">
            <td class="p-text-left">
              <p-treeTableToggler [rowNode]="rowNode"></p-treeTableToggler>
            </td>
            <td class="p-text-left">
              {{rowData.code}}
            </td>
            <td><a href="javascript:void(0)" [style]="{color: '#05c6e0'}" (click)="edit(rowData)">
              {{rowData.name}}<br><span [style]="{fontSize:'10px'}">{{rowData.contact?.name}}</span>
            </a></td>
            <td>{{ rowData.accountingCode}}</td>
            <td>{{rowData.storeId}}</td>
            <td><i class="pi pi-pencil"
                   (click)="openAddressDialog(rowData.id, rowData.address)"></i> {{ 'ADDRESS'|translate}} </td>
            <td>
              <p-button label="{{ 'INTEGRATIONS' | translate}}" icon="pi pi-plus" (click)="addAccounting(rowData)"
                        styleClass="p-button-sm"></p-button>
            </td>
          </tr>
        </ng-template>
      </p-treeTable>

      <div class="p-grid" style="padding-top: 20px" *ngIf="moreLoading">
        <div class="p-col-4 p-offset-4" >
          <button pButton label="{{ 'MORE' | translate}}" class="p-ml-3 p-mb-3" [style]="{width:'100%'}"
                  [icon]="'pi pi-arrow-down'"
                  (click)="load()"></button>
        </div>
      </div>
    </div>
  </div>
</p-card>
<p-sidebar [(visible)]="showForm" position="right" [modal]="true" styleClass="p-sidebar-md">
  <div class="p-fluid">
    <h2>{{'LOCATION_CREATE' | translate}}</h2>
    <div class="p-field">
      <label for="name">{{ 'SELECT_PARENT' | translate }}</label>
      <p-dropdown [options]="items" (onChange)="getParentNumber($event)" filter="true" appendTo="body"
                  [placeholder]="'SELECT_PARENT' | translate" [required]="false" [(ngModel)]="item.parent"
                  optionLabel="name" optionValue="@id"></p-dropdown>
    </div>
    <div class="p-field"><label for="name">{{'CODE' | translate}}</label>
      <span class="p-float-label p-input-icon-right">
        <i class="pi pi-spin pi-spinner" *ngIf="loading || codeLoading"></i>
            <input type="text" name="code" id="code" pInputText [(ngModel)]="item.code"
                   [disabled]="loading || codeLoading">
        </span>
      <small class="p-error">{{ errors['code'] }}</small>

    </div>

    <div class="p-field"><label for="name">{{'ACCOUNTING_CODE' | translate}}</label>
      <span class="p-float-label p-input-icon-right">
            <input type="text" name="accountingCodes" id="accountingCodes" pInputText [(ngModel)]="item.accountingCode">
        </span>
      <small class="p-error">{{ errors['code'] }}</small>

    </div>

    <div class="p-field">
      <label for="name">{{'LOCATION' | translate}}
        <app-tooltip description="LOCATION_NAME_TOOLTIP"></app-tooltip>
      </label>
      <input id="name" name="LocationName" type="text" pInputText [(ngModel)]="item.name" autofocus>
      <small class="p-error">{{ errors['name'] }}</small>
    </div>
    <div class="p-field">
      <label for="name">{{'LOCATION_ID' | translate}}
        <app-tooltip description="LOCATION_NAME_TOOLTIP"></app-tooltip>
      </label>
      <input id="storeId" name="storeId" type="text" pInputText [(ngModel)]="item.storeId" autofocus>
    </div>
    <div class="p-grid">
      <div class="p-col-10">
        <label for="name">{{'HEAD_LOCATION' | translate}}
          <app-tooltip description="HEAD_LOCATION_TOOLTIP"></app-tooltip>
        </label>
      </div>
      <div class="p-col-2">
        <p-inputSwitch [(ngModel)]="item.isDefault"></p-inputSwitch>
        <small class="p-error">{{ errors['isDefault'] }}</small>
      </div>
    </div>

    <div class="p-field">
      <label for="name">{{'INVENTORY_COUNT_TYPE' | translate}}
        <app-tooltip description="INVENTORY_COUNT_TYPE_TOOLTIP"></app-tooltip>
      </label>
      <p-dropdown placeholder="{{ 'SELECT' | translate }}" [options]="inventoryCountTypes"
                  [(ngModel)]="item.inventoryCountType" appendTo="body" optionValue="value"
                  optionLabel="name"></p-dropdown>
    </div>
    <div class="p-field">
      <label for="name">{{'CONTACT' | translate}}
        <app-tooltip description="CONTACT_TOOLTIP"></app-tooltip>
      </label>
      <p-dropdown placeholder="{{ 'SELECT' | translate }}" [options]="contacts"
                  [(ngModel)]="item.contact" appendTo="body" optionValue="id" filter="true"
                  (onChange)="addContact($event)"
                  optionLabel="name"></p-dropdown>
    </div>

  </div>
  <ng-template pTemplate="footer">
    <div class="p-grid">
      <div class="p-col-6">
        <button pButton label="{{'CANCEL' | translate}}"
                [style]="{width: '100%',background: 'none',color: '#444444', borderColor: '#444444'}" icon="pi pi-times"
                class="p-mr-2" (click)="hideForm()"></button>
      </div>
      <div class="p-col-6">
        <button pButton label="{{'SAVE' | translate}}" [style]="{width: '100%'}"
                [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" [disabled]="this.submitted || this.loading"
                (click)="save()"></button>
      </div>
    </div>
  </ng-template>

</p-sidebar>

<p-confirmPopup></p-confirmPopup>

<!-- Accounting-->
<p-sidebar [(visible)]="showAccounting" *ngIf="loading === false" position="right" [modal]="true"
           [style]="{width:'60em'}">
  <p-tabMenu [model]="tabMenuItems" [activeItem]="activeItem"></p-tabMenu>
  <div *ngIf="activeItem == tabMenuItems[0]">
    <div *ngIf="!loading">
      <div class="p-grid">
        <div class="p-col-12">
          <div *ngFor="let item of integrations">
            <div *ngIf="item.isActive && item.type === 'pos'" class="p-col-12">
              <img [src]="item.logo " width="100" alt="">
              <p-button *ngIf="activeIntegrations[activeLocation + '/' + item.id ]?.integrationId === item.id"
                        (click)="disconnect(activeIntegrations[activeLocation + '/' + item.id ].id)"
                        class="p-button-secondary" [icon]="'pi pi-trash'" styleClass="p-button-secondary p-ml-2"
                        [style]="{float: 'right'}" [label]="'DISCONNECT'|translate">
              </p-button>

              <p-button *ngIf="item.name === 'Linga'" class="p-button-secondary"
                        (click)="connectLinga(activeIntegrations[activeLocation + '/' + item.id ], activeLocation)"
                        [icon]="activeIntegrations[activeLocation + '/' + item.id ]?.integrationId === item.id ? 'pi pi-check': ''"
                        styleClass="p-button-secondary"
                        [style]="{float: 'right'}"
                        [label]="(activeIntegrations[activeLocation + '/' + item.id ]?.integrationId === item.id ? 'CONNECTED': 'CONNECT')|translate">
              </p-button>

              <p-button *ngIf="item.name === 'Poscana'" class="p-button-secondary"
                        (click)="connectPoscana(activeIntegrations[activeLocation + '/' + item.id ], activeLocation)"
                        [icon]="activeIntegrations[activeLocation + '/' + item.id ]?.integrationId === item.id ? 'pi pi-check': ''"
                        styleClass="p-button-secondary"
                        [style]="{float: 'right'}"
                        [label]="(activeIntegrations[activeLocation + '/' + item.id ]?.integrationId === item.id ? 'CONNECTED': 'CONNECT')|translate">
              </p-button>
              <p-button *ngIf="item.name === 'Square'" class="p-button-secondary"
                        (click)="connectSquare(activeIntegrations[activeLocation + '/' + item.id ], activeLocation)"
                        [icon]="activeIntegrations[activeLocation + '/' + item.id ]?.integrationId === item.id ? 'pi pi-check': ''"
                        styleClass="p-button-secondary"
                        [style]="{float: 'right'}"
                        [label]="(activeIntegrations[activeLocation + '/' + item.id ]?.integrationId === item.id ? 'CONNECTED': 'CONNECT')|translate">
              </p-button>
              <p-button *ngIf="item.name === 'Adisyo'" class="p-button-secondary"
                        (click)="connectAdisyo(activeIntegrations[activeLocation + '/' + item.id ], activeLocation, activeLocationName)"
                        [icon]="activeIntegrations[activeLocation + '/' + item.id ]?.integrationId === item.id ? 'pi pi-check': ''"
                        styleClass="p-button-secondary"
                        [style]="{float: 'right'}"
                        [label]="(activeIntegrations[activeLocation + '/' + item.id ]?.integrationId === item.id ? 'CONNECTED': 'CONNECT')|translate">
              </p-button>
              <p-button *ngIf="item.name === 'Paycell'" class="p-button-secondary"
                        (click)="connectPaycell(activeIntegrations[activeLocation + '/' + item.id ], activeLocation)"
                        [icon]="activeIntegrations[activeLocation + '/' + item.id ]?.integrationId === item.id ? 'pi pi-check': ''"
                        styleClass="p-button-secondary"
                        [style]="{float: 'right'}"
                        [label]="(activeIntegrations[activeLocation + '/' + item.id ]?.integrationId === item.id ? 'CONNECTED': 'CONNECT')|translate">
              </p-button>
              <p-button *ngIf="item.name === 'Simpra'" class="p-button-secondary"
                        (click)="connectSimpra(activeIntegrations[activeLocation + '/' + item.id ], activeLocation)"
                        [icon]="activeIntegrations[activeLocation + '/' + item.id ]?.integrationId === item.id ? 'pi pi-check': ''"
                        styleClass="p-button-secondary"
                        [style]="{float: 'right'}"
                        [label]="(activeIntegrations[activeLocation + '/' + item.id ]?.integrationId === item.id ? 'CONNECTED': 'CONNECT')|translate">
              </p-button>
              <p-button *ngIf="item.name === 'Menulux'" class="p-button-secondary"
                        (click)="connectMenulux(activeIntegrations[activeLocation + '/' + item.id ], activeLocation)"
                        [icon]="activeIntegrations[activeLocation + '/' + item.id ]?.integrationId === item.id ? 'pi pi-check': ''"
                        styleClass="p-button-secondary"
                        [style]="{float: 'right'}"
                        [label]="(activeIntegrations[activeLocation + '/' + item.id ]?.integrationId === item.id ? 'CONNECTED': 'CONNECT')|translate">
              </p-button>
    <p-divider></p-divider>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div *ngIf="activeItem == tabMenuItems[1]">
    <div *ngIf="!loading">
      <div class="p-grid">
        <div class="p-col-12">
          <div *ngFor="let item of integrations">
            <div *ngIf="item.isActive && item.type ==='accounting'" class="p-col-12">
              <img [src]="item.logo " width="100" alt="">
              <p-button *ngIf="item.name === 'Luca'" class="p-button-secondary" (click)="openModal(item.id)"
                        [icon]="activeIntegrations[item['@id']] ? 'pi pi-check': ''" styleClass="p-button-secondary"
                        [style]="{float: 'right'}"
                        [label]="(activeIntegrations[item['@id']] ? 'CONNECTED': 'CONNECT')|translate"></p-button>

              <p-button *ngIf="activeIntegrations[activeLocation + '/' + item.id ]?.integrationId === item.id"
                        (click)="disconnect(activeIntegrations[activeLocation + '/' + item.id ].id)"
                        class="p-button-secondary" [icon]="'pi pi-trash'" styleClass="p-button-secondary p-ml-2"
                        [style]="{float: 'right'}" [label]="'DISCONNECT'|translate"></p-button>

              <p-button *ngIf="item.name === 'KolayBi'" class="p-button-secondary" (click)="connectKolayBi()"
                        [icon]="activeIntegrations[item['@id']] ? 'pi pi-check': ''" styleClass="p-button-secondary"
                        [style]="{float: 'right'}"
                        [label]="(activeIntegrations[item['@id']] ? 'CONNECTED': 'CONNECT')|translate"></p-button>

              <p-button *ngIf="item.name === 'Paraşüt'" class="p-button-secondary"
                        (click)="connectParasut(activeIntegrations[activeLocation + '/' + item.id ], activeLocation)"
                        [icon]="activeIntegrations[item['@id']] ? 'pi pi-check': ''" styleClass="p-button-secondary"
                        [style]="{float: 'right'}"
                        [label]="(activeIntegrations[item['@id']] ? 'CONNECTED': 'CONNECT')|translate"></p-button>

              <p-button *ngIf="item.name === 'Efatura'" class="p-button-secondary"
                        (click)="connectEfatura(activeIntegrations[activeLocation + '/' + item.id ], activeLocation)"
                        [icon]="activeIntegrations[activeLocation + '/' + item.id ]?.integrationId === item.id   ? 'pi pi-check': ''"
                        styleClass="p-button-secondary"
                        [style]="{float: 'right'}"
                        [label]="(activeIntegrations[activeLocation + '/' + item.id ]?.integrationId === item.id ? 'CONNECTED': 'CONNECT')|translate"></p-button>
              <p-button *ngIf="item.name === 'Logo'" class="p-button-secondary"
                        (click)="connectLogo(activeIntegrations[activeLocation + '/' + item.id ], activeLocation)"
                        [icon]="activeIntegrations[activeLocation + '/' + item.id ]?.integrationId === item.id ? 'pi pi-check': ''"
                        styleClass="p-button-secondary"
                        [style]="{float: 'right'}"
                        [label]="(activeIntegrations[activeLocation + '/' + item.id ]?.integrationId === item.id ? 'CONNECTED': 'CONNECT')|translate"></p-button>
              <p-button *ngIf="item.name === 'TikoHesap'" class="p-button-secondary"
                        (click)="connectTikoHesap(activeIntegrations[activeLocation + '/' + item.id ], activeLocation)"
                        [icon]="activeIntegrations[activeLocation + '/' + item.id ]?.integrationId === item.id ? 'pi pi-check': ''"
                        styleClass="p-button-secondary"
                        [style]="{float: 'right'}"
                        [label]="(activeIntegrations[activeLocation + '/' + item.id ]?.integrationId === item.id ? 'CONNECTED': 'CONNECT')|translate"></p-button>
              <p-button *ngIf="item.name === 'BizimHesap'" class="p-button-secondary"
                        (click)="connectBizimHesap(activeIntegrations[activeLocation + '/' + item.id ], activeLocation)"
                        [icon]="activeIntegrations[activeLocation + '/' + item.id ]?.integrationId === item.id ? 'pi pi-check': ''"
                        styleClass="p-button-secondary"
                        [style]="{float: 'right'}"
                        [label]="(activeIntegrations[activeLocation + '/' + item.id ]?.integrationId === item.id ? 'CONNECTED': 'CONNECT')|translate"></p-button>

              <p-button *ngIf="item.name === 'LogoIsBasi'" class="p-button-secondary" (click)="connectIsBasi(activeIntegrations[activeLocation + '/' + item.id ], activeLocation)"
                        [icon]="activeIntegrations[item['@id']] ? 'pi pi-check': ''" styleClass="p-button-secondary"
                        [style]="{float: 'right'}"
                        [label]="(activeIntegrations[item['@id']] ? 'CONNECTED': 'CONNECT')|translate"></p-button>
              <p-divider></p-divider>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div *ngIf="activeItem == tabMenuItems[2]">
    <div *ngIf="!loading">
      <div class="p-grid">
        <div class="p-col-12">
          <h3>{{'INTEGRATOR'}}</h3>
          <div *ngFor="let item of integrations">
            <div *ngIf="item.isActive && item.type ==='integrator'" class="p-col-12">
              <img [src]="item.logo " width="100" alt="">
              <p-button *ngIf="item.name === 'Logo'" class="p-button-secondary"
                        (click)="connectLogo(activeIntegrations[activeLocation + '/' + item.id ], activeLocation)"
                        [icon]="activeIntegrations[activeLocation + '/' + item.id ]?.integrationId === item.id ? 'pi pi-check': ''"
                        styleClass="p-button-secondary"
                        [style]="{float: 'right'}"
                        [label]="(activeIntegrations[activeLocation + '/' + item.id ]?.integrationId === item.id ? 'CONNECTED': 'CONNECT')|translate"></p-button>

              <p-button *ngIf="activeIntegrations[activeLocation + '/' + item.id ]?.integrationId === item.id"
                        (click)="disconnect(activeIntegrations[activeLocation + '/' + item.id ].id)"
                        class="p-button-secondary" [icon]="'pi pi-trash'" styleClass="p-button-secondary p-ml-2"
                        [style]="{float: 'right'}" [label]="'DISCONNECT'|translate"></p-button>
              <p-divider></p-divider>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
  <div *ngIf="activeItem == tabMenuItems[3]">

    <div class="p-grid" *ngFor="let main of categories">
      <div class="p-col-12" *ngIf="main.children.length > 0 " [style]="{marginBottom: '200px'}"><h3>{{ main.name }}</h3>
        <hr>
        <p-table [loading]="loading" [lazy]="true" [value]="main.children" *ngIf="!loading"
                 [tableStyle]="{'min-width': '50rem'}">
          <ng-template pTemplate="body" let-item>
            <tr>
              <td [style]="{width:'10%'}">{{ item.code}}</td>
              <td [style]="{width:'60%'}">{{ item.name}}</td>
              <td [style]="{width:'20%'}"><input pInputText name="accountingCode"
                                                 [value]="values[item.id]? values[item.id]: ''" id="accountingCode"
                                                 type="text"
                                                 (change)="saveAccountingCode(item['@id'],$event,activeLocation)"></td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>

  </div>

</p-sidebar>
