// sidebar.service.ts
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {
  private displaySubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  toggle(state?: boolean): void {
      this.displaySubject.next(state);
  }

  getDisplay(): Observable<boolean> {
    return this.displaySubject.asObservable();
  }

}
