<app-loading-indicator [loading]="loading"></app-loading-indicator>
<p-card>

  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'SUBSCRIPTION' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'LOCATIONS_SUBSCRIPTION'"></app-page-info>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 p-md-6 p-lg-2">
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12" style="overflow-x: auto;margin-top:20px">
      <p-checkbox [(ngModel)]="isFilter" [binary]="true" inputId="binary" (onChange)="locationFilter()"></p-checkbox>
      <label for="binary">  {{'OWNER_LOCATIONS' | translate}}</label>
      <p-divider></p-divider>
      <p-table [loading]="loading" [value]="items"
               [lazy]="true" (onLazyLoad)="load($event)"
               [rows]="20"  [totalRecords]="total" [paginator]="true"
                   [tableStyle]="{'min-width': '50rem'}" styleClass="p-datatable-striped">
        <ng-template pTemplate="header">
          <tr>
            <th scope="col" [style]="{width:'20%'}">{{'LOCATION' | translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{'PACKET_NAME' | translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{'PAY_PERIOD' | translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{'START_DATE' | translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{'END_DATE' | translate}}</th>
            <th scope="col"[style]="{width:'10%'}">{{'REMAINING_DAY' | translate}}</th>
            <th scope="col"[style]="{width:'30%'}" colspan="2">{{'ACTIONS' | translate}}</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item>
          <tr>
            <td class="p-text-left">{{item.name}}</td>
            <td class="p-text-left">{{item.packName}}</td>
            <td class="p-text-left">{{item.packPeriod}}</td>
            <td class="p-text-left">{{item.subscription?.startDate | date: 'Y-M-dd'}}</td>
            <td class="p-text-left">{{item.subscription?.endDate | date: 'Y-M-dd'}}</td>
            <td class="p-text-right"><b [style]="">{{calculateDay(item.subscription?.endDate)}} {{ 'DAY'| translate}} </b></td>
            <td class="p-text-right">
              <p-button [style]="{width:'100%'}" *ngIf="calculateDay(item.subscription?.endDate) < 15" label="{{'PAY' | translate}}" (onClick)="showDialog(item)" icon="pi pi-check">
              </p-button>
            </td>
            <td class="p-text-right">
              <p-button *ngIf="item.subscription?.subscriptionReferenceCode" [style]="{width:'100%'}" label="{{'SUBSCRIPTION_DETAIL' | translate}}"
                        (onClick)="subscriptionDetail(item.subscription.subscriptionReferenceCode)" icon="pi pi-check">
              </p-button>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</p-card>
<p-dialog header="{{ 'PAYMENT_TITLE'|translate }}" [(visible)]="displayPay" [style]="{width: '70vw'}">
  <form action="" (submit)="send()">
  <div *ngIf="!plainSelected" class="p-grid">
    <div class="p-col-12">
        <p-tabView [(activeIndex)]="index">
          <p-tabPanel header="Aylık Plan">
            <p-carousel [value]="companyLanguage === 'tr'? monthlyPlansTl : monthlyPlans" [showNavigators]="false" [showIndicators]="false" [numVisible]="3" [numScroll]="3" [circular]="false" >
              <ng-template let-plain let-i="rowIndex" pTemplate="item">
                <div class="product-item p-mt-6">
                  <div class="product-item-content">
                    <div class="plan-area">
                      <div class="title-area">
                        <div class="title p-text-center" >
                          <h2 class="mb-1">{{plain.name}}</h2>
                          <small class=" small">{{ plain.description}}</small>
                        </div>
                        <div class="price">
                          {{plain.price | countryCurrency}}
                        </div>

                        <p>{{ plain.priceSub}}</p>
                      </div>
                      <hr>
                      <div class="list-area" *ngFor="let spect of plain.specification">
                        <div class="list">
                          <div class="button">
                            <button pButton pRipple type="button" icon="pi pi-check" class="p-button-rounded p-button-text"></button>
                          </div>
                          <div class="text">
                            {{ spect.name}}
                          </div>
                        </div>
                      </div>
                      <p-button [style]="{width:'100%'}" label="Seç"  (onClick)="onSelectPlain(plain)"></p-button>
                    </div>
                  </div>
                </div>
              </ng-template>
            </p-carousel>
          </p-tabPanel>
          <p-tabPanel header="Yıllık Plan">
            <p-carousel [value]="companyLanguage === 'tr'? annualPlansTL : annualPlans" [showNavigators]="false" [showIndicators]="false" [numVisible]="3" [numScroll]="3" [circular]="false" >
              <ng-template let-plain pTemplate="item">
                <div class="product-item p-mt-6">
                  <div class="product-item-content">
                    <div class="plan-area">
                      <div class="title-area">
                        <div class="title p-text-center" >
                          <h2 class="mb-1">{{plain.name}}</h2>
                          <small class=" small">{{ plain.description}}</small>
                        </div>
                        <div class="price">
                          {{plain.price | countryCurrency}}
                        </div>

                        <p>{{ plain.priceSub}}</p>
                      </div>
                      <hr>
                      <div class="list-area" *ngFor="let spect of plain.specification">
                        <div class="list">
                          <div class="button">
                            <button pButton pRipple type="button" icon="pi pi-check" class="p-button-rounded p-button-text"></button>
                          </div>
                          <div class="text">
                            {{ spect.name}}
                          </div>
                        </div>
                      </div>
                      <p-button [style]="{width:'100%'}" label="Seç"  (onClick)="onSelectPlain(plain)"></p-button>
                    </div>
                  </div>
                </div>
              </ng-template>
            </p-carousel>
          </p-tabPanel>
        </p-tabView>
    </div>
  </div>
    <div class="alert" *ngIf="error" style="padding: 10px">
      Lütfen Zorunlu Alanları Doldurun.
    </div>
      <div class="p-grid" *ngIf="plainSelected">

        <div class="p-col-6 paymnet-area" style="    padding: 30px;
    border: 2px solid #e9e9e9;
    margin-top: 10px;
    margin-right: 30px;
    width: 46%;
    border-radius: 20px;">
          <h3><b>Firma Bilgileri</b></h3>

          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col">
              <label for="companyName">{{'COMPANY_NAME' | translate}} *</label>
              <input id="companyName" type="text" pInputText [(ngModel)]="card.companyName"  name="companyName">
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col">
              <label for="adress">{{'ADDRESS' | translate}} * </label>
              <input id="adress" type="text" pInputText  [(ngModel)]="card.companyAddress" name="adress">
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col">
              <label for="phone">{{'PHONE_NUMBER' | translate}} *</label>
              <p-inputMask  id="phone" name="phone" mask="(599) 999-99-99" [(ngModel)]="card.phone" placeholder="(500) 000-00-00"></p-inputMask>
            </div>
            <div class="p-field p-col">
              <label for="email">{{'EMAIL' | translate}} *</label>
              <input id="email" type="text" pInputText  [(ngModel)]="card.email" name="email">
            </div>
          </div>

          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col">
              <label for="city">{{'CITY' | translate}} *</label>
              <input id="city" type="text" pInputText  [(ngModel)]="card.city" name="city">
            </div>
            <div class="p-field p-col">
              <label for="zipcode">{{'ZIP_CODE' | translate}} * </label>
              <input id="zipcode" type="text" pInputText  [(ngModel)]="card.zipCode" name="zipcode">
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col">
              <label for="taxOffice">{{'TAX_OFFICE' | translate}} * </label>
              <input id="taxOffice" type="text" pInputText  [(ngModel)]="card.taxOffice" name="taxOffice">
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col">
              <label for="taxNumber">{{'TAX_NUMBER' | translate}} * </label>
              <input id="taxNumber" type="text" pInputText  [(ngModel)]="card.taxNumber" name="taxNumber">
            </div>
          </div>
          {{ 'REQUIRED_AREAS' | translate}}
        </div>
        <div class="p-col-6 paymnet-area" style="    padding: 30px;
            background: #e9e9e9;
            border-radius: 30px;
            margin-top: 10px;">
          <h3><b>Kart Bilgileri</b></h3>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col">
              <label for="cardHolderName">{{'CARD_HOLDER_NAME' | translate}}</label>
              <input id="cardHolderName" type="text" pInputText [(ngModel)]="card.cardHolderName" name="cardHolderName">
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col">
              <label for="cardNumber">{{'CARD_NUMBER' | translate}}</label>
              <p-inputMask id="cardNumber" mask="9999 9999 9999 9999" type="text"  [(ngModel)]="card.cardNumber" name="cardNumber"></p-inputMask>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col">
              <label for="expireMonth">{{'EXPIRY_MONTH' | translate}}</label>
              <p-inputMask id="expireMonth" mask="99" type="text" [(ngModel)]="card.expireMonth" name="expireMonth"></p-inputMask>
            </div>
            <div class="p-field p-col">
              <label for="expireYear">{{'EXPIRY_YEAR' | translate}}</label>
              <p-inputMask id="expireYear" type="text" mask="99" [(ngModel)]="card.expireYear" name="expireYear"></p-inputMask>
            </div>
            <div class="p-field p-col">
              <label for="cvc">{{'CVC' | translate}}</label>
              <p-inputMask id="cvc" type="text" mask="999" [(ngModel)]="card.cvc" name="cvc"></p-inputMask>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col" style="display: flex;align-items: end;">
              <div>
              <label for="promotionCode">{{'PROMOTION_CODE' | translate}}</label>
              <input id="promotionCode" type="text" pInputText  [(ngModel)]="promotionCode" name="promotionCode">
              </div>
              <button style="margin-left: 10px" pButton pRipple type="button" icon="pi pi-search" class="p-button-rounded" (click)="promotionCodeControl()"></button>
            </div>
          </div>
          <span *ngIf="promotionCodeError" class="p-error"> {{'PROMOTION_CODE_ERROR' | translate}}</span>
          <div class="p-fluid p-formgrid p-grid" *ngIf="yapiKrediPromotion">
            <div class="p-field p-col" style="display: flex;align-items: center;">
              <div>
               <img src="assets/img/yapikredi.jpg" alt="yapıkredi" width="150px">
              </div>
              <div style="margin-left: 10px">
                {{'PROMOTION_CODE_YAPI_KREDI' | translate}}
              </div>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid" *ngIf="isBasiPromotion">
            <div class="p-field p-col" style="display: flex;align-items: center;">
              <div>
               <img src="assets/img/isbasi.png" alt="isbasi" width="150px">
              </div>
              <div style="margin-left: 10px">
                {{'PROMOTION_CODE_ISBASI' | translate}}
              </div>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col" style="display: flex;align-items: center;">
              <div style="margin-left: 10px">
                <p><b style="margin-right: 30px">{{'PACK' | translate}}:</b>{{selectedPlain}} {{'PACK' | translate}}</p>
                <p *ngIf="!yapiKrediPromotion && !isBasiPromotion"><b style="margin-right: 40px">{{'PRICE' | translate}}:</b>{{selectedPlainPrice | countryCurrency}}  {{ selectedPlainArray.priceSub}}</p>
                <p *ngIf="yapiKrediPromotion || isBasiPromotion"><b style="margin-right: 40px">{{'PRICE' | translate}}:</b>
                  <span style="text-decoration: line-through;text-decoration-color: red;">
                    {{selectedPlainArray.price | countryCurrency}}</span>  / {{selectedPlainPrice | countryCurrency}}  {{ selectedPlainArray.priceSub}}
                </p>
                <p><b style="margin-right: 40px">{{'AMOUNT_TO_BE_PAID' | translate}}:</b>{{selectedPlainPrice * periodCount | countryCurrency}} </p>

              </div>
            </div>
          </div>
          <p-button [icon]="submitted ? 'pi pi-spin pi-spinner': 'pi pi-check'" [style]="{width:'100%'}"
                    type="submit" label="{{'PAY' | translate}}">
          </p-button>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col">
            </div>
          </div>
        </div>
      </div>
  </form>
</p-dialog>
<p-dialog header="{{ 'SUBSCRIPTION_DETAIL'|translate }}" *ngIf="detailsDisplay" [(visible)]="detailsDisplay" [style]="{width: '70vw'}">
  <div class="p-grid">
    <div class="p-col-12">
      <table style="width: 100%">
        <tr>
          <td style="width: 20%">Paket Adı</td>
          <td style="width: 1%">:</td>
          <td style="width: 79%">{{details.pricingPlanName}}</td>
        </tr>
        <tr>
          <td>Referans Kodu</td>
          <td>:</td>
          <td>{{details.referenceCode}}</td>
        </tr>
        <tr>
          <td>Durum</td>
          <td>:</td>
          <td>{{details.subscriptionStatus | translate}}</td>
        </tr>
        <tr>
          <td>Mail Adresi</td>
          <td>:</td>
          <td>{{details.customerEmail | translate}}</td>
        </tr>
        <tr>
          <td>Telefon</td>
          <td>:</td>
          <td>{{details.customerGsmNumber | translate}}</td>
        </tr>
      </table>
      <p><b>{{ 'SUBSCRIPTION_ORDERS'|translate }}</b></p>
      <p-table [value]="details.orders" >
        <ng-template pTemplate="header">
          <tr>
            <th style="width: 10%">Tarih</th>
            <th style="width: 40%">Referans Kodu</th>
            <th style="width: 10%">Ödeme</th>
            <th style="width: 10%">Başlangış</th>
            <th style="width: 10%">Bitiş</th>
            <th style="width: 15%">Durum</th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-order>
          <tr *ngIf="order.orderStatus === 'SUCCESS' || order.orderStatus ==='FAILED'">
            <td>{{ convertTimestampToDate(order.paymentAttempts[0]?.createdDate)}}</td>
            <td>{{order.referenceCode}}</td>
            <td>{{order.price | countryCurrency}}</td>
            <td>{{convertTimestampToDate(order.startPeriod)}}</td>
            <td>{{convertTimestampToDate(order.endPeriod)}}</td>
            <td style="display: flex; justify-content: space-around;">
              <p-tag *ngIf="order.orderStatus === 'SUCCESS'" styleClass="mr-2" severity="success" value="{{order.orderStatus | translate}}"></p-tag>
              <p-tag *ngIf="order.orderStatus === 'FAILED'" styleClass="mr-2" severity="danger" value="{{order.orderStatus | translate}}"></p-tag>
              <p-tag *ngIf="order.orderStatus === 'FAILED'" styleClass="mr-2" severity="primary" (click)="paymentTrial(order.referenceCode)" value="{{'AGAIN_RETRY' | translate}}"></p-tag>
            </td>
          </tr>
          <tr *ngIf="order.orderStatus === 'FAILED'"  style="background: #d3302f;color: #fbfbfb;">
            <td colspan="6"><i class="pi pi-exclamation-circle"></i> Hata Mesajı : <b>{{order.paymentAttempts[0]?.errorMessage}}</b></td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
</p-dialog>

