import { Component, OnInit } from '@angular/core';
import {HttpService} from '../../../service/http.service';
import {FlashMessageService} from '../../../service/flash-message.service';
import {TranslateService} from '@ngx-translate/core';
import {LocationModel} from '../../../model/location.model';
import {CompanyModel} from '../../../model/company.model';
import {ExpensService} from '../../../service/expens.service';
import {ExpensModel} from '../../../model/expens.model';
import {CreateUnitComponent} from '../../settings/unit/create-unit/create-unit.component';
import {DialogService} from 'primeng/dynamicdialog';
import {CreateExpensComponent} from './create-expens/create-expens.component';
import {StockVariantModel} from '../../../model/stock-variant.model';
import {FlashMessage, FlashMessageModel} from '../../../model/flash-message.model';
import {LocationExpenseModel} from '../../../model/location-expense.model';

@Component({
  selector: 'app-location-expenses',
  templateUrl: './location-expenses.component.html',
  styleUrls: ['./location-expenses.component.scss']
})
export class LocationExpensesComponent implements OnInit {
  items: LocationModel[] = [];
  expenses: ExpensModel[] = [];
  autoCompleteValues: ExpensModel[] = [];
  item = new LocationModel();
  company: CompanyModel = new CompanyModel();
  loading: boolean;
  isFilter: boolean;
  display: boolean;
  userLocations: any;
  submitted: boolean;
  constructor(private http: HttpService,
              private flashMessageService: FlashMessageService,
              private expensesService: ExpensService,
              private dialogService: DialogService,
              private translate: TranslateService) {
  }


  ngOnInit(): void {
    const userLocationsJson = localStorage.getItem('locations');
    const language = localStorage.getItem('language');
    this.userLocations = JSON.parse(userLocationsJson);
    this.load();

    this.http.get(CompanyModel.IRI).subscribe(response => {
      this.company = response;
    });

    this.expensesService.getExpensAction({isActive: true});
    this.expensesService.getExpenses().subscribe(response => {
      this.expenses = response;
    });
  }
  load(): void {
    this.loading = true;
    this.http.get(LocationModel.IRI).subscribe((response: LocationModel[]) => {
      this.items = response['hydra:member'];
      this.loading = false;
    });
  }
  locationFilter(): void {
    if (this.isFilter){
      this.items = this.items.filter((location) => {
        const findIndex = this.userLocations.findIndex(obj => obj.id !== location.id);
        return findIndex === -1;
      });
    }else{
      this.http.get(LocationModel.IRI).subscribe((response: LocationModel[]) => {
        this.items = response['hydra:member'];
      });
    }
  }

  edit(row): void {
    this.item = {...row};
    if (!this.item.expense){
      const newExpense = {
        items: [],
        location: this.item.id,
        total: 0
      };
      this.item.expense = newExpense;
    }
    this.item.expense.items.map((item, i) => {
      this.autoCompleteValues[i] = item.expense;
    });
    this.display = true;
    console.log(this.item.expense);
  }
  addExpenseType(): void {
    const ref = this.dialogService.open(CreateExpensComponent, {
      width: '50vw',
      header: this.translate.instant('EXPENS_CREATE'),
    });
    ref.onClose.subscribe(() => {
      this.ngOnInit();
    });
  }
  addItems(): void {
    const expense = {
      amount: 0,
      expense: null
    };
    this.item.expense.items.push(expense);
  }
  calculate(): void{
    let grandTotal = 0;
    this.item.expense.items.map(item => {
      grandTotal = grandTotal + item.amount;
    });
    this.item.expense.total = grandTotal;
    console.log('');
  }
  searchExpense = event => {
    this.http.get(ExpensModel.IRI, {name: event.query}).subscribe((response: ExpensModel) => {
      this.expenses = response['hydra:member'];
    });
  }
  onExpenseSelect(expense, i: number): void {
    this.item.expense.items[i].expense = expense.value;
    const findIndex = this.expenses.findIndex(obj => obj['@id'] === expense.value);
    this.autoCompleteValues[i] =  this.expenses[findIndex];
    console.log(this.autoCompleteValues[i]);
  }
  async save(): Promise<void> {
    this.submitted = true;
    await this.http.post(`${LocationModel.IRI}/expenses`, this.item.expense).then(response => {
      if (response.status === 'success') {
        this.load();
        this.display = false;
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS'));
        }else {
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Error, response.errorMessage));
      }
      this.submitted = false;
    });
  }

}
