import {ApiResourceModel} from './api-resource.model';
import {StockModel} from './stock.model';
import {StockVariantModel} from './stock-variant.model';
import {TaxRateModel} from './tax-rate.model';
import {CurrencyModel} from './currency.model';
import {WorkOrderModel} from './work-order.model';
import {UserModel} from './user.model';
import {PurchaseOptionModel} from './purchase-option.model';

export class InvoiceItemModel extends ApiResourceModel{
  public static IRI = '/api/invoice_items';
  public stock: StockModel|string;
  public variant: StockVariantModel|string;
  public quantity: number;
  public incomingQuantity: number;
  public workOrderCreatedQuantity: number;
  public remainingQuantity: number;
  public subtotal: number;
  public tax: number;
  public taxRate: any;
  public total: number;
  public unitPrice: number;
  public firstUnitPrice: number;
  public isReceived: boolean;
  public status: string;
  public expectedDate: Date|string;
  public materialStatus: string;
  public deliveryStatus: string;
  public stockExpectedDate: Date|string;
  public productionStatus: string;
  public nonDiscountedUnitPrice: number;
  public isWorkOrderCreated: boolean;
  public normalizedSubtotal: number;
  public normalizedTax: number;
  public normalizedTotal: number;
  public normalizedUnitPrice: number;
  public currency: CurrencyModel|string;
  public notes: string;
  public workOrders: WorkOrderModel[];
  public createdBy: UserModel [] = [];
  public optionOrderType: string;
  public name: string;
  public code: string;
  public taxRateNumber: number;
  public marked: boolean;
  public recipe: number;
  public documentQuantity: number;
  public purchaseOption: PurchaseOptionModel[] = [];
  public discountRate: number;
  public discount: number;
  public type: string;
  public priceChangeAction: string;
  public credit: boolean;
  public accountingCode: boolean;
  optionsValue: PurchaseOptionModel[];
  public locationQuantity: number;
  public barcode: any;
}

export enum DeliveryActions {
  PENDING = 'PENDING',
  PACKAGED = 'PACKAGED',
  DELIVERED = 'DELIVERED',
  PARTIAL_DELIVERED = 'PARTIAL_DELIVERED',
  READY = 'READY',
}
