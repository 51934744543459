<p-card>
  <app-loading-indicator [loading]="loading"></app-loading-indicator>

  <div class="p-grid">
    <div class="p-col-5 p-md-5 p-sm-5"><h3 [style]="{ fontWeight: '600' }">
      {{ 'ORDER_DETAIL' | translate}}</h3></div>
    <div class="p-col-6 p-md-6 p-sm-6 p-offset-1">
      <app-page-info [pageName]="'ORDER_DETAIL'"></app-page-info>
      <p-button icon="pi pi-print" [style]="{marginLeft: '5px', float:'right'}"></p-button>
      <button *ngIf="!inCompanyOrder" pButton pRipple [routerLink]="['/orders/order-history']" [style]="{marginLeft: '5px', float:'right'}"
              icon="pi pi-arrow-left" class="p-button-secondary">
      </button>
      <button *ngIf="inCompanyOrder" pButton pRipple [routerLink]="['/in-company-orders']" [style]="{marginLeft: '5px', float:'right'}"
              icon="pi pi-arrow-left" class="p-button-secondary">
      </button>
      <button pButton label="{{ 'SAVE' | translate}}" class="p-ml-3 p-mb-3" *ngIf="!inCompanyOrder"
              [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
              [style]="{marginLeft: '5px', float:'right'}"
              [disabled]="this.submitted || invoice.isReceived"
              (click)="save()">
      </button>

    </div>
  </div>
  <div *ngIf="!inCompanyOrder" class="p-grid">

    <div class="p-col-12 p-md-6 p-lg-2"
         *ngIf="invoice.isReceived === false && invoice.status == 'CONFIRMED' || invoice.status == 'CONFIRMED_SUPPLIER' || invoice.status == 'VIEWED_SUPPLIER'">
      <button pButton pRipple [style]="{width:'100%'}" label="{{ 'RECEIVE_WITH_WAYBILL' | translate }}" [disabled]="agencyNumber"
              (click)="onshowWaybillDialog()"></button>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2"
         *ngIf="invoice.isReceived === false && invoice.status == 'CONFIRMED' || invoice.status == 'CONFIRMED_SUPPLIER' || invoice.status == 'VIEWED_SUPPLIER'">
      <button pButton pRipple [style]="{width:'100%'}" label="{{ 'RECEIVE_WITH_INVOICE' | translate }}" [disabled]="agencyNumber"
              (click)="onshowInvoiceDialog()" ></button>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2" *ngIf="invoice.status == 'WAITING_FOR_APPROVAL'">
      <button pButton pRipple [style]="{width:'100%'}" label="{{ 'CONFIRM_ORDER' | translate}}"
              icon="{{ submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'}}" (click)="approveOrder()"></button>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2" *ngIf="invoice.isReceived">
      <button pButton pRipple [style]="{width:'100%'}" label="{{ 'VIEW_INVOICE' | translate}}"
              [routerLink]="['/order/receive-manuel/'+ invoice.id]"></button>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2" *ngIf="invoice.isReceived === false">
      <button pButton pRipple [style]="{width:'100%'}" label="{{ 'CANCEL_ORDER' | translate}}" (click)="confirm()"
              [disabled]="invoice.isReceived" ></button>
    </div>
  </div>
  <div  *ngIf="inCompanyOrder"class="p-grid">

    <div class="p-col-12 p-md-6 p-lg-2">
      <button pButton pRipple [style]="{width:'100%'}" label="{{ 'CREATE_SALE_INVOICE' | translate }}" [disabled]="agencyNumber"
              (click)="createSaleInvoice()"></button>
    </div>
  </div>
  <div class="p-col alert-div p-mt-3" *ngIf="agencyNumber && invoice.status != 'WAITING_FOR_APPROVAL'">
    <b> {{ 'ALERT' | translate}}!</b> {{'CONFIRM_ORDER_ALERT_AGANCY_NUMBER'| translate }}
  </div>
  <div class="p-grid grid-div p-mt-3" >
    <div class="p-col div-sums">
      <table>
        <tr>
          <td [style]="{width: '30%'}"><b
            class="fw-500">{{ (invoice.status === invoiceStatus.Pending ? 'QUOTE_NUMBER' : 'ORDER_NUMBER')|translate}}</b>
          </td>
          <td [style]="{width: '1%'}">:</td>
          <td [style]="{width: '70%'}"><input [class.border-0]="true" type="text" pInputText
                                              [style]="{background: 'none',boxShadow:'none',border:'none',width:'50px',float: 'left'}"
                                              [(ngModel)]="invoice.code" [disabled]="invoice.isReceived">
            <small class="p-error">{{ errors['code'] }}</small>
          </td>
        </tr>
        <tr>
          <td><b class="fw-500">{{ 'LOCATION'|translate}}</b></td>
          <td>:</td>
          <td>
            <p-dropdown [ngModel]="invoice.location" [options]="locations"
                        [style]="{background: 'none',boxShadow:'none',border:'none',width:'50px',float: 'left'}"
                        name="location" optionLabel="name" optionValue="@id"></p-dropdown>
          </td>
        </tr>
        <tr  *ngIf="invoice.status == 'WAITING_FOR_APPROVAL'">
          <td><b class="fw-500">{{ 'SUPPLIER'|translate}}</b></td>
          <td>:</td>
          <td>
            <p-dropdown [ngModel]="invoice.contact" [options]="contacts"
                        [style]="{background: 'none',boxShadow:'none',border:'none',width:'50px',float: 'left'}"
                        name="contact" optionLabel="name" optionValue="@id" (onChange)="invoiceSave($event)"></p-dropdown>
          </td>
        </tr>
        <tr>
          <td><b class="fw-500">{{ 'DELIVERY_TIME'|translate}}</b></td>
          <td>:</td>
          <td>
            <p-calendar [(ngModel)]="invoice.expectedDate"
                        [style]="{background: 'none',boxShadow:'none',border:'none',width:'50px',float: 'left'}"
                        [disabled]="invoice.isReceived">
            </p-calendar>
          </td>
        </tr>
        <tr>
          <td><b class="fw-500"> {{ 'ITEM_COUNT' | translate}}</b></td>
          <td>:</td>
          <td>{{ invoice.items.length }}</td>
        </tr>
        <tr>
          <td><b class="fw-500"> {{ 'TOTAL_EXL_TAX' | translate}}</b></td>
          <td>:</td>
          <td [style]="{textAlign:'right',fontSize:'35px'}">{{ invoice.total | countryCurrency }}</td>
        </tr>
      </table>
    </div>
    <div class="p-col div-sums">
      <table>
        <tr>
          <td [style]="{width: '30%'}"><b class="fw-500">{{'SEND_BY' | translate}}:</b></td>
          <td [style]="{width: '1%'}">:</td>
          <td [style]="{width: '70%'}">{{ createdUserName }}</td>
        </tr>
        <tr>
          <td><b class="fw-500">{{ 'STATUS' | translate}}</b></td>
          <td>:</td>
          <td><i *ngIf="invoice.status === 'CONFIRMED'" class="pi pi-check-circle" [style]="{color:'#55c416'}"></i>
            <b> {{ invoice.status | translate }}</b></td>
        </tr>
        <tr>
          <td><b class="fw-500">{{'CONFIRM_USER' | translate}}:</b></td>
          <td>:</td>
          <td>{{ createdUserName }}</td>
        </tr>
        <tr>
          <td colspan="3">
            <div class="inside-div" *ngIf="!inCompanyOrder">
              <img class="inside-img" src="assets/img/truck-delivery.png"/>
              <table>
                <tr>
                  <td [style]="{width: '30%'}"><b class="fw-500">{{'SUPPLIER' | translate}}:</b></td>
                  <td [style]="{width: '1%'}">:</td>
                  <td [style]="{width: '70%'}"><b class="fw-500"></b> {{ supplierName }}</td>
                </tr>
                <tr>
                  <td><b class="fw-500">{{'TAX_AGENCY_NUMBER' | translate}}:</b></td>
                  <td>:</td>
                  <td *ngIf="!agencyNumber"> <b class="fw-500"></b> {{ supplierTCKN }}</td>
                  <td *ngIf="agencyNumber && invoice.status != 'WAITING_FOR_APPROVAL'">
                    <input [class.border-0]="true" type="text" pInputText
                           [style]="{boxShadow:'none',border:'none',width:'60% !important',float: 'left'}"
                           [(ngModel)]="supplierTCKN">
                    <br>
                    <small class="p-error" *ngIf="agencyNumberNotNull">{{'TAX_AGENCY_NUMBER_NOT_NULL' | translate}}</small>
                  </td>
                </tr>
                <tr *ngIf="!agencyNumber">
                  <td><b class="fw-500">{{'SUPPLIER_EMAIL' | translate}}</b></td>
                  <td>:</td>
                  <td>{{ supplierEmail }}</td>
                </tr>
                <tr *ngIf="agencyNumber && invoice.status != 'WAITING_FOR_APPROVAL'">
                  <td><b class="fw-500">{{'SUPPLIER_EMAIL' | translate}}</b></td>
                  <td>:</td>
                  <td><input [class.border-0]="true" type="text" pInputText
                             [style]="{boxShadow:'none',border:'none',width:'60% !important',float: 'left'}"
                             [(ngModel)]="supplierEmail">
                    <button type="button" (click)="addAgencyNUmber(supplierTCKN,supplierId)"
                            pButton
                            pRipple icon="pi pi-check" styleClass="p-button-success"></button>
                    <br>
                    <small class="p-error" *ngIf="agencyEmailNotNull">{{'TAX_AGENCY_EMAIL_NOT_NULL' | translate}}</small>
                  </td>
                </tr>

                <tr>
                  <td><b class="fw-500">{{'ORDER_SENT_ON' | translate}}</b></td>
                  <td>:</td>
                  <td>
                    <b *ngIf="invoice.status === 'CONFIRMED'">
                      <i class="pi pi-check-circle" [style]="{color:'#55c416'}"></i> {{ 'YES' | translate}}
                      <p-button label="{{'AGAIN_SEND_MAIL' | translate}}"  (onClick)="againSendMail(invoice.id)" icon="pi pi-refresh" styleClass="p-button-sm"></p-button>
                    </b>
                    <b *ngIf="invoice.status === 'WAITING_FOR_APPROVAL'">{{ 'NO' | translate}}</b>
                  </td>
                </tr>
              </table>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
  <div class="p-grid">
  </div>

  <div class="p-grid p-mt-3">
    <div class="p-col-12">
      <div class="show-elements">
        <p-checkbox [(ngModel)]="showQuantities" [binary]="true" id="price" inputId="binary"></p-checkbox>
        <label for="price"> {{'SHOW_STOCK_QUANTITY' | translate}}</label>
      </div>
    </div>
    <div class="p-col-12" style="overflow-x: auto;margin-top:20px">
      <p-table [value]="invoice.items" [loading]="submitted" dataKey="id" responsiveLayout="scroll"
               styleClass="p-datatable-striped p-datatable-sm" #table [tableStyle]="{'min-width': '65rem'}">
        <ng-template pTemplate="header">
          <tr>
            <th scope="col" [style]="{width: '10%'}">{{ 'CODE' | translate}}
              <app-tooltip [description]="'TOOLTIP_CODE'"></app-tooltip>
            </th>
            <th scope="col" [style]="{width: '25%'}">{{ 'STOCK' | translate}}
              <app-tooltip [description]="'TOOLTIP_STOCK'"></app-tooltip>
            </th>
            <th *ngIf="showQuantities" scope="col" [style]="{width: '10%'}">{{ 'STOCK_QUANTITY' | translate}}
              <app-tooltip [description]="'TOOLTIP_STOCK'"></app-tooltip>
            </th>
            <th scope="col" [style]="{width: '10%'}">{{ 'ORDER_QUANTITY' | translate}}
              <app-tooltip [description]="'TOOLTIP_QUANTITY'"></app-tooltip>
            </th>
            <th scope="col" [style]="{width: '10%'}">{{ 'UNIT_PRICE' | translate}}
              <app-tooltip [description]="'TOOLTIP_UNIT_PRICE'"></app-tooltip>
            </th>
            <th scope="col" [style]="{width: '10%'}">{{ 'DISCOUNT_RATE' | translate}}
              <app-tooltip [description]="'TOOLTIP_UNIT_PRICE'"></app-tooltip>
            </th>
            <th scope="col" [style]="{width: '10%'}">{{ 'DISCOUNT' | translate}}
              <app-tooltip [description]="'TOOLTIP_UNIT_PRICE'"></app-tooltip>
            </th>
            <th scope="col" [style]="{width: '10%'}">{{ 'TOTAL_PRICE' | translate}}
              <app-tooltip [description]="'TOOLTIP_TOTAL_PRICE'"></app-tooltip>
            </th>
            <th scope="col" [style]="{width: '8%'}">{{ 'TAX_RATE' | translate}}
              <app-tooltip [description]="'TOOLTIP_TAX'"></app-tooltip>
            </th>
            <th scope="col" [style]="{width: '8%'}">{{ 'TAX' | translate}}
              <app-tooltip [description]="'TOOLTIP_TAX'"></app-tooltip>
            </th>
            <th scope="col" [style]="{width: '5%'}"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-i="rowIndex">
          <tr>
            <td>
              {{ item.purchaseOption === null ? autoCompleteValues[i] ? autoCompleteValues[i].code : '' : item.purchaseOption.code }}
            </td>
            <td pEditableColumn>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-autoComplete [(ngModel)]="autoCompleteValues[i]" [disabled]="item.isReceived"
                                  (onSelect)="onVariantSelect($event, i); setStatus(item.variant, item.quantity, i)"
                                  field="name" [suggestions]="variants" appendTo="body" au
                                  (completeMethod)="searchVariant($event)"></p-autoComplete>
                </ng-template>
                <ng-template pTemplate="output">
                  {{ item.purchaseOption !== null ? item.orderType : '' }}
                  <!--- <i class="pi pi-link" (click)="edit(autoCompleteValues[i])"></i>--->
                  {{ item.purchaseOption === null ? autoCompleteValues[i] ? autoCompleteValues[i].name : '' : item.purchaseOption.name }}
                </ng-template>
              </p-cellEditor>

            </td>
            <td *ngIf="showQuantities" [style]="{textAlign: 'right'}">{{ item.locationQuantity}}</td>
            <td [style]="{textAlign: 'right'}" pEditableColumn class="{{ hasErrors('quantity', i) ? 'has-error': '' }}">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-inputNumber mode="decimal" minFractionDigits="2" maxFractionDigits="2" [(ngModel)]="item.quantity"
                                 [min]="item.incomingQuantity" [disabled]="item.isReceived"
                                 (ngModelChange)=" setStatus(item.variant, item.quantity, i); calculateTotalPrice(i)"></p-inputNumber>
                </ng-template>
                <ng-template pTemplate="output">
                  {{ item.quantity}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td  [style]="{textAlign: 'right'}" pEditableColumn class="{{ hasErrors('unitPrice', i) ? 'has-error': '' }}">
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-inputNumber [(ngModel)]="item.unitPrice" mode="decimal" minFractionDigits="3" maxFractionDigits="3"
                                 [disabled]="item.isReceived || discountLoading"
                                 (ngModelChange)="item.nonDiscountedUnitPrice = item.unitPrice; calculateTotalPrice(i)"></p-inputNumber>
                </ng-template>
                <ng-template pTemplate="output">
                  {{item.unitPrice|currency: currencyLabel: '': '1.3-3'}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td [style]="{textAlign: 'right'}" pEditableColumn>

              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-inputNumber [(ngModel)]="item.discountRate" mode="decimal" minFractionDigits="2" maxFractionDigits="2"
                                 [disabled]="item.isReceived || discountLoading"
                                 (ngModelChange)="item.nonDiscountedUnitPrice = item.unitPrice; calculateTotalPrice(i)"></p-inputNumber>
                </ng-template>
                <ng-template pTemplate="output">
                  % {{item.discountRate}}
                </ng-template>
              </p-cellEditor>

            </td>
            <td [style]="{textAlign: 'right'}">
              {{item.discount| countryCurrency}}
            </td>

            <td [style]="{textAlign: 'right'}">
              {{item.subtotal| countryCurrency}}
            </td>
            <td pEditableColumn>
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <p-dropdown [options]="taxRates" [(ngModel)]="item.taxRate" appendTo="body"
                              (onChange)="calculateTotalPrice(i); setTax(i, $event)" optionLabel="name" id="tax"
                              optionValue="@id"></p-dropdown>
                </ng-template>
                <ng-template pTemplate="output">
                  {{taxes.length > i ? taxes[i]?.name : ''}}
                </ng-template>
              </p-cellEditor>
            </td>
            <td [style]="{textAlign: 'right'}">
              {{item.tax| countryCurrency}}
            </td>
            <td [style]="{textAlign: 'right'}">
              <button pButton label="" *ngIf="invoice.type == invoiceTypes.Sales && invoice.id !== undefined"
                      routerLink="/work-orders/create"
                      [state]="{description: item.notes, quantity: item.quantity - item.workOrderCreatedQuantity, invoice: invoice['@id'], invoiceItem: item['@id'], variant: autoCompleteValues[i]}"
                      icon="pi pi-cog" class="p-button-outlined p-mr-2" iconPos="left"></button>
              <button [disabled]="item.isReceived || item.isWorkOrderCreated" pButton label="" icon="pi pi-trash"
                      class="p-button-outlined p-button-danger" (click)="removeItem(i)" iconPos="left"></button>
            </td>
          </tr>
        </ng-template>
      </p-table>
      <button pButton *ngIf="invoice.status == 'WAITING_FOR_APPROVAL'" label="{{'ADD_STOCK' | translate}}"
              class="p-mb-3 add-button p-mt-6" [icon]="'pi pi-plus'"
              (click)="addItem()" [disabled]="invoice.isReceived"></button>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12">
      <table class="total-box p-p-3">
        <tr>
          <th scope="col">{{ 'TOTAL' | translate}}</th>
          <td>{{ invoice.subtotal|currency: currencyLabel }}</td>
        </tr>
        <tr>
          <th scope="col">{{ 'DISCOUNT' | translate}}</th>
          <td>{{ invoice.discount|currency: currencyLabel }}</td>
        </tr>
        <tr>
          <th scope="col">{{ 'SUBTOTAL' | translate}}</th>
          <td>{{ invoice.subtotal - invoice.discount|currency: currencyLabel }}</td>
        </tr>
        <tr>
          <th scope="col">{{ 'TAX' | translate}}</th>
          <td>{{ invoice.tax|currency: currencyLabel }}</td>
        </tr>
        <tr>
          <th scope="col">{{ 'GRAND_TOTAL' | translate}}</th>
          <td>{{ invoice.total|currency: currencyLabel }}</td>
        </tr>
      </table>
    </div>
  </div>
</p-card>


<p-dialog [(visible)]="showWaybillDialog" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '50vw'}">

  <div class="p-grid">
    <div class="p-col-2">
      <b class="fw-500">{{'SUPPLIER' | translate}} :</b>
    </div>
    <div class="p-col-6">
      {{ supplierName}}
    </div>
  </div>

  <div class="p-grid">
    <div class="p-col-2">
      <b class="fw-500">{{'CODE' | translate}} :</b>
    </div>
    <div class="p-col-6">
      {{ invoice.code}}
    </div>
  </div>
  <div class="card">
    <p-fileUpload name="demo[]" url="./upload.php" (onUpload)="onUpload($event)"
                  multiple="multiple" accept="image/*" maxFileSize="1000000">
      <ng-template pTemplate="content">
        <ul *ngIf="uploadedFiles.length">
          <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
        </ul>
      </ng-template>
    </p-fileUpload>

    <div class="p-grid" style="margin-top:50px">
      <div class="p-col-6">
        <button pButton class="p-button-raised p-button-secondary"
                [style]="{width:'100%', background: 'none', color: '#444444', borderColor: '#444444'}"
                label="{{ 'RECEIVE_WITH_WAYBILL_SCAN' | translate}}" [style]="{width:'100%'}"></button>
      </div>
      <div class="p-col-6">
        <button pButton [routerLink]="['/order/receive-manuel/'+ invoice.id, {type: 'INVOICE'}]"
                [style]="{width:'100%'}" label="{{ 'RECEIVE_MANUEL' | translate}}"></button>
      </div>
    </div>

  </div>
</p-dialog>

<p-dialog [(visible)]="showInvoiceDialog" [breakpoints]="{'960px': '75vw', '640px': '100vw'}" [style]="{width: '70vw'}">
  <div class="p-grid">
    <div class="p-col-2">
      <b class="fw-500">{{'SUPPLIER' | translate}} :</b>
    </div>
    <div class="p-col-6">
      {{ supplierName}}
    </div>
  </div>

  <div class="p-grid">
    <div class="p-col-2">
      <b class="fw-500">{{'CODE' | translate}} :</b>
    </div>
    <div class="p-col-6">
      {{ invoice.code}}
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-8">
      <h2 class="p-d-inline-block">
        <i class="pi pi-users" style="font-size: 1.5rem; margin-right: 10px"></i>
        {{ 'GET_CONTACT_INVOICES'|translate }}
      </h2>
      <p-button styleClass="p-button-secondary p-d-inline-block p-mt-3"
                [disabled]="invoiceLoading" [style]="{'float': 'right' }"
                label="{{'LOAD_INCOMING_INVOICE' | translate}}" [icon]="invoiceLoading ? 'pi pi-spin pi-spinner' : 'pi pi-spinner'"
                (click)="getIncomingEinvoices(supplierId)"></p-button>
    </div>
    <div class="p-col-12">
      <p-table [value]="inComingInvoices">
        <ng-template pTemplate="header">
          <tr>
            <th scope="col" [style]="{width:'10%'}">{{'DATE' | translate}}</th>
            <th scope="col" [style]="{width:'14%'}">{{'DOCUMENT_NUMBER' | translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{'SUB_TOTAL' | translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{'DISCOUNT' | translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{'TAX' | translate}}</th>
            <th scope="col" [style]="{width:'10%'}">{{'TOTAL' | translate}}</th>
            <th scope="col" [style]="{width:'5%'}"></th>
            <th scope="col" [style]="{width:'10%'}"></th>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-item let-i ="rowIndex">
          <tr [style]="selectedRow === i ? ' background: #ced4da' : ''">
            <td>{{ item.invoiceDate | date: 'Y-MM-dd'  }}</td>
            <td>{{ item.invoiceNumber }}</td>
            <td>{{ item.subtotal | countryCurrency}}</td>
            <td>{{ item.discount | countryCurrency}}</td>
            <td>{{ item.tax | countryCurrency}}</td>
            <td>{{ item.total | countryCurrency}}</td>
            <td><i class="pi pi-eye" (click)="viewInvoiceItems(item.relatedId, i , item.currencyCode )"></i></td>
            <td><p-button label="{{ 'CHOSE_INVOICE' | translate}}" (onClick)="setIncomingInvoice(invoice.id, item.id, i )"></p-button></td>
          </tr>
        </ng-template>
      </p-table>
    </div>
  </div>
  <div class="card">
    <p-fileUpload name="demo[]" url="./upload.php" (onUpload)="onUpload($event)"
                  multiple="multiple" accept="image/*" maxFileSize="1000000">
      <ng-template pTemplate="content">
        <ul *ngIf="uploadedFiles.length">
          <li *ngFor="let file of uploadedFiles">{{file.name}} - {{file.size}} bytes</li>
        </ul>
      </ng-template>
    </p-fileUpload>

    <div class="p-grid" style="margin-top:50px">
      <div class="p-col-6">
        <button pButton class="p-button-raised p-button-secondary"
                [style]="{width:'100%', background: 'none', color: '#444444', borderColor: '#444444'}"
                label="{{ 'RECEIVE_WITH_INVOICE_SCAN' | translate}}" [style]="{width:'100%'}"></button>
      </div>
      <div class="p-col-6">
        <!---
        <button pButton [routerLink]="['/order/receive-manuel/'+ invoice.id, {type: 'INVOICE'}]"
                [style]="{width:'100%'}" label="{{ 'RECEIVE_MANUEL' | translate}}"
                [disabled]="selectedRow === undefined">
        </button>
       ---->
        <button pButton [routerLink]="['/order/receive-manuel/'+ invoice.id, {type: 'INVOICE'}]"
                [style]="{width:'100%'}" label="{{ 'RECEIVE_MANUEL' | translate}}"
                >
        </button>
      </div>
    </div>

  </div>
</p-dialog>

<p-toast></p-toast>
<p-confirmDialog #cd header="{{ 'ARE_YOU_SURE' | translate }}" icon="pi pi-exclamation-triangle">
  <ng-template pTemplate="footer">
    <button type="button" pButton icon="pi pi-times" label="{{'NO'| translate}}" (click)="cd.reject()"></button>
    <button type="button" pButton icon="pi pi-check" label="{{'YES' | translate}}" (click)="cd.accept()"></button>
  </ng-template>
</p-confirmDialog>

<p-sidebar [(visible)]="display" [style]="{width:'90em'}" position="right" (onHide)="reload()">
  <app-new-material-update *ngIf="displayUpdate" [item]="item" [loading]="true" [type]="type"></app-new-material-update>
  <app-new-material-create *ngIf="displayCreate" [type]="type" [item]="item"></app-new-material-create>
</p-sidebar>


<p-dialog header="Title" [(visible)]="invoiceItemsShow" [style]="{width: '60vw'}">
  <p-table [value]="invoiceItems.items" *ngIf="invoiceItemsShow" [tableStyle]="{ 'min-width': '50rem' }">
    <ng-template pTemplate="header">
      <tr>
        <th scope="col" [style]="{width:'30%'}">{{'NAME' | translate}}</th>
        <th scope="col" [style]="{width:'10%'}">{{'QUANTITY' | translate}}</th>
        <th scope="col" [style]="{width:'10%'}">{{'PRICE' | translate}}</th>
        <th scope="col" [style]="{width:'10%'}">{{'SUB_TOTAL' | translate}}</th>
        <th scope="col" [style]="{width:'10%'}">{{'DISCOUNT_RATE' | translate}}</th>
        <th scope="col" [style]="{width:'10%'}">{{'DISCOUNT_TOTAL' | translate}}</th>
        <th scope="col" [style]="{width:'10%'}">{{'TOTAL' | translate}}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-item>
      <tr>
        <td>{{ item.productName }}</td>
        <td>{{ item.quantity}} {{ item.unit}}</td>
        <td>{{ item.price | countryCurrency}}</td>
        <td>{{ item.subTotal | countryCurrency}}</td>
        <td>{{ item.discountRate | countryCurrency}}</td>
        <td>{{ item.discountAmount | countryCurrency}}</td>
        <td>{{ item.subTotal | countryCurrency}}</td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>


<p-dialog [(visible)]="displayAction" [style]="{width: '50vw'}">
  <div style="display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;">
    <i class="pi pi-ban" [style]="{color:'#fe8a8a', fontSize:'80px'}"></i>
    <p [style]="{width: '300px', color:'#fe8a8a', textAlign: 'center'}">{{'UNAUTHORIZED_TRANSACTION'| translate}}</p>
  </div>
</p-dialog>
