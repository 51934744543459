import { Component, OnInit } from '@angular/core';
import {FlashMessageService} from '../../../../service/flash-message.service';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import {ValidationService} from '../../../../service/validation.service';
import {FlashMessage, FlashMessageModel} from '../../../../model/flash-message.model';
import {ExpensModel} from '../../../../model/expens.model';
import {ExpensService} from '../../../../service/expens.service';

@Component({
  selector: 'app-create-expens',
  templateUrl: './create-expens.component.html',
  styleUrls: ['./create-expens.component.scss']
})
export class CreateExpensComponent implements OnInit {



  item: ExpensModel = new ExpensModel();
  submitted: boolean;
  errors = [];
  expenses: ExpensModel[] = [];

  constructor(private expensService: ExpensService, private flashMessageService: FlashMessageService,
              private ref: DynamicDialogRef, private validationService: ValidationService,
              private config: DynamicDialogConfig) { }

  ngOnInit(): void {
    if (this.config.data?.item) {
      this.item = this.config.data.item;
    }

    this.validationService.errorsData.subscribe(response => {
      if (response) {
        this.errors = response;
      }
    });
    this.expensService.getExpensAction({isActive: true});
    this.expensService.getExpenses().subscribe(response => {
      this.expenses = response;
    });
  }

  async save(item = null): Promise<void> {
    this.errors = [];
    this.submitted = true;

    if (item) {
      await this.expensService.update(item).then(response => {
        if (response !== undefined) {
          this.expensService.getExpensAction();
          // this.ref.close();
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
        }
      });
    } else {
      await this.expensService.save(this.item).then(response => {
        if (response !== undefined) {
          this.ref.close();
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS_CREATED'));
        }
      });
    }

    this.submitted = false;
  }

  close(): void {
    this.ref.close();
  }


}
