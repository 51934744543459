<form (submit)="save()">

  <p-table [value]="expenses" dataKey="id" editMode="row" [tableStyle]="{'min-width': '50rem'}">
    <ng-template pTemplate="header">
      <tr>
        <th style="width:80%">{{'NAME' | translate}}</th>
        <th style="width:20%"></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-product let-editing="editing" let-ri="rowIndex">
      <tr [pEditableRow]="product">
        <td>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input pInputText type="text" [(ngModel)]="product.name" required (change)="product.name = $event.target.value">
            </ng-template>
            <ng-template pTemplate="output">
              {{product.name}}
            </ng-template>
          </p-cellEditor>
        </td>
        <td>
          <div class="flex align-items-center justify-content-center gap-2">
            <button *ngIf="!editing" pButton pRipple type="button" pInitEditableRow icon="pi pi-pencil"  class="p-button-rounded p-button-text"></button>
            <button *ngIf="editing" pButton pRipple type="button" pSaveEditableRow icon="pi pi-check" (click)="save(product)"  class="p-button-rounded p-button-text p-button-success mr-2"></button>
            <button *ngIf="editing" pButton pRipple type="button" pCancelEditableRow icon="pi pi-times"  class="p-button-rounded p-button-text p-button-danger"></button>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
  <div class="p-fluid">
    <div class="p-field">
      <label for="name">{{'NAME' | translate}}</label>
      <input id="name" name="name" type="text" pInputText [(ngModel)]="item.name" autofocus>
      <small class="p-error">{{ errors['name'] }}</small>
    </div>
  </div>
  <div class="p-grid"[style]="{marginTop:'20px'}">
    <div class="p-col-4 p-offset-2">
      <button pButton type="reset" (click)="close()" [style]="{width: '100%', height:'45px',background: 'none',color: '#444444', borderColor: '#444444'}" label="{{'CANCEL' | translate}}" icon="pi pi-times" class="p-mr-2"></button>
    </div>
    <div class="p-col-4">
      <button pButton type="submit" [style]="{width: '100%', height:'45px'}" label="{{'SAVE' | translate}}" [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" [disabled]="this.submitted"></button>
    </div>
  </div>
</form>
