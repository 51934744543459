import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from '../../environments/environment';
import {CurrencyModel} from '../model/currency.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient) { }

  async login(email: string, password: string): Promise<any> {
    return await this.http.post(`${environment.apiUrl}/api/login_check`, {
      email,
      password
    }).toPromise().then(response => {

      console.log(response);
      // @ts-ignore
      window.localStorage.setItem('user', JSON.stringify(response));
      // @ts-ignore
      window.localStorage.setItem('token', response.token);
      // @ts-ignore
      window.localStorage.setItem('actions', JSON.stringify(response.actions));
      // @ts-ignore
      response.company.currency['@id'] = `${CurrencyModel.IRI}/${response.company.currency.id}`;
      // @ts-ignore
      window.localStorage.setItem('currency', JSON.stringify(response.company.currency));
      // @ts-ignore
      window.localStorage.setItem('language', response.language);
      // @ts-ignore
      window.localStorage.setItem('company', JSON.stringify(response.company));
      // @ts-ignore
      window.localStorage.setItem('locations', JSON.stringify(response.locations));


      return response;
    }).catch(error => {
      return error;
    });
  }

  async loginWithToken(token: string): Promise<any> {
    return await this.http.get(`${environment.apiUrl}/api/login?token=${token}`).toPromise().then(response => {
      // @ts-ignore
      window.localStorage.setItem('user', JSON.stringify(response));
      // @ts-ignore
      window.localStorage.setItem('token', response.token);
      // @ts-ignore
      window.localStorage.setItem('permissions', JSON.stringify(response.permissions));
      // @ts-ignore
      window.localStorage.setItem('actions', JSON.stringify(response.actions));
      // @ts-ignore
      response.company.currency['@id'] = `${CurrencyModel.IRI}/${response.company.currency.id}`;
      // @ts-ignore
      window.localStorage.setItem('currency', JSON.stringify(response.company.currency));
      // @ts-ignore
      window.localStorage.setItem('language', response.language);
      // @ts-ignore
      window.localStorage.setItem('company', JSON.stringify(response.company));
      // @ts-ignore
      window.localStorage.setItem('role', JSON.stringify(response.roles[0]));
      // @ts-ignore
      window.localStorage.setItem('contact', JSON.stringify(response.contact));
      // @ts-ignore
      window.localStorage.setItem('locations', JSON.stringify(response.locations));


      return response;
    }).catch(error => {
      return error;
    });
  }

  async checkLogin(): Promise<any> {
    const token = window.localStorage.getItem('token');
    let r = null;

    await this.http.get(`${environment.apiUrl}/api/check_token/${token}`).toPromise().then(response => {
      r = response;
    });

    return r;
  }
}
