<app-flash-messages></app-flash-messages>

<div class="container" style="display: none">
  <div class="left-sidebar">
    <!-- Logo Alanı -->
    <div class="logo">
      <img *ngIf="!labelShow" class="mobil-hide" src="assets/img/logo-qapera.svg" height="40px" alt="" style="margin-top:0px">
      <img *ngIf="labelShow" class="mobil-hide" src="assets/img/q-logo.png" width="40px" alt="" style="margin-top:0px;">

      <img src="assets/img/menu-close.png" *ngIf="!labelShow" (click)="sidebarIcon()" alt="">
      <img src="assets/img/menu-close.png" *ngIf="labelShow" (click)="sidebarIcon()" alt="">

    </div>

    <!-- Menü Alanı -->
    <div class="menu">
      <!-- Right Sidebar'ı açıp kapatma butonu -->
      <button (click)="toggleRightSidebar()">Toggle Right Sidebar</button>
      <div (mouseover)="sidebarShort === true ? sidebarWideOpen() :  ''" (mouseleave)="sidebarShort === true ? sidebarShortOpen(): ''" class="">
        <p-panelMenu [transitionOptions]="'300ms'" [multiple]="false" [model]="mainMenu" *ngIf="!labelShow"></p-panelMenu>
        <p-panelMenu [transitionOptions]="'300ms'" [multiple]="false" [model]="mainMenuIcon" *ngIf="labelShow"></p-panelMenu>
      </div>
    </div>

    <!-- Kullanıcı Alanı -->
    <div class="user-info">
      <img src="assets/user.png" alt="User Image" class="user-image">
      <p class="user-name">John Doe</p>
      <p class="user-role">Administrator</p>
    </div>
  </div>

  <div [ngClass]="{'content-expanded': !rightSidebarOpen, 'content-normal': rightSidebarOpen}">
    <!-- Orta İçerik -->
    Main Content Area
  </div>

  <div *ngIf="rightSidebarOpen" class="right-sidebar">
    <!-- Sağ Sidebar İçeriği -->
    Right Sidebar
  </div>
</div>
<div *ngIf="isLogged && hasInitialSetupPage === false && hasPaymentPage === false">
  <div class="p-sm-1 p-md-2 p-lg-2 p-xl-2 q-sidebar" >
    <div class="logo1">
      <div clas="logoImage">
        <img *ngIf="!labelShow" class="mobil-hide" src="assets/img/logo-qapera.svg" width="200px" style="margin-top:0px">
        <img *ngIf="labelShow" class="mobil-hide" src="assets/img/q-logo.png" width="40px" style="margin-top:0px;">
      </div>
      <div style="width: 100%">
        <button pButton pRipple (click)="sidebarIcon()" type="button"
                icon="{{ sidebarShort === true ? 'pi pi-angle-double-right' :  'pi pi-angle-double-left'}}"
                [style]="{marginLeft: '20px'}"
                class="p-button-rounded p-button-text sidebar-icon">
        </button>
      </div>
    </div>

    <div (mouseover)="sidebarShort === true ? sidebarWideOpen() :  ''" (mouseleave)="sidebarShort === true ? sidebarShortOpen(): ''" class="" style="margin-top: 00px;overflow: scroll;height: 75%;">
      <p-panelMenu [transitionOptions]="'300ms'" [multiple]="false" [model]="mainMenu" *ngIf="!labelShow"></p-panelMenu>
      <p-panelMenu [transitionOptions]="'300ms'" [multiple]="false" [model]="mainMenuIcon" *ngIf="labelShow"></p-panelMenu>
    </div>
    <div class="avatar">
      <div class="p-grid">
        <div [class]="!labelShow? 'p-col-6': 'p-col-12'" style="width: 50px;margin-left: 30px;">
          <p-avatar image="assets/img/avatar.png" shape="circle" size="large" [style]="{marginTop:'10px'}"></p-avatar>
        </div>
        <div [class]="!labelShow? 'p-col-6': 'p-col-12'" style="width: 150px"><p>{{ user.firstName }} {{ user.lastName }}<br>
          <span *ngIf="contact !== undefined ">{{ company.name }}</span>
        </p></div>
      </div>
    </div>
  </div>
  <div class="p-sm-12 p-md-12 p-lg-12 p-xl-12 q-header" *ngIf="isLogged && hasInitialSetupPage === false">
    <div class="p-sm-6 p-md-4 p-lg-2 p-xl-2" style="display: flex;align-items: center;">
      <i (click)="onLeftSidebarDisplay()" [style]="{fontSize: '24px',color: '#00b4d2'}" class="pi pi-align-justify"></i>
      <img class="mobil-show" src="assets/img/logo-qapera.svg" alt="" height="35" style="margin-left: 10px"></div>
    <div class="p-sm-1 p-md-4 p-lg-7 p-xl-7 dp-flex mobil-hide">
    <p-chip [style]="{marginRight: '3px',background: '#efbb03'}"  class="sidebar-icon" [removable]="true" *ngIf="inventoryClosingDelay && inventoryClosingDelay  < 4 && inventoryClosingDelay  > -1">
      <i class="pi pi-exclamation-triangle"></i> <b [style]="{marginLeft: '7px',marginRight: '7px'}">{{ 'INVENTORY_CLOSING_ERROR_TITLE_WARNING' | translate }} {{ inventoryClosingDelay }} {{ 'DAY' | translate }}</b>
    </p-chip>

      <p-chip [style]="{marginRight: '3px',background: '#cb3734'}"  class="sidebar-icon" [removable]="true" *ngIf="inventoryClosingDelay && inventoryClosingDelay  < 0">
        <i class="pi pi-exclamation-triangle"></i> <b [style]="{marginLeft: '7px',marginRight: '7px'}">{{ 'INVENTORY_CLOSING_ERROR_TITLE' | translate }} {{ inventoryClosingDelay }} {{ 'INVENTORY_CLOSING_ERROR_TITLE2' | translate }}</b>
      </p-chip>

      <p-chip [style]="{marginRight: '3px',cursor: 'pointer',background: '#efbb03'}" class="sidebar-icon" (click)="deleteDemoData()"  [removable]="true" *ngIf="isLogged && !company.isDemoDataDeleted">
        <i class="{{ loading ? 'pi pi-spin pi-spinner' : 'pi pi-trash' }}" ></i> <b [style]="{marginLeft: '7px',marginRight: '7px'}">{{ 'DELETE_DEMO_DATA'|translate }}</b>
      </p-chip>

      <p-chip [style]="{marginRight: '3px'}" class="sidebar-icon" [removable]="true" *ngIf="dayCount <= 0">
        <i class="pi pi-microsoft"></i> <b [style]="{marginLeft: '7px',marginRight: '7px'}">{{'ACTIVE_USAGE_PERIOD_EXPIRED' | translate}}</b>
      </p-chip>
    </div>
    <div class="p-sm-6 p-md-4 p-lg-3 p-xl-3 dp-flex flex-end">
      <a href="https://calendly.com/qaperailetanis/qaperainventorymanagement" target="_blank">
        <p-button icon="pi pi-calendar"label="{{ 'SET_MEETING' | translate}}" [style]="{marginLeft: '5px'}"></p-button>
      </a>
      <p-button icon="pi pi-bell" [style]="{marginLeft: '5px'}" (click)="onRightSidebarDisplay()">

        <span  class="CountNotification ng-binding {{notification.length > 0 ? 'CountNotificationScale' : ''}}" appendTo="body">{{ notification.length}}</span>
      </p-button>
      <p-button icon="pi pi-question" [style]="{marginLeft: '5px'}"></p-button>
    </div>
  </div>
  <div class="p-sm-1 p-md-10 p-lg-10 p-xl-10 q-main">
    <router-outlet *ngIf="isLogged"></router-outlet>
  </div>
</div>

<div class="wrapper" *ngIf="isLogged && hasInitialSetupPage === false">
  <!--
  <div class="sidebar" (mouseover)="sidebarShort === true ? sidebarWideOpen() :  ''" (mouseleave)="sidebarShort === true ? sidebarShortOpen(): ''" >
   <div class="logo1">
      <div clas="closeIcon">
        </div>
      <div clas="logoImage">
        <img *ngIf="!labelShow" src="assets/img/logo-qapera.svg" width="200px" style="margin-top:0px">
      </div>
    </div>
    <div class="sidebar-wrapper" style="margin-top: 00px;">
      <p-panelMenu [transitionOptions]="'300ms'" [multiple]="false" [model]="mainMenu" *ngIf="!labelShow"></p-panelMenu>
      <p-panelMenu [transitionOptions]="'300ms'" [multiple]="false" [model]="mainMenuIcon" *ngIf="labelShow"></p-panelMenu>
    </div>
    <div style="margin-bottom:20px;" class="avatar">

        <div style="margin-left: -30px">
          <p-avatar image="assets/img/avatar.png" shape="circle" size="large" [style]="{float:'right'}"></p-avatar>
        </div>
        <div style="margin-left: 10px">
          <p>{{ user.firstName }} {{ user.lastName }}<br>
          <span *ngIf="contact !== undefined ">{{ company.name }}</span>
          <span *ngIf="contact.name === '' ">{{ contact }}</span>
          </p>
        </div>

    </div>
  </div>
  --->
</div>

<router-outlet *ngIf="isLogged === false || hasInitialSetupPage === true || hasPaymentPage === true"></router-outlet>
<p-sidebar  *ngIf="notification.length > 0" [(visible)]="rightSidebarDisplay" position="right" [baseZIndex]="10000" [style]="{width:'30em'}">
  <h3>{{'NOTICES' | translate}}</h3>
  <div *ngFor="let item of notification">
    <div class="notification-main">
      <div class="notification-icon">
        <i class="pi pi-exclamation-triangle"></i>
      </div>
      <div class="notification-text">
        <b style="font-size: 12px;">{{item.body.error | translate}}</b>
        <span style="font-size: 10px;">{{item.body.name | translate}}</span>
      </div>
      <div class="notification-button">
        <button pButton pRipple type="button" icon="pi pi-times" class="p-button-rounded p-button-danger" (click)="removeNotification(item.id)"></button>
      </div>
    </div>
  </div>
</p-sidebar>

<p-sidebar [(visible)]="leftSidebarDisplay" [baseZIndex]="10000" [style]="{width:'22em'}">
  <p-panelMenu *ngIf="leftSidebarDisplay" [transitionOptions]="'300ms'" [multiple]="false" [model]="mainMenu"></p-panelMenu>
</p-sidebar>

<p-dialog header="{{'POS_INTEGRATION_ERROR' | translate}}" [(visible)]="displayMatchError" [style]="{width: '50vw'}">
  <div class="notification-main">
    <div class="notification-icon">
      <i style="font-size: 40px;" class="pi pi-exclamation-triangle"></i>
    </div>
    <div class="notification-text">
      <b style="font-size: 18px;">{{ 'POS_INTEGRATION_ERROR_TITLE'  | translate}}</b>
      <span style="font-size: 16px;">{{ 'POS_INTEGRATION_ERROR_DESCRIPTION' | translate}}</span>
    </div>
  </div>
  <div style="margin-left: 10%;">
    <h3>{{'SOLUTION_SUGGESTION' | translate}}</h3>
    <ul>
      <li>{{'POS_INTEGRATION_ERROR_HELP1' | translate}}</li>
      <li>{{'POS_INTEGRATION_ERROR_HELP2' | translate}}</li>
      <li>{{'POS_INTEGRATION_ERROR_HELP3' | translate}}</li>
    </ul>
  </div>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="displayMatchError=false" label="Ok" styleClass="p-button-text"></p-button>
    <p-button icon="pi pi-search" (click)="rightSidebarDisplay=true" label="{{'WHICH_PRODUCT' | translate}}" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>
<p-dialog header="{{'INVENTORY_CLOSING_ERROR' | translate}}" [focusTrap]="true" [(visible)]="inventoryClosingDelayDisplay" [style]="{width: '50vw'}">
  <div class="notification-main">
    <div class="notification-icon">
      <i style="font-size: 50px;" class="pi pi-exclamation-triangle"></i>
    </div>
    <div class="notification-text">
      <b style="font-size: 24px;    margin-top: 20px;">{{ 'INVENTORY_CLOSING_ERROR_TITLE'  | translate}} {{inventoryClosingDelay}} {{ 'INVENTORY_CLOSING_ERROR_TITLE2'  | translate}}</b>
      <span style="font-size: 16px;margin-top:10px;margin-bottom: 20px;">{{ 'INVENTORY_CLOSING_ERROR_DESCRIPTION' | translate}}</span>
    </div>
  </div>
  <div style="margin-left: 10%;" *ngIf="activeInventory">
    <h3>{{'OPENED_INVENTORY_INFO' | translate}}</h3>
    {{activeInventory.code}} ( {{activeInventory.startDate | date: 'Y-MM-dd' }} - {{activeInventory.endDate | date: 'Y-MM-dd'}})
    <!--
    <ul>
      <li>{{'POS_INTEGRATION_ERROR_HELP1' | translate}}</li>
      <li>{{'POS_INTEGRATION_ERROR_HELP2' | translate}}</li>
      <li>{{'POS_INTEGRATION_ERROR_HELP3' | translate}}</li>
    </ul>
    -->
</div>
</p-dialog>


