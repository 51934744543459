<p-card>
<div class="p-grid">
  <div class="p-col-6 p-md-6 p-sm-6"><h3 [style]="{ fontWeight: '600' }">{{ 'CREDIT_REMINDER' | translate}}</h3></div>
  <div class="p-col-6 p-md-6 p-sm-6">
    <app-page-info [pageName]="'CREDIT_REMINDER'"></app-page-info>
  </div>
</div>
<div class="p-grid">
  <div class="p-col-12 p-md-6 p-lg-2">

  </div>
  <div class="p-col-12 p-md-6 p-lg-2">

  </div>
  <div class="p-col-12 p-md-6 p-lg-2">

  </div>
</div>
<div class="p-grid">
  <div class="p-col-12" style="overflow-x: auto;">

    <p-table [value]="credits" [tableStyle]="{'min-width': '50rem'}">
      <ng-template pTemplate="header">
        <tr>
          <th [style]="{width: '10%'}">{{ 'DATE' | translate}}</th>
          <th [style]="{width: '10%'}">{{ 'SUPPLIER' | translate}}</th>
          <th [style]="{width: '10%'}">{{ 'DOCUMENT_TYPE' | translate}}</th>
          <th [style]="{width: '10%'}">{{ 'DOCUMENT_NUMBER' | translate}}</th>
          <th [style]="{width: '10%'}">{{ 'CREDIT_TYPE' | translate}}</th>
          <th [style]="{width: '20%'}">{{ 'PRODUCT_NAME' | translate}}</th>
          <th [style]="{width: '10%'}">{{ 'QUANTITY' | translate}}</th>
          <th [style]="{width: '10%'}">{{ 'CREDIT' | translate}}</th>
          <th [style]="{width: '10%'}">{{ 'GRAND_TOTAL' | translate}}</th>
          <th [style]="{width: '10%'}">{{ 'TAX_RATE' | translate}}</th>
          <th [style]="{width: '10%'}">{{ 'TAX_VALUE' | translate}}</th>
          <th [style]="{width: '10%'}">{{'STATUS'| translate}}</th>
          <th [style]="{width: '10%'}">{{'WAS_SENT'| translate}}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-credit>
        <tr>
          <td [style]="{padding:'0px'}" colspan="13">
            <a [routerLink]="'/order/receive-manuel/' + credit.invoice.id ">
            <p-table [value]="credit.items">
              <ng-template pTemplate="body" let-row>
                <tr>
                  <td [style]="{width: '10%',padding:'5px'}">{{credit.createdAt |date : 'd/M/Y'}}</td>
                  <td [style]="{width: '10%',padding:'5px'}">{{credit.contact.name}}</td>
                  <td [style]="{width: '10%',padding:'5px'}">{{credit.invoice['@type']}}</td>
                  <td [style]="{width: '10%',padding:'5px'}"></td>
                  <td [style]="{width: '10%',padding:'5px'}">{{row.type | translate}}</td>
                  <td [style]="{width: '20%',padding:'5px'}">{{row.stock.name}}</td>
                  <td [style]="{width: '10%',padding:'5px'}">{{row.quantity}}</td>
                  <td [style]="{width: '10%',padding:'5px'}">{{(row.variant.price - (row.newPrice ? row.newPrice : 0)) | countryCurrency}}</td>
                  <td [style]="{width: '10%',padding:'5px'}">{{row.quantity * (row.variant.price - (row.newPrice ? row.newPrice : 0)) | countryCurrency}}</td>
                  <td [style]="{width: '10%',padding:'5px'}">{{ row.stock.tax.name}}</td>
                  <td [style]="{width: '10%',padding:'5px'}">{{(row.quantity * (row.variant.price - (row.newPrice ? row.newPrice : 0))) *(row.stock.tax.rate / 100)| countryCurrency}}</td>
                  <td [style]="{width: '10%',padding:'5px'}"></td>
                  <td [style]="{width: '10%',padding:'5px'}"></td>
                </tr>
              </ng-template>
            </p-table>
            </a>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
</p-card>
<p-dialog [(visible)]="showCreditDetailDialog" [style]="{width: '60vw'}">
  <p-table [value]="creditItems">
    <ng-template pTemplate="header">
      <tr>
        <th [style]="{width: '10%'}">{{ 'CODE' | translate}}</th>
        <th [style]="{width: '20%'}">{{ 'PRODUCT_NAME' | translate}}</th>
        <th [style]="{width: '10%'}">{{ 'QUANTITY' | translate}}</th>
        <th [style]="{width: '10%'}">{{ 'PRICE' | translate}}</th>
        <th [style]="{width: '10%'}">{{ 'GRAND_TOTAL' | translate}}</th>
        <th [style]="{width: '10%'}">{{ 'TAX_RATE' | translate}}</th>
        <th [style]="{width: '10%'}">{{ 'TAX_VALUE' | translate}}</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-i="rowIndex" let-creditItem>
      <tr>
        <td>{{ creditItem.variant.code }}</td>
        <td>{{ creditItem.stock.name }}</td>
        <td>{{ creditItem.quantity }}</td>
        <td>{{ creditItem.variant.price | countryCurrency}}</td>
        <td>{{ creditItem.quantity * creditItem.variant.price | countryCurrency }}</td>
        <td>{{ creditItem.stock.tax.name }}</td>
        <td>{{ creditItem.quantity * creditItem.variant.price * (creditItem.stock.tax.rate / 100) | countryCurrency }}</td>
      </tr>
    </ng-template>
  </p-table>
</p-dialog>
