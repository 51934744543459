  <div *ngIf="!loading">
    <div class="p-grid">
      <div class="p-col-2 p-offset-8">
        <h4>Toplam</h4>
        <span class="total-price">{{ total | countryCurrency }}</span> </div>

    </div>
    <div class="p-mb-5" style="display: none">
      <p-inplace closable closeIcon="pi pi-trash">
        <ng-template pTemplate="display">
          <button pButton [label]="'COPY_PRODUCT_RECIPE'|translate" icon="pi pi-copy" class="p-button-outlined"  iconPos="left"></button>
        </ng-template>
        <ng-template pTemplate="content">
          <p-autoComplete field="name" [suggestions]="stocks" (onSelect)="loadRecipe($event)" (completeMethod)="searchStock($event)"></p-autoComplete>
        </ng-template>
      </p-inplace>
    </div>
    <p-table class="p-mt-5" [value]="recipe.items" dataKey="id" responsiveLayout="scroll" styleClass="p-datatable-striped">
      <ng-template pTemplate="header">
        <tr>
        <th *ngFor="let variantOption of stock.stockVariantOptions">
          {{ variantOption.name }}
        </th>
          <th [style]="{width:'10%'}">{{ 'SKU' | translate}} </th>
          <th [style]="{width:'30%'}">{{ 'STOCK' | translate}}<app-tooltip description="PRODUCT_RECIPE_ITEM_TOOLTIP"></app-tooltip></th>
          <th [style]="{width:'10%'}">{{ 'AMOUNT' | translate}} <app-tooltip description="PRODUCT_RECIPE_QUANTITY_TOOLTIP"></app-tooltip></th>
          <th [style]="{width:'10%'}">{{ 'WASTAGE' | translate}} <app-tooltip description="PRODUCT_RECIPE_WASTAGE_TOOLTIP"></app-tooltip></th>
          <th [style]="{width:'10%'}">{{ 'AMOUNT_WITH_WASTAGE' | translate}} <app-tooltip description="PRODUCT_RECIPE_AMOUNT_WASTAGE_TOOLTIP"></app-tooltip></th>
          <th [style]="{width:'10%'}">{{ 'UNIT' | translate}}</th>
          <th [style]="{width:'10%'}">{{ 'COST' | translate}} <app-tooltip description="PRODUCT_RECIPE_COST_TOOLTIP"></app-tooltip></th>
          <th [style]="{width:'10%'}">{{ 'CURRENCY' | translate}}</th>
          <th [style]="{width:'10%'}">{{ 'NOTE' | translate}} <app-tooltip description="PRODUCT_RECIPE_NOTES_TOOLTIP"></app-tooltip></th>
          <th [style]="{width:'10%'}"></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-item let-i="rowIndex">
        <tr>
          <td pEditableColumn *ngFor="let variantOption of stock.stockVariantOptions;let j = index">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-multiSelect [options]="variantOption.optionValues"  [(ngModel)]="item.productRecipeItemVariantOptions[j].variantOptionValues" optionLabel="name" optionValue="@id"></p-multiSelect>
              </ng-template>
              <ng-template pTemplate="output">
              <span *ngFor="let v of variantOption.optionValues">
                <span *ngFor="let k of item.productRecipeItemVariantOptions[j]?.variantOptionValues">
                  {{ k === v['@id'] ? v.name : '' }}
                </span>
              </span>
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            {{ autoCompleteValues[i]?.code }}
          </td>
          <td pEditableColumn class="{{ hasErrors('stock', i) ? 'has-error': '' }}">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-autoComplete [(ngModel)]="autoCompleteValues[i]" (onSelect)="onVariantSelect($event, i)" field="name" [suggestions]="variants" (completeMethod)="searchVariant($event)"></p-autoComplete>
              </ng-template>
              <ng-template pTemplate="output">
                {{ autoCompleteValues[i]?.name }}
                <a *ngIf="autoCompleteValues[i]" [routerLink]="'/stocks/' + autoCompleteValues[i].stock.id + '/edit'" target="_blank"><i class="pi pi-link"></i></a>
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn class="{{ hasErrors('amount', i) ? 'has-error': '' }}">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-inputNumber mode="decimal"  maxFractionDigits="5" minFractionDigits="5"   [(ngModel)]="item.amount" (ngModelChange)=" item.amountWithWastage = calculateAmountWithWastage(item.amount, item.wastage); item.cost = calculateCost(autoCompleteValues[i].averageCost, item.amountWithWastage, autoCompleteValues[i].totalCost, autoCompleteValues[i].stock?.isMaking);"></p-inputNumber>
              </ng-template>
              <ng-template pTemplate="output">
                {{ item.amount }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn class="{{ hasErrors('wastage', i) ? 'has-error': '' }}">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <p-inputNumber prefix="%" mode="decimal"  maxFractionDigits="5" minFractionDigits="2" [(ngModel)]="item.wastage" (ngModelChange)="item.amountWithWastage = calculateAmountWithWastage(item.amount, item.wastage);item.cost = calculateCost(autoCompleteValues[i].averageCost, item.amountWithWastage, autoCompleteValues[i].totalCost, autoCompleteValues[i].stock?.isMaking);"></p-inputNumber>
              </ng-template>
              <ng-template pTemplate="output">
                %{{ item.wastage }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            {{ item.amountWithWastage }}
          </td>
          <td>
            {{ autoCompleteValues[i]?.stock?.unit?.name }}
          </td>
          <td>
            {{ item.cost|currency: (currencies[i] ? currencies[i]: currency): '' }}
          </td>
          <td *ngIf="item.id">
            {{ currencies[i] ? currencies[i]: currency }}
            {{ rates[i] ? '('+rates[i]+')' : '' }}
          </td>
          <td pEditableColumn *ngIf="!item.id">
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText name="rate" [(ngModel)]="rates[i]" (change)="onCurrencyChange(rates[i], i)"  />
              </ng-template>
              <ng-template pTemplate="output">
                {{ currencies[i] ? currencies[i]: currency }}
                {{ rates[i] ? '('+rates[i]+')' : '' }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td pEditableColumn>
            <p-cellEditor>
              <ng-template pTemplate="input">
                <input pInputText name="note" [(ngModel)]="item.note" />
              </ng-template>
              <ng-template pTemplate="output">
                {{ item.note }}
              </ng-template>
            </p-cellEditor>
          </td>
          <td>
            <button pButton label="" icon="pi pi-trash" class="p-button-outlined p-button-danger" iconPos="left" (click)="removeItem(i)"></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
    <div class="p-fluid p-pt-3 p-mb-3">
      <button pButton label="" icon="pi pi-plus" class="p-button-outlined" (click)="addItem()" iconPos="left"></button>
    </div>
    <button pButton label="{{'SAVE' | translate}}" class="p-mb-3" [icon]="submitted ? 'pi pi-spin pi-spinner' : 'pi pi-check'" [disabled]="this.submitted" (click)="save()"></button>
    <button *ngIf="recipe.isNeedRecalculate" pButton label="{{'RECALCULATE' | translate}}" class="p-ml-3 p-mb-3" [icon]="recalculating ? 'pi pi-spin pi-spinner' : 'pi pi-refresh'" [disabled]="this.recalculating" (click)="recalculate()"></button>
  </div>

