import {Component, OnDestroy, OnInit} from '@angular/core';
import {HttpService} from '../../service/http.service';
import {ConfirmationService, LazyLoadEvent} from 'primeng/api';
import {ContactModel, ContactType} from '../../model/contact.model';
import {TranslateService} from '@ngx-translate/core';
import {UtilityService} from '../../service/utility.service';
import {FlashMessageService} from '../../service/flash-message.service';
import {FlashMessage, FlashMessageModel} from '../../model/flash-message.model';
import {DialogService} from 'primeng/dynamicdialog';
import {AddressComponent} from '../address/address.component';
import {Subscription} from 'rxjs';
import {CompanyIntegrationModel} from '../../model/company-integration.model';
import {ContactService} from '../../service/contact.service';
import {CategoryModel} from '../../model/category.model';
import {CategoryService} from '../../service/category.service';
import {TaxRateModel} from '../../model/tax-rate.model';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import {first} from 'rxjs/operators';
import {ContactCreateDialogComponent} from './contact-create-dialog/contact-create-dialog.component';
import {ContactDeliveryAddressModel} from '../../model/contact-delivery-address.model';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss'],
  providers: [DialogService]
})
export class ContactComponent implements OnInit, OnDestroy {
  items: ContactModel[] = [];
  item: ContactModel = new ContactModel();
  loading: boolean;
  showForm: boolean;
  submitted: boolean;
  total: number;
  entities = [];
  errors = [];
  companyIntegration: CompanyIntegrationModel[] = [];

  parameters = {
    id: '',
    code: '',
    name: '',
    agency: '',
    agencyNumber: '',
    email: '',
    phoneNumber: '',
    page: 1,
    itemsPerPage: 20,
    pagination: true,
    'order[name]': 'ASC',
  };

  paymentTerms = [
    {name: 'Cash', value: 'cash'},
    {name: 'Days From Doc.Date ', value: 'daysFromDocDate'},
    {name: 'Days From End Month ', value: 'daysFromEndMonth'},
    {name: 'Days From Following Month ', value: 'daysFromFollowingMonth'},
  ];
  contactType: ContactType;

  contactTypes: [{ name: any; value: any }, { name: any, value: any }];

  validationSubscriber: Subscription;
  showFormModal: boolean;
  daysCheckBox = false;
  moreLoading: boolean;
  first = 0;
  private actions: any;
  displayAction: boolean;
  deliverAddressDisplay: boolean;
  clonedProducts: any[];
  products1: any[];
  products2: any[];
  statuses: any[];
  needReload: boolean;

  constructor(private http: HttpService, private confirmationService: ConfirmationService,
              private translate: TranslateService, private utilityService: UtilityService,
              private flashMessageService: FlashMessageService, private dialogService: DialogService,
              private contactService: ContactService, private categoryService: CategoryService) {
  }

  ngOnInit(): void {
    this.loading = false;
    this.load();
    this.entities.push('contact');
    this.http.get(CompanyIntegrationModel.IRI).subscribe((response: CompanyIntegrationModel[]) => {
      this.companyIntegration = response['hydra:member'];
    });
    this.contactTypes = [
      {name: 'Tedarikçi', value: 'supplier'},
      {name: 'Müşteri', value: 'customer'},
    ];
  }

  load(event: LazyLoadEvent = null): void {
    if (event) {
      this.parameters.page = event.first / this.parameters.itemsPerPage + 1;
      this.first = event.first;
    }

    if (event && event.rows) {
      this.parameters.itemsPerPage = event.rows;
    }
    this.parameters = {...this.parameters};

    // @ts-ignore
    this.parameters = Object.fromEntries(Object.entries(this.parameters).filter(([_, v]) => v !== ''));

    this.loading = true;
    this.showForm = history.state.openForm;
    this.contactService.getContactsAction(this.parameters);
    this.contactService.getContacts().subscribe(response => {
      if (response) {
        this.items = response;

        this.loading = false;
      }
      this.contactService.getTotal().subscribe(total => {
        this.total = total;
      });
    });


  }

  search(): void {
    if (this.parameters.name) {
      this.parameters.page = 1;
    }
    this.first = 0;
    this.load();
  }

  delete(event, row): void {
    if (this.checkActionPermission('CAN_DELETE_SUPPLIER')) {
      this.confirmationService.confirm({
        target: event.target,
        message: this.translate.instant('DELETE_SUPPLIER'),
        header: this.translate.instant('ALERT'),
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: this.translate.instant('YES'),
        rejectLabel: this.translate.instant('NO'),
        accept: () => {
          this.http.delete(`${ContactModel.IRI}/${row.id}`).then(() => {
            this.items = this.items.filter(val => val.id !== row.id);
          });
          this.load();
        }
      });
    } else {
      this.displayAction = true;
    }
  }

  export(): void {
    const parameters = {...this.parameters};
    delete parameters.page;
    delete parameters.id;

    this.http.download('/api/data/exports/contacts', parameters)
      .subscribe(r => {
        this.utilityService.downloadFile(r, 'contacts.xlsx');
      });
  }

  openAddressDialog(id: number, address: string): void {
    const ref = this.dialogService.open(AddressComponent, {
      data: {id: address},
      width: '50vw',
      header: this.translate.instant('ADDRESS'),
    });

    ref.onClose.subscribe(response => {
      if (response && response.saved) {
        this.http.put(`${ContactModel.IRI}/${id}`, {address: response.id}).then((contactModel: ContactModel) => {
          if (contactModel) {
            this.load();
            this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS_CREATED'));
          }
        });
      }
    });
  }

  openDeliverAddressDialog(row): void {
    this.item = row;
    this.deliverAddressDisplay = true;
  }

  showFormModalDisplay(): void {
    if (this.checkActionPermission('CAN_ADD_SUPPLIER')) {
      this.item = new ContactModel();
      this.showFormModal = true;
    } else {
      this.displayAction = true;
    }
  }

  showFormModalDisplayHide(): void {
    this.showFormModal = false;
  }

  onChangePaymentTerms(event): void {
    if (event.value !== 'cash') {
      this.daysCheckBox = true;
    } else {
      this.daysCheckBox = false;
    }
  }

  async save(): Promise<void> {
    console.log('dwedwe', this.item.deliveryAddresses.length );
    this.submitted = true;

    if (this.item.address) {
      this.item.address = this.item.address['@id'];
    }
    if (this.item.deliveryAddresses.length > 0) {
      console.log(this.item.deliveryAddresses.length );
      this.item.deliveryAddresses.map((value, i) => {
        this.item.deliveryAddresses[i] = value['@id'];
      });
    }
    if (this.item.id) {
      await this.contactService.update(this.item).then(response => {
        if (response !== undefined) {
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
          this.showFormModal = false;
          this.load();
        }
      });
    } else {
      await this.contactService.save(this.item).then(response => {
        if (response !== undefined) {
          this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success, 'SUCCESS_CREATED'));
          this.showFormModal = false;
          this.load();
        }
      });
    }

    this.submitted = false;
  }

  hideForm(): void {
    this.showFormModal = false;
  }

  ngOnDestroy(): void {
    this.errors = [];
    this.contactService.contacts.next([]);
  }

  generatePdf(): void {
    const content = document.getElementById('pdf-content') as HTMLElement; // PDF içeriği
    const buttonToRemove = document.getElementById('button'); // Kaldırmak istediğiniz butonun CSS sınıfını belirtin

    if (buttonToRemove) {
      // @ts-ignore
      buttonToRemove.style.display = 'none'; // Butonu gizle
    }
    html2canvas(content).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF();
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', 10, 10, pdfWidth * 0.9, pdfHeight);
      if (buttonToRemove) {
        // @ts-ignore
        buttonToRemove.style.display = 'block';
      }
      // @ts-ignore
      pdf.save(`inventory.pdf`); // PDF'i indir
    });
  }

  checkActionPermission(text): boolean {
    this.actions = JSON.parse(localStorage.getItem('actions'));
    if (this.actions) {
      const findIndex = this.actions[0].suppliers.findIndex(obj => obj.name === text);
      return this.actions[0].suppliers[findIndex].value ? this.actions[0].suppliers[findIndex].value : false;
    }
  }

  addContact(item): void {
    this.dialogService.open(ContactCreateDialogComponent, {
      width: '50vw',
      header: this.translate.instant('CONTACT_CREATE'),
      data: {item}
    });
  }

  edit(row: ContactModel): void {
    this.item = {...row};
    this.addContact(this.item);
  }

  onRowEditSave(product): void {
    this.needReload = true;
    if (product.id) {
      this.http.put(`${ContactDeliveryAddressModel.IRI}/${product.id}`, product).then(response => {
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
      });
    } else {
      product.contact = this.item['@id'];
      this.http.post(ContactDeliveryAddressModel.IRI, product).subscribe(response => {
        this.flashMessageService.updateMessages(new FlashMessageModel(FlashMessage.Success));
      });
    }
  }

  onRowEditCancel(product, index: number): void {
    this.products2[index] = this.clonedProducts[product.id];
    delete this.clonedProducts[product.id];
  }

  addItem(): void {
    const item = new ContactDeliveryAddressModel();
    // @ts-ignore
    this.item.deliveryAddresses.push(item);
  }

  reload(): void {
    if (this.needReload === true){
      this.load();
      this.needReload = false;
    }
    this.deliverAddressDisplay = false;
  }
}
