<p-card>
  <app-loading-indicator [loading]=loading></app-loading-indicator>

  <div class="p-grid">
    <div class="p-col-6 p-md-6 p-sm-6">
      <h3 [style]="{ fontWeight: '600' }">{{ 'COOKBOOK' | translate}}</h3>
    </div>
    <div class="p-col-6 p-md-6 p-sm-6">
      <app-page-info [pageName]="'COOKBOOK'"></app-page-info>
      <p-button icon="pi pi-print" [style]="{marginLeft: '5px', float:'right'}"></p-button>
      <button pButton [style]="{marginLeft: '5px', float:'right'}" class="p-button-secondary"
              label="{{'NEW_RECIPE' | translate }}" (click)="onOpenAdd()"></button>
      <button pButton [style]="{marginLeft: '5px', float:'right'}" class="p-button-secondary"
              label="{{'COOK_BOOK_STOCKS' | translate }}" [routerLink]="'/inventory/cook-book/stocks'"></button>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-1" style="width: 110px">
      <button pButton [style]="{float:'left'}" icon="pi pi-microsoft" (click)="listView = true"></button>
      <button pButton [style]="{marginLeft: '15px', float:'left'}" icon="pi pi-bars" (click)="listView = false"></button>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <input pInputText name="name" id="name" [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}" [(ngModel)]="parameters.name">
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-dropdown
        [style]="{width: '100% !important',border:'none',borderBottom: '2px solid #47bfd4'}"
        [options]="categories" appendTo="body" (onChange)="onSelectCategory($event)"
        [(ngModel)]="parameters.category"
        optionLabel="name" optionValue="id" placeholder="{{ 'SEARCH_CATEGORY' | translate }}"
        [filter]="true" filterBy="name">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-2">
      <p-dropdown
        [style]="{width: '100% !important',border:'none',borderBottom: '2px solid #47bfd4'}"
        [options]="sectors" appendTo="body" (onChange)="onSelectSector($event)"
        [(ngModel)]="parameters.sector"
        optionLabel="name" optionValue="id" placeholder="{{ 'SEARCH_SECTOR' | translate }}"
        [filter]="true" filterBy="name">
      </p-dropdown>
    </div>
    <div class="p-col-12 p-md-6 p-lg-1">
      <p-button [style]="{height: '100%'}" type="submit" (click)="load()" label="{{'SEARCH' | translate}}"></p-button>
    </div>
  </div>
  <div class="p-grid">
    <div class="p-col-12 success-div">
      <div class="icon"><i class="pi pi-info"></i></div>
      <div class="text">{{'WARNING_COOKBOOK' | translate}}</div>
    </div>
  </div>
  <div class="p-grid">

    <div class="p-col-12" style="overflow-x: auto;">
      <div class="main-content-div">
        <div>
          <div class="main-content-div-row3">
            <div class="category-div">
              <div class="p-grid" *ngIf="listView === false">
                <p-table [value]="cookBooks" [tableStyle]="{'min-width': '50rem'}"
                         [paginator]="true" [rows]="20" [totalRecords]="total" [lazy]="true" (onLazyLoad)="load($event)"
                         [rowsPerPageOptions]="[10,25,50]">
                  <ng-template pTemplate="header">
                    <tr>
                      <th [style]="{width:'20%'}" pSortableColumn="name">
                        <p-sortIcon field="name"></p-sortIcon>
                        {{ 'NAME' | translate}}</th>
                      <th [style]="{width:'20%'}" pSortableColumn="category.name">
                        <p-sortIcon field="category.name"></p-sortIcon>
                        {{ 'CATEGORY' | translate}}</th>
                      <th [style]="{width:'40%'}">{{ 'DESCRIPTION' | translate}}</th>
                      <th [style]="{width:'14%'}" pSortableColumn="totalCost">
                        <p-sortIcon field="totalCost"></p-sortIcon>
                        {{ 'COST' | translate}}</th>
                      <th [style]="{width:'10%'}" pSortableColumn="cookTime">
                        <p-sortIcon field="cookTime"></p-sortIcon>
                        {{ 'PREP_TIME' | translate}}</th>
                      <th [style]="{width:'10%'}" pSortableColumn="prepTime">
                        <p-sortIcon field="prepTime"></p-sortIcon>
                        {{ 'COOK_TIME' | translate}}</th>
                      <th [style]="{width:'10%'}">{{ 'READY_IN' | translate}}</th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-product>
                    <tr>
                      <td><a href="javascript:void(0)" [style]="{color:'#0edcdc'}"
                             (click)="onOpenEdit(product)">{{product.name}}</a></td>
                      <td>{{product.category?.name}}</td>
                      <td>{{product.cookingInstruction}}</td>
                      <td>{{product.totalCost | countryCurrency}}</td>
                      <td>{{ product.cookTime}}  {{ 'MIN' | translate}}</td>
                      <td>{{product.prepTime}} {{ 'MIN' | translate}}</td>
                      <td>{{product.prepTime + product.cookTime}} {{ 'MIN' | translate}}
                      </td>
                    </tr>
                  </ng-template>
                </p-table>
              </div>
              <div class="p-grid" *ngIf="listView === true">
                <div class="p-col-3" *ngFor="let product of cookBooks">
                  <a href="javascript:void(0)" [style]="{color:'#0edcdc'}"
                     (click)="onOpenEdit(product)">
                  <div class="cookbook-div">

                    <img *ngIf="product.image" src="{{product.image}}" width="100%" class="gradient-image">
                    <img *ngIf="!product.image"
                         src="{{'https://upload.wikimedia.org/wikipedia/commons/a/ac/No_image_available.svg' }}"
                         width="75%">
                    <div class="prep-main">
                      <div class="prep-item">
                        <img src="assets/img/prep-time-fig.svg" width="20" style="color: #fff">
                        <span style="margin-left: 10px">{{product.prepTime}} Dk</span>
                      </div>
                      <div class="prep-item"><img src="assets/img/cook-time-fig.svg" width="20">
                        <span style="margin-left: 10px">{{product.cookTime}} Dk</span>
                      </div>
                      <div class="prep-item"><img src="assets/img/ready-in-fig.svg" width="20">
                        <span style="margin-left: 10px">{{product.prepTime + product.cookTime }} Dk</span>
                      </div>
                    </div>
                    <p [style]="{textAlign:'center',fontSize: '20px',fontWeight: 800}">{{product.name}} / {{product.size}} <br>
                      <span [style]="{textAlign:'center'}">{{product.totalCost | countryCurrency}}</span>
                    </p>

                  </div>
                    </a>
                </div>
                <br>

              </div>
              <div class="p-grid">
                <div class="p-col-12 p-justify-between">
                  <p-paginator [rows]="20" [totalRecords]="total" (onPageChange)="paginate($event)"></p-paginator>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</p-card>
<!--
<p-sidebar [(visible)]="visibleSidebar" position="right" [style]="{width:'90em'}" [baseZIndex]="10000">
  <div class="main-content-div">
    <div class="main-content-div-row1">
      <div class="left-div">
        <div class="search-div">
          <div class="title">Kahvaltılıklar</div>
          <div class="search-div-content"><b [style]="{fontSize: '20px'}">{{ item.name}}</b></div>
        </div>
      </div>
    </div>
    <div class="main-content-div-row3">
      <div class="row-first">
        <div class="icon-div">
          <img src="{{ item.image}}">
        </div>
        <div class="table-div">
          <div class="svg-div-item">
            <div class="svg-div">
              <div class="svg-div-item">
                <div class="svg-div-item-icon">
                  <img src="assets/img/prep-time-fig.svg">
                </div>
                <div class="svg-div-item-text">
                  <b [style]="{fontSize: '30px'}">{{ item.prepTime}}</b>
                  {{ 'MIN' | translate}}
                  <br>
                  {{ 'PREP_TIME' | translate}}
                </div>
              </div>
            </div>
            <div class="svg-div">
              <div class="svg-div-item">
                <div class="svg-div-item-icon"><img src="assets/img/cook-time-fig.svg"></div>
                <div class="svg-div-item-text">
                  <b [style]="{fontSize: '30px'}">{{ item.cookTime}}</b>
                  {{ 'MIN' | translate}}
                  <br>
                  {{ 'COOK_TIME' | translate}}
                </div>
              </div>
            </div>
            <div class="svg-div">
              <div class="svg-div-item">
                <div class="svg-div-item-icon"><img src="assets/img/ready-in-fig.svg"></div>
                <div class="svg-div-item-text">
                  <b [style]="{fontSize: '30px'}">{{ item.prepTime + item.cookTime}}</b>
                  {{ 'MIN' | translate}}
                  <br>
                  {{ 'READY_IN' | translate}}
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>


    </div>
  </div>
</p-sidebar>
-->
<p-sidebar *ngIf="addNewCook" [(visible)]="addNewCook" position="right" [style]="{width:'70em'}" (onHide)="load()">

  <div class="p-mt-3 p-grid">
    <table style="width: 100%">
      <tr>
        <td style="width: 30%"><b>Adı</b></td>
        <td style="width: 70%"><input class="p-mt-2" type="text" pInputText [(ngModel)]="cookBook.name" name="code">
        </td>
      </tr>
      <tr>
        <td style="width: 30%"><b>Sektör</b></td>
        <td>
          <div style="display: flex;justify-content: flex-start;">
            <div style="width: 400px">
              <p-dropdown
                [style]="{width: '100%',border:'none',borderBottom: '2px solid #47bfd4'}"
                [options]="sectors" appendTo="body" (ngModelChange)="onSetSector($event)"
                [(ngModel)]="cookBook.sector" autoZIndex="true"
                optionLabel="name" optionValue="@id" placeholder="{{ 'SELECT_SECTOR'| translate}}"
                [filter]="true" filterBy="name">
              </p-dropdown>
            </div>
            <span class="p-inputgroup-addon" (click)="addSector()"><i class="pi pi-plus"></i></span>
          </div>
        </td>
      </tr>
      <tr>
        <td style="width: 30%"><b>Kategori</b></td>
        <td>


          <div style="display: flex;justify-content: flex-start;">
            <div style="width: 400px">
              <p-dropdown
                [style]="{width: '100% !important',border:'none',borderBottom: '2px solid #47bfd4'}"
                [options]="categories" appendTo="body" (ngModelChange)="onSetCategory($event)"
                [(ngModel)]="cookBook.category" autoZIndex="true"
                optionLabel="name" optionValue="@id" placeholder="{{ 'CATEGORY'| translate}}"
                [filter]="true" filterBy="name">
              </p-dropdown>
            </div>
            <span class="p-inputgroup-addon" (click)="addCategory('cookbook')"><i class="pi pi-plus"></i></span>
          </div>
        </td>
      </tr>
      <tr>
        <td style="width: 30%"><b>Birim</b></td>
        <td>
          <input class="p-mt-2" type="text" pInputText [(ngModel)]="cookBook.size" name="size">
        </td>
      </tr>
      <tr>
        <td style="width: 30%"><b>Hazırlanma Süresi</b></td>
        <td>
          <p-inputNumber mode="decimal" maxFractionDigits="5"
                         [(ngModel)]="cookBook.prepTime"
                         (ngModelChange)="cookBook.prepTime = onSetTime($event)">
          </p-inputNumber>
          {{'MIN' | translate}}
        </td>
      </tr>
      <tr>
        <td style="width: 30%"><b>Pişirme Süresi</b></td>
        <td>
          <p-inputNumber mode="decimal" maxFractionDigits="5"
                         [(ngModel)]="cookBook.cookTime"
                         (ngModelChange)="cookBook.cookTime = onSetTime($event)">
          </p-inputNumber>
          {{'MIN' | translate}}
        </td>
      </tr>
      <tr>
      <tr>
        <td style="width: 30%"><b>Maliyet</b></td>
        <td>
          <b [style]="{fontSize: '30px'}">{{ cookBook.totalCost  | countryCurrency}}</b>
        </td>
      </tr>
    </table>
    <div class="p-col-12 p-grid">
      <div class="p-col-4"><b>Tarif</b></div>
      <div class="p-col-8"> <textarea type="text"
                                      [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                                      pInputText placeholder="{{ 'DESCRIPTION'| translate }}"
                                      [(ngModel)]="cookBook.cookingInstruction"
                                      (ngModelChange)="cookBook.cookingInstruction = onSetDescription($event)">
      </textarea></div>
    </div>

    <div class="p-col-12 p-grid">
      <div class="p-col-4"><b>Resim</b></div>
      <div class="p-col-8">
        <img *ngIf="cookBook.image" src="{{cookBook.image}}" (click)="showBasicDialog()" width="200" class="gradient-image">
        <img *ngIf="!cookBook.image" src="assets/img/cloud.png" (click)="showBasicDialog()" width="200" class="gradient-image">
      </div>
    </div>

  </div>
  <div class="main-content-div">
    <div class="p-grid">
      <div class="p-col-12" [style]="{marginTop: '20px'}">
        <p-table [value]="cookBook.items" styleClass="p-datatable-sm">
          <ng-template pTemplate="header">
            <tr>
              <th [style]="{width:'30%'}" scope="col">{{ 'STOCK' | translate}}</th>
              <th [style]="{width:'10%'}" scope="col">{{ 'UNIT' | translate}}</th>
              <th [style]="{width:'10%'}" scope="col">{{ 'PRICE' | translate}}</th>
              <th [style]="{width:'10%'}" scope="col">{{ 'RECIPE_AMOUNT' | translate}} </th>
              <th [style]="{width:'10%'}" scope="col">{{ 'COST' | translate}}</th>
              <th [style]="{width:'5%'}" scope="col"></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-item let-i="rowIndex">
            <tr>
              <td pEditableColumn>
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-autoComplete appendTo="body"
                                    [style]="{width: '100%',border:'none', borderBottom: '2px solid #47bfd4'}"
                                    (onSelect)="onVariantSelect($event, i)" field="name" [suggestions]="variants"
                                    (completeMethod)="searchVariant($event)"></p-autoComplete>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{cookBook.items[i].cookBookStock?.name}}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td [style]="{textAlign: 'right'}">
                {{cookBook.items[i].cookBookStock?.unit}}
              </td>
              <td [style]="{textAlign: 'right'}">
                {{cookBook.items[i].cookBookStock?.price | countryCurrency}}
              </td>
              <td pEditableColumn [style]="{textAlign: 'right'}">

                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <p-inputNumber mode="decimal" maxFractionDigits="5" minFractionDigits="3"
                                   placeholder="{{item.unit}}" [(ngModel)]="cookBook.items[i].amount"
                                   (ngModelChange)="onInputRecipe($event,i)">
                    </p-inputNumber>
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ cookBook.items[i].amount | number: '1.2-3' }}
                  </ng-template>
                </p-cellEditor>
              </td>
              <td [style]="{textAlign: 'right'}">
                {{ (cookBook.items[i].amount * cookBook.items[i].cookBookStock?.price) | countryCurrency}}
              </td>
              <td [style]="{textAlign: 'right'}">
                <i class="pi pi-trash" [style]="{ color: '#ff2525'}"
                   (click)="removeItem(i)"></i>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
    <div [style]="{marginTop: '20px'}">
      <button pButton label="{{'ADD_STOCK' | translate}}" class=" add-button "
              [icon]="'pi pi-plus'"
              (click)="addItem()"></button>
      <button pButton label="{{'SAVE' | translate}}" class="p-ml-3" (click)="onConsole()"></button>

      <button pButton label="{{'NEW_STOCK' | translate}}" class="p-ml-3 add-button "
              [icon]="'pi pi-plus'"
              (click)="addStock()"></button>
    </div>
  </div>
</p-sidebar>

<p-dialog header="" [(visible)]="displayBasic" [style]="{width: '50vw'}">
  <div class="cropper add-div" style="display: flex;flex-direction: column;">
    <div class="file-input">
      <input type="file" (change)="fileChangeEvent($event)"/>
    </div>

    <div class="img-cropper" style="display: flex;flex-direction: row;width: 100%;
    justify-content: center;
    height: 400px;
    align-items: center;">
      <div class="cropper-before" style="height: 100%;width: 100%">
        <image-cropper
          [imageChangedEvent]="imageChangedEvent"
          [maintainAspectRatio]="true"
          [aspectRatio]="4/3"
          [resizeToWidth]="800"
          format="png"
          [transform]="transform"
          (imageCropped)="imageCropped($event)"
          (imageLoaded)="imageLoaded()"
          (cropperReady)="cropperReady()"
          (loadImageFailed)="loadImageFailed()"
        ></image-cropper>
      </div>
      <!---
      <div class="zoom-area">
        <img [src]="croppedImage" />

      </div>
      --->
    </div>
    <div style="margin-top: 40px;">
      <p-slider [(ngModel)]="scale" [min]="0.1" [max]="2" [step]="0.01" (onChange)="zoomIn()"></p-slider>
    </div>

  </div>
  <ng-template pTemplate="footer">
    <p-button icon="pi pi-check" (click)="onloadImage()" label="Upload" styleClass="p-button-text"></p-button>
    <p-button icon="pi pi-check" (click)="displayBasic=false;" label="Ok" styleClass="p-button-text"></p-button>
  </ng-template>
</p-dialog>
